import { CancelIcon } from 'common/assets';
import HOCInputControl from 'components/common/hoc-input-control';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import TextInput from 'components/elements/text-input';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import { Translate } from 'next-translate';
import React from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import * as Yup from 'yup';

import { ItemType, OutcomeFormType } from '../form-type';

interface Props {
  idx: number;
  t: Translate;
  control: Control<OutcomeFormType, any>;
  getValues: UseFormGetValues<OutcomeFormType>;
  setValue: UseFormSetValue<OutcomeFormType>;
  close: () => void;
}

const containerStyle = {
  overflow: 'auto',
  height: 'calc( 100vh - 132px)',
  padding: '0px 16px ',
};
const footerStyle = {
  backgroundColor: 'white',
  padding: '16px 16px',
  borderTop: '1px solid rgba(0, 0, 0, 0.04)',
  width: '100%',
};
type StockItemFormType = {
  items: ItemType[];
};
const formSchema = () =>
  Yup.object().shape({
    items: Yup.array().of(
      Yup.object({
        description: Yup.string().nullable(),
        merk: Yup.string().nullable(),
        files: Yup.array(
          Yup.object({
            url: Yup.string().required(),
            file: Yup.string().required(),
          }),
        ),
      }),
    ),
  });

export default function ItemPanel(props: Props) {
  const { control, getValues, idx, setValue, t, close } = props;
  const defaultValue = getValues(`stockPurchaseItems.${idx}.items`);

  const value = useWatch({
    name: `stockPurchaseItems.${idx}.detail`,
    control,
  });

  const resolver = useYupValidationResolver(formSchema());
  const methods = useForm<StockItemFormType>({
    mode: 'onChange',
    resolver,
    defaultValues: {
      items: defaultValue,
    },
  });

  return (
    <Form
      methods={methods}
      onSubmit={(value) => {
        setValue(`stockPurchaseItems.${idx}.items`, value.items);
        close();
      }}
    >
      <div style={containerStyle}>
        {defaultValue?.map((_, index) => (
          <div className={moduleStyles.itemContainer} key={index}>
            <HOCInputControl
              control={control}
              keys={[`stockPurchaseItems.${idx}.items.${index}.barcode`]}
            >
              {({
                [`stockPurchaseItems.${idx}.items.${index}.barcode`]: value,
              }) => (
                <TextInput
                  value={value || ''}
                  required
                  disabled
                  withAsterisk
                  label={t('inventory:barcode')}
                />
              )}
            </HOCInputControl>
            <div className={moduleStyles.sectionContainer}>
              <div className={moduleStyles.halfContainer}>
                <TextInput
                  value={value?.name}
                  required
                  disabled
                  withAsterisk
                  label={t('common:name')}
                />
              </div>
              <div className={moduleStyles.halfContainer}>
                <Input
                  type="text"
                  label={t('inventory:merk')}
                  placeholder={t('common:enter_extra', {
                    extra: t('inventory:merk'),
                  })}
                  name={`items.${index}.merk`}
                />
              </div>
            </div>
            <Input
              type="text-area"
              label={t('common:description')}
              placeholder={t('common:enter_extra', {
                extra: t('common:description'),
              })}
              name={`items.${index}.description`}
            />

            <Input
              type="files"
              previewKey="url"
              valueKey="file"
              label={t('inventory:picture')}
              name={`items.${index}.files`}
            />
            <Input
              type="checkbox"
              name={`items.${index}.isUsed`}
              label={t('inventory:is_used')}
            />
          </div>
        ))}
      </div>
      <div
        className={moduleStyles.row({
          justify: 'end',
        })}
        style={footerStyle}
      >
        <Button
          error
          variant="secondary"
          leftIcon={(size) => <CancelIcon size={size} />}
          onClick={close}
        >
          {t('common:cancel')}
        </Button>
        <Separator gap={16} />
        <Input type="submit" />
      </div>
    </Form>
  );
}
