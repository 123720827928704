import { AccountLiteModel } from 'api-hooks/account';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { useEntity } from 'hooks/use-entities';
import { useTableViewDetailColumn } from 'modules/common/table';
import Balance from 'modules/entity-settings/common/components/balance';
import BalanceWithDetails from 'modules/entity-settings/common/components/balance-with-details';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

function useEntityAccountsColumns({ t, entity }) {
  const _columns = React.useMemo<IColumn<AccountLiteModel>[]>(
    () => [
      {
        header: t('common:account_name'),
        accessorKey: 'name',
        minSize: 200,
        sortName: 'name',
      },
      {
        header: t('common:currency'),
        accessorKey: 'currencyName',
        minSize: 250,
        sortName: 'currencyName',
      },
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 250,
        sortName: 'description',
      },
      {
        header: t('common:balance'),
        accessorKey: 'balance',
        minSize: 250,
        textAlign: 'right',
        sortName: 'balance',
        cell: ({ row }) => {
          const {
            original: { balance, rate, currency },
          } = row;
          return currency !== entity?.currency ? (
            <BalanceWithDetails
              currency={currency}
              balance={balance}
              rate={rate}
              entityCurrency={entity?.currency}
            />
          ) : (
            <Balance currency={currency} balance={balance} />
          );
        },
      },
    ],
    [entity?.currency, t],
  );

  return useTableViewDetailColumn(_columns, 'AccountView', 'AccountsView');
}

export default function EntityAccountsInformation(props: {
  data: AccountLiteModel[];
}) {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const columns = useEntityAccountsColumns({ t, entity });

  return (
    <div style={{ marginTop: 48 }}>
      <Text textVariant="HeadingSmall">{t('entity:accounts')}</Text>
      <Text textVariant="BodyDefault" color={colors.textLight} mt={8}>
        {t('entity:accounts_description')}
      </Text>
      <Separator direction="vertical" gap={36} />
      <div className={moduleStyles.tableContainer}>
        <TableComponent data={props.data} columns={columns} />
      </div>
    </div>
  );
}
