import Separator from 'components/common/separator';
import React from 'react';

import ApprovalHistoryTable from './approval-history-table';
import ApprovalInformationForm from './approval-information-form';
import ApprovalFormHeader from './form-header';
import { ApprovalFormProps } from '../form-type';

export default function ApprovalForm(props: ApprovalFormProps) {
  const { approval } = props;

  return (
    <>
      <ApprovalFormHeader approval={approval} />
      <ApprovalInformationForm approval={approval} />
      <Separator direction="vertical" gap={48} />
      <ApprovalHistoryTable
        data={approval.approvalHistories}
        user={approval.employee.name}
      />
    </>
  );
}
