import { EntityCurrencyLiteModel } from 'api-hooks/entity-currency/model';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import TableComponent from 'components/elements/table';
import Text from 'components/elements/text';
import useEntityCurrencyColumns from 'modules/entity-settings/entity-currency/helpers/use-columns';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const EntityCurrencyInformation = (props: {
  data: EntityCurrencyLiteModel[];
}) => {
  const { t } = useTranslation();

  const columns = useEntityCurrencyColumns();

  return (
    <div>
      <Text textVariant="HeadingSmall">{t('common:currency')}</Text>
      <Text textVariant="BodyDefault" color={colors.textLight} mt={8}>
        {t('entity:currency_description')}
      </Text>
      <Separator direction="vertical" gap={36} />

      <TableComponent data={props.data} columns={columns || []} />
    </div>
  );
};

export default EntityCurrencyInformation;
