import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Text from 'components/elements/text';
import { useEntity } from 'hooks/use-entities';
import BrandMultiAllSelect from 'modules/entity-settings/brand/components/multi-all-select-input';
import { moduleStyles } from 'modules/styles.css';
import TransferDetailForm from 'modules/transactions/transfer/components/transfer-detail-form';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const TransferApprovalInformationForm = ({
  receivedAmount,
}: {
  receivedAmount: number;
}) => {
  const { t } = useTranslation();
  const { entity } = useEntity();

  return (
    <div>
      <Text textVariant="HeadingSmall">{t('common:transfer_approval')}</Text>
      <Separator gap={32} direction="vertical" />
      <div className={moduleStyles.halfContainer}>
        <Input
          type="date-time"
          label={t('common:received_at')}
          name="receiveAt"
          required
        />
      </div>
      <section>
        <Text textVariant="HeadingSmall">
          {t('transfer:transaction_details')}
        </Text>
        <Separator gap={48} direction="vertical" />
        <div className={moduleStyles.halfContainer}>
          <Input
            type="date-time"
            label={t('common:transaction_at')}
            name="transactionAt"
            required
            disabled
          />
        </div>
        <div className={moduleStyles.sectionContainer}>
          <div className={moduleStyles.halfContainer}>
            <Input
              label={t('common:description')}
              placeholder={t('common:enter_extra', {
                extra: t('common:description'),
              })}
              name="description"
              type="text"
              disabled
            />
          </div>
          <div className={moduleStyles.halfContainer}>
            <BrandMultiAllSelect
              name="brands"
              label={t('common:brand')}
              disabled
              entityId={entity?.id}
            />
          </div>
          <div />
        </div>
      </section>
      <Separator
        gap={54}
        direction="vertical"
        style={{ borderTop: `1px solid ${colors.dividerDefault}` }}
      />
      <TransferDetailForm hasValue isDisabled receivedAmount={receivedAmount} />
    </div>
  );
};

export default TransferApprovalInformationForm;
