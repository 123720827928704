import { getIndexInput } from 'api-hooks/common/model';

// Model
export class WarehouseLiteModel {
  id: string;
  name: string;
  address: string;
  regional: string;
}

export class WarehouseModel extends WarehouseLiteModel {}

// Input
export type getWarehouseInput = {
  warehouseId: string;
};

export type getWarehousesInput = getIndexInput;

export type WarehouseMutationInput = {
  entityId: string;
  regional: string;
  address: string;
  name: string;
};

export type WarehouseUpdateInput = {
  warehouseId: string;
  body: WarehouseMutationInput;
};

export type WarehouseDeleteInput = {
  warehouseId: string;
};
