import CollapsibleComponent from './components/collapsible-component';
import LinkComponent from './components/link-component';
import { MappedRoute } from './side-navigation';
interface Props {
  route: MappedRoute;
  depth: number;
  badges?: any;
  withLeftBorder?: boolean;
}

export default function NavigationComponent(props: Props) {
  const { route, depth, badges, withLeftBorder } = props;

  if (route.subNav) {
    return (
      <CollapsibleComponent route={props.route} depth={depth} badges={badges} />
    );
  }
  return (
    <LinkComponent
      route={props.route}
      depth={depth}
      badges={badges}
      withLeftBorder={withLeftBorder}
    />
  );
}
