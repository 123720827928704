import { StockItemModel } from 'api-hooks/stock-item';
import * as Yup from 'yup';

export type StockGroupingItem = {
  stockId: string;
  stockItemDetail?: StockItemModel;
  stockItemId: string;
  qty?: number;
};

export type StockGroupingFormType = {
  stockId: string;
  stockItemId: string;
  stockItemDetail?: StockItemModel;
  stockGroupingItems: StockGroupingItem[];
};

const schema = {
  stockId: Yup.string().required().nullable(),
  stockItemId: Yup.string().required().nullable(),
  stockItemDetail: Yup.object().strip(),
};

export const formSchema = () =>
  Yup.object().shape({
    ...schema,
    stockGroupItems: Yup.array().of(Yup.object(schema)),
  });
