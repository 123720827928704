import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getStockGroupingInput,
  getStockGroupingsInput,
  StockGroupingLiteModel,
  StockGroupingModel,
} from './model';

export const stockGroupingKey = {
  listKey: 'getStockGroupings',
  detailKey: 'getStockGrouping',
  list(input?: getStockGroupingsInput) {
    return [stockGroupingKey.listKey, input?.params];
  },
  detail(input?: getStockGroupingInput) {
    return [stockGroupingKey.detailKey, input?.stockGroupingId];
  },
};

export function useGetStockGroupings(
  input?: getStockGroupingsInput,
  options?: UseQueryOptions<
    ExtendedApiResult<StockGroupingLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<StockGroupingLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockGroupingKey.list(input),
      () =>
        QueryFetchFunction({
          url: 'stock-groupings',
          params: input?.params,
        }),
      options,
    ),
    StockGroupingLiteModel,
  );
}

export function useGetStockGrouping(
  input?: getStockGroupingInput,
  options?: UseQueryOptions<ApiResult<StockGroupingModel>, ApiError>,
): UseQueryResult<ApiResult<StockGroupingModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockGroupingKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `stock-groupings/${input?.stockGroupingId}`,
        }),
      options,
    ),
    StockGroupingModel,
  );
}
