import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import { TransferApprovalApproveInput, TransferApprovalModel } from './model';

export function useApproveTransferApproval(
  options?: UseMutationOptions<
    ApiResult<TransferApprovalModel>,
    ApiError,
    TransferApprovalApproveInput
  >,
): UseMutationResult<
  ApiResult<TransferApprovalModel>,
  ApiError,
  TransferApprovalApproveInput
> {
  return useMutation<
    ApiResult<TransferApprovalModel>,
    ApiError,
    TransferApprovalApproveInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `transfer-approvals/${body.transferId}/approve`,
      method: 'POST',
      body: body.body,
      classType: TransferApprovalModel,
    });
  }, options);
}
