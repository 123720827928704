import { Menu } from '@mantine/core';
import { PermissionCategoryModel, PermissionGroupModel } from 'api-hooks/role';
import classNames from 'classnames';
import {
  ChevronDownIcon,
  EditIcon,
  EyeIcon,
  EyeSlashIcon,
  UserSquareIcon,
} from 'common/assets';
import Separator from 'components/common/separator';
import Checkbox from 'components/elements/checkbox';
import Text from 'components/elements/text';
import { Translate } from 'next-translate';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { roleMenuStyles } from './styles.css';

interface Props extends PermissionCategoryModel {
  t: Translate;
  editable: boolean;
}

interface RoleMenuMenuProps {
  permissionGroups: PermissionGroupModel[];
  t: Translate;
  onChange: (value: string) => void;
  value: string;
  editable: boolean;
}
interface RoleMenuItemProps extends React.ComponentPropsWithoutRef<'button'> {
  _id: string;
  name: string;
  t: Translate;
  isSelected?: boolean;
  isTarget?: boolean;
  editable: boolean;
}

const CUSTOM_LOCALES: {
  key: string;
  accessor: string;
}[] = [
  {
    key: 'employee.reports-transaction:restricted-view',
    accessor: 'restricted-view-transaction-report-desc',
  },
  {
    key: 'employee.reports-transaction:unrestricted-view',
    accessor: 'unrestricted-view-transaction-report-desc',
  },
  {
    key: 'employee.expenses:restricted-view',
    accessor: 'restricted-view-expenses-desc',
  },
  {
    key: 'employee.incomes:restricted-view',
    accessor: 'restricted-view-incomes-desc',
  },
  {
    key: 'employee.export-histories:restricted-view',
    accessor: 'restricted-view-export-histories-desc',
  },
];

function getRoleItemIcon(size: number, role: string) {
  switch (role) {
    case 'viewer':
    case 'unrestricted-view':
      return <EyeIcon {...{ size }} />;
    case 'restricted-view':
      return <EyeSlashIcon {...{ size }} />;
    case 'manager':
      return <UserSquareIcon {...{ size }} />;
    case 'editor':
      return <EditIcon {...{ size }} />;
    default:
      return <EyeIcon {...{ size }} />;
  }
}
const RoleMenuItem = React.forwardRef<HTMLButtonElement, RoleMenuItemProps>(
  (props: RoleMenuItemProps, ref) => {
    const { name, _id, t, isTarget, isSelected, editable, ...rest } = props;
    const role = React.useMemo(() => _id.slice(_id.indexOf(':') + 1), [_id]);

    const locale = React.useMemo(() => {
      const find = CUSTOM_LOCALES.filter((locale) => locale.key === _id)?.[0];
      if (find) {
        return t(`role:${find.accessor}`);
      }
      switch (role) {
        case 'restricted-view':
        case 'unrestricted-view':
        case 'manager':
          return t(`role:${role}-desc`, {
            extra: t(
              `role:${_id.slice(_id.indexOf('.') + 1, _id.indexOf(':'))}${
                role === 'manager' ? '-desc' : ''
              }`,
            ),
          });
        default:
          return t(`role:${role}-desc`);
      }
    }, [_id, role, t]);

    return (
      <button
        type="button"
        ref={ref}
        {...rest}
        className={classNames(
          roleMenuStyles.itemContainer,
          isTarget
            ? roleMenuStyles.targetContainer
            : isSelected
            ? roleMenuStyles.activeContainer
            : roleMenuStyles.inactiveContainer,
          !editable && roleMenuStyles.disabled,
        )}
      >
        <span>{getRoleItemIcon(24, role)}</span>
        <Separator gap={16} direction="horizontal" />
        <span>
          <Text textVariant="BodyDefault">{t(`role:${role}`)}</Text>
          <Text textVariant="BodySmaller" className="description">
            {locale}
          </Text>
        </span>
        {isTarget && (
          <span className={roleMenuStyles.chevronContainer}>
            <ChevronDownIcon />
          </span>
        )}
      </button>
    );
  },
);

function RoleMenuMenu(props: RoleMenuMenuProps) {
  const { permissionGroups, t, value, onChange, editable } = props;
  const selected = React.useMemo<PermissionGroupModel>(
    () => permissionGroups?.filter((item) => item.id === value)?.[0],
    [permissionGroups, value],
  );

  if (selected) {
    return (
      <Menu shadow="md" radius={4} position="bottom-start" disabled={!editable}>
        <Menu.Target>
          <RoleMenuItem
            _id={selected?.id}
            name={selected?.name}
            t={t}
            isTarget
            editable={editable}
          />
        </Menu.Target>
        <Menu.Dropdown className={roleMenuStyles.dropdown}>
          {permissionGroups.map((role) => (
            <Menu.Item onClick={() => onChange(role.id)} key={role.id}>
              <RoleMenuItem
                _id={role.id}
                name={role.name}
                t={t}
                isSelected={role.id === value}
                editable={editable}
              />
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    );
  }
  return <></>;
}

export default function RoleMenu(props: Props) {
  const { category, permissionGroups, editable } = props;
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: category });
  const [isChecked, setIsChecked] = React.useState<boolean>(!!field.value);

  return (
    <>
      <Checkbox
        label={
          <Text
            textVariant="BodyDefault"
            className={roleMenuStyles.checkboxLabel}
          >
            {category}
          </Text>
        }
        disabled={!editable}
        checked={isChecked}
        readOnly
        onClick={() => {
          setIsChecked((prev) => {
            // eslint-disable-next-line no-unused-expressions
            prev
              ? field.onChange('')
              : field.onChange(permissionGroups?.[0].id);
            return !prev;
          });
        }}
      />
      <Separator gap={16} direction="vertical" />
      {isChecked && (
        <RoleMenuMenu
          {...props}
          onChange={(value) => {
            setIsChecked(true);
            field.onChange(value);
          }}
          value={field.value}
        />
      )}
    </>
  );
}
