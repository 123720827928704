import { moduleStyles } from 'modules/styles.css';
import React from 'react';

import TransferDefaultView from './default-view';
import TransferUpdateRate from './update-rate';

export default function TransferList() {
  const [isUpdateRate, setIsUpdateRate] = React.useState<boolean>(false);

  return (
    <div className={moduleStyles.container}>
      {isUpdateRate ? (
        <TransferUpdateRate setIsUpdateRate={setIsUpdateRate} />
      ) : (
        <TransferDefaultView setIsUpdateRate={setIsUpdateRate} />
      )}
    </div>
  );
}
