import {
  useDeleteDivision,
  getDivisionsKey,
  getDivisionsByEmployeeKey,
} from 'api-hooks/division';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { DivisionFormProps, DivisionFormType } from './form-type';

export default function DivisionForm(props: DivisionFormProps) {
  const { t } = useTranslation();
  const { division } = props;
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeleteDivision();
  const { can } = useAuthorization();
  const dialog = useDialog();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().nullable().required(),
    }),
  );

  const intialValues = React.useMemo<DivisionFormType>(() => {
    return {
      name: division?.name || '',
    };
  }, [division]);

  const methods = useForm<DivisionFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const _input = {
          ...values,
          entityId: entity?.id,
        };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            divisionId: division?.id!,
          });
          queryClient.refetchQueries([getDivisionsKey()[0]]);
          queryClient.refetchQueries([getDivisionsByEmployeeKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, division?.id, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!division}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={division}
          title={
            division
              ? `${t('common:division')} - ${division?.name}`
              : t('common:create_extra', { extra: t('common:division') })
          }
          noDelete={!can(AuthorizationRules.DivisionsDelete)}
          noEdit={!can(AuthorizationRules.DivisionsUpdate)}
        />
        <div className={moduleStyles.halfContainer}>
          <Input
            type="text"
            label={t('common:division')}
            placeholder={t('common:enter_extra', {
              extra: t('common:division'),
            })}
            name="name"
            required
          />
        </div>
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
}
