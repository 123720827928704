import React from 'react';

import { ReportQueryFilterProps } from './query-filter';

interface QueryFilterState extends ReportQueryFilterProps {}

export const reportQueryFilterContext = React.createContext<QueryFilterState>({
  filters: [],
  setFilters() {},
});
