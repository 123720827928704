import { Menu } from '@mantine/core';
import {
  NotificationModel,
  getNotificationsKey,
  useGetNotifications,
  useUpdateNotification,
} from 'api-hooks/notification';
import classNames from 'classnames';
import { BellIcon } from 'common/assets';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import { ActionIcon, Button } from 'components/elements/button';
import Text from 'components/elements/text';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import Item from './item';
import { notificationStyles } from './styles.css';

export default function Notification() {
  const { t } = useTranslation();
  const { mutateAsync } = useUpdateNotification();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
  } = useGetNotifications(
    {
      params: {
        limit: 3,
      },
    },
    {
      getNextPageParam: (lastPage) => {
        if (
          (lastPage.meta as any).last_page ===
          (lastPage.meta as any).current_page
        ) {
          return null;
        }
        return (lastPage.meta as any).current_page + 1;
      },
      refetchInterval: 60000,
    },
  );

  const currentData = React.useMemo<NotificationModel[]>(() => {
    if (data?.pages) {
      return data?.pages?.reduce((prev, cur) => {
        return [...prev, ...cur.data] as any;
      }, []);
    }
    return [];
  }, [data?.pages]);

  const handleReadAll = React.useCallback(async () => {
    try {
      await mutateAsync({
        body: {
          ids: currentData.map((item) => item.id),
        },
      });
      queryClient.refetchQueries([getNotificationsKey()[0]]);
    } catch {}
  }, [currentData, mutateAsync]);

  return (
    <Menu position="bottom-end" offset={20} closeOnItemClick>
      <Menu.Target>
        <ActionIcon variant="transparent">
          {(size) => (
            <div>
              <BellIcon size={size} color={colors.black} />
              {!!data?.pages?.[0]?.count && (
                <div className={notificationStyles.indicator} />
              )}
            </div>
          )}
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown className={notificationStyles.container}>
        <div className={notificationStyles.header}>
          <Button
            variant="tertiary"
            className={notificationStyles.button}
            onClick={handleReadAll}
            disabled={
              isFetchingNextPage ||
              isFetching ||
              isLoading ||
              !currentData.filter((item) => item.isRead === false).length
            }
          >
            {t('common:read_all')}
          </Button>
        </div>

        <div className={notificationStyles.itemContainer}>
          {!currentData?.length ? (
            <Separator gap={16} direction="vertical">
              <div className={notificationStyles.emptyContainer}>
                <Text textVariant="BodyDefault" color={colors.textLight}>
                  {t('common:there_is_no_notification')}
                </Text>
              </div>
            </Separator>
          ) : (
            <>
              {(currentData || []).map((item) => (
                <Menu.Item
                  closeMenuOnClick
                  style={{
                    backgroundColor: 'transparent',
                    padding: 0,
                  }}
                  key={item.id}
                >
                  <Item {...item} />
                </Menu.Item>
              ))}
            </>
          )}
        </div>
        <div className={notificationStyles.footer}>
          <Button
            variant="tertiary"
            className={classNames(
              moduleStyles.fullContainer,
              notificationStyles.button,
            )}
            disabled={
              isFetchingNextPage || isFetching || isLoading || !hasNextPage
            }
            onClick={() => fetchNextPage()}
          >
            {t('common:show_more')}
          </Button>
        </div>
      </Menu.Dropdown>
    </Menu>
  );
}
