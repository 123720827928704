import { Filter, FilterType } from 'api-hooks/common/model';
import { FilterIcon } from 'common/assets';
import TagPillComponent from 'components/common/query-filter/tag-pill';
import { Button } from 'components/elements/button';
import DatePicker from 'components/elements/date-picker';
import TextInput from 'components/elements/text-input';
import { format } from 'date-fns';
import produce from 'immer';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import styles from 'styles/Filter.module.css';

import ReportFilterControl from './filter-control';
import { reportQueryFilterContext } from './query-filter.context';
import { reportStyle } from './style.css';
import AccountSelectInput from '../select/account-select-input';
import BrandMultiSelectInput from '../select/brand-multi-select-input';
import ChartOfAccountMultiSelectInput from '../select/chart-of-account-multi-select-input';
import ChartOfAccountsSelectInput from '../select/chart-of-account-select-input';
import WarehouseSelectInput from '../select/warehouse-select-input';

const { Provider } = reportQueryFilterContext;

export interface ReportQueryFilterProps {
  filters: Filter[];
  setFilters: any;
  setParams?: any;
  customFilter?: any;
  compact?: {
    isCompact: boolean;
    requiredKeys?: string[];
  };
}

function DefaultReportQueryFilter({
  setFilters,
  _filters,
  setParams,
  _setFilters,
  requiredKeys,
}: {
  setFilters;
  _filters;
  setParams;
  _setFilters;
  requiredKeys: string[];
}) {
  const { t } = useTranslation();

  return (
    <form
      className={reportStyle.form}
      onSubmit={(e) => {
        e.preventDefault();
        setFilters(_filters);
        setParams?.(_filters.map((item) => ({ [`item.name`]: item.value })));
      }}
    >
      <div className={reportStyle.queryContainer}>
        {_filters.map((filter) => {
          let content: React.ReactNode = null;
          switch (filter.type) {
            case 'text':
              content = (
                <TextInput
                  label={filter.label}
                  onChange={(e) =>
                    _setFilters((prevFilter) => {
                      return produce(prevFilter, (draft) => {
                        const matchedFilter = draft?.find(
                          (f) => f.name === filter.name,
                        );
                        if (matchedFilter) {
                          matchedFilter.value = e.target?.value;
                        }
                      });
                    })
                  }
                  value={(filter.value || filter.default) as any}
                  required={!!requiredKeys.find((curr) => curr === filter.name)}
                />
              );
              if (filter.name === 'account_id') {
                content = (
                  <AccountSelectInput
                    onSelected={(value) =>
                      _setFilters((prevFilter) =>
                        produce(prevFilter, (draft) => {
                          const matchedFilter = draft?.find(
                            (f) => f.name === filter.name,
                          );
                          if (matchedFilter) {
                            if (value) {
                              matchedFilter.value = String(value);
                            } else {
                              matchedFilter.value = '';
                            }
                          }
                        }),
                      )
                    }
                    label={filter.label}
                    value={(filter.value || filter.default) as any}
                    required={
                      !!requiredKeys.find((curr) => curr === filter.name)
                    }
                  />
                );
                break;
              }
              if (filter.name === 'chart_of_account_id') {
                content = (
                  <ChartOfAccountsSelectInput
                    onSelected={(value) =>
                      _setFilters((prevFilter) =>
                        produce(prevFilter, (draft) => {
                          const matchedFilter = draft?.find(
                            (f) => f.name === filter.name,
                          );
                          if (matchedFilter) {
                            if (value) {
                              matchedFilter.value = String(value);
                            } else {
                              matchedFilter.value = '';
                            }
                          }
                        }),
                      )
                    }
                    label={filter.label}
                    value={(filter.value || filter.default) as any}
                    required={
                      !!requiredKeys.find((curr) => curr === filter.name)
                    }
                  />
                );
                break;
              }
              if (filter.name === 'warehouse_id') {
                content = (
                  <WarehouseSelectInput
                    onSelected={(value) =>
                      _setFilters((prevFilter) =>
                        produce(prevFilter, (draft) => {
                          const matchedFilter = draft?.find(
                            (f) => f.name === filter.name,
                          );
                          if (matchedFilter) {
                            if (value) {
                              matchedFilter.value = String(value);
                            } else {
                              matchedFilter.value = '';
                            }
                          }
                        }),
                      )
                    }
                    label={filter.label}
                    value={(filter.value || filter.default) as any}
                    required={
                      !!requiredKeys.find((curr) => curr === filter.name)
                    }
                  />
                );
              }
              break;
            case 'option':
              if (filter.name === 'chart_of_account_ids') {
                content = (
                  <ChartOfAccountMultiSelectInput
                    value={
                      ((filter.value || '').split('|') || filter.default) as any
                    }
                    onSelected={(value) =>
                      _setFilters((prevFilter) =>
                        produce(prevFilter, (draft) => {
                          const matchedFilter = draft?.find(
                            (f) => f.name === filter.name,
                          );
                          if (matchedFilter) {
                            if (value) {
                              const temp =
                                typeof value === 'object'
                                  ? value.join('|')
                                  : value;
                              matchedFilter.value = String(temp);
                            } else {
                              matchedFilter.value = '';
                            }
                          }
                        }),
                      )
                    }
                    required={
                      !!requiredKeys.find((curr) => curr === filter.name)
                    }
                  />
                );
                break;
              }
              if (filter.name === 'brand_ids') {
                content = (
                  <BrandMultiSelectInput
                    value={
                      ((filter.value || '').split('|') || filter.default) as any
                    }
                    onSelected={(value) =>
                      _setFilters((prevFilter) =>
                        produce(prevFilter, (draft) => {
                          const matchedFilter = draft?.find(
                            (f) => f.name === filter.name,
                          );
                          if (matchedFilter) {
                            if (value) {
                              const temp =
                                typeof value === 'object'
                                  ? value.join('|')
                                  : value;
                              matchedFilter.value = String(temp);
                            } else {
                              matchedFilter.value = '';
                            }
                          }
                        }),
                      )
                    }
                    required={
                      !!requiredKeys.find((curr) => curr === filter.name)
                    }
                  />
                );
                break;
              }
              break;
            default:
              content = (
                <DatePicker
                  label={filter.label}
                  clearable={!requiredKeys.find((curr) => curr === filter.name)}
                  onChange={(e) =>
                    _setFilters((prevFilter) => {
                      return produce(prevFilter, (draft) => {
                        const matchedFilter = draft?.find(
                          (f) => f.name === filter.name,
                        );
                        if (matchedFilter) {
                          matchedFilter.value = e?.toISOString();
                        }
                      });
                    })
                  }
                  defaultValue={null}
                  value={
                    filter.value
                      ? new Date(filter.value)
                      : filter.default
                      ? new Date(filter.default)
                      : null
                  }
                  required={!!requiredKeys.find((curr) => curr === filter.name)}
                />
              );
              break;
          }
          return <div className={reportStyle.contentItem}>{content}</div>;
        })}
        <div className={reportStyle.searchContainer}>
          <Button
            type="submit"
            disabled={_filters.some(
              (filter) => requiredKeys.includes(filter.name) && !filter.value,
            )}
          >
            {t('common:search')}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default function ReportQueryFilter(props: ReportQueryFilterProps) {
  const { filters, setFilters, setParams } = props;
  const { t } = useTranslation();
  const [_filters, _setFilters] = React.useState<Filter[]>(filters);

  React.useEffect(() => {
    _setFilters(filters);
  }, [filters, setFilters]);

  React.useEffect(() => {
    props.customFilter &&
      Object.entries(props.customFilter).map((key) => {
        _setFilters((prevFilter) =>
          produce(prevFilter, (draft) => {
            const matchedFilter = draft?.find((f) => f.name === key[0]);
            if (matchedFilter) {
              matchedFilter.value = String(key[1]);
            }
          }),
        );
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!props.compact?.isCompact) {
    return (
      <DefaultReportQueryFilter
        {...{
          _filters,
          _setFilters,
          setFilters,
          setParams,
          requiredKeys: props.compact?.requiredKeys || [],
        }}
      />
    );
  }

  return (
    <div className={styles.filterContainer}>
      <Provider value={{ filters, setFilters }}>
        <div className={styles.filterBaseContainer}>
          {filters.map((filter) => {
            const currentValue = filter.value || filter?.default;

            return currentValue ? (
              <ReportFilterControl key={filter.name} name={filter.name}>
                {(toggleFilter) => (
                  <div className={styles.filterItemContainer}>
                    <TagPillComponent
                      onClick={toggleFilter}
                      onClear={() =>
                        setFilters((prevFilters) =>
                          produce(prevFilters, (draft) => {
                            const matchedFilter = draft?.find(
                              (f) => f.name === filter.name,
                            );
                            if (matchedFilter) {
                              matchedFilter.value = undefined;
                            }
                          }),
                        )
                      }
                      text={`${filter.label}:
                      ${
                        filter.type === FilterType.Date
                          ? format(new Date(currentValue!), 'dd MMMM yyyy')
                          : currentValue
                      }`}
                    />
                  </div>
                )}
              </ReportFilterControl>
            ) : null;
          })}
          <ReportFilterControl>
            {(toggleFilter) => (
              <Button
                leftIcon={(size) => <FilterIcon size={size} />}
                onClick={toggleFilter}
                variant="secondary"
              >
                {t('common:filter')}
              </Button>
            )}
          </ReportFilterControl>
        </div>
      </Provider>
    </div>
  );
}
