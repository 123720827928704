import { Menu } from '@mantine/core';
import { ActiveBudgetingItemLiteModel } from 'api-hooks/budgeting';
import { CommonSimpleModel } from 'api-hooks/common/model';
import { EditIcon, GearIcon, TransferIcon } from 'common/assets';
import { AuthorizationRules } from 'common/constants';
import { ActionIcon, Button } from 'components/elements/button';
import Text from 'components/elements/text';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import useDrawer from 'hooks/use-drawer';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import ProgressBar from './bar';
import { progressStyle } from './styles.css';
import EditBudgetModal from '../form/view/edit-budget-modal';
import TransferBudgetModal from '../form/view/transfer-budget-modal';

interface Props extends ActiveBudgetingItemLiteModel {
  variant?: 'primary' | 'secondary';
  disabled?: boolean;
  viewOnly?: boolean;
  budgetingId: string;
  employeeToNotifies: CommonSimpleModel[];
  showDetails?: boolean;
}

export default function Progress(props: Props) {
  const { disabled, viewOnly, ...rest } = props;
  const { can } = useAuthorization();
  const { t } = useTranslation();
  const dialog = useDialog();
  const drawer = useDrawer();
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);

  const onClickEdit = React.useCallback(
    () =>
      drawer.showCustom({
        render: (close) => <EditBudgetModal onClose={close} {...rest} />,
        size: 500,
        padding: 'sm',
        title: (
          <Text textVariant="HeadingMedium">{t('budgeting:edit_budget')}</Text>
        ),
      }),
    [drawer, rest, t],
  );

  const onClickTransfer = React.useCallback(
    () =>
      dialog.showCustom({
        render: (close) => <TransferBudgetModal onClose={close} {...rest} />,
      }),
    [dialog, rest],
  );

  return (
    <div className={progressStyle.container}>
      <ProgressBar
        {...{
          t,
          disabled,
          variant: 'secondary',
          ...rest,
          onRenderRight: (
            <>
              {!viewOnly &&
                !disabled &&
                can(AuthorizationRules.BudgetingsStoreBudgetingTransfer) &&
                can(AuthorizationRules.BudgetingsUpdateBudgetingItem) && (
                  <Menu
                    closeOnItemClick
                    closeOnClickOutside
                    withinPortal
                    opened={isMenuOpen}
                    onChange={setIsMenuOpen}
                  >
                    <Menu.Target>
                      <ActionIcon
                        size="small"
                        variant="transparent"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {(size) => <GearIcon size={size} weight="bold" />}
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      {can(
                        AuthorizationRules.BudgetingsUpdateBudgetingItem,
                      ) && (
                        <Menu.Item>
                          <Button
                            leftIcon={(size) => <EditIcon size={size} />}
                            className={moduleStyles.fullContainer}
                            variant="tertiary"
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickEdit();
                              setIsMenuOpen(false);
                            }}
                          >
                            {t('budgeting:edit_budget')}
                          </Button>
                        </Menu.Item>
                      )}
                      {can(
                        AuthorizationRules.BudgetingsStoreBudgetingTransfer,
                      ) && (
                        <Menu.Item>
                          <Button
                            leftIcon={(size) => <TransferIcon size={size} />}
                            variant="tertiary"
                            className={moduleStyles.fullContainer}
                            onClick={(e) => {
                              e.stopPropagation();
                              onClickTransfer();
                              setIsMenuOpen(false);
                            }}
                          >
                            {t('budgeting:transfer_budget')}
                          </Button>
                        </Menu.Item>
                      )}
                    </Menu.Dropdown>
                  </Menu>
                )}
            </>
          ),
        }}
      />
    </div>
  );
}
