import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  StockDeleteInput,
  StockModel,
  StockMutationInput,
  StockUpdateInput,
} from './model';

export function useCreateStock(
  options?: UseMutationOptions<
    ApiResult<StockModel>,
    ApiError,
    StockMutationInput
  >,
): UseMutationResult<ApiResult<StockModel>, ApiError, StockMutationInput> {
  return useMutation<ApiResult<StockModel>, ApiError, StockMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'stocks',
        method: 'POST',
        classType: StockModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateStock(
  options?: UseMutationOptions<
    ApiResult<StockModel>,
    ApiError,
    StockUpdateInput
  >,
): UseMutationResult<ApiResult<StockModel>, ApiError, StockUpdateInput> {
  return useMutation<ApiResult<StockModel>, ApiError, StockUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `stocks/${body.stockId}`,
        method: 'PUT',
        classType: StockModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteStock(
  options?: UseMutationOptions<MessageResult, ApiError, StockDeleteInput>,
): UseMutationResult<MessageResult, ApiError, StockDeleteInput> {
  return useMutation<MessageResult, ApiError, StockDeleteInput>(async function (
    body,
  ) {
    return await MutationFetchFunction({
      url: `stocks/${body.stockId}`,
      method: 'DELETE',
    });
  },
  options);
}
