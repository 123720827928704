import useDebounceValue from 'common/helpers/debounce';
import { moduleStyles } from 'modules/styles.css';
import { useRouter } from 'next/router';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  latName: string;
  lngName: string;
  noMargin?: boolean;
}

export default function Map(props: Props) {
  const { latName, lngName, noMargin } = props;
  const { locale } = useRouter();
  const { control } = useFormContext<any>();
  const { field: lat } = useController({
    control,
    name: latName,
  });
  const { field: lng } = useController({
    control,
    name: lngName,
  });

  const latitude = useDebounceValue(lat.value, 1000);
  const longitude = useDebounceValue(lng.value, 1000);

  const decodeUrl = React.useMemo(
    () =>
      decodeURIComponent(
        encodeURIComponent(
          'https://maps.google.com/maps?q=' +
            latitude.debouncedValue.toString() +
            ',' +
            longitude.debouncedValue.toString() +
            `&hl=${locale}&z=14&output=embed`,
        ),
      ),
    [latitude.debouncedValue, locale, longitude.debouncedValue],
  );

  return (
    <div style={!noMargin ? mb16 : {}}>
      <iframe
        src={decodeUrl}
        title="location-map"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        aria-disabled
        className={moduleStyles.fullContainer}
      />
    </div>
  );
}

const mb16: React.CSSProperties = {
  marginBottom: '16px',
};
