import {
  StockModel,
  StockMutationInput,
  stockKey,
  useDeleteStock,
} from 'api-hooks/stock';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import TextInput from 'components/elements/text-input';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import DivisionSelectInput from 'modules/master/division/components/select-input';
import StockItemCodeSelectInput from 'modules/stock/item-code/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import { StockFormType, formSchema } from './form-type';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  stock?: StockModel;
  onSubmit: (
    input: StockMutationInput,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}

export default function StockForm(props: Props) {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const { stock, navigation } = props;
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteStock();

  const dialog = useDialog();
  const resolver = useYupValidationResolver(formSchema());

  const initialValues = React.useMemo<StockFormType>(
    () => ({
      assetId: stock?.asset.id || '',
      description: stock?.description || '',
      isAsset: stock?.isAsset || false,
      isUsable: stock?.isUsable || false,
      name: stock?.name || '',
      stockItemCodeComponentId: stock?.stockItemCodeComponent?.id || '',
      stockItemCodeGroupId: stock?.stockItemCodeGroup?.id || '',
      stockItemCodeItemId: stock?.stockItemCodeItem?.id || '',
      stockItemCodeStatedId: stock?.stockItemCodeStated?.id || '',
      unit: stock?.unit || '',
    }),
    [stock],
  );

  const methods = useForm<StockFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const _input = {
          ...values,
          entityId: entity?.id,
        };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            stockId: stock!.id,
          });
          queryClient.refetchQueries([stockKey.listKey]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, stock, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!stock}
      setNavigations={setNavigations}
      navigation={navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={stock}
          title={
            stock
              ? `${t('common:stock')} - ${stock?.name}`
              : t('common:create_extra', { extra: t('common:stock') })
          }
          noDelete={!can(AuthorizationRules.StocksDelete)}
          noEdit={!can(AuthorizationRules.StocksUpdate)}
        />
        <div className={moduleStyles.fullContainer}>
          <div className={moduleStyles.halfContainer}>
            {stock?.code && (
              <TextInput
                value={stock.code}
                label={t('common:code')}
                required
                disabled
              />
            )}
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="text"
                name="name"
                label={t('common:name')}
                placeholder={t('common:enter_extra', {
                  extra: t('common:name'),
                })}
                required
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="text"
                name="unit"
                label={t('inventory:unit')}
                placeholder={t('common:enter_extra', {
                  extra: t('inventory:unit'),
                })}
                required
              />
            </div>
          </div>
          <div className={moduleStyles.halfContainer}>
            <Input
              type="checkbox"
              label={t('inventory:is_asset')}
              placeholder={t('common:enter_extra', {
                extra: t('inventory:is_asset'),
              })}
              name="isAsset"
            />
            <Separator gap={16} direction="vertical" />
            <Input
              type="checkbox"
              label={t('inventory:is_usable')}
              placeholder={t('common:enter_extra', {
                extra: t('inventory:is_usable'),
              })}
              name="isUsable"
            />
            <Separator gap={16} direction="vertical" />
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <StockItemCodeSelectInput
                name="stockItemCodeGroupId"
                type="group"
                label={t('inventory:group')}
                placeholder={t('common:choose_extra', {
                  extra: t('inventory:group'),
                })}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <StockItemCodeSelectInput
                name="stockItemCodeItemId"
                type="item"
                label={t('inventory:item')}
                placeholder={t('common:choose_extra', {
                  extra: t('inventory:item'),
                })}
              />
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <StockItemCodeSelectInput
                name="stockItemCodeStatedId"
                type="stated"
                label={t('inventory:stated')}
                placeholder={t('common:choose_extra', {
                  extra: t('inventory:stated'),
                })}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <StockItemCodeSelectInput
                name="stockItemCodeComponentId"
                type="component"
                label={t('inventory:component')}
                placeholder={t('common:choose_extra', {
                  extra: t('inventory:component'),
                })}
              />
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <DivisionSelectInput
                name="assetId"
                filter={{ has_asset: true }}
                required
                placeholder={t('common:choose_extra', {
                  extra: t('inventory:asset'),
                })}
                label={t('inventory:asset')}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="text-area"
                label={t('common:description')}
                placeholder={t('common:enter_extra', {
                  extra: t('common:description'),
                })}
                name="description"
              />
            </div>{' '}
          </div>
        </div>
      </FormContent>
    </Form>
  );
}
