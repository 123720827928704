import { getBrandsKey, useDeleteBrand } from 'api-hooks/brand';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { BrandFormProps, BrandFormType } from './form-type';
import BrandInformationForm from './label-information-form';

const BrandForm = (props: BrandFormProps) => {
  const { t } = useTranslation();
  const { brand } = props;
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeleteBrand();
  const { can } = useAuthorization();
  const dialog = useDialog();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().nullable().required(),
      color: Yup.string().nullable().required(),
    }),
  );

  const intialValues = React.useMemo<BrandFormType>(() => {
    return {
      name: brand?.name || '',
      color: brand?.color || 'dark',
    };
  }, [brand]);

  const methods = useForm<BrandFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const _input = {
          ...values,
          entityId: entity?.id,
        };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            brandId: brand!.id,
          });
          queryClient.refetchQueries([getBrandsKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [brand, close, deleteMutate, dialog, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!brand}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={brand}
          title={
            brand
              ? `${t('common:brand')} - ${brand?.name}`
              : t('common:create_extra', { extra: t('common:brand') })
          }
          noDelete={!can(AuthorizationRules.BrandsDelete)}
          noEdit={!can(AuthorizationRules.BrandsUpdate)}
        />
        <BrandInformationForm />
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
};

export default BrandForm;
