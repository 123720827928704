import { useGetStockBarcode } from 'api-hooks/stock';
import { AlertIcon, PlusIcon, StackIcon, TrashIcon } from 'common/assets';
import colors from 'common/styles/colors';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { ActionIcon, Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import { FormContext } from 'components/elements/form';
import Text from 'components/elements/text';
import useDrawer from 'hooks/use-drawer';
import WarehouseSelectInput from 'modules/master/warehouse/components/select-input';
import StockSelectInput from 'modules/stock/stock/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import React, { useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import ItemPanel from './item-panel';
import { outcomeStyles } from '../../styles.css';
import { OutcomeFormType } from '../form-type';

const DEFAULT_ITEM_VALUE = {
  stockId: '',
  warehouseId: '',
  qty: null,
  price: null,
};

function StockPurchases({ t }) {
  const context = useContext(FormContext);

  const { control, setValue, getValues } = useFormContext<OutcomeFormType>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stockPurchaseItems',
    keyName: 'customId',
  });
  const drawer = useDrawer();

  const { mutateAsync } = useGetStockBarcode();

  const onShowPanel = React.useCallback(
    (idx: number) =>
      drawer.showCustom({
        render: (close) => (
          <ItemPanel
            t={t}
            setValue={setValue}
            getValues={getValues}
            control={control}
            idx={idx}
            close={close}
          />
        ),
        size: 500,
        title: (
          <Separator gap={16} direction="horizontal">
            <Text textVariant="HeadingLarge">
              {t('inventory:item_details')}
            </Text>
          </Separator>
        ),
      }),
    [control, drawer, getValues, setValue, t],
  );

  const onAfterChangeQty = React.useCallback(
    async (qty: number | undefined, index: number) => {
      const resetValue = () =>
        setValue(`stockPurchaseItems.${index}.items`, []);
      if (!qty) {
        return resetValue();
      }
      try {
        resetValue();
        const res = await mutateAsync({
          body: {
            qty,
            transactionAt: new Date(),
          },
          stockId: getValues(`stockPurchaseItems.${index}.stockId`),
        });
        setValue(
          `stockPurchaseItems.${index}.items`,
          Array.from({ length: qty }, (_, index) => ({
            description: '',
            files: [],
            merk: '',
            barcode: res.data.barcodes[index],
            isUsed: false,
          })),
        );
      } catch {}
    },
    [getValues, mutateAsync, setValue],
  );

  return (
    <>
      {fields.map((field, index) => (
        <div className={moduleStyles.itemContainer} key={field.customId}>
          {context.editable && (
            <ActionIcon
              children={(size) => <TrashIcon size={size} />}
              variant="transparent"
              error
              onClick={() => remove(index)}
            />
          )}
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <StockSelectInput
                name={`stockPurchaseItems.${index}.stockId`}
                onAfterChange={(val) => {
                  setValue(`stockPurchaseItems.${index}.detail`, val);
                }}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <HOCInput keys={[`stockPurchaseItems.${index}.detail.isAsset`]}>
                {({
                  [`stockPurchaseItems.${index}.detail.isAsset`]: isAsset,
                }) => (
                  <Input
                    type="number"
                    isMoneyFormat
                    label={t('inventory:qty')}
                    placeholder={t('common:enter_extra', {
                      extra: t('inventory:qty'),
                    })}
                    required
                    name={`stockPurchaseItems.${index}.qty`}
                    hideControls
                    onAfterChange={(val) => {
                      if (isAsset) {
                        onAfterChangeQty(val, index);
                      }
                    }}
                  />
                )}
              </HOCInput>
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <WarehouseSelectInput
                name={`stockPurchaseItems.${index}.warehouseId`}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="number"
                isMoneyFormat
                label={t('inventory:price')}
                placeholder={t('common:enter_extra', {
                  extra: t('inventory:price'),
                })}
                required
                name={`stockPurchaseItems.${index}.price`}
                hideControls
              />
            </div>
          </div>
          <HOCInput
            keys={[
              `stockPurchaseItems.${index}.detail.isAsset`,
              `stockPurchaseItems.${index}.qty`,
            ]}
          >
            {({
              [`stockPurchaseItems.${index}.detail.isAsset`]: isAsset,
              [`stockPurchaseItems.${index}.qty`]: qty,
            }) =>
              isAsset &&
              context.editable && (
                <div className={outcomeStyles.itemBadgeContainer}>
                  <HOCInput keys={[`stockPurchaseItems.${index}.items`]}>
                    {({ [`stockPurchaseItems.${index}.items`]: items }) => (
                      <Button
                        onClick={() => onShowPanel(index)}
                        leftIcon={(size) => <StackIcon size={size} />}
                        disabled={!qty}
                        loading={!!qty && !items.length}
                      >
                        {t('inventory:input_item_details')}
                      </Button>
                    )}
                  </HOCInput>
                  {!!qty && (
                    <div className={outcomeStyles.itemBadge}>
                      <AlertIcon
                        weight="fill"
                        color={colors.bgError}
                        size={20}
                      />
                    </div>
                  )}
                </div>
              )
            }
          </HOCInput>
        </div>
      ))}
      <Separator gap={16} direction="vertical" />
      {context.editable && (
        <Button
          leftIcon={(size) => <PlusIcon size={size} />}
          variant="secondary"
          onClick={() => append(DEFAULT_ITEM_VALUE)}
        >
          {t('common:add_extra', { extra: t('inventory:item') })}
        </Button>
      )}
    </>
  );
}

export default function StockPurchaseForm({ t }) {
  return (
    <>
      <Input
        type="checkbox"
        name="isStockPurchase"
        label={t('inventory:stock_purchase')}
        withMarginBottom
      />

      <HOCInput keys={['isStockPurchase']}>
        {({ isStockPurchase }) => isStockPurchase && <StockPurchases t={t} />}
      </HOCInput>
    </>
  );
}
