import { BrandModel } from 'api-hooks/brand';
import { CommonSimpleModel, getIndexInput } from 'api-hooks/common/model';
import { Files } from 'api-hooks/income';
import { PurchaseRequisitionsLiteModel } from 'api-hooks/purchase-requisitions';
import { Expose, Type } from 'class-transformer';

// Enum

export enum FeeOnEnum {
  Sender = 'sender',
  Reciever = 'receiver',
  None = 'none',
}

export enum StatusEnum {
  Waiting = 'waiting',
  finished = 'finished',
}

export enum TransferTypeEnum {
  top_up = 'top_up',
  others = 'others',
}

// Model
export class TransferLiteModel {
  id: string;
  description: string;
  status: string;
  code: string;
  type: string;

  @Type(() => BrandModel)
  brand: BrandModel[];

  @Expose({ name: 'account_to' })
  accountTo: string;

  @Expose({ name: 'account_from' })
  accountFrom: string;

  @Expose({ name: 'transfer_currency' })
  transferCurrency: string;

  @Expose({ name: 'receive_currency' })
  receiveCurrency: string;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'receive_at' })
  @Type(() => Date)
  receiveAt: Date;

  @Expose({ name: 'transfer_amount' })
  @Type(() => Number)
  transferAmount: number;

  @Expose({ name: 'account_from_rate' })
  @Type(() => Number)
  accountFromRate: number;

  @Expose({ name: 'receive_amount' })
  @Type(() => Number)
  receiveAmount: number;

  @Expose({ name: 'account_to_rate' })
  @Type(() => Number)
  accountToRate: number;

  @Expose({ name: 'is_account_from_base' })
  @Type(() => Number)
  isAccountFromBase: number;

  @Expose({ name: 'is_account_to_base' })
  @Type(() => Number)
  isAccountToBase: number;

  @Expose({ name: 'has_attachment' })
  hasAttachment: boolean;

  @Expose({ name: 'is_attachment_completed' })
  isAttachmentCompleted: boolean;

  @Expose({ name: 'created_by' })
  createdBy: CommonSimpleModel;

  @Expose({ name: 'updated_by' })
  updatedBy: CommonSimpleModel;
}

export class TransferModel {
  id: string;
  description: string;
  status: string;
  code: string;
  type: string;

  @Expose({ name: 'account_from' })
  accountFrom: string;

  @Expose({ name: 'account_to' })
  accountTo: string;

  @Expose({ name: 'is_delay' })
  isDelay: boolean;

  @Expose({ name: 'fee_on' })
  feeOn: string;

  @Expose({ name: 'is_transfer_fee_base' })
  isTransferFeeBase: boolean;

  @Type(() => BrandModel)
  brands: BrandModel[];

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'receive_at' })
  @Type(() => Date)
  receiveAt: Date;

  @Expose({ name: 'transfer_amount' })
  @Type(() => Number)
  transferAmount: number;

  @Expose({ name: 'account_from_rate' })
  @Type(() => Number)
  accountFromRate: number;

  @Expose({ name: 'account_to_rate' })
  @Type(() => Number)
  accountToRate: number;

  @Expose({ name: 'receive_amount' })
  @Type(() => Number)
  receiveAmount: number;

  @Expose({ name: 'is_account_from_base' })
  @Type(() => Number)
  isAccountFromBase: number;

  @Expose({ name: 'is_account_to_base' })
  @Type(() => Number)
  isAccountToBase: number;

  @Expose({ name: 'transfer_fee' })
  @Type(() => Number)
  transferFee: number;

  @Expose({ name: 'transfer_fee_rate' })
  @Type(() => Number)
  transferFeeRate: number;

  @Expose({ name: 'request_form' })
  @Type(() => PurchaseRequisitionsLiteModel)
  requestForm: PurchaseRequisitionsLiteModel;

  @Expose({ name: 'is_include_mutation' })
  isIncludeMutation: boolean;

  @Expose({ name: 'has_attachment' })
  hasAttachment: boolean;

  @Expose({ name: 'is_transaction_closed' })
  isTransactionClosed: boolean;

  @Expose({ name: 'is_attachment_completed' })
  isAttachmentCompleted: boolean;

  @Expose({ name: 'files' })
  @Type(() => Files)
  files: Files[];

  @Expose({ name: 'chart_of_account_from' })
  chartOfAccountFrom: string;

  @Expose({ name: 'chart_of_account_to' })
  chartOfAccountTo: string;
}

// Input
export type getTransferInput = {
  id: string;
};
export type getTransfersInput = getIndexInput;

export type TransferMutationInput = {
  entityId: string;
  transactionAt: Date;
  description: string;
  brands: string[];
  accountFromId: string;
  accountToId: string;
  accountFromRate: number;
  accountToRate: number;
  transferAmount: number;
  feeOn: string;
  transferFee: number;
  transferFeeRate: number;
  isDelay: boolean;
  chartOfAccountFromId: string;
  chartOfAccountToId: string;
};

export type TransferUpdateInput = {
  transferId: string;
  body: TransferMutationInput;
};

export type TransferDeleteInput = {
  transferId: string;
};
