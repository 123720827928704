import { OutcomeModel } from 'api-hooks/outcome';
import { StockModel } from 'api-hooks/stock';
import * as Yup from 'yup';

export type ItemType = {
  merk: string;
  description: string;
  files?: { id?: string; file: string; url?: string }[];
  isUsed: boolean;
};

export type OutcomeFormType = {
  amount: number | '';
  accountId: string | null;
  description: string | null;
  isBaseCurrency: boolean;
  brands: string[];
  rate: number;
  transactionAt: Date | null;
  account: {
    id: string;
    isBaseCurrency: boolean;
    balance: number;
    currency: string;
    entityCurrencyId: string;
  };
  hasAttachment: boolean;
  requestFormId: string;
  supplierId: string;
  divisionId: string;
  isAttachmentCompleted: boolean;
  chartOfAccountId: null | string;
  files?: { id?: string; file: string; url?: string }[];
  isNotify: boolean;
  employeeId: string[];
  email: string[];
  notifyType: NotificationTypeEnum;
  entityCurrencyId: string;
  transactionRate: number | '';
  isIncludeMutation: boolean;
  isStockPurchase: boolean;
  // required only when is stock purchase is true
  stockPurchaseItems?: {
    stockId: string;
    warehouseId: string;
    detail?: StockModel;
    qty: number | null;
    price: number | null;

    items?: ItemType[];
  }[];
};

export type OutcomeImportFromType = {
  file: any;
  fileUrl: string;
};

export enum NotificationTypeEnum {
  employee = 'employee',
  email = 'email',
}

export type OutcomeNotificationFormType = {
  employeeId: string[];
  email: string[];
  notifyOptions: string[];
};

export const formSchema = (outcome: OutcomeModel | undefined) =>
  Yup.object().shape({
    amount: Yup.number().positive().required(),
    accountId: Yup.string().nullable().required(),
    description: Yup.string().nullable(),
    isBaseCurrency: Yup.bool(),
    brands: Yup.array().of(Yup.string().required()).min(1).required(),
    rate: Yup.number().min(0).required(),
    transactionRate: Yup.number().nullable().required(),
    entityCurrencyId: Yup.string().nullable().required(),
    requestFormId: Yup.string().nullable(),
    transactionAt: Yup.date().nullable().required(),
    isIncludeMutation: Yup.bool(),
    hasAttachment: Yup.bool(),
    isAttachmentCompleted: Yup.bool(),
    supplierId: Yup.string().nullable().required(),
    divisionId: Yup.string().nullable().required(),
    files: Yup.array().when(['hasAttachment'], {
      is: (val) => val,
      then: Yup.array(
        Yup.object({
          url: Yup.string().when(['hasAttachment'], {
            is: (val) => val === true,
            then: Yup.string().required(),
          }),
          file: Yup.string().when(['hasAttachment'], {
            is: (val) => val === true,
            then: Yup.string().required(),
          }),
        }),
      ).min(1),
    }),
    isNotify: Yup.bool(),
    notifyType: Yup.string().when('isNotify', {
      is: (val) => val && !outcome,
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().strip(true),
    }),
    email: Yup.array()
      .of(Yup.string().nullable())
      .when(['notifyType', 'isNotify'], {
        is: (type, isNotify) => {
          return (
            !!outcome || type === NotificationTypeEnum.employee || !isNotify
          );
        },
        then: Yup.array()
          .of(Yup.string().nullable().notRequired())
          .notRequired(),
        otherwise: Yup.array().of(Yup.string().required()).min(1),
      }),
    employeeId: Yup.array()
      .of(Yup.string().nullable())
      .when(['notifyType', 'isNotify'], {
        is: (type, isNotify) => {
          return !!outcome || type === NotificationTypeEnum.email || !isNotify;
        },
        then: Yup.array()
          .of(Yup.string().nullable().notRequired())
          .notRequired(),
        otherwise: Yup.array().of(Yup.string().required()).min(1),
      }),
    isStockPurchase: Yup.bool(),
    stockPurchaseItems: Yup.array().when('isStockPurchase', {
      is: (isStockPurchase) => isStockPurchase,
      then: Yup.array().of(
        Yup.object({
          stockId: Yup.string().required().nullable(),
          warehouseId: Yup.string().required().nullable(),
          qty: Yup.number().required().nullable(),
          price: Yup.number().required().nullable(),
          items: Yup.array().when(['detail.isAsset'], {
            is: (isAsset) => isAsset,
            then: Yup.array().of(
              Yup.object({
                barcode: Yup.string().strip(),
                merk: Yup.string().nullable(),
                description: Yup.string().nullable(),
                files: Yup.array().of(
                  Yup.object({
                    url: Yup.string().nullable(),
                    file: Yup.string().nullable(),
                  }),
                ),
              }),
            ),
            otherwise: Yup.array().nullable().strip(),
          }),
        }),
      ),
      otherwise: Yup.array().strip(),
    }),
  });
