import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import Badge from 'components/elements/badge';
import { Input } from 'components/elements/field';
import Text from 'components/elements/text';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { colorSwatchesWrapper } from './styles.css';

const BrandInformationForm = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={moduleStyles.halfContainer}>
        <Input
          type="text"
          label={t('common:brand')}
          placeholder={t('common:enter_extra', { extra: t('common:brand') })}
          name="name"
          required
        />
      </div>
      <div className={moduleStyles.halfContainer}>
        <div className={colorSwatchesWrapper}>
          <Input
            type="color-swatch"
            name="color"
            label={t('common:color')}
            required
          />
        </div>
      </div>
      <Separator gap={16} direction="vertical" />

      <HOCInput keys={['name', 'color']}>
        {({ name, color }) => (
          <div className={moduleStyles.halfContainer}>
            <Text textVariant="BodyBoldDefault">{t('common:preview')}</Text>
            {name && (
              <Badge color={color} badgeType="tagging">
                {name}
              </Badge>
            )}
          </div>
        )}
      </HOCInput>
    </div>
  );
};

export default BrandInformationForm;
