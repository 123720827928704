import { TransferTypeEnum } from 'api-hooks/transfer';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

export default function TransferAttachmentForm() {
  const { clearErrors } = useFormContext<any>();
  const { t } = useTranslation();

  const type = useWatch({ name: 'type' });
  const isTopUp = type === TransferTypeEnum.top_up;
  return (
    <div className={moduleStyles.halfContainer}>
      {!isTopUp && (
        <Input
          type="checkbox"
          name="hasAttachment"
          label={t('common:has_attachment')}
          onAfterChange={() => clearErrors()}
        />
      )}
      <HOCInput keys={['hasAttachment']}>
        {({ hasAttachment }) =>
          isTopUp || hasAttachment ? (
            <>
              <Separator gap={16} direction="vertical" />
              {!isTopUp && (
                <>
                  <Input
                    type="checkbox"
                    name="isAttachmentCompleted"
                    label={t('common:attachment_completed')}
                  />
                  <Separator gap={16} direction="vertical" />
                </>
              )}
              <Input
                name="files"
                previewKey="url"
                valueKey="file"
                type="files"
                label={t('common:attachment')}
                required
              />
            </>
          ) : (
            <></>
          )
        }
      </HOCInput>
    </div>
  );
}
