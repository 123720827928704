import { useCreateEntityCurrency } from 'api-hooks/entity-currency/mutation';
import { getEntitiesCurrenciesKey } from 'api-hooks/entity-currency/query';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import React from 'react';

import EntityCurrencyForm from './components/form';

const EntityCurrencyCreate = (props: NavigationProps) => {
  const { close, navigate } = useNavigation();
  const { can } = useAuthorization();
  const { mutateAsync, error } = useCreateEntityCurrency();

  return (
    <EntityCurrencyForm
      {...props}
      renderError={
        !!error &&
        !!error.message && (
          <>
            <Alert variant="error" description={error.message} />
            <Separator gap={8} />
          </>
        )
      }
      onSubmit={async (input, form) => {
        const result = await mutateAsync(input);
        close();
        form.reset();
        queryClient.refetchQueries([getEntitiesCurrenciesKey()[0]]);
        if (can(AuthorizationRules.EntityCurrenciesView)) {
          navigate(NavigationRoutes.EntityCurrencyView, {
            params: { id: result.data?.id },
          });
        }
        notification.success({ message: result?.message });
        return result.data;
      }}
    />
  );
};

export default EntityCurrencyCreate;
