import { ExportIcon } from 'common/assets';
import generateUniqueId from 'common/utils/generate-unique-id';
import Button from 'components/elements/button/default';
import useNavigation from 'hooks/use-navigation';
import invariant from 'invariant';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

interface ContextProps {
  tableId: string;
  setTableId: () => void;
}

const Context = React.createContext<ContextProps>({
  tableId: generateUniqueId(),
  setTableId: () => {},
});

interface PivotTableProviderProps {
  children: (
    ExportButton: () => React.ReactElement,
    handleExport: () => void,
  ) => React.ReactNode;
}

export function PivotTableProvider(props: PivotTableProviderProps) {
  const [tableId, setState] = React.useState<string>(generateUniqueId());
  const { t } = useTranslation();
  const { activeKey } = useNavigation();
  const setTableId = React.useCallback(() => {
    setState(generateUniqueId());
  }, []);

  const ExportButtons = document.getElementById(tableId);

  const onHandleExport = React.useCallback(() => {
    if (typeof window !== 'undefined') {
      const { TableExport } = require('tableexport');
      const ExportInstance = new TableExport(ExportButtons as any, {
        formats: ['xlsx'],
        exportButtons: false,
        filename: activeKey || 'report',
      });
      const XLSX = (ExportInstance as any).CONSTANTS.FORMAT.XLSX;
      const ExportData = ExportInstance.getExportData()[tableId][XLSX];
      ExportInstance.export2file(
        ExportData.data,
        ExportData.mimeType,
        ExportData.filename,
        ExportData.fileExtension,
      );
    }
  }, [ExportButtons, activeKey, tableId]);

  return (
    <Context.Provider value={{ tableId, setTableId }}>
      {props.children(
        () => (
          <Button
            variant="secondary"
            onClick={onHandleExport}
            leftIcon={(size) => <ExportIcon size={size} />}
            style={{ minWidth: 180 }}
          >
            {t('common:export_to_excel')}
          </Button>
        ),
        onHandleExport,
      )}
    </Context.Provider>
  );
}

export function usePivotTable() {
  const context = React.useContext(Context);
  invariant(
    context !== undefined,
    'usePivotTable must be used inside Pivot Table Container',
  );
  return context;
}
