import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getSupplierInput,
  getSuppliersInput,
  SupplierLiteModel,
  SupplierModel,
} from './model';

export function useGetSuppliers(
  input?: getSuppliersInput,
  options?: UseQueryOptions<ExtendedApiResult<SupplierLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<SupplierLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getSuppliers', input?.params],
      () =>
        QueryFetchFunction({
          url: 'suppliers',
          params: input?.params,
        }),
      options,
    ),
    SupplierLiteModel,
  );
}

export function useGetSupplier(
  input?: getSupplierInput,
  options?: UseQueryOptions<ApiResult<SupplierModel>, ApiError>,
): UseQueryResult<ApiResult<SupplierModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getSupplier', input?.id],
      () =>
        QueryFetchFunction({
          url: `suppliers/${input?.id}`,
        }),
      options,
    ),
    SupplierModel,
  );
}

export function getSuppliersKey(input?: getSuppliersInput) {
  return ['getSuppliers', input?.params];
}

export function getSupplierKey(input?: getSupplierInput) {
  return ['getSupplier', input?.id];
}
