import { getIndexInput } from 'api-hooks/common';
import { CurrencyLiteModel } from 'api-hooks/currency';
import { Expose, Type } from 'class-transformer';

// Model
export class EntityCurrencyLiteModel {
  id: string;
  currency: CurrencyLiteModel;
  name: string;

  @Type(() => Number)
  rate: number;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

type Entity = {
  id: string;
  name: string;
  description: string;
  currency: string;
};

export class EntityCurrencyModel {
  id: string;
  name: string;
  currency: CurrencyLiteModel;
  entity: Entity;

  @Type(() => Number)
  rate: number;
}

// Input
export type EntityCurrencyMutationInput = {
  name: string;
  rate: number;
  currency: string; //currencyId
  entityId: string;
};

export type EntityCurrencyInput = {
  name: string;
  rate: number;
};

export type EntityCurrencyUpdateInput = {
  entityId: string;
  body: EntityCurrencyInput;
};

export type EntityCurrencyDeleteInput = {
  entityId: string;
};

export type getEntityCurrencyInput = {
  id: string;
};

export type getEntityCurrenciesInput = getIndexInput;
