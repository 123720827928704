import { BudgetingItemsModel } from 'api-hooks/budgeting';
import { HistoryIcon } from 'common/assets';
import { ActionIcon } from 'components/elements/button';
import Text from 'components/elements/text';
import useDrawer from 'hooks/use-drawer';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import BudgetHistoryTable from './table';

interface Props {
  data: BudgetingItemsModel[];
  coaIds: string[];
  brandIds: string[];
}

function BudgetHistoryModal(props) {
  return (
    <div className={moduleStyles.panelContainer}>
      <BudgetHistoryTable {...props} />
    </div>
  );
}
export default function BudgetHistory(props: Props) {
  const { showCustom } = useDrawer();
  const { t } = useTranslation();

  const onClick = () => {
    showCustom({
      render: (close) => <BudgetHistoryModal {...props} dismiss={close} />,
      title: (
        <Text textVariant="HeadingLarge">{t('budgeting:budget_history')}</Text>
      ),
      size: 600,
      padding: 'md',
    });
  };

  return (
    <ActionIcon
      children={(size) => <HistoryIcon size={size} />}
      onClick={onClick}
      variant="transparent"
    />
  );
}
