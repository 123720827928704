import {
  AccountModel,
  getAccountKey,
  getAccountsKey,
  useGetAccount,
  useGetAccounts,
} from 'api-hooks/account';
import { QueryTransformer } from 'common/helpers/common';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(item: any): OptionProps {
  return {
    value: item.id,
    label: `${item.name} - ${item.currencyName}`,
    group: item.currencyName,
  };
}
function _detailTransformer(item: any) {
  return {
    id: item.id,
    balance: Number(item.balance),
    rate: Number(item.rate),
    isBaseCurrency: item.isBaseCurrency,
    currency: item.currency,
    currencyName: item.currencyName,
    entityCurrencyId: item.entityCurrencyId,
    name: item.name,
    type: item.type,
  };
}
interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  onAfterDetailChange?: (value: any) => void;
  disabled?: boolean;
  noMargin?: boolean;
  remove?: string;
  description?: React.ReactNode;
}

const AccountSelectInputByEntityId = (props: Props) => {
  const { t } = useTranslation();
  const {
    label = t('common:account'),
    placeholder = t('common:choose_extra', {
      extra: t('common:account'),
    }),
  } = props;
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const { entity } = useEntity();

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
      props?.onAfterChange?.(value);
    },
    useListQueryHook: useGetAccounts,
    useDetailLazyQueryHook: useGetAccount,
    getListsKey: React.useCallback(
      (page, q) =>
        getAccountsKey({
          params: {
            q,
            page,
            filter: {
              entity_id: entity?.id!,
            },
          },
        }),
      [entity?.id],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            entity_id: entity?.id!,
          },
        },
      }),
      [entity],
    ),
    renderCreate: false,
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    getDetailKey: React.useCallback(
      (value) =>
        getAccountKey({
          id: value,
        }),
      [],
    ),
    listTransformer(result) {
      const list = result.data.map((v) => transformer(v));
      return props.remove
        ? list.filter((val) => val.value !== props.remove)
        : list;
    },

    detailTransformer(result) {
      const resultTransform = QueryTransformer(result, AccountModel);
      props.onAfterDetailChange?.(_detailTransformer(resultTransform.data));
      return transformer(resultTransform.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });
  return (
    <Input
      type="select"
      name={props.name}
      required={props.required}
      label={label}
      placeholder={placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
      description={props.description}
    />
  );
};

export default AccountSelectInputByEntityId;
