import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { getIndexInput } from 'api-hooks/common';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  AccountAccessLiteModel,
  AccountAccessModel,
  getAccountAccessInput,
} from './model';

export function useGetAccountsAccess(
  input?: getIndexInput,
  options?: UseQueryOptions<
    ExtendedApiResult<AccountAccessLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<AccountAccessLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getAccountsAccess', input?.params],
      () =>
        QueryFetchFunction({
          url: 'account-accesses',
          params: input?.params,
        }),
      options,
    ),
    AccountAccessLiteModel,
  );
}

export function useGetAccountAccess(
  input?: getAccountAccessInput,
  options?: UseQueryOptions<ApiResult<AccountAccessModel>, ApiError>,
): UseQueryResult<ApiResult<AccountAccessModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getAccountAccess', input?.employeeId],
      () =>
        QueryFetchFunction({
          url: `account-accesses/${input?.employeeId}`,
        }),
      options,
    ),
    AccountAccessModel,
  );
}

export function getAccountsAccesssKey(input?: getIndexInput) {
  return ['getAccountsAccess', input?.params];
}

export function getAccountAccessKey(input?: getAccountAccessInput) {
  return ['getAccountAccess', input?.employeeId];
}
