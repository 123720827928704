import {
  StockItemLiteModel,
  StockItemModel,
  stockItemKey,
  useGetStockItem,
  useGetStockItems,
} from 'api-hooks/stock-item';
import { QueryTransformer } from 'common/helpers/common';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  noMargin?: boolean;
  stockId?: string;
  barcodeLabel?: boolean;
  onAfterChange?: (value: StockItemModel) => void;
  dependStockId?: boolean;
}

function transformer(item: StockItemModel | StockItemLiteModel): OptionProps {
  return {
    value: item.id,
    label: `${item.barcode} ${item.merk ? `- ${item.merk}` : ''} ${
      item.description ? `- ${item.description}` : ''
    }`,
  };
}
function barcodeLabeltransformer(
  item: StockItemModel | StockItemLiteModel,
): OptionProps {
  return {
    value: item.id,
    label: item.barcode,
  };
}

export default function StockItemSelectInput(props: Props) {
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const _transformer = props.barcodeLabel
    ? barcodeLabeltransformer
    : transformer;

  const { dependStockId, stockId } = props;

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetStockItems,
    useDetailLazyQueryHook: useGetStockItem,
    getListsKey: React.useCallback(
      (page, q) =>
        stockItemKey.list({
          params: {
            q,
            page,
            ...(!!stockId && {
              filter: {
                stock_id: stockId,
              },
            }),
          },
        }),
      [stockId],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          ...(!!stockId && {
            filter: {
              stock_id: stockId,
            },
          }),
        },
      }),
      [stockId],
    ),
    renderCreate: false,
    renderNoData: true,
    getDetailKey: React.useCallback(
      (value) =>
        stockItemKey.detail({
          stockItemId: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        stockItemId: value,
      }),
      [],
    ),
    listTransformer(result) {
      return (dependStockId ? !!stockId : true)
        ? result.data.map((v) => _transformer(v))
        : [];
    },
    detailTransformer(result) {
      const res = QueryTransformer(result, StockItemModel);
      props.onAfterChange?.(res.data);
      return _transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });
  return (
    <Input
      type="select"
      required
      label={props.label}
      placeholder={props.placeholder}
      name={props.name}
      noMargin={props.noMargin}
      disabled={props.disabled}
      {...selectProps}
    />
  );
}
