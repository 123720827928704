import { useDeleteEntityCurrency } from 'api-hooks/entity-currency/mutation';
import { getEntitiesCurrenciesKey } from 'api-hooks/entity-currency/query';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import EntityCurrencyInformationForm from './entity-currency-information-form';
import { EntityCurrencyFormProps, EntityCurrencyFormType } from './form-type';

const EntityCurrencyForm = (props: EntityCurrencyFormProps) => {
  const { t } = useTranslation();
  const { entityCurrency } = props;
  const dialog = useDialog();
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteEntityCurrency();
  const { entity } = useEntity();
  const { can } = useAuthorization();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().nullable().required(),
      currencyId: Yup.string().nullable().required(),
      rate: Yup.number().min(0).required(),
    }),
  );

  const intialValues = React.useMemo<EntityCurrencyFormType>(() => {
    return {
      name: entityCurrency?.name || '',
      currencyId: entityCurrency?.currency.id || '',
      rate: entityCurrency?.rate || 0,
    };
  }, [entityCurrency?.currency.id, entityCurrency?.name, entityCurrency?.rate]);

  const methods = useForm<EntityCurrencyFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const _input = {
          entityId: entity?.id,
          currencyId: values.currencyId,
          name: values.name,
          rate: values.rate,
        };
        await props.onSubmit(_input, methods);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          await deleteMutate({
            entityId: entityCurrency!.id,
          });
          dismiss();
          queryClient.refetchQueries([getEntitiesCurrenciesKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, entityCurrency, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!entityCurrency}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={entityCurrency}
          title={
            entityCurrency
              ? `${t('common:entity_currency')} - ${entityCurrency?.name}`
              : t('common:add_extra', {
                  extra: t('common:entity_currency'),
                })
          }
          noDelete={!can(AuthorizationRules.EntityCurrenciesDelete)}
          noEdit={!can(AuthorizationRules.EntityCurrenciesUpdate)}
        />
        <EntityCurrencyInformationForm
          isBase={
            !!entity && entity?.currencyId === entityCurrency?.currency.id
          }
        />
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
};

export default EntityCurrencyForm;
