import {
  useGetPurchaseRequisition,
  useUpdatePurchaseRequisitions,
} from 'api-hooks/purchase-requisitions';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import PurchaseRequisitionsForm from './components/form';
import refetchDetail from './helpers';

export default function PurchaseRequisitionsView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } =
    useGetPurchaseRequisition({
      id: props.navigation.params.id,
    });

  const { mutateAsync, error: mutateError } = useUpdatePurchaseRequisitions();

  const purchaseRequisitions = data?.data;

  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <PurchaseRequisitionsForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          purchaseRequisitions={purchaseRequisitions}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              id: purchaseRequisitions!.id,
            });
            form.reset();
            refetchDetail(result, purchaseRequisitions?.id!);
            return result.data;
          }}
        />
      }
    />
  );
}
