import { useUpdateEntityCurrency } from 'api-hooks/entity-currency/mutation';
import {
  getEntitiesCurrenciesKey,
  getEntitiesCurrencyKey,
  useGetEntityCurrency,
} from 'api-hooks/entity-currency/query';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import EntityCurrencyForm from './components/form';

const EntityCurrencyView = (props: NavigationProps) => {
  const { data, error, isLoading, isFetching, refetch } = useGetEntityCurrency({
    id: props.navigation.params.id,
  });

  const { mutateAsync, error: mutateError } = useUpdateEntityCurrency();

  const entityCurrency = data?.data;

  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <EntityCurrencyForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          entityCurrency={entityCurrency}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              entityId: entityCurrency!.id,
            });
            form.reset();
            queryClient.refetchQueries([getEntitiesCurrenciesKey()[0]]);
            queryClient.refetchQueries(
              getEntitiesCurrencyKey({ id: entityCurrency!.id }),
            );
            notification.success({ message: result?.message });
            return result.data;
          }}
        />
      }
    />
  );
};

export default EntityCurrencyView;
