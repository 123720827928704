import formSetErrors from 'common/helpers/form-setError';
import { initialDate } from 'common/utils/date';
import FormContent from 'components/common/form-content';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import AccountSelectInputByEntityId from 'modules/entity-settings/account/components/select-input-by-entity';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import ReconciliationAccountListTable from './reconciliation-account-list';
import ReconciliationSystemListTable from './reconciliation-system-list';

export interface ReconciliationFormProps extends NavigationProps {
  renderError?: React.ReactNode;
  onSubmit: (
    input: any,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}
const ReconciliationForm = (props: ReconciliationFormProps) => {
  const { t } = useTranslation();
  const { close, setNavigations } = useNavigation();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      transactionAt: Yup.date().nullable().required(),
      accountId: Yup.string().nullable().required(),
      description: Yup.string().nullable(),
    }),
  );

  const intialValues = React.useMemo<any>(() => {
    return {
      transactionAt: initialDate(),
      accountId: null,
      description: '',
      accounts: [],
      systems: [],
      systemDescription: '',
      accountDescription: '',
    };
  }, []);

  const methods = useForm<any>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const _input = {
          ...values,
          systems: values.systems
            .filter((item) => item.value === true)
            .map((item) => item.id),
          accounts: values.accounts
            .filter((item) => item.value === true)
            .map((item) => item.id),
        };
        await props.onSubmit(_input, methods as any);
        close();
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [close, methods, props],
  );

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          noDelete
          onDelete={() => {}}
          title={t('common:create_extra', {
            extra: t('common:reconciliation'),
          })}
        />
        <div
          className={moduleStyles.halfContainer}
          onClick={() => !!props.renderError && methods.clearErrors()}
        >
          <Input
            label={t('common:transaction_at')}
            placeholder={t('common:transaction_at')}
            name="transactionAt"
            type="date-time"
            required
          />
          <AccountSelectInputByEntityId name="accountId" required />
          <Input
            label={t('common:description')}
            placeholder={t('common:description')}
            name="description"
            type="text"
          />
        </div>

        <HOCInput keys={['accountId', 'systems', 'accounts']}>
          {({ accountId, systems, accounts }) => {
            const systemsLength = systems.filter(
              (val) => val.value === true,
            ).length;
            const accountsLength = accounts.filter(
              (val) => val.value === true,
            ).length;

            return (
              <>
                <Separator direction="vertical" gap={24} />
                {accountId && (
                  <div className={moduleStyles.sectionContainer}>
                    <div className={moduleStyles.halfContainer}>
                      <ReconciliationSystemListTable
                        disabled={systemsLength === 1 && accountsLength > 1}
                      />
                    </div>
                    <div className={moduleStyles.halfContainer}>
                      <ReconciliationAccountListTable
                        disabled={systemsLength > 1 && accountsLength === 1}
                      />
                    </div>
                  </div>
                )}
              </>
            );
          }}
        </HOCInput>
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
};

export default ReconciliationForm;
