import { BrandLiteModel, useGetBrands } from 'api-hooks/brand';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import ListHeader from 'components/common/list-header';
import { IColumn } from 'components/elements/table';
import { useAuthorization } from 'hooks/use-authorization';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import {
  GenericQueryTable,
  useGenericTableProps,
  useTableViewDetailColumn,
} from 'modules/common/table';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

function useBrandColumns({ t }) {
  const columns = React.useMemo<IColumn<BrandLiteModel>[]>(
    () => [
      {
        header: t('common:brand'),
        accessorKey: 'name',
        minSize: 200,
        sortName: 'name',
      },
      {
        header: t('common:color'),
        accessorKey: 'color',
        minSize: 200,
        sortName: 'color',
      },
    ],
    [t],
  );

  return useTableViewDetailColumn(columns, 'BrandView', 'BrandsView');
}

export default function BrandList() {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const navigation = useNavigation();

  const columns = useBrandColumns({ t });
  const onCreate = () => navigation.navigate(NavigationRoutes.BrandCreate);

  const { refetch, ...table } = useGenericTableProps(columns, useGetBrands, {
    params: {
      filter: {
        entity_id: entity?.id!,
      },
    },
  });

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:brand')}
        showAdd={can(AuthorizationRules.BrandsCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
      />
      <GenericQueryTable {...table} />
    </div>
  );
}
