import {
  ChartOfAccountsLiteModel,
  ChartOfAccountsModel,
  getChartOfAccountKey,
  getChartOfAccountsKey,
  useGetChartOfAccount,
  useGetChartOfAccounts,
} from 'api-hooks/chart-of-accounts';
import { QueryTransformer } from 'common/helpers/common';
import Select, { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

function transformer(
  item: ChartOfAccountsModel | ChartOfAccountsLiteModel,
): OptionProps {
  return {
    value: item.id,
    label: `${item.number} - ${item.name}`,
  };
}
interface Props {
  required?: boolean;
  label?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  noMargin?: boolean;
  onSelected?: (value?: string | null) => void;
}

export default function ChartOfAccountsSelectInput(props: Props) {
  const { t } = useTranslation();
  const {
    label = t('common:account'),
    placeholder = t('common:chart_of_accounts'),
  } = props;
  const { entity } = useEntity();

  const selectProps = useSelectInputHelper({
    value: props.value,
    onSelectItem: (value) => {
      props.onSelected?.(value);
    },
    useListQueryHook: useGetChartOfAccounts,
    useDetailLazyQueryHook: useGetChartOfAccount,
    getListsKey: React.useCallback(
      (page, q) =>
        getChartOfAccountsKey({
          params: {
            q,
            page,
            filter: {
              entity_id: entity?.id!,
              is_parent: false,
            },
          },
        }),
      [entity?.id],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            entity_id: entity?.id!,
            is_parent: false,
          },
        },
      }),
      [entity?.id],
    ),
    getDetailKey: React.useCallback(
      (value) =>
        getChartOfAccountKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback((id) => {
      return { id };
    }, []),
    renderCreate: false,

    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      const resultTransformer = QueryTransformer(result, ChartOfAccountsModel);
      return transformer(resultTransformer.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Select
      required={props.required}
      label={label}
      placeholder={placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
