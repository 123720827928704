import { Loader } from '@mantine/core';
import {
  getRolesKey,
  PermissionCategoryModel,
  RoleModel,
  RoleMutationInput,
  useDeleteRole,
  useGetPermissionGroups,
} from 'api-hooks/role';
import classNames from 'classnames';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form, { FormState } from 'components/elements/form';
import { CustomLabel } from 'components/elements/form-control';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import RoleMenu from './role-menu';
import { roleStyles } from '../style.css';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  role?: RoleModel;
  title?: string;
  onSubmit: (
    input: RoleMutationInput,
    form: ReturnType<typeof useForm>,
  ) => Promise<RoleModel | undefined>;
}

interface RoleRoleProps extends Props {
  permissionGroups: PermissionCategoryModel[];
}

type FormType = {
  name: string;
  permissionGroups?: {
    category: string;
    permissions?: {
      isEnable?: boolean;
      id?: string;
      name?: string;
    }[];
  }[];
};

function RoleRoleForm(props: RoleRoleProps) {
  const { t } = useTranslation();
  const { role, permissionGroups } = props;
  const dialog = useDialog();
  const { can } = useAuthorization();
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate, isLoading: deleteLoading } =
    useDeleteRole();

  const initialValues = React.useMemo<FormType>(() => {
    if (role) {
      const temp = {};
      permissionGroups.map((permissionGroups) => {
        Object.assign(temp, {
          [permissionGroups.category]:
            permissionGroups.permissionGroups
              .map((item) => item.id)
              .flat()
              .filter((curr) => role?.permissionGroups.includes(curr))?.[0] ||
            '',
        });
      });
      return {
        name: role.name,
        ...temp,
      };
    }
    return {
      name: '',
    };
  }, [permissionGroups, role]);

  const YupSchema = React.useMemo(
    () =>
      Yup.object()
        .shape({
          name: Yup.string().required(),
        })
        .required(),
    [],
  );

  const resolver = useYupValidationResolver(YupSchema);

  const methods = useForm<any>({
    defaultValues: initialValues,
    mode: 'onChange',
    resolver,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const { name, ...rest } = values;
        const input = {
          name: values.name,
          permissions: Object.values(rest).filter((item) => !!item),
        };
        await props.onSubmit(input as any, methods);
      } catch (err) {
        if (err.errors) {
          formSetErrors(err.errors, methods.setError);
        }
      }
    },
    [methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();

          await deleteMutate({
            roleId: role!.id!,
          });
          queryClient.refetchQueries([getRolesKey()[0]]);

          close();

          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [dialog, t, deleteMutate, role, close]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!role}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={role}
          title={t('common:role')}
          isDeleteLoading={deleteLoading}
          noDelete={!can(AuthorizationRules.RolesDelete)}
          noEdit={!can(AuthorizationRules.RolesUpdate)}
        />
        <div className={moduleStyles.cardContent({ noPadding: true })}>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                name="name"
                type="text"
                label={t('common:role_name')}
                placeholder={t('common:role_name')}
                required
              />
            </div>
          </div>
          <div className={roleStyles.container}>
            <FormState>
              {({ editable }) => (
                <>
                  <CustomLabel
                    label={t('common:permissions')}
                    required
                    disabled={!editable}
                  />

                  <div className={classNames(roleStyles.box, 'row')}>
                    {permissionGroups.map((permission) => (
                      <div
                        className="col-xs-12 col-sm-6 col-md-4"
                        key={permission.category}
                      >
                        <div className={roleStyles.permission}>
                          <RoleMenu {...permission} t={t} editable={editable} />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </FormState>
          </div>
        </div>
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
}

export default function RoleForm(props: Props) {
  const { data, refetch, isLoading, isFetching, error } =
    useGetPermissionGroups();

  return (
    <>
      <FetchWrapperComponent
        onRetry={refetch}
        loadingComponent={
          <DetailComponent>
            <Loader color={colors.productNormal} />
          </DetailComponent>
        }
        error={!!error}
        errorComponent={
          <DetailComponent>
            <ErrorViewComponent refetch={refetch} />
          </DetailComponent>
        }
        isLoading={isLoading || isFetching}
        component={
          <RoleRoleForm
            {...props}
            permissionGroups={data.data || []}
            title={props.title}
          />
        }
      />
    </>
  );
}
