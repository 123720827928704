import {
  NotificationModel,
  getNotificationsKey,
  useUpdateNotification,
} from 'api-hooks/notification';
import classNames from 'classnames';
import { NavigationRoutes } from 'common/constants';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import Text from 'components/elements/text';
import { format } from 'date-fns';
import useNavigation from 'hooks/use-navigation';
import { moduleStyles } from 'modules/styles.css';
import React from 'react';

import { itemStyles } from './styles.css';

export enum ItemTypeEnum {
  new_request_form = 'new_request_form',
  approval_status_changed = 'approval_status_changed',
  request_form_approved = 'request_form_approved',
}

enum NotificationTypeEnum {
  approval = 'Approval',
  request_form = 'Request Form',
  expense = 'Expense',
}

export default function Item(props: NotificationModel) {
  const {
    description,
    footerEmployeeName,
    header,
    id,
    isRead,
    notificationableType,
    notificationableId,
  } = props;

  const { navigate } = useNavigation();
  const { mutateAsync } = useUpdateNotification();

  const route = React.useMemo(() => {
    return NotificationTypeEnum.approval === notificationableType
      ? NavigationRoutes.ApprovalView
      : NotificationTypeEnum.expense === notificationableType
      ? NavigationRoutes.OutcomeView
      : NavigationRoutes.PurchaseRequisitionsView;
  }, [notificationableType]);

  const onHandleClick = React.useCallback(
    async (isRead) => {
      !isRead &&
        (await mutateAsync({
          body: {
            ids: [id],
          },
        }));
      queryClient.refetchQueries([getNotificationsKey()[0]]);
      navigate(route, {
        params: {
          id: notificationableId,
        },
      });
    },
    [id, mutateAsync, navigate, notificationableId, route],
  );

  return (
    <div
      className={classNames(
        itemStyles.container,
        !isRead && itemStyles.activeContainer,
      )}
      key={id}
      onClick={() => onHandleClick(isRead)}
    >
      <Text className={itemStyles.title}>{header}</Text>
      <Separator direction="vertical" gap={8}>
        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className={itemStyles.bodyDefault}
        />
      </Separator>
      <Text
        textVariant="BodySmaller"
        className={moduleStyles.row()}
        color={colors.textLight}
      >
        {footerEmployeeName + ' - '}
        <Separator gap={4} direction="horizontal" />
        <span> {format(new Date(), 'dd MMM yyyy, HH:mm')}</span>
      </Text>
    </div>
  );
}
