import {
  useUpdateAccountAccess,
  getAccountAccessKey,
  getAccountsAccesssKey,
  useGetAccountAccess,
} from 'api-hooks/account-access';
import { useGetEmployee } from 'api-hooks/employee';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { OptionProps } from 'components/elements/select';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import AccountAccessForm from './components/form';

export default function AccountAccessView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } = useGetAccountAccess({
    employeeId: props.navigation.params.id,
  });
  const {
    data: employee,
    error: errorEmployee,
    isLoading: isGetEmployeeLoading,
    isFetching: isFetchingEmployee,
    refetch: refetchEmployee,
  } = useGetEmployee({
    id: props.navigation.params.id,
  });

  const { mutateAsync, error: mutateError } = useUpdateAccountAccess();

  const accountAccess = data?.data;

  const _refetch = () => {
    refetch();
    refetchEmployee();
  };

  const entitiesSelectOptions = React.useMemo<OptionProps[]>(
    () =>
      employee?.data?.entities.map((entity) => ({
        value: entity.id,
        label: entity.name,
      })) || [],
    [employee?.data?.entities],
  );

  return (
    <FetchWrapperComponent
      onRetry={_refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error || !!errorEmployee}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={_refetch} />
        </DetailComponent>
      }
      isLoading={
        isLoading || isFetching || isFetchingEmployee || isGetEmployeeLoading
      }
      component={
        <AccountAccessForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          accountAccess={accountAccess}
          entitiesSelectOptions={entitiesSelectOptions}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              employeeId: accountAccess?.id!,
            });
            form.reset();
            queryClient.refetchQueries([getAccountsAccesssKey()[0]]);
            queryClient.refetchQueries(
              getAccountAccessKey({ employeeId: accountAccess!.id }),
            );
            notification.success({ message: result?.message });

            return result.data;
          }}
        />
      }
    />
  );
}
