import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { ApiError, PaginationMeta } from 'api-hooks/common/model';
import { OptionProps, SelectProps } from 'components/elements/select';
import { camelizeKeys } from 'humps';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface Options<ListQueryData, ListQueryVariables> {
  useListQueryHook: (
    baseInputs: ListQueryVariables,
    baseOptions?: UseQueryOptions<ListQueryData, ApiError>,
  ) => UseQueryResult<ListQueryData, ApiError>;
  listTransformer: (result: ListQueryData) => OptionProps[];
  paginationTransformer: (result: ListQueryData) => PaginationMeta;
  getMemoizedListVariables: (
    page: number,
    query?: string,
  ) => ListQueryVariables;
  onSelectItem: (value: string | null) => void;
  renderCreate?: boolean;
  onClickCreate?: () => void;
  createText?: string;
  value?: string | null;
  enabled?: boolean;
}

export default function useSelectInputWithoutDetailHelper<
  ListQueryData,
  ListQueryVariables,
>(helperOptions: Options<ListQueryData, ListQueryVariables>): SelectProps {
  const { t } = useTranslation();
  const {
    renderCreate = false,
    createText = t('common:create_new'),
    onClickCreate = () => {},
    onSelectItem,
    useListQueryHook,
    listTransformer,
    paginationTransformer,
    getMemoizedListVariables,
    value,
    enabled = true,
  } = helperOptions;

  const [options, setOptions] = React.useState<OptionProps[]>([]);
  const [query, setQuery] = React.useState<string | undefined>();
  const [isFocus, setIsFocus] = React.useState<boolean>(false);
  const page = -1;

  const {
    isLoading: listLoading,
    data: list,
    error,
    refetch,
  } = useListQueryHook(getMemoizedListVariables(page, query), {
    enabled,
    onSuccess: (result) => {
      setOptions(
        listTransformer(
          camelizeKeys(result as any) as unknown as ListQueryData,
        ),
      );
    },
  });

  const loading = listLoading;

  const onDropdownOpen = React.useCallback(() => {
    if (!isFocus) {
      setQuery(undefined);
    }

    if (error) {
      refetch();
    }

    setIsFocus(true);
  }, [error, isFocus, refetch]);

  const items = React.useMemo(() => {
    const _options = [...options];

    const pagination = list && paginationTransformer(list);

    if (pagination && pagination.currentPage < pagination.lastPage) {
      _options.push({ label: 'Load More...', value: 'actionLoad' });
    }

    if (renderCreate) {
      _options.push({ label: createText, value: 'actionCreate' });
    }

    return _options;
  }, [createText, list, options, paginationTransformer, renderCreate]);

  const onQueryChange = useDebouncedCallback(
    (q) => {
      if (isFocus) {
        setQuery(q || '');
      }
    },
    800,
    {
      trailing: true,
    },
  );

  const _onChange = React.useCallback(
    (params: string | null) => {
      if (params === 'actionCreate') {
        onClickCreate();
      } else {
        onSelectItem(params);
        setIsFocus(false);
      }
    },
    [onClickCreate, onSelectItem],
  );

  const _value = React.useMemo(() => {
    return value;
  }, [value]);

  return {
    value: _value,
    data: items,
    disabled: loading,
    onSearchChange: onQueryChange,
    onChange: _onChange,
    searchable: true,
    onDropdownOpen,
  };
}
