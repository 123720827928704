import {
  AccountAccessLiteModel,
  useGetAccountsAccess,
} from 'api-hooks/account-access';
import notification from 'common/helpers/notification';
import ListHeader from 'components/common/list-header';
import QueryFilter, {
  useApplyQueryFilter,
} from 'components/common/query-filter/query-filter';
import Separator from 'components/common/separator';
import { IColumn } from 'components/elements/table';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import useComposedQuery from 'hooks/use-composed-query';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import AccountAccessTable from './components/table';

export default function AccountAccessList() {
  const { t } = useTranslation();

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const _columns = React.useMemo<IColumn<AccountAccessLiteModel>[]>(
    () => [
      {
        header: t('common:name'),
        accessorKey: 'name',
        minSize: 200,
        sortName: 'name',
      },
      {
        header: t('common:email'),
        accessorKey: 'email',
        minSize: 200,
        sortName: 'email',
      },
      {
        header: t('common:entities'),
        accessorKey: 'entities',
        minSize: 300,
        cell: ({ row }) => (
          <>{row.original.entities.map((entity) => entity.name).join(', ')}</>
        ),
        sortName: 'entities',
      },
      {
        header: t('common:account_access'),
        accessorKey: 'accounts',
        minSize: 400,
        cell: ({ row }) => (
          <>{row.original.accounts.map((entity) => entity.name).join(', ')}</>
        ),
        sortName: 'accounts',
      },
    ],
    [t],
  );

  const {
    data,
    refetch,
    isLoading,
    isFetching,
    error,
    extras: [{ filters, setFilters }, { columns }],
  } = useComposedQuery(
    useGetAccountsAccess,
    {
      params: {
        page,
        limit,
      },
    },
    useApplyQueryFilter((data: any) => {
      return data.filters;
    }),
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
  );

  React.useEffect(() => {
    if (!!error && !!error.message) {
      notification.error({ message: error?.message });
    }
  }, [error]);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:account_access')}
        showAdd={false}
        onRefresh={refetch}
      />
      <Separator gap={8} />
      <div className={moduleStyles.topContainer}>
        <QueryFilter
          setPage={setPage}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <AccountAccessTable
        accountsAccess={data?.data || []}
        loading={isLoading || isFetching}
        page={page}
        onPageChange={(page) => setPage(page)}
        meta={data?.meta}
        sortColumns={columns || []}
        limit={limit}
        onLimitChange={(limit) => setLimit(limit)}
      />
    </div>
  );
}
