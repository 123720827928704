import {
  EntityAccounts,
  getEntityAccountsKey,
  useGetEntityAccount,
} from 'api-hooks/entity';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import useMultiSelectInputHelper from 'hooks/use-multi-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(item: EntityAccounts): OptionProps {
  return {
    value: item.id,
    label: item.name,
    group: item.entityCurrencyName,
  };
}
interface Props {
  entityId: string;
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  onListChange?: (value?: any) => void;
  isAllAccount?: boolean;
  disabled?: boolean;
  noMargin?: boolean;
}

const AccountMultiSelectInput = (props: Props) => {
  const { t } = useTranslation();
  const { label = t('common:account') } = props;
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const [list, setList] = React.useState<any>();

  const selectProps = useMultiSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value || []);
      props.onAfterChange?.();
    },
    useListQueryHook: useGetEntityAccount,
    getListsKey: React.useCallback(
      (page) =>
        getEntityAccountsKey({
          id: props.entityId,
          params: {
            limit: -1,
          },
        }),
      [props.entityId],
    ),
    getMemoizedListVariables: React.useCallback(
      (page) => {
        return {
          id: props.entityId,
          params: {
            limit: -1,
          },
        };
      },
      [props.entityId],
    ),
    enabled: !!props.entityId,
    renderCreate: false,
    listTransformer(result) {
      const data = result.data.map((v) => transformer(v));
      setList(data.map((val) => val.value));
      return data;
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });
  React.useEffect(() => {
    props?.isAllAccount && props?.onListChange?.(list);
    //eslint-disable-next-line
  }, [props?.isAllAccount, list]);

  return (
    <Input
      type="select-multi"
      name={props.name}
      required={props.required}
      label={label}
      placeholder={props.placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
};

export default AccountMultiSelectInput;
