import {
  AccountMutationModel,
  getAccountMutationsKey,
  useDeleteAccountMutation,
} from 'api-hooks/account-mutation';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import { initialDate } from 'common/utils/date';
import { string2moneyDefault } from 'common/utils/string';
import FormContent from 'components/common/form-content';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form, { FormState } from 'components/elements/form';
import Text from 'components/elements/text';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import AccountSelectInputByEntityId from 'modules/entity-settings/account/components/select-input-by-entity';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { AccountMutationFormType } from './form-type';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  accountMutation?: AccountMutationModel;
  onSubmit: (
    input: any,
    form: ReturnType<typeof useForm>,
  ) => Promise<AccountMutationModel | undefined>;
}

export default function AccountMutationForm(props: Props) {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { accountMutation } = props;
  const dialog = useDialog();
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeleteAccountMutation();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      amount: Yup.number().required(),
      accountId: Yup.string().nullable().required(),
      description: Yup.string().nullable(),
      isBaseCurrency: Yup.bool(),
      rate: Yup.number().min(0).required(),
      transactionAt: Yup.date().nullable().required(),
    }),
  );

  const intialValues = React.useMemo<AccountMutationFormType>(() => {
    return {
      amount: accountMutation?.amount || '',
      account: {
        id: '',
        isBaseCurrency: false,
        balance: 0,
        currency: '',
      },
      accountId: accountMutation?.account.id || null,
      description: accountMutation?.description || '',
      isBaseCurrency: accountMutation?.isBaseCurrency || false,
      rate: accountMutation?.rate || 1,
      transactionAt: accountMutation?.transactionAt || initialDate(),
      entityId: entity?.id || null,
    };
  }, [accountMutation, entity?.id]);

  const methods = useForm<any>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const { setValue } = methods;

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        await props.onSubmit(values, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
        notification.error({ message: e.message });
      }
    },
    [methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            accountMutationId: accountMutation!.id,
          });
          queryClient.refetchQueries([getAccountMutationsKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [accountMutation, close, deleteMutate, dialog, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!accountMutation}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={accountMutation}
          title={
            accountMutation
              ? `${t('common:account_mutation')}`
              : t('common:create_extra', {
                  extra: t('common:account_mutation'),
                })
          }
          noDelete={
            !!accountMutation?.isTransactionClosed ||
            !can(AuthorizationRules.WalletMutationsDelete)
          }
          noEdit={
            !!accountMutation?.isTransactionClosed ||
            !can(AuthorizationRules.WalletMutationsUpdate)
          }
        />
        <div>
          <div className={moduleStyles.halfContainer}>
            <Input
              label={t('common:transaction_at')}
              placeholder={t('common:transaction_at')}
              name="transactionAt"
              type="date-time"
              required
            />
            <FormState>
              {({ editable }) => (
                <AccountSelectInputByEntityId
                  name="accountId"
                  required
                  onAfterDetailChange={(val) => {
                    setValue('account', val);
                    if (!val.isBaseCurrency) {
                      editable &&
                        setValue('rate', val.rate, { shouldValidate: true });
                    } else {
                      setValue('rate', 1);
                    }
                  }}
                  noMargin
                />
              )}
            </FormState>
            <Separator gap={8} direction="vertical" />
            <HOCInput keys={['account']}>
              {({ account }) => {
                return (
                  account.id && (
                    <Text
                      textVariant="MonoSmaller"
                      color={colors.textLight}
                    >{`${t('common:balance')}: ${
                      account.currency
                    } ${string2moneyDefault(account.balance)}`}</Text>
                  )
                );
              }}
            </HOCInput>
          </div>
          <Separator gap={8} direction="vertical" />
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                label={t('transfer:amount')}
                placeholder={t('transfer:amount')}
                name="amount"
                type="number"
                isMoneyFormat
                required
                description={
                  <HOCInput keys={['rate', 'amount']}>
                    {({ rate, amount }) => (
                      <>
                        {!!amount && !!rate && (
                          <Text
                            textVariant="MonoSmaller"
                            color={colors.textLight}
                          >{`~ ${entity?.currency!} ${string2moneyDefault(
                            rate * amount,
                          )}`}</Text>
                        )}
                      </>
                    )}
                  </HOCInput>
                }
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <HOCInput
                keys={['account.isBaseCurrency', 'account.currencyName']}
              >
                {({
                  'account.isBaseCurrency': isBase,
                  'account.currencyName': currencyName,
                }) => {
                  return (
                    !isBase &&
                    currencyName && (
                      <Input
                        label={t('common:rate')}
                        placeholder={t('common:rate')}
                        name="rate"
                        type="number"
                        inputMode="decimal"
                        isMoneyFormat
                        required
                      />
                    )
                  );
                }}
              </HOCInput>
            </div>
          </div>
          <Separator gap={8} direction="vertical" />
          <div className={moduleStyles.halfContainer}>
            <Input
              label={t('common:description')}
              placeholder={t('common:description')}
              name="description"
              type="text"
            />
          </div>
        </div>
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
}
