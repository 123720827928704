import { Control } from 'react-hook-form';

import HOCWatchValueCustomControl from './hoc-watch-value-custom-control';

export default function HOCInputControl(props: {
  keys: string[];
  control: Control<any>;

  children: (value: any) => React.ReactNode;
}): any {
  const { keys, control } = props;
  const objValues = HOCWatchValueCustomControl({ keys, control });

  return props.children(objValues);
}
