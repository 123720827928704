import {
  useUpdateDivision,
  getDivisionKey,
  getDivisionsKey,
  useGetDivision,
  getDivisionsByEmployeeKey,
} from 'api-hooks/division';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import DivisionForm from './components/form';

export default function DivisionView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } = useGetDivision({
    id: props.navigation.params.id,
  });

  const { mutateAsync, error: mutateError } = useUpdateDivision();

  const division = data?.data;
  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <DivisionForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          division={division}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              divisionId: division?.id!,
            });
            form.reset();
            queryClient.refetchQueries([getDivisionsKey()[0]]);
            queryClient.refetchQueries([getDivisionsByEmployeeKey()[0]]);
            queryClient.refetchQueries(getDivisionKey({ id: division?.id! }));
            notification.success({ message: result?.message });

            return result.data;
          }}
        />
      }
    />
  );
}
