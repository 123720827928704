import { OptionProps } from 'components/elements/select';
import { camelizeKeys } from 'humps';
import React from 'react';

interface Props<ListQueryData> {
  setOptions: React.Dispatch<React.SetStateAction<OptionProps[]>>;
  page: number;
  listTransformer: (result: ListQueryData) => OptionProps[];
}

export default function useGenerateOptionSelectInputHelper<ListQueryData>(
  props: Props<ListQueryData>,
): { onSuccess: (result: ListQueryData, page?: number) => void } {
  const { page, setOptions, listTransformer } = props;

  const onSuccess = React.useCallback(
    (result, _page = undefined) => {
      const __page = _page || page;

      if (__page === 1) {
        setOptions(
          listTransformer(
            camelizeKeys(result as any) as unknown as ListQueryData,
          ),
        );
      } else {
        setOptions((prevOptions) =>
          prevOptions.concat(
            listTransformer(
              camelizeKeys(result as any) as unknown as ListQueryData,
            ),
          ),
        );
      }
    },
    [listTransformer, page, setOptions],
  );

  return { onSuccess };
}
