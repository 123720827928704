import { useGetMutationReport } from 'api-hooks/report';
import { RefreshIcon } from 'common/assets';
import filterReduce from 'common/helpers/filter-reduce';
import EmptyView from 'components/common/empty-view';
import ListHeader from 'components/common/list-header';
import { useApplyQueryFilter } from 'components/common/query-filter/query-filter';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { PivotTable } from 'components/pivot';
import { NavigationProps } from 'containers/navigation';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import ReportQueryFilter from 'modules/report/components/query-filter';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import ExportButton from './components/export-button';
import { coaReportStyles } from './styles.css';

export default function AccountMutationReport(props: NavigationProps) {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const {
    data,
    refetch,
    extras: [{ filters, setFilters }],
  } = useComposedQuery(
    useGetMutationReport,
    {
      params: {
        filter: {
          account_id: props.navigation.params || undefined,
        },
      },
    },
    useApplyQueryFilter((data: any) => data?.filters),
  );

  return (
    <div>
      <ListHeader
        showAdd={false}
        showRefresh={false}
        title={t('common:account_mutation_report')}
        rightCustomComponent={
          <>
            {!!data?.data?.length && (
              <ExportButton
                category="account"
                params={{
                  filter: {
                    ...(filterReduce(filters || []) as any),
                    entity_id: entity?.id,
                  },
                }}
              />
            )}

            <Separator gap={16} />
            <Button
              onClick={() => refetch()}
              leftIcon={(size) => <RefreshIcon size={size} />}
              variant="secondary"
            >
              {t('common:refresh')}
            </Button>
          </>
        }
      />
      {filters && (
        <div className={coaReportStyles.filterContainer}>
          <ReportQueryFilter
            filters={filters}
            setFilters={setFilters}
            compact={{
              isCompact: false,
              requiredKeys: ['account_id'],
            }}
          />
        </div>
      )}
      {data?.data?.length ? (
        <PivotTable
          queryResultData={data}
          numberColorKeys={['amount', 'baseAmount', 'balance', 'baseBalance']}
          currencyAccessorKey={['amount', 'balance']}
        />
      ) : (
        <EmptyView />
      )}
    </div>
  );
}
