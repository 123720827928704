import Text from 'components/elements/text';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { styles } from './style.css';
import Separator from '../separator';

interface Props {
  children?: React.ReactNode;
}

export default function EmptyView(props: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {props.children || (
          <>
            <Text>{t('common:nothing_here')}</Text>
            <Separator gap={12} />
            <Text>{t('common:you_have_no_data_at_this_moment')}</Text>
          </>
        )}
      </div>
    </div>
  );
}
