import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getTransferApprovalInput,
  getTransferApprovalsInput,
  TransferApprovalLiteModel,
  TransferApprovalModel,
} from './model';

export function useGetTransferApprovals(
  input?: getTransferApprovalsInput,
  options?: UseQueryOptions<
    ExtendedApiResult<TransferApprovalLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<TransferApprovalLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getTransferApprovals', input?.params],
      () =>
        QueryFetchFunction({
          url: 'transfer-approvals',
          params: input?.params,
        }),
      options,
    ),
    TransferApprovalLiteModel,
  );
}

export function useGetTransferApproval(
  input?: getTransferApprovalInput,
  options?: UseQueryOptions<ApiResult<TransferApprovalModel>, ApiError>,
): UseQueryResult<ApiResult<TransferApprovalModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getTransferApproval', input?.id],
      () =>
        QueryFetchFunction({
          url: `transfer-approvals/${input?.id}`,
        }),
      options,
    ),
    TransferApprovalModel,
  );
}

export function getTransferApprovalsKey(input?: getTransferApprovalsInput) {
  return ['getTransferApprovals', input?.params];
}

export function getTransferApprovalKey(input?: getTransferApprovalInput) {
  return ['getTransferApproval', input?.id];
}
