import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  EntityCurrencyLiteModel,
  EntityCurrencyModel,
  getEntityCurrenciesInput,
  getEntityCurrencyInput,
} from './model';

export function useGetEntityCurrencies(
  input?: getEntityCurrenciesInput,
  options?: UseQueryOptions<
    ExtendedApiResult<EntityCurrencyLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<EntityCurrencyLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getEntityCurrencies', input?.params],
      () =>
        QueryFetchFunction({
          url: 'entity-currencies',
          params: input?.params,
        }),
      options,
    ),
    EntityCurrencyLiteModel,
  );
}

export function useGetEntityCurrency(
  input?: getEntityCurrencyInput,
  options?: UseQueryOptions<ApiResult<EntityCurrencyModel>, ApiError>,
): UseQueryResult<ApiResult<EntityCurrencyModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getEntityCurrency', input?.id],
      () =>
        QueryFetchFunction({
          url: `entity-currencies/${input?.id}`,
        }),
      options,
    ),
    EntityCurrencyModel,
  );
}
export function getEntitiesCurrenciesKey(input?: getEntityCurrenciesInput) {
  return ['getEntityCurrencies', input?.params];
}
export function getEntitiesCurrencyKey(input?: getEntityCurrencyInput) {
  return ['getEntityCurrency', input?.id];
}
