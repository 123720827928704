import { EntityCurrencyLiteModel } from 'api-hooks/entity-currency/model';
import colors from 'common/styles/colors';
import { string2moneyDefault } from 'common/utils/string';
import { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { format } from 'date-fns';
import { useEntity } from 'hooks/use-entities';
import { useTableViewDetailColumn } from 'modules/common/table';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

export default function useEntityCurrencyColumns() {
  const { entity } = useEntity();
  const { t } = useTranslation();

  const columns = React.useMemo<IColumn<EntityCurrencyLiteModel>[]>(
    () => [
      {
        header: t('common:currency'),
        accessorKey: 'currency',
        minSize: 250,
        sortName: 'currency',
        cell: ({ row }) => {
          const { original } = row;
          return `${original.currency.currency} - ${original.currency.name}`;
        },
      },
      {
        header: t('common:name'),
        accessorKey: 'name',
        minSize: 200,
        sortName: 'name',
      },
      {
        header: t('common:rate'),
        accessorKey: 'rate',
        minSize: 250,
        sortName: 'rate',
        cell: ({ row }) => {
          const {
            original: {
              currency: { currency },
              rate,
            },
          } = row;
          if (currency === entity?.currency) {
            return (
              <Text textVariant="MonoDefault" color={colors.textLighter}>
                {t('currency:this_is_base_currency')}
              </Text>
            );
          } else {
            return (
              <Text textVariant="MonoDefault">
                {`${currency} 1 = ${entity?.currency} ${string2moneyDefault(
                  rate,
                )}`}
              </Text>
            );
          }
        },
      },
      {
        header: t('common:updated_at'),
        accessorKey: 'updatedAt',
        minSize: 200,
        cell: ({ getValue }) => {
          return (
            <>
              {getValue()
                ? format(new Date(getValue() as Date), 'dd MMM yyyy, HH:mm')
                : ''}
            </>
          );
        },
      },
    ],
    [entity?.currency, t],
  );
  return useTableViewDetailColumn(
    columns,
    'EntityCurrencyView',
    'EntityCurrenciesView',
  );
}
