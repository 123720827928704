import { Modal } from '@mantine/core';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import {
  ApprovalModel,
  ApprovalUpdateInput,
  useRejectApproval,
  useRequestRevisionApproval,
} from 'api-hooks/approval';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import { defaultValues, formSchema, onAfterAction } from './utils';

interface Props {
  onClose: () => void;
  id: string;
  variant: 'revision' | 'reject';
  onCatch: (e: string) => void;
}

type Extra = {
  submitAction: UseMutateAsyncFunction<
    ApiResult<ApprovalModel>,
    ApiError,
    ApprovalUpdateInput,
    unknown
  >;
  title: string;
  button: {
    label: string;
    warning?: boolean;
    error?: boolean;
    success?: boolean;
  };
};
export default function ActionModal(props: Props) {
  const { t } = useTranslation();
  const { onClose, id, variant } = props;

  const { mutateAsync: mutateReject, isLoading: isLoadingReject } =
    useRejectApproval();
  const {
    mutateAsync: mutateRequestRevision,
    isLoading: isLoadingRequestRevision,
  } = useRequestRevisionApproval();

  const resolver = useYupValidationResolver(formSchema);
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver,
  });

  const extra = React.useMemo<Extra>(() => {
    switch (variant) {
      case 'revision':
        return {
          button: { label: 'send_revision_request', warning: true },
          title: 'need_revision',
          submitAction: mutateRequestRevision,
        };
      default:
        return {
          button: { label: 'reject_pr', warning: true },
          title: 'reject',
          submitAction: mutateReject,
        };
    }
  }, [mutateReject, mutateRequestRevision, variant]);

  const onSubmit = async (values) => {
    try {
      await extra.submitAction({ id, ...values });
      onAfterAction(id);
      onClose();
    } catch (e: any) {
      props.onCatch(e?.message);
    }
  };

  return (
    <Modal
      onClose={onClose}
      opened
      centered
      title={
        <Text textVariant="HeadingSmall">{t(`approval:${extra.title}`)}</Text>
      }
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <div className={moduleStyles.fullContainer}>
          <Input
            type="text-area"
            name="note"
            label={t('approval:note')}
            placeholder={t(`approval:${extra.title}_desc`)}
          />
        </div>
        <Separator gap={16} direction="vertical">
          <Button
            type="submit"
            loading={isLoadingReject || isLoadingRequestRevision}
            {...extra.button}
            fullWidth
          >
            {t(`approval:${extra.button.label}`)}
          </Button>
        </Separator>
        <Button type="button" variant="tertiary" onClick={onClose}>
          {t('common:cancel')}
        </Button>
      </Form>
    </Modal>
  );
}
