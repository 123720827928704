import {
  StockUsageModel,
  StockUsageMutationInput,
  stockUsageKey,
  useDeleteStockUsage,
} from 'api-hooks/stock-usage';
import { PlusIcon, TrashIcon } from 'common/assets';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { ActionIcon, Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form, { FormState } from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import StockSelectInput from 'modules/stock/stock/components/select-input';
import StockGroupingItem from 'modules/stock/stock-grouping/components/item';
import StockItemSelectInput from 'modules/stock/stock-items/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { StockUsageFormType, formSchema } from './form-type';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  stockUsage?: StockUsageModel;
  onSubmit: (
    input: StockUsageMutationInput,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}

const DEFAULT_VALUE = { stockId: '', stockItemId: '' };
export default function StockUsageForm(props: Props) {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const { stockUsage, navigation } = props;
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteStockUsage();

  const dialog = useDialog();
  const resolver = useYupValidationResolver(formSchema());

  const initialValues = React.useMemo<StockUsageFormType>(
    () => ({
      stockUsageItems: stockUsage?.stockUsageItems?.map((item) => ({
        stockId: item.stock?.id,
        qty: item.qty,
        stockItemId: item.stockItem?.id,
      })) || [DEFAULT_VALUE],
    }),
    [stockUsage?.stockUsageItems],
  );

  const methods = useForm<StockUsageFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });
  const { control, setValue } = methods;
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'stockUsageItems',
    keyName: 'customId',
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const _input = {
          ...values,
          entityId: entity?.id,
        };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            stockUsageId: stockUsage?.id!,
          });
          queryClient.refetchQueries([stockUsageKey.listKey]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, stockUsage?.id, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!stockUsage}
      setNavigations={setNavigations}
      navigation={navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={stockUsage}
          title={t('common:stock_usage')}
          noDelete={!can(AuthorizationRules.StockUsagesDelete)}
          noEdit={!can(AuthorizationRules.StockUsagesUpdate)}
          noCancel
        />
        <Separator gap={16} direction="vertical" />
        <div className={moduleStyles.fullContainer}>
          {fields.map((field, index) => (
            <div className={moduleStyles.fullContainer} key={field.customId}>
              <div className={moduleStyles.sectionContainer}>
                <div className={moduleStyles.halfContainer}>
                  <StockSelectInput
                    name={`stockUsageItems.${index}.stockId`}
                    onAfterChange={(val) =>
                      setValue(`stockUsageItems.${index}.isAsset`, val?.isAsset)
                    }
                  />
                </div>
                <div className={moduleStyles.halfContainer}>
                  <div
                    className={moduleStyles.row({
                      align: 'start',
                    })}
                  >
                    <div className={moduleStyles.flex1}>
                      <HOCInput keys={[`stockUsageItems.${index}.isAsset`]}>
                        {({ [`stockUsageItems.${index}.isAsset`]: isAsset }) =>
                          isAsset ? (
                            <HOCInput
                              keys={[`stockUsageItems.${index}.stockId`]}
                            >
                              {({
                                [`stockUsageItems.${index}.stockId`]: stockId,
                              }) => (
                                <StockItemSelectInput
                                  stockId={stockId}
                                  name={`stockUsageItems.${index}.stockItemId`}
                                  label={t('inventory:barcode')}
                                  placeholder={t('common:choose_extra', {
                                    extra: t('inventory:barcode'),
                                  })}
                                  barcodeLabel
                                  dependStockId
                                  onAfterChange={(val) =>
                                    setValue(
                                      `stockUsageItems.${index}.stockItemDetail`,
                                      val,
                                    )
                                  }
                                />
                              )}
                            </HOCInput>
                          ) : (
                            <Input
                              type="number"
                              isMoneyFormat
                              hideControls
                              label={t('inventory:qty')}
                              placeholder={t('common:enter_extra', {
                                extra: t('inventory:qty'),
                              })}
                              name={`stockUsageItems.${index}.qty`}
                              required
                            />
                          )
                        }
                      </HOCInput>
                    </div>

                    <FormState>
                      {({ editable }) => (
                        <>
                          {editable && (
                            <>
                              <Separator gap={16} direction="horizontal" />
                              <ActionIcon
                                error
                                variant="transparent"
                                children={(size) => <TrashIcon size={size} />}
                                onClick={() => remove(index)}
                              />
                            </>
                          )}
                        </>
                      )}
                    </FormState>
                  </div>
                </div>
              </div>
              <StockGroupingItem
                keyName={`stockUsageItems.${index}.stockItemDetail`}
              />
            </div>
          ))}
          <FormState>
            {({ editable }) => (
              <>
                {editable && (
                  <>
                    <Separator gap={16} direction="vertical" />
                    <Button
                      onClick={() => append(DEFAULT_VALUE)}
                      leftIcon={(size) => <PlusIcon size={size} />}
                      variant="secondary"
                    >
                      {t('common:add_extra', { extra: t('inventory:item') })}
                    </Button>
                  </>
                )}
              </>
            )}
          </FormState>
        </div>
      </FormContent>
    </Form>
  );
}
