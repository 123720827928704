import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MassUpdateInput } from 'api-hooks/mass-update';
import { CancelIcon } from 'common/assets';
import notification from 'common/helpers/notification';
import HOCInput from 'components/common/hoc-input';
import SearchInput from 'components/common/search-input';
import Separator from 'components/common/separator';
import { ActionIcon } from 'components/elements/button';
import DatePicker from 'components/elements/date-picker';
import { Input } from 'components/elements/field';
import Form, { FormFooter } from 'components/elements/form';
import Pagination from 'components/elements/pagination';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { updateRateStyles } from './styles.css';

interface Props {
  columns: IColumn<any>[];
  setIsUpdateRate: React.Dispatch<React.SetStateAction<boolean>>;
  useGetData: (
    input?: any,
    options?: UseQueryOptions<any, ApiError>,
  ) => UseQueryResult<any, ApiError>;
  useMassUpdate: (
    options?:
      | UseMutationOptions<
          ApiResult<MessageResult>,
          ApiError,
          MassUpdateInput,
          unknown
        >
      | undefined,
  ) => UseMutationResult<ApiResult<MessageResult>, ApiError, MassUpdateInput>;
  type?: 'transfer' | 'outcome' | 'income';
}

type IdsType = { [key: string]: boolean };
type FormType = {
  rate: number | null;
  toRate: number | null;
  fromRate: number | null;
  ids: IdsType;
};

const transferValidation = {
  toRate: Yup.number().nullable().required(),
};

const defaultValidation = {
  rate: Yup.number().nullable().required(),
};

export default function UpdateRateComponent(props: Props) {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const {
    useGetData,
    setIsUpdateRate,
    useMassUpdate,
    columns: _columns,
    type,
  } = props;

  const isTransfer = type === 'transfer';

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [search, setSearch] = React.useState<string>('');
  const [transactionAtAfter, setTransactionAtAfter] =
    React.useState<Date | null>(null);
  const [transactionAtBefore, setTransactionAtBefore] =
    React.useState<Date | null>(null);

  const { mutateAsync, isLoading: isUpdateLoading } = useMassUpdate();

  const {
    data,
    isLoading,
    isFetching,
    error,
    extras: [{ columns }],
  } = useComposedQuery(
    useGetData,
    {
      params: {
        page,
        limit,
        filter: {
          entity_id: entity?.id!,
          transaction_at_after: transactionAtAfter,
          transaction_at_before: transactionAtBefore,
          ...(!isTransfer && {
            is_transaction_closed: false,
          }),
        },
        q: search,
      },
    },
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
    {
      input: {},
      extra: {},
      onSuccess: (data) => {
        if (!isTransfer) {
          data.filters?.forEach((filter) => {
            if (filter.name === 'transaction_at_after') {
              setTransactionAtAfter(
                filter.value ? new Date(filter.value) : null,
              );
            }
            if (filter.name === 'transaction_at_before') {
              setTransactionAtBefore(
                filter.value ? new Date(filter.value) : null,
              );
            }
          });
        }
      },
    },
  );

  React.useEffect(() => {
    if (!!error && !!error.message) {
      notification.error({ message: error?.message });
    }
  }, [error]);

  const resolver = useYupValidationResolver(
    Yup.object(isTransfer ? transferValidation : defaultValidation),
  );

  const methods = useForm<FormType>({
    mode: 'onChange',
    resolver,
    defaultValues: (() => {
      const temp: IdsType = {};
      data?.data?.forEach((item) => (temp[`${item.id}`] = false));
      return { ids: temp, rate: null, toRate: null, fromRate: null };
    })(),
  });

  const onSubmit = async ({ ids, rate, fromRate, toRate }: FormType) => {
    try {
      const _ids: string[] = [];
      Object.keys(ids).forEach((key) => ids[key] && _ids.push(key));
      const res = await mutateAsync({
        ids: _ids,
        entityId: entity?.id!,
        rate,
        fromRate,
        toRate,
      } as any);
      notification.success({ message: res.message });
      setIsUpdateRate(false);
    } catch (e) {
      notification.error({ message: e.message });
    }
  };

  const [headerRef, setHeaderRef] = React.useState<HTMLDivElement | null>(null);
  const [containerRef, setcontainerRef] = React.useState<HTMLDivElement | null>(
    null,
  );

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <div
        className={updateRateStyles.container}
        style={{ height: 'calc( 100% - 134px)' }}
        ref={setcontainerRef}
      >
        <div ref={setHeaderRef}>
          <div className={updateRateStyles.headerContainer}>
            <Text textVariant="HeadingSmall">
              {t('transaction:update_rate')}
            </Text>
            <ActionIcon
              children={(size) => <CancelIcon size={size} />}
              variant="transparent"
              onClick={() => setIsUpdateRate(false)}
            />
          </div>
          <Separator gap={32} direction="vertical" />
          <div className={updateRateStyles.topContainer}>
            <SearchInput setValue={setSearch} setPage={setPage} withLabel />
            <DatePicker
              onChange={(value) => setTransactionAtAfter(value)}
              value={transactionAtAfter}
              label={t('transaction:transaction_at_after')}
              placeholder={t('transaction:transaction_at_after')}
              noMargin
            />

            <DatePicker
              onChange={(value) => setTransactionAtBefore(value)}
              value={transactionAtBefore}
              label={t('transaction:transaction_at_before')}
              placeholder={t('transaction:transaction_at_before')}
              noMargin
            />
          </div>
          <Separator gap={32} direction="vertical" />
        </div>
        {headerRef?.clientHeight && containerRef?.clientHeight && (
          <div
            className={moduleStyles.tableContainer}
            style={{
              height:
                (containerRef?.clientHeight || 0) -
                (headerRef?.clientHeight || 0) -
                14,
            }}
          >
            <TableComponent
              data={data?.data || []}
              columns={columns!}
              isLoading={isLoading || isFetching}
            />
          </div>
        )}
        <Pagination
          {...{
            page,
            onPageChange: (page) => setPage(page),
            meta: data?.meta,
            limit,
            onLimitChange: (limit) => setLimit(limit),
          }}
        />
      </div>
      <FormFooter
        renderLeft={
          <HOCInput keys={['ids']}>
            {({ ids }) => (
              <>
                <Text textVariant="BodyBoldDefault">
                  {t('transaction:extra_transaction_selected', {
                    extra: Object.values(ids as IdsType).reduce((acc, curr) => {
                      if (curr) {
                        return (acc += 1);
                      } else {
                        return acc;
                      }
                    }, 0),
                  })}
                </Text>
              </>
            )}
          </HOCInput>
        }
        showSubmit={false}
        renderBeforeSubmit={
          <>
            {isTransfer ? (
              <>
                <Input
                  type="number"
                  name="fromRate"
                  hideControls
                  placeholder={t('common:enter_extra', {
                    extra: t('transfer:from_rate'),
                  })}
                />
                <Separator gap={16} direction="horizontal" />

                <Input
                  type="number"
                  name="toRate"
                  hideControls
                  placeholder={t('common:enter_extra', {
                    extra: t('transfer:to_rate'),
                  })}
                />
              </>
            ) : (
              <Input
                type="number"
                name="rate"
                hideControls
                placeholder={t('common:enter_extra', {
                  extra: t('transaction:new_rate'),
                })}
              />
            )}
            <Separator gap={16} direction="horizontal" />
            <HOCInput keys={['ids']}>
              {({ ids }) => (
                <Input
                  type="submit"
                  loading={isUpdateLoading}
                  text={t('transaction:change_rate')}
                  disabled={!Object.values(ids).find((val) => !!val)}
                />
              )}
            </HOCInput>
          </>
        }
      />
    </Form>
  );
}
