import { Filter } from 'api-hooks/common/model';
import { useGetInfiniteStockSimpleReport } from 'api-hooks/report';
import filterReduce from 'common/helpers/filter-reduce';
import { aMonthBefore } from 'common/utils/date';
import { useEntity } from 'hooks/use-entities';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import InfiniteReportContainer, {
  getNextPageParam,
} from './components/infinite-report-container';

const UPPER_FILTER_KEY = [
  'transaction_at_after',
  'transaction_at_before',
  'warehouse_id',
];

const REQUIRED_KEY = ['transaction_at_after'];

export default function StockSimpleReport() {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const [filters, setFilters] = React.useState<Filter[]>([]);

  const [headerRef, setHeaderRef] = React.useState<HTMLDivElement | null>();

  const query = useGetInfiniteStockSimpleReport(
    {
      params: {
        filter: {
          entity_id: entity?.id!,
          transaction_at_after: aMonthBefore(),
          ...(filterReduce(filters) as any),
        } as unknown as any,
      },
    },
    {
      getNextPageParam,
      cacheTime: 0,
      onSuccess: (data) => {
        setFilters((data.pages?.[0]?.filters || []) as any);
      },
    },
  );

  const _params = React.useMemo(
    () => ({
      filter: {
        ...(filterReduce(filters || []) as any),
        entity_id: entity?.id,
      },
    }),
    [entity?.id, filters],
  );

  return (
    <InfiniteReportContainer
      header={{
        title: t('common:stock_simple_report'),
        params: _params,
        category: 'stock_simple',
      }}
      setHeaderRef={setHeaderRef}
      {...query}
      filters={filters}
      setFilters={setFilters}
      headerOffsetHeight={headerRef?.offsetHeight}
      upperFilterKey={UPPER_FILTER_KEY}
      requiredKeys={REQUIRED_KEY}
    />
  );
}
