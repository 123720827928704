import { useDeleteChartOfAccountCategories } from 'api-hooks/chart-of-account-categories';
import { getChartOfAccountCategoriesKey } from 'api-hooks/chart-of-account-categories/query';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import {
  ChartOfAccountCategoriesFormProps,
  ChartOfAccountFormType,
} from './form-type';

export default function ChartOfAccountCategoriesForm(
  props: ChartOfAccountCategoriesFormProps,
) {
  const { t } = useTranslation();
  const { chartOfAccountCategories } = props;
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeleteChartOfAccountCategories();
  const { can } = useAuthorization();
  const dialog = useDialog();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().nullable().required(),
    }),
  );

  const intialValues = React.useMemo<ChartOfAccountFormType>(() => {
    return { name: chartOfAccountCategories?.name || '' };
  }, [chartOfAccountCategories?.name]);

  const methods = useForm<ChartOfAccountFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values: ChartOfAccountFormType) => {
      try {
        const _input = {
          name: values.name,
          entityId: entity?.id!,
        };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            id: chartOfAccountCategories!.id,
          });
          queryClient.refetchQueries([getChartOfAccountCategoriesKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [chartOfAccountCategories, close, deleteMutate, dialog, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!chartOfAccountCategories}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={chartOfAccountCategories}
          title={
            chartOfAccountCategories
              ? `${t('common:chart_of_account_categories')} - ${
                  chartOfAccountCategories.name
                }`
              : t('common:create_extra', {
                  extra: t('common:chart_of_account_categories'),
                })
          }
          noDelete={!can(AuthorizationRules.ChartOfAccountCategoriesDelete)}
          noEdit={!can(AuthorizationRules.ChartOfAccountCategoriesUpdate)}
        />
        <div className={moduleStyles.fullContainer}>
          <div className={moduleStyles.halfContainer}>
            <Input
              type="text"
              name="name"
              label={t('common:category')}
              placeholder={t('common:enter_extra', {
                extra: t('common:category'),
              })}
              required
            />
          </div>
        </div>
      </FormContent>
    </Form>
  );
}
