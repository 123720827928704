import { useCreateStockItemCode } from 'api-hooks/stock-item-code/mutation';
import { stockItemCodeKey } from 'api-hooks/stock-item-code/query';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import React from 'react';

import StockItemCodeForm from './components/form';

export default function StockItemCodeCreate(props: NavigationProps) {
  const { navigate, close } = useNavigation();
  const { can } = useAuthorization();
  const { mutateAsync, error } = useCreateStockItemCode();
  return (
    <StockItemCodeForm
      {...props}
      renderError={
        !!error &&
        !!error.message && (
          <>
            <Alert variant="error" description={error.message} />
            <Separator gap={8} />
          </>
        )
      }
      onSubmit={async (input, form) => {
        const result = await mutateAsync(input);
        close();
        form.reset();
        queryClient.refetchQueries([stockItemCodeKey.listKey]);
        if (can(AuthorizationRules.StockItemCodesView)) {
          navigate(NavigationRoutes.StockItemCodeView, {
            params: { id: result.data?.id },
          });
        }
        notification.success({ message: result?.message });
        return result.data;
      }}
    />
  );
}
