import { PlusIcon, TrashIcon } from 'common/assets';
import HOCInput from 'components/common/hoc-input';
import { ActionIcon, Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import { FormContext } from 'components/elements/form';
import WarehouseSelectInput from 'modules/master/warehouse/components/select-input';
import StockSelectInput from 'modules/stock/stock/components/select-input';
import StockItemSelectInput from 'modules/stock/stock-items/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import React, { useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { StockTransferFormType, StockTransferItemType } from './form-type';

const DEFAULT_VALUE: StockTransferItemType = {
  stockId: '',
  fromWarehouseId: '',
  toWarehouseId: '',
  stockItemId: '',
  isAsset: false,
  qty: 0,
};

export default function StockTransferItem({ t }) {
  const context = useContext(FormContext);
  const { control, setValue } = useFormContext<StockTransferFormType>();
  const { fields, append, remove } = useFieldArray({
    name: 'stockTransferItems',
    control,
    keyName: 'customId',
  });

  return (
    <>
      {fields.map((field, index) => (
        <div className={moduleStyles.itemContainer} key={field.customId}>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <StockSelectInput
                name={`stockTransferItems.${index}.stockId`}
                onAfterChange={(value) => {
                  !value &&
                    setValue(`stockTransferItems.${index}.stockItemId`, '');
                  setValue(
                    `stockTransferItems.${index}.isAsset`,
                    value?.isAsset,
                  );
                }}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <HOCInput keys={[`stockTransferItems.${index}.isAsset`]}>
                {({ [`stockTransferItems.${index}.isAsset`]: isAsset }) =>
                  isAsset ? (
                    <HOCInput keys={[`stockTransferItems.${index}.stockId`]}>
                      {({
                        [`stockTransferItems.${index}.stockId`]: stockId,
                      }) => (
                        <StockItemSelectInput
                          stockId={stockId}
                          name={`stockTransferItems.${index}.stockItemId`}
                          label={t('inventory:barcode')}
                          placeholder={t('common:choose_extra', {
                            extra: t('inventory:barcode'),
                          })}
                          barcodeLabel
                          dependStockId
                        />
                      )}
                    </HOCInput>
                  ) : (
                    <Input
                      type="number"
                      isMoneyFormat
                      hideControls
                      name={`stockTransferItems.${index}.qty`}
                      label={t('inventory:quantity')}
                      placeholder={t('common:choose_extra', {
                        extra: t('inventory:quantity'),
                      })}
                    />
                  )
                }
              </HOCInput>
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <WarehouseSelectInput
                name={`stockTransferItems.${index}.fromWarehouseId`}
                label={t('inventory:from_warehouse')}
                placeholder={t('common:choose_extra', {
                  extra: t('inventory:from_warehouse'),
                })}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <WarehouseSelectInput
                name={`stockTransferItems.${index}.toWarehouseId`}
                label={t('inventory:to_warehouse')}
                placeholder={t('common:choose_extra', {
                  extra: t('inventory:to_warehouse'),
                })}
              />
            </div>
          </div>

          {!!context.editable && (
            <ActionIcon
              children={(size) => <TrashIcon size={size} />}
              onClick={() => remove(index)}
              error
              variant="transparent"
            />
          )}
        </div>
      ))}

      {!!context.editable && (
        <Button
          leftIcon={(size) => <PlusIcon size={size} />}
          variant="secondary"
          onClick={() => append(DEFAULT_VALUE)}
        >
          {t('common:add_extra', { extra: t('inventory:item') })}
        </Button>
      )}
    </>
  );
}
