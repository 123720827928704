import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  AccountModel,
  getAccountInput,
  getAccountsInput,
  AccountLiteModel,
} from './model';

export function useGetAccounts(
  input?: getAccountsInput,
  options?: UseQueryOptions<ExtendedApiResult<AccountLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<AccountLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getAccounts', input?.params],
      () =>
        QueryFetchFunction({
          url: 'accounts',
          params: input?.params,
        }),
      options,
    ),
    AccountLiteModel,
  );
}

export function useGetAccount(
  input?: getAccountInput,
  options?: UseQueryOptions<ApiResult<AccountModel>, ApiError>,
): UseQueryResult<ApiResult<AccountModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getAccount', input?.id],
      () =>
        QueryFetchFunction({
          url: `accounts/${input?.id}`,
        }),
      options,
    ),
    AccountModel,
  );
}
export function getAccountsKey(input?: getAccountsInput) {
  return ['getAccounts', input?.params];
}

export function getAccountKey(input?: getAccountInput) {
  return ['getAccount', input?.id];
}
