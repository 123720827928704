import {
  getAccountMutationsKey,
  useCreateAccountMutation,
} from 'api-hooks/account-mutation';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import React from 'react';

import AccountMutationForm from './components/form';

const AccountMutationCreate = (props: NavigationProps) => {
  const { navigate, close } = useNavigation();
  const { can } = useAuthorization();
  const { mutateAsync, error } = useCreateAccountMutation();
  const { entity } = useEntity();

  return (
    <AccountMutationForm
      {...props}
      renderError={
        !!error &&
        !!error.message && (
          <>
            <Alert variant="error" description={error.message} />
            <Separator gap={8} />
          </>
        )
      }
      onSubmit={async ({ currencyId, ...input }, form) => {
        const _input = {
          ...input,
          entityId: entity?.id,
        };

        const result = await mutateAsync(_input);
        close();
        form.reset();

        queryClient.refetchQueries([getAccountMutationsKey()[0]]);
        if (can(AuthorizationRules.WalletMutationsView)) {
          navigate(NavigationRoutes.AccountMutationView, {
            params: { id: result.data?.id },
          });
        }
        notification.success({ message: result?.message });

        return result.data;
      }}
    />
  );
};

export default AccountMutationCreate;
