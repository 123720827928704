import classNames from 'classnames';
import { PlusCircleIcon, TrashIcon } from 'common/assets';
import colors from 'common/styles/colors';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { FormState } from 'components/elements/form';
import Text from 'components/elements/text';
import BrandMultiAllSelect from 'modules/entity-settings/brand/components/multi-all-select-input';
import EntitySelectInput from 'modules/master/entity/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { entityAccessStyles } from './styles.css';

export const EmployeeEntityAccess = () => {
  const { t } = useTranslation();
  const { control } = useFormContext<any>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'entities',
    keyName: 'customId',
  });

  const onAdd = () =>
    append({
      id: '',
      brands: [],
    });

  return (
    <div className={moduleStyles.fullContainer}>
      <Text textVariant="HeadingSmall">{t('common:entity_access')}</Text>
      <Separator gap={48} direction="vertical" />

      <div>
        <FormState>
          {({ editable }) => (
            <>
              {fields.map((field, index) => (
                <div
                  className={classNames(
                    moduleStyles.fullContainer,
                    entityAccessStyles.container,
                  )}
                  key={field.customId}
                >
                  {editable && !!index && (
                    <div className={entityAccessStyles.deleteButtonSm}>
                      <Button
                        variant="tertiary"
                        error
                        leftIcon={(size) => (
                          <TrashIcon
                            size={size}
                            color={colors.sentimentError}
                          />
                        )}
                        onClick={() => remove(index)}
                        className={entityAccessStyles.deleteButton}
                      >
                        {t('common:delete')}
                      </Button>
                    </div>
                  )}
                  <div className={moduleStyles.sectionContainer}>
                    <div className={moduleStyles.halfContainer}>
                      <EntitySelectInput
                        label={t('common:entity')}
                        placeholder={t('common:choose_extra', {
                          extra: t('common:entity'),
                        })}
                        name={`entities.${index}.id`}
                        required
                      />
                    </div>

                    <div className={moduleStyles.halfContainer}>
                      <HOCInput keys={[`entities.${index}.id`]}>
                        {(value) => {
                          const val = value[`entities.${index}.id`];
                          return (
                            <BrandMultiAllSelect
                              label={t('common:brands')}
                              entityId={val}
                              name={`entities.${index}.brands`}
                              required
                              disabled={!val}
                              model="employees"
                            />
                          );
                        }}
                      </HOCInput>
                    </div>
                  </div>

                  {editable && !!index && (
                    <div className={entityAccessStyles.deleteButtonXl}>
                      <Separator gap={16} direction="vertical" />
                      <Button
                        variant="tertiary"
                        error
                        leftIcon={(size) => (
                          <TrashIcon
                            size={size}
                            color={colors.sentimentError}
                          />
                        )}
                        onClick={() => remove(index)}
                        className={entityAccessStyles.deleteButton}
                      >
                        {t('common:delete')}
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </FormState>
      </div>

      <FormState>
        {({ editable }) =>
          editable && (
            <>
              <Button
                variant="tertiary"
                leftIcon={(size) => <PlusCircleIcon size={size} />}
                onClick={() => onAdd()}
              >
                {t('common:add')}
              </Button>
            </>
          )
        }
      </FormState>
    </div>
  );
};
