import {
  getChartOfAccountsKey,
  useDeleteChartOfAccounts,
} from 'api-hooks/chart-of-accounts';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import EntityCurrencySelectInput from 'modules/entity-settings/entity-currency/components/select-input';
import ChartOfAccountCategoriesSelectInput from 'modules/master/chart-of-account-categories/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { ChartOfAccountsFormProps, ChartOfAccountsFormType } from './form-type';
import ChartOfAccountsSelectInput from './select-input';

export default function ChartOfAccountsForm(props: ChartOfAccountsFormProps) {
  const { t } = useTranslation();
  const { chartOfAccounts } = props;
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeleteChartOfAccounts();
  const { can } = useAuthorization();
  const dialog = useDialog();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().nullable().required(),
      number: Yup.string().nullable().required(),
      categoryId: Yup.string().nullable().required(),
      balance: Yup.number().required().nullable(),
      entityCurrencyId: Yup.string().nullable().required(),
    }),
  );

  const intialValues = React.useMemo<ChartOfAccountsFormType>(() => {
    return {
      name: chartOfAccounts?.name || null,
      number: chartOfAccounts?.number || null,
      parentId: chartOfAccounts?.parent?.id || null,
      categoryId: chartOfAccounts?.category.id || null,
      desription: chartOfAccounts?.description || null,
      balance: chartOfAccounts ? chartOfAccounts?.balance : null,
      entityCurrencyId: chartOfAccounts?.entityCurrency?.id || null,
    };
  }, [chartOfAccounts]);

  const methods = useForm<ChartOfAccountsFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values: ChartOfAccountsFormType) => {
      try {
        const _input = {
          ...values,
          entityId: entity?.id!,
        };
        await props.onSubmit(_input as any, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            id: chartOfAccounts!.id,
          });
          queryClient.refetchQueries([getChartOfAccountsKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [chartOfAccounts, close, deleteMutate, dialog, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!chartOfAccounts}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={chartOfAccounts}
          title={
            chartOfAccounts
              ? `${t('common:chart_of_accounts')} - ${chartOfAccounts.name}`
              : t('common:create_extra', {
                  extra: t('common:chart_of_accounts'),
                })
          }
          noDelete={!can(AuthorizationRules.ChartOfAccountsDelete)}
          noEdit={!can(AuthorizationRules.ChartOfAccountsUpdate)}
        />
        <div className={moduleStyles.fullContainer}>
          <div className={moduleStyles.halfContainer}>
            <Input
              type="text"
              name="name"
              label={t('common:name')}
              placeholder={t('common:enter_extra', {
                extra: t('common:name'),
              })}
              required
            />
            <Input
              type="text"
              name="number"
              label={t('common:number_full')}
              placeholder={t('common:enter_extra', {
                extra: t('common:number_full'),
              })}
              required
            />
            <ChartOfAccountsSelectInput name="parentId" />
            <ChartOfAccountCategoriesSelectInput name="categoryId" />
            <EntityCurrencySelectInput
              name="entityCurrencyId"
              required
              disabled={!!chartOfAccounts}
            />
            <Input
              type="number"
              name="balance"
              label={t('common:opening_balance')}
              placeholder={t('common:enter_extra', {
                extra: t('common:opening_balance'),
              })}
              required
              hideControls
              isMoneyFormat
            />
            <Input
              type="text"
              name="description"
              label={t('common:description')}
              placeholder={t('common:enter_extra', {
                extra: t('common:description'),
              })}
            />
          </div>
        </div>
      </FormContent>
    </Form>
  );
}
