import Separator from 'components/common/separator';
import Text from 'components/elements/text';
import { moduleStyles } from 'modules/styles.css';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import FirstInstruction from 'src/common/assets/telegram/instruction_1.svg';
import SecondInstruction from 'src/common/assets/telegram/instruction_2.svg';
import ThirdInstruction from 'src/common/assets/telegram/instruction_3.svg';
import FourthInstruction from 'src/common/assets/telegram/instruction_4.svg';

import { employeeStyle } from '../styles.css';

interface ItemProps {
  title: string;
  index: number;
  src?: any;
}
const ModalItem = (props: ItemProps) => {
  const { index, title, src } = props;
  return (
    <>
      <div className={moduleStyles.row()}>
        <Text
          textVariant="BodyBoldDefault"
          className={employeeStyle.modalContentTitle}
        >
          {index + 1}.
        </Text>
        <Text textVariant="BodyBoldDefault">{title}</Text>
      </div>

      {!!src && (
        <Separator gap={32} direction="vertical">
          <Image
            alt={`telegram-instruction-${index + 1}`}
            src={src}
            width={452}
            height={380}
          />
        </Separator>
      )}
    </>
  );
};

export default function ModalContent() {
  const { t } = useTranslation();

  const instructions = React.useMemo<{ title: string; src?: any }[]>(
    () => [
      { title: t('employee:instruction_1'), src: FirstInstruction },
      {
        title: t('employee:instruction_2'),
        src: SecondInstruction,
      },
      {
        title: t('employee:instruction_3'),
        src: ThirdInstruction,
      },
      {
        title: t('employee:instruction_4'),
      },
    ],
    [t],
  );

  return (
    <div className={employeeStyle.modalContentContainer}>
      {instructions.map((item, index) => (
        <React.Fragment key={index}>
          <ModalItem {...item} index={index} />
        </React.Fragment>
      ))}
    </div>
  );
}

export function ModalNotificationContent() {
  const { t } = useTranslation();

  const instructions = React.useMemo<{ title: string; src?: any }[]>(
    () => [
      { title: t('employee:instruction_5'), src: FirstInstruction },
      {
        title: t('employee:instruction_2'),
        src: FourthInstruction,
      },
      {
        title: t('employee:instruction_6'),
      },
    ],
    [t],
  );

  return (
    <div className={employeeStyle.modalContentContainer}>
      {instructions.map((item, index) => (
        <React.Fragment key={index}>
          <ModalItem {...item} index={index} />
        </React.Fragment>
      ))}
    </div>
  );
}
