import { ArrowRight } from '@phosphor-icons/react';
import { ApprovalStatusQueryEnum, useGetApprovals } from 'api-hooks/approval';
import { AlertIcon, CancelIcon, CheckIcon, ClockIcon } from 'common/assets';
import { convertDateStart } from 'common/utils/date';
import ListHeader from 'components/common/list-header';
import { sub } from 'date-fns';
import { useEntity } from 'hooks/use-entities';
import { GenericQueryTable, useGenericTableProps } from 'modules/common/table';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import useApprovalsColumns from './helpers/use-columns';

export default function ApprovalList() {
  const { entity } = useEntity();
  const { t } = useTranslation();

  const [status, setStatus] = React.useState<string>(
    ApprovalStatusQueryEnum.PENDING,
  );

  const { columns } = useApprovalsColumns(status);

  const tabs = React.useMemo(
    () => [
      {
        value: ApprovalStatusQueryEnum.PENDING,
        label: t('common:pending'),
        icon: (size, color) => <ClockIcon size={size} color={color} />,
      },
      {
        value: ApprovalStatusQueryEnum.REJECTED,
        label: t('common:rejected'),
        icon: (size, color) => <CancelIcon size={size} color={color} />,
      },
      {
        value: ApprovalStatusQueryEnum.IN_PROGRESS,
        label: t('common:in_progress'),
        icon: (size, color) => <ArrowRight size={size} color={color} />,
      },
      {
        value: ApprovalStatusQueryEnum.COMPLETED,
        label: t('common:approved'),
        icon: (size, color) => <CheckIcon size={size} color={color} />,
      },
      {
        value: ApprovalStatusQueryEnum.CANCELLED,
        label: t('common:cancelled'),
        icon: (size, color) => <AlertIcon size={size} color={color} />,
      },
    ],
    [t],
  );

  const { refetch, setFilters, ...table } = useGenericTableProps(
    columns,
    useGetApprovals,
    {
      params: {
        status,
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
  );

  const onAfterTabValueChange = React.useCallback(
    (value: ApprovalStatusQueryEnum) => {
      setStatus(value);
      setFilters((prev) => {
        const temp = prev.map((item) => {
          if (item.name === 'requested_at_after') {
            return {
              ...item,
              value:
                value === ApprovalStatusQueryEnum.COMPLETED
                  ? sub(convertDateStart(new Date()), {
                      days: 30,
                    }).toISOString()
                  : null,
            };
          }
          return item;
        });
        return temp;
      });
    },
    [setFilters],
  );

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:approval')}
        showAdd={false}
        onRefresh={refetch}
      />
      <GenericQueryTable
        tabs={{
          onChangeTab(tab) {
            onAfterTabValueChange(tab as any);
          },
          options: tabs,
          tab: status,
        }}
        {...table}
        setFilters={setFilters}
      />
    </div>
  );
}
