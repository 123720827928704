import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  PurchaseRequisitionsDeleteInput,
  PurchaseRequisitionsExtendDeadlineInput,
  PurchaseRequisitionsModel,
  PurchaseRequisitionsMutationInput,
  PurchaseRequisitionsUpdateInput,
} from './model';

export function useCreatePurchaseRequisitions(
  options?: UseMutationOptions<
    ApiResult<PurchaseRequisitionsModel>,
    ApiError,
    PurchaseRequisitionsMutationInput
  >,
): UseMutationResult<
  ApiResult<PurchaseRequisitionsModel>,
  ApiError,
  PurchaseRequisitionsMutationInput
> {
  return useMutation<
    ApiResult<PurchaseRequisitionsModel>,
    ApiError,
    PurchaseRequisitionsMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'request-forms',
      method: 'POST',
      classType: PurchaseRequisitionsModel,
      body,
    });
  }, options);
}

export function useUpdatePurchaseRequisitions(
  options?: UseMutationOptions<
    ApiResult<PurchaseRequisitionsModel>,
    ApiError,
    PurchaseRequisitionsUpdateInput
  >,
): UseMutationResult<
  ApiResult<PurchaseRequisitionsModel>,
  ApiError,
  PurchaseRequisitionsUpdateInput
> {
  return useMutation<
    ApiResult<PurchaseRequisitionsModel>,
    ApiError,
    PurchaseRequisitionsUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `request-forms/${body.id}`,
      method: 'PUT',
      classType: PurchaseRequisitionsModel,
      body: body.body,
    });
  }, options);
}

export function useDeletePurchaseRequisitions(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    PurchaseRequisitionsDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, PurchaseRequisitionsDeleteInput> {
  return useMutation<MessageResult, ApiError, PurchaseRequisitionsDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `request-forms/${body.id}`,
        method: 'DELETE',
      });
    },
    options,
  );
}

export function useExtendDeadlinePurchaseRequisitions(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    PurchaseRequisitionsExtendDeadlineInput
  >,
): UseMutationResult<
  MessageResult,
  ApiError,
  PurchaseRequisitionsExtendDeadlineInput
> {
  return useMutation<
    MessageResult,
    ApiError,
    PurchaseRequisitionsExtendDeadlineInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `request-forms/${body.id}/extend-deadline`,
      method: 'POST',
      body: body.body,
    });
  }, options);
}
