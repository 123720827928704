import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  WarehouseDeleteInput,
  WarehouseModel,
  WarehouseMutationInput,
  WarehouseUpdateInput,
} from './model';

export function useCreateWarehouse(
  options?: UseMutationOptions<
    ApiResult<WarehouseModel>,
    ApiError,
    WarehouseMutationInput
  >,
): UseMutationResult<
  ApiResult<WarehouseModel>,
  ApiError,
  WarehouseMutationInput
> {
  return useMutation<
    ApiResult<WarehouseModel>,
    ApiError,
    WarehouseMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'warehouses',
      method: 'POST',
      classType: WarehouseModel,
      body,
    });
  }, options);
}

export function useUpdateWarehouse(
  options?: UseMutationOptions<
    ApiResult<WarehouseModel>,
    ApiError,
    WarehouseUpdateInput
  >,
): UseMutationResult<
  ApiResult<WarehouseModel>,
  ApiError,
  WarehouseUpdateInput
> {
  return useMutation<ApiResult<WarehouseModel>, ApiError, WarehouseUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `warehouses/${body.warehouseId}`,
        method: 'PUT',
        classType: WarehouseModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteWarehouse(
  options?: UseMutationOptions<MessageResult, ApiError, WarehouseDeleteInput>,
): UseMutationResult<MessageResult, ApiError, WarehouseDeleteInput> {
  return useMutation<MessageResult, ApiError, WarehouseDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `warehouses/${body.warehouseId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
