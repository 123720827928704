import {
  useCreateReconciliation,
  getReconciliationsKey,
} from 'api-hooks/reconciliation';
import { NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import useNavigation from 'hooks/use-navigation';
import React from 'react';

import ReconciliationForm from './components/form';

export default function ReconciliationCreate(props: NavigationProps) {
  const { navigate, close } = useNavigation();
  const { mutateAsync, error } = useCreateReconciliation();

  return (
    <div>
      <ReconciliationForm
        {...props}
        renderError={
          !!error &&
          !!error.message && (
            <>
              <Alert variant="error" description={error.message} />
              <Separator gap={8} />
            </>
          )
        }
        onSubmit={async (input, form) => {
          const result = await mutateAsync(input);
          close();
          form.reset();
          queryClient.refetchQueries([getReconciliationsKey()[0]]);
          navigate(NavigationRoutes.ReconciliationList);
          notification.success({ message: result?.message });
          return result.data;
        }}
      />
    </div>
  );
}
