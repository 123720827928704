import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  EmployeeModel,
  EmployeeChangePasswordInput,
  EmployeeMutationInput,
  EmployeeUpdateInput,
  Employee2FAModel,
  Employee2FAInput,
  EmployeeUpdateStatusInput,
} from './model';

export function useCreateEmployee(
  options?: UseMutationOptions<
    ApiResult<EmployeeModel>,
    ApiError,
    EmployeeMutationInput
  >,
): UseMutationResult<
  ApiResult<EmployeeModel>,
  ApiError,
  EmployeeMutationInput
> {
  return useMutation<ApiResult<EmployeeModel>, ApiError, EmployeeMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'employees',
        method: 'POST',
        classType: EmployeeModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateEmployee(
  options?: UseMutationOptions<
    ApiResult<EmployeeModel>,
    ApiError,
    EmployeeUpdateInput
  >,
): UseMutationResult<ApiResult<EmployeeModel>, ApiError, EmployeeUpdateInput> {
  return useMutation<ApiResult<EmployeeModel>, ApiError, EmployeeUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `employees/${body.employeeId}`,
        method: 'PUT',
        classType: EmployeeModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useResetEmployee2FA(
  options?: UseMutationOptions<
    ApiResult<Employee2FAModel>,
    ApiError,
    Employee2FAInput
  >,
): UseMutationResult<ApiResult<Employee2FAModel>, ApiError, Employee2FAInput> {
  return useMutation<ApiResult<Employee2FAModel>, ApiError, Employee2FAInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `employees/${body.employeeId}/google-2fa`,
        method: 'PATCH',
        classType: Employee2FAModel,
      });
    },
    options,
  );
}

export function useChangePassword(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    EmployeeChangePasswordInput
  >,
): UseMutationResult<MessageResult, ApiError, EmployeeChangePasswordInput> {
  return useMutation<MessageResult, ApiError, EmployeeChangePasswordInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'me/auth/change-password',
        method: 'POST',
        body,
      });
    },
    options,
  );
}

export function useUpdateEmployeeStatus(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    EmployeeUpdateStatusInput
  >,
): UseMutationResult<MessageResult, ApiError, EmployeeUpdateStatusInput> {
  return useMutation<MessageResult, ApiError, EmployeeUpdateStatusInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `employees/${body.employeeId}/update-active-status`,
        method: 'PATCH',
        body: {
          isActive: body.isActive,
        },
      });
    },
    options,
  );
}
