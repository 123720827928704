import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Text from 'components/elements/text';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import BankSelectInput from './bank-select-input';

export default function BankMutation() {
  const { control } = useFormContext();
  const connected = useWatch({ control, name: 'isBankConnected' });
  const { t } = useTranslation();

  if (!connected) return <></>;

  return (
    <>
      <Separator gap={48} direction="vertical" />
      <div className={moduleStyles.boxContainer}>
        <Text textVariant="HeadingSmall">{t('common:bank_mutation')}</Text>
        <div className={moduleStyles.fullContainer}>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <BankSelectInput name="bankType" required disabled />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="number"
                name="interval"
                required
                label={t('account:interval_second')}
                placeholder={t('common:enter_extra', {
                  extra: t('common:interval'),
                })}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
