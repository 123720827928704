import { PlusIcon, TrashIcon } from 'common/assets';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { FormState } from 'components/elements/form';
import { useEntity } from 'hooks/use-entities';
import BrandMultiAllSelect from 'modules/entity-settings/brand/components/multi-all-select-input';
import DivisionSelectInput from 'modules/master/division/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import ApprovalLevelForm from './approval-level-form';
import CCForm from './cc-form';
import { approvalMasterStyles } from './styles.css';

export default function ApprovalDivisionForm() {
  const { control, setValue } = useFormContext<any>();
  const { entity } = useEntity();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'divisions',
    keyName: 'customId',
  });

  const onRemove = (customId: string) => {
    const temp = fields;
    remove();
    const filtered = temp.filter((item) => item.customId !== customId);
    setValue('divisions', filtered);
  };

  const onAdd = () =>
    append({
      divisionId: '',
      brands: [],
      details: [
        {
          employeeId: '',
          order: 0,
        },
      ],
      carbonCopies: [],
    });

  return (
    <>
      <Separator gap={32} direction="vertical" />
      {fields.map((item, index) => (
        <>
          <div
            key={`divisions.${item.customId}`}
            className={approvalMasterStyles.itemContainer}
          >
            <div className={moduleStyles.fullContainer}>
              <FormState>
                {({ editable }) =>
                  editable && (
                    <div className={approvalMasterStyles.headerButton}>
                      <Button
                        variant="tertiary"
                        error
                        type="button"
                        onClick={() => onRemove(item?.customId)}
                      >
                        <TrashIcon size={24} />
                      </Button>
                    </div>
                  )
                }
              </FormState>
              <div className={moduleStyles.sectionContainer}>
                <div className={moduleStyles.halfContainer}>
                  <DivisionSelectInput name={`divisions.${index}.divisionId`} />
                </div>
                <div className={moduleStyles.halfContainer}>
                  <BrandMultiAllSelect
                    name={`divisions.${index}.brands`}
                    required
                    entityId={entity?.id!}
                  />
                </div>
              </div>
            </div>

            <div className={moduleStyles.sectionContainer}>
              <div className={moduleStyles.halfContainer}>
                <ApprovalLevelForm idx={index} name="divisions" />
              </div>
              <div className={moduleStyles.halfContainer}>
                <CCForm idx={index} />
              </div>
            </div>
          </div>
          {index !== fields.length - 1 && (
            <Separator direction="vertical" gap={48} />
          )}
        </>
      ))}

      {!!fields.length && <Separator gap={48} direction="vertical" />}
      <FormState>
        {({ editable }) =>
          editable && (
            <div>
              <Button
                onClick={() => onAdd()}
                type="button"
                variant="secondary"
                leftIcon={(size) => <PlusIcon size={size} />}
                disabled={!editable}
              >
                {`${t('common:add')} ${t('common:division')}`}
              </Button>
            </div>
          )
        }
      </FormState>
    </>
  );
}
