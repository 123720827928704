import { Input } from 'components/elements/field';
import CurrencySelectInput from 'modules/master/currency/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const EntityInformationForm = ({ hasValue }: { hasValue?: boolean }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={moduleStyles.halfContainer}>
        <Input
          label={t('common:entity')}
          placeholder={t('common:enter_extra', {
            extra: t('common:name'),
          })}
          name="name"
          type="text"
          required
        />
      </div>
      <div className={moduleStyles.halfContainer}>
        <Input
          label={t('common:description')}
          placeholder={t('common:enter_extra', {
            extra: t('common:description'),
          })}
          name="description"
          type="text"
        />
      </div>
      <div className={moduleStyles.halfContainer}>
        <CurrencySelectInput
          name="currencyId"
          placeholder={t('common:choose_extra', {
            extra: t('entity:base_currency'),
          })}
          label={t('entity:base_currency')}
          disabled={!!hasValue}
        />
      </div>
    </div>
  );
};

export default EntityInformationForm;
