import { FileResultModel } from 'api-hooks/common';
import { CommonSimpleModel, getIndexInput } from 'api-hooks/common/model';
import { WarehouseModel } from 'api-hooks/warehouse';
import { Expose, Type } from 'class-transformer';

// Enum
export enum StockAdjustmentTypeEnum {
  out = 'out',
  in = 'in',
}

// Model

export class WarehouseModelWithEntity extends WarehouseModel {
  @Expose({ name: 'entity_id' })
  entityId: string;
}
export class StockAdjustmentLiteModel {
  id: string;
  code: string;

  @Type(() => WarehouseModelWithEntity)
  warehouse: WarehouseModelWithEntity;

  @Expose({ name: 'created_by' })
  @Type(() => CommonSimpleModel)
  createdBy: CommonSimpleModel;

  @Expose({ name: 'updated_by' })
  @Type(() => CommonSimpleModel)
  updatedBy: CommonSimpleModel | null;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;
}

class StockStockModel {
  id: string;
  code: string;
  name: string;
}

export class StockAdjustmentItemItemModel {
  id: string;
  name: string;
  merk: string;
  barcode: string;
  description: string;

  @Type(() => FileResultModel)
  files: FileResultModel[];

  @Type(() => WarehouseModelWithEntity)
  warehouse: WarehouseModelWithEntity;

  @Expose({ name: 'is_used' })
  isUsed: boolean;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;

  @Expose({ name: 'received_at' })
  @Type(() => Date)
  receivedAt: Date;
}

export class StockAdjustmentItemModel {
  id: string;
  qty: number;
  type: StockAdjustmentTypeEnum;

  @Type(() => StockStockModel)
  stock: StockStockModel;

  @Type(() => Number)
  price: number;

  @Expose({ name: 'stock_item' })
  @Type(() => StockAdjustmentItemItemModel)
  stockItem: StockAdjustmentItemItemModel | null;
}
export class StockAdjustmentModel extends StockAdjustmentLiteModel {
  description: string;

  @Expose({ name: 'stock_adjustment_items' })
  @Type(() => StockAdjustmentItemModel)
  stockAdjustmentItems: StockAdjustmentItemModel[];
}

// Input

export type getStockAdjustmentsInput = getIndexInput;

export type getStockAdjustmentInput = {
  stockAdjustmentId: string;
};

export type StockAdjustmentItemInput = {
  type: string;
  stockId: string;
  price: number;
  qty: number;
  stockItemId: string;
  merk: string;
  description: string;
  files: string[];
};

export type StockAdjustmentMutationInput = {
  entityId: string;
  warehouseId: string;
  transactionAt: Date;
  description: string;
  stockAdjustmentItems: StockAdjustmentItemInput[];
  code: string;
};

export type StockAdjustmentUpdateInput = {
  stockAdjustmentId: string;
  body: StockAdjustmentMutationInput;
};

export type StockAdjustmentDeleteInput = {
  stockAdjustmentId: string;
};
