import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';
import { client } from 'hooks/use-ky';

import {
  IncomeDeleteInput,
  IncomeModel,
  IncomeMutationInput,
  IncomeUpdateInput,
} from './model';

export function useCreateIncome(
  options?: UseMutationOptions<
    ApiResult<IncomeModel>,
    ApiError,
    IncomeMutationInput
  >,
): UseMutationResult<ApiResult<IncomeModel>, ApiError, IncomeMutationInput> {
  return useMutation<ApiResult<IncomeModel>, ApiError, IncomeMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'incomes',
        method: 'POST',
        classType: IncomeModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateIncome(
  options?: UseMutationOptions<
    ApiResult<IncomeModel>,
    ApiError,
    IncomeUpdateInput
  >,
): UseMutationResult<ApiResult<IncomeModel>, ApiError, IncomeUpdateInput> {
  return useMutation<ApiResult<IncomeModel>, ApiError, IncomeUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `incomes/${body.incomeId}`,
        method: 'PUT',
        classType: IncomeModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteIncome(
  options?: UseMutationOptions<MessageResult, ApiError, IncomeDeleteInput>,
): UseMutationResult<MessageResult, ApiError, IncomeDeleteInput> {
  return useMutation<MessageResult, ApiError, IncomeDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `incomes/${body.incomeId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}

export function useImportIncome(
  options?: UseMutationOptions<any>,
): UseMutationResult<any> {
  return useMutation<ApiResult<MessageResult>>(async function (body) {
    return await client
      .post('incomes/import', {
        body: body as any,
      })
      .json<ApiResult<MessageResult>>();
  }, options);
}
