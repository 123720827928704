import { NotifyEnum } from 'api-hooks/outcome';
import classNames from 'classnames';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import { Radio } from 'components/elements/radio';
import DivisionSelectInput from 'modules/master/division/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { NotificationTypeEnum } from './form-type';
import { NotificationEmail } from './notification-modal/notification-email';
import { NotificationEmployee } from './notification-modal/notification-employee';
import { outcomeTableStyles } from './styles.css';

export default function NotificationForm() {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext<any>();
  const { field } = useController({ control, name: 'notifyType' });

  const notifyOption = React.useMemo(
    () => [
      {
        label: t('common:account'),
        value: NotifyEnum.account,
      },
      {
        label: t('common:amount'),
        value: NotifyEnum.amount,
      },
      {
        label: t('common:description'),
        value: NotifyEnum.description,
      },
      {
        label: t('modal:attachments'),
        value: NotifyEnum.attachments,
      },
    ],
    [t],
  );

  return (
    <div>
      <Separator gap={16} direction="vertical" />
      <Input
        type="checkbox-group"
        name="notifyOptions"
        label={t('modal:notify_options')}
        data={notifyOption}
      />

      <div
        className={classNames(
          moduleStyles.row(),
          outcomeTableStyles.itemsCenter,
        )}
      >
        <Radio
          label={t('common:send_to_employee')}
          checked={field.value === NotificationTypeEnum.employee}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            e.target.checked && field.onChange(NotificationTypeEnum.employee)
          }
        />
        {field.value === NotificationTypeEnum.employee && (
          <>
            <Separator gap={16} direction="horizontal" />
            <DivisionSelectInput
              name="divisionNotifyId"
              label=""
              className={moduleStyles.flex1}
              noMargin
              onAfterChange={() => setValue('employeeId', [])}
            />
          </>
        )}
      </div>
      {field.value === NotificationTypeEnum.employee && (
        <NotificationEmployee name="employeeId" watchName="divisionNotifyId" />
      )}
      <Separator direction="vertical" gap={16} />
      <Radio
        label={t('common:send_to_email')}
        checked={field.value === NotificationTypeEnum.email}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          e.target.checked && field.onChange(NotificationTypeEnum.email)
        }
      />
      {field.value === NotificationTypeEnum.email && (
        <NotificationEmail name="email" />
      )}
    </div>
  );
}
