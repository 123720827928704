import {
  getIncomesKey,
  useGetIncomes,
  useImportIncome,
  useImportIncomeTemplate,
} from 'api-hooks/income';
import { ClosingTypeEnum } from 'api-hooks/transaction-closing';
import classNames from 'classnames';
import { ChevronDownIcon, ClockIcon, PlusIcon } from 'common/assets';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import ListHeader from 'components/common/list-header';
import SearchInput from 'components/common/search-input';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import ResponsivePopoverButton, {
  ResponsivePopoverButtonProps,
} from 'components/elements/button/responsive-popover-button';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import { GenericQueryTable, useGenericTableProps } from 'modules/common/table';
import { moduleStyles } from 'modules/styles.css';
import TransactionClosing from 'modules/transactions/common/components/closing';
import ImportModal from 'modules/transactions/common/components/import-modal';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { useIncomeDefaultColumns } from './column';

interface Props {
  setIsUpdateRate: React.Dispatch<React.SetStateAction<boolean>>;
}

function IncomeDefaultView({ setIsUpdateRate }: Props) {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const { can } = useAuthorization();

  const [search, setSearch] = React.useState<string>('');

  const navigation = useNavigation();
  const dialog = useDialog();
  const _columns = useIncomeDefaultColumns();

  const { refetch, setPage, ...table } = useGenericTableProps(
    _columns,
    useGetIncomes,
    {
      params: {
        filter: {
          entity_id: entity?.id!,
        },
        q: search,
      },
    },
  );

  const onHandleImport = React.useMemo(
    () =>
      dialog.showCustom({
        render: (close) => (
          <ImportModal
            name={t('common:income')}
            rule="IncomesImportTemplate"
            onClose={close}
            useImport={useImportIncome}
            useImportTemplate={useImportIncomeTemplate}
            getKeys={getIncomesKey as any}
            t={t}
          />
        ),
      }),
    [dialog, t],
  );

  const responsivePopoverButtonProps =
    React.useMemo<ResponsivePopoverButtonProps>(
      () => ({
        items: [
          {
            action: onHandleImport,
            buttonVariant: 'secondary',
            label: t('modal:import_file'),
            hide: !can(AuthorizationRules.IncomesImport),
          },
          {
            content: <TransactionClosing type={ClosingTypeEnum.incomes} />,
            hide: !can(AuthorizationRules.TransactionClosingsList),
          },
          {
            hide: !can(AuthorizationRules.IncomesCreate),
            label: t('common:create'),
            action: () => navigation.navigate(NavigationRoutes.IncomeCreate),
            icon: (size) => <PlusIcon size={size} />,
          },
        ],
        parent: {
          label: t('common:action'),
          buttonVariant: 'secondary',
          icon: (size) => <ChevronDownIcon size={size} />,
        },
      }),
      [can, navigation, onHandleImport, t],
    );

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:income')}
        showAdd={false}
        onRefresh={refetch}
        showRefresh={false}
        showSmallRefresh
        rightCustomComponent={
          <ResponsivePopoverButton {...responsivePopoverButtonProps} />
        }
      />
      <GenericQueryTable
        {...table}
        setPage={setPage}
        onRenderBesideQueryFilter={
          <div
            className={classNames(
              moduleStyles.row({ justify: 'between' }),
              moduleStyles.fullContainer,
            )}
          >
            <SearchInput setValue={setSearch} setPage={setPage} />
            {can(AuthorizationRules.IncomesMassUpdateRate) && (
              <>
                <Separator gap={16} direction="horizontal" />
                <Button
                  variant="secondary"
                  leftIcon={(size) => <ClockIcon size={size} />}
                  onClick={() => setIsUpdateRate(true)}
                >
                  {t('transaction:update_rate')}
                </Button>
              </>
            )}
          </div>
        }
      />
    </div>
  );
}
export default React.memo(IncomeDefaultView);
