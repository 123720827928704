import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getStockItemInput,
  getStockItemsInput,
  StockItemLiteModel,
  StockItemModel,
} from './model';

export const stockItemKey = {
  listKey: 'getStockItems',
  detailKey: 'getStockItem',
  list(input?: getStockItemsInput) {
    return [stockItemKey.listKey, input?.params];
  },
  detail(input?: getStockItemInput) {
    return [stockItemKey.detailKey, input?.stockItemId];
  },
};

export function useGetStockItems(
  input?: getStockItemsInput,
  options?: UseQueryOptions<ExtendedApiResult<StockItemLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<StockItemLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockItemKey.list(input),
      () =>
        QueryFetchFunction({
          url: 'stock-items',
          params: input?.params,
        }),
      options,
    ),
    StockItemLiteModel,
  );
}

export function useGetStockItem(
  input?: getStockItemInput,
  options?: UseQueryOptions<ApiResult<StockItemModel>, ApiError>,
): UseQueryResult<ApiResult<StockItemModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockItemKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `stock-items/${input?.stockItemId}`,
        }),
      options,
    ),
    StockItemModel,
  );
}
