// Enum

import { getIndexInput } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';

export enum ClosingTypeEnum {
  transfers = 'transfer',
  outcomes = 'expense',
  incomes = 'income',
  accountMutation = 'wallet_mutation',
}

// Model

export class TransactionClosingLiteModel {
  id: string;
  type: string;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'start_at' })
  @Type(() => Date)
  startAt: Date;

  @Expose({ name: 'end_at' })
  @Type(() => Date)
  endAt: Date;
}

export class TransactionClosingModel extends TransactionClosingLiteModel {}

// Input

export type getTransactionClosings = getIndexInput;

export type getTransactionClosing = {
  transactionClosingId: string;
};

export type TransactionClosingMutationInput = {
  startAt: string;
  endAt: string;
  type: ClosingTypeEnum;
};

export type TransactionClosingUpdateInput = {
  transactionClosingId: string;
  body: TransactionClosingMutationInput;
};

export type TransactionClosingDeleteInput = {
  transactionClosingId: string;
};
