import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  StockUsageDeleteInput,
  StockUsageModel,
  StockUsageMutationInput,
  StockUsageUpdateInput,
} from './model';

export function useCreateStockUsage(
  options?: UseMutationOptions<
    ApiResult<StockUsageModel>,
    ApiError,
    StockUsageMutationInput
  >,
): UseMutationResult<
  ApiResult<StockUsageModel>,
  ApiError,
  StockUsageMutationInput
> {
  return useMutation<
    ApiResult<StockUsageModel>,
    ApiError,
    StockUsageMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'stock-usages',
      method: 'POST',
      classType: StockUsageModel,
      body,
    });
  }, options);
}

export function useUpdateStockUsage(
  options?: UseMutationOptions<
    ApiResult<StockUsageModel>,
    ApiError,
    StockUsageUpdateInput
  >,
): UseMutationResult<
  ApiResult<StockUsageModel>,
  ApiError,
  StockUsageUpdateInput
> {
  return useMutation<
    ApiResult<StockUsageModel>,
    ApiError,
    StockUsageUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `stock-usages/${body.stockUsageId}`,
      method: 'PUT',
      classType: StockUsageModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteStockUsage(
  options?: UseMutationOptions<MessageResult, ApiError, StockUsageDeleteInput>,
): UseMutationResult<MessageResult, ApiError, StockUsageDeleteInput> {
  return useMutation<MessageResult, ApiError, StockUsageDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `stock-usages/${body.stockUsageId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
