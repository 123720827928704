import { MantineColor } from '@mantine/core';
import {
  ActiveBudgetingItemModel,
  BudgetingItemsModel,
  BudgetStatusEnum,
} from 'api-hooks/budgeting/model';
import { NavigationRoutes } from 'common/constants';
import { string2moneyDefault } from 'common/utils/string';
import Separator from 'components/common/separator';
import Badge from 'components/elements/badge';
import { Button } from 'components/elements/button';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { format } from 'date-fns';
import useNavigation from 'hooks/use-navigation';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface Props {
  data?: BudgetingItemsModel[];
  coaIds: string[];
  dismiss: () => void;
  brandIds: string[];
}

const getStatusColor = (str: BudgetStatusEnum): MantineColor => {
  switch (str) {
    case BudgetStatusEnum.active:
      return 'grape';
    case BudgetStatusEnum.upcoming:
    case BudgetStatusEnum.completed:
      return 'gray';
  }
};

export default function BudgetHistoryTable(props: Props) {
  const { data, coaIds, brandIds } = props;
  const { t } = useTranslation();
  const { navigate } = useNavigation();

  const _columns = React.useMemo<IColumn<ActiveBudgetingItemModel>[]>(
    () => [
      {
        header: t('budgeting:period'),
        accessorKey: '',
        minSize: 200,
        sortName: 'name',
        cell: ({ row }) => {
          const { original }: { original: BudgetingItemsModel } = row;
          return `${format(original.startFrom, 'dd/MM/yyyy')} -
          ${format(original.endAt, 'dd/MM/yyyy')}`;
        },
      },
      {
        header: t('common:status'),
        accessorKey: 'status',
        minSize: 100,
        sortName: 'status',
        cell: ({ getValue }) => {
          return (
            <Badge color={getStatusColor(getValue() as BudgetStatusEnum)}>
              {getValue() as BudgetStatusEnum}
            </Badge>
          );
        },
      },
      {
        header: t('budgeting:used'),
        accessorKey: 'usedAmount',
        minSize: 200,
        sortName: 'usedAmount',
        textAlign: 'right',
        cell: ({ getValue }) => (
          <Text textVariant="MonoDefault">
            {string2moneyDefault(getValue() as string)}
          </Text>
        ),
      },

      {
        header: t('budgeting:amount'),
        accessorKey: 'amount',
        minSize: 200,
        sortName: 'amount',
        textAlign: 'right',
        cell: ({ getValue }) => (
          <Text textVariant="MonoDefault">
            {string2moneyDefault(getValue() as string)}
          </Text>
        ),
      },
      {
        header: t('budgeting:amount'),
        accessorKey: 'action',
        minSize: 200,
        cell: ({ row: { original } }) => (
          <Separator gap={2}>
            <Button
              variant="secondary"
              onClick={() => {
                props.dismiss();
                navigate(NavigationRoutes.ChartOfAccountReportList, {
                  params: {
                    transactionAtAfter: original.startFrom,
                    transactionAtBefore: original.endAt,
                    coaIds,
                    brandIds,
                  },
                });
              }}
            >
              {t('budgeting:view_history')}
            </Button>
          </Separator>
        ),
      },
    ],
    [brandIds, coaIds, navigate, props, t],
  );

  return <TableComponent columns={_columns} data={data || []} />;
}
