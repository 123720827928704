import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { ApiError, PaginationMeta } from 'api-hooks/common/model';
import classNames from 'classnames';
import { PlusIcon } from 'common/assets';
import { DEFAULT_STALE_TIME } from 'common/constants/utils';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import {
  MultiOptionProps,
  MultiSelectProps,
} from 'components/elements/multi-select';
import { camelizeKeys } from 'humps';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useDebouncedCallback } from 'use-debounce';

const SelectItem = React.forwardRef<HTMLDivElement, any>(
  ({ label, value, className, ...others }: any, ref) => {
    if (value === 'actionLoad') {
      return (
        <div
          ref={ref}
          {...others}
          className={classNames(moduleStyles.actionContainer, className)}
        >
          <Separator gap={4} />
          {label}
        </div>
      );
    }

    if (value === 'actionCreate') {
      return (
        <div
          ref={ref}
          {...others}
          className={classNames(moduleStyles.actionContainer, className)}
        >
          <PlusIcon size={20} />
          <Separator gap={4} />
          {label}
        </div>
      );
    }

    if (value === 'noData') {
      return (
        <div
          ref={ref}
          {...others}
          className={classNames(
            moduleStyles.disabledActionContainer,
            className,
          )}
        >
          {label}
        </div>
      );
    }
    return (
      <div {...others} className={className}>
        {label}
      </div>
    );
  },
);

interface Options<ListQueryData, ListQueryVariables> {
  useListQueryHook: (
    baseInputs: ListQueryVariables,
    baseOptions?: UseQueryOptions<ListQueryData, ApiError>,
  ) => UseQueryResult<ListQueryData, ApiError>;

  listTransformer: (result: ListQueryData) => MultiOptionProps[];
  paginationTransformer: (result: ListQueryData) => PaginationMeta;
  getMemoizedListVariables: (
    page: number,
    query?: string,
  ) => ListQueryVariables;
  onSelectItem: (value: string[] | null) => void;
  resetPageWhenQueryChanged?: boolean;
  renderCreate?: boolean;
  renderNoData?: boolean;
  renderSelectAll?: boolean;
  onClickCreate?: () => void;
  onClickSelectAll?: () => void;
  createText?: string;
  value?: string[];
  enabled?: boolean;
  getListsKey: (page: number, query?: string) => any[];
}

function checkOptionValue(
  options: MultiOptionProps[],
  value: string[] | undefined,
) {
  const _options = options.map(({ value }) => value);
  return value?.some((val) => !!val && !_options.includes(val));
}

export default function useMultiSelectInputHelper<
  ListQueryData,
  ListQueryVariables,
>(helperOptions: Options<ListQueryData, ListQueryVariables>): MultiSelectProps {
  const { t } = useTranslation();
  const {
    renderCreate = false,
    renderSelectAll,
    createText = t('common:create_new'),
    onClickCreate = () => {},
    onClickSelectAll,
    onSelectItem,
    useListQueryHook,
    listTransformer,
    paginationTransformer,
    getMemoizedListVariables,
    value,
    enabled = true,
    resetPageWhenQueryChanged,
    renderNoData,
    getListsKey,
  } = helperOptions;

  const [options, setOptions] = React.useState<MultiOptionProps[]>([]);
  const [query, setQuery] = React.useState<string | undefined>();
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    if (getListsKey) {
      const res = queryClient.getQueryData(getListsKey(page, query));
      if (res) {
        const optionResult = listTransformer(
          camelizeKeys(res as any) as unknown as ListQueryData,
        );
        if (page === 1) {
          setOptions(optionResult);
        } else {
          setOptions((prevOptions) => prevOptions.concat(optionResult));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListsKey, page]);

  const { isLoading: listLoading, data: list } = useListQueryHook(
    getMemoizedListVariables(page, query),
    {
      enabled,
      staleTime: DEFAULT_STALE_TIME,
      onSuccess: (result) => {
        const optionResult = listTransformer(
          camelizeKeys(result as any) as unknown as ListQueryData,
        );
        if (result?.hasOwnProperty('meta')) {
          const curr = (result as any)?.meta?.current_page;
          const last = (result as any)?.meta?.last_page;
          if (page === 1) {
            checkOptionValue(optionResult, value) &&
              curr < last &&
              setPage((prev) => prev + 1);
            setOptions(optionResult);
          } else {
            setOptions((prevOptions) => {
              const temp = prevOptions.concat(optionResult);

              checkOptionValue(temp, value) &&
                curr < last &&
                setPage((prev) => prev + 1);

              return temp;
            });
          }
          return;
        }
        if (page === 1) {
          setOptions(optionResult);
        } else {
          setOptions((prevOptions) => prevOptions.concat(optionResult));
        }
      },
    },
  );

  const loading = listLoading;

  const items = React.useMemo(() => {
    const _options = [...options];

    const pagination = list && paginationTransformer(list);

    if (renderSelectAll && !!_options.length) {
      _options.unshift({
        label: t('common:all_brand'),
        value: 'actionAll',
      });
    }

    if (pagination && pagination.currentPage < pagination.lastPage) {
      _options.push({ label: 'Load More...', value: 'actionLoad' });
    }

    if (renderCreate) {
      _options.push({ label: createText, value: 'actionCreate' });
    }

    if (!pagination?.to && renderNoData) {
      _options.unshift({
        label: t('common:no_data'),
        value: 'noData',
        disabled: true,
      });
    }

    return _options;
  }, [
    options,
    list,
    paginationTransformer,
    renderCreate,
    renderNoData,
    renderSelectAll,
    createText,
    t,
  ]);

  const onQueryChange = useDebouncedCallback(
    (eventValue) => {
      resetPageWhenQueryChanged && setPage(1);
      setQuery(eventValue || '');
    },
    800,
    {
      trailing: true,
    },
  );

  const _onChange = React.useCallback(
    (params: string[] | null) => {
      const pagination = list && paginationTransformer(list);

      if (
        params?.[params.length > 0 ? (params || []).length - 1 : 0] ===
          'actionLoad' &&
        pagination
      ) {
        setPage(pagination.currentPage + 1);
      } else if (
        params?.[params.length > 0 ? (params || []).length - 1 : 0] ===
        'actionCreate'
      ) {
        onClickCreate();
      } else if (
        params?.[params.length > 0 ? (params || []).length - 1 : 0] ===
        'actionAll'
      ) {
        onClickSelectAll?.();
      } else {
        onSelectItem(params);
      }
    },
    [
      list,
      onClickCreate,
      onClickSelectAll,
      onSelectItem,
      paginationTransformer,
    ],
  );

  const _value = React.useMemo(() => {
    return value;
  }, [value]);

  return {
    value: _value,
    data: items,
    disabled: loading,
    onSearchChange: onQueryChange,
    onChange: _onChange,
    itemComponent: SelectItem,
    searchable: true,
  };
}
