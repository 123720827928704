import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  EntitiesGet,
  EntityAccounts,
  EntityLiteModel,
  EntityModel,
  getAllEntitiesInput,
  getEntitiesAccountInput,
  getEntitiesInput,
  getEntityInput,
} from './model';

export function useGetEntities(
  input?: getEntitiesInput,
  options?: UseQueryOptions<ExtendedApiResult<EntityLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<EntityLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getEntities', input?.params],
      () => QueryFetchFunction({ url: 'entities', params: input?.params }),
      options,
    ),
    EntityLiteModel,
  );
}

export function useGetEntity(
  input?: getEntityInput,
  options?: UseQueryOptions<ApiResult<EntityModel>, ApiError>,
): UseQueryResult<ApiResult<EntityModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getEntity', input?.id],
      () =>
        QueryFetchFunction({
          url: `entities/${input?.id}`,
        }),
      options,
    ),
    EntityModel,
  );
}

export function useGetEntityAccount(
  input?: getEntitiesAccountInput,
  options?: UseQueryOptions<ExtendedApiResult<EntityAccounts[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<EntityAccounts[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getEntityAccounts', input?.id, input?.params],
      () =>
        QueryFetchFunction({
          url: `entity/${input?.id}/accounts`,
          params: input?.params,
        }),
      options,
    ),
    EntityAccounts,
  );
}

export function useGetAllEntity(
  input?: getAllEntitiesInput,
  options?: UseQueryOptions<ExtendedApiResult<EntitiesGet[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<EntitiesGet[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getAllEntities', input?.params],
      () => QueryFetchFunction({ url: 'entities/get', params: input?.params }),
      options,
    ),
    EntitiesGet,
  );
}

export function getEntitiesKey(input?: getEntitiesInput) {
  return ['getEntities', input?.params];
}

export function getEntityKey(input?: getEntityInput) {
  return ['getEntity', input?.id];
}

export function getEntityAccountsKey(input?: getEntitiesAccountInput) {
  return ['getEntityAccounts', input?.id, input?.params];
}

export function getAllEntitiesKey(input?: getAllEntitiesInput) {
  return ['getAllEntities', input?.params];
}
