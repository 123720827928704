import { ExtendedApiResult, getIndexInput } from 'api-hooks/common/model';
import { Expose, Type } from 'class-transformer';

// Model
export class NotificationModel {
  id: string;
  header: string;
  description: string;

  @Expose({ name: 'footer_employee_name' })
  footerEmployeeName: string;

  @Expose({ name: 'notificationable_id' })
  notificationableId: string;

  @Expose({ name: 'notificationable_type' })
  notificationableType: string;

  @Expose({ name: 'is_read' })
  isRead: boolean;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;
}

export class NotificationResult<T> extends ExtendedApiResult<T> {
  count: number;
}

// Input
export type NotificationUpdateInput = {
  body: {
    ids: string[];
  };
};

export type getNotificationsInput = getIndexInput;
