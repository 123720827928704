export enum NavigationRoutes {
  // Master
  EmployeesList = 'employee/employees',
  EmployeesCreate = 'employee/employees/create',
  EmployeesView = 'employee/employees/:id',

  AccountAccessList = 'employee/account-access',
  AccountAccessView = 'employee/account-access/:id',

  RolesList = 'employee/roles',
  RolesCreate = 'employee/roles/create',
  RolesView = 'employee/roles/:id',

  CurrencyList = 'employee/currencies',
  CurrencyCreate = 'employee/currencies/create',
  CurrencyView = 'employee/currencies/:id',

  EntityList = 'employee/entities',
  EntityCreate = 'employee/entities/create',
  EntityView = 'employee/entities/:id',

  BrandList = 'employee/brands',
  BrandCreate = 'employee/brands/create',
  BrandView = 'employee/brands/:id',

  ChartOfAccountCategoriesList = 'employee/chart-account-categories',
  ChartOfAccountCategoriesCreate = 'employee/chart-account-categories/create',
  ChartOfAccountCategoriesView = 'employee/chart-account-categories/:id',

  ChartOfAccountsList = 'employee/chart-accounts',
  ChartOfAccountsCreate = 'employee/chart-accounts/create',
  ChartOfAccountsView = 'employee/chart-accounts/:id',

  ApprovalMastersList = 'employee/approval-masters',
  ApprovalMastersCreate = 'employee/approval-masters/create',
  ApprovalMastersView = 'employee/approval-masters/:id',

  DivisionList = 'employee/divisions',
  DivisionCreate = 'employee/divisions/create',
  DivisionView = 'employee/divisions/:id',

  SupplierList = 'employee/suppliers',
  SupplierCreate = 'employee/suppliers/create',
  SupplierView = 'employee/suppliers/:id',

  WarehouseList = 'employee/warehouses',
  WarehouseCreate = 'employee/warehouses/create',
  WarehouseView = 'employee/warehouses/:id',

  // Stock
  StockItemCodeList = 'employee/stock-item-codes',
  StockItemCodeCreate = 'employee/stock-item-codes/create',
  StockItemCodeView = 'employee/stock-item-codes/:id',

  StockList = 'employee/stocks',
  StockCreate = 'employee/stocks/create',
  StockView = 'employee/stocks/:id',

  StockItemsList = 'employee/stock-items',
  StockItemsView = 'employee/stock-items/:id',

  StockUsageList = 'employee/stock-usages',
  StockUsageCreate = 'employee/stock-usages/create',
  StockUsageView = 'employee/stock-usages/:id',

  StockAdjustmentList = 'employee/stock-adjustments',
  StockAdjustmentCreate = 'employee/stock-adjustments/create',
  StockAdjustmentView = 'employee/stock-adjustments/:id',

  StockGroupingList = 'employee/stock-groupings',
  StockGroupingCreate = 'employee/stock-groupings/create',
  StockGroupingView = 'employee/stock-groupings/:id',

  StockTransferList = 'employee/stock-transfers',
  StockTransferCreate = 'employee/stock-transfers/create',
  StockTransferView = 'employee/stock-transfers/:id',

  //Account
  AccountList = 'employee/accounts',
  AccountCreate = 'employee/accounts/create',
  AccountView = 'employee/accounts/:id',

  //EntityCurrency
  EntityCurrencyList = 'employee/entity-currencies',
  EntityCurrencyCreate = 'employee/entity-currencies/create',
  EntityCurrencyView = 'employee/entity-currencies/:id',

  //Transfer
  TransferList = 'employee/transfers',
  TransferCreate = 'employee/transfers/create',
  TransferView = 'employee/transfers/:id',

  // Income
  IncomeList = 'employee/incomes',
  IncomeCreate = 'employee/incomes/create',
  IncomeView = 'employee/incomes/:id',

  // Expense
  OutcomeList = 'employee/outcomes',
  OutcomeCreate = 'employee/outcomes/create',
  OutcomeView = 'employee/outcomes/:id',

  //Transfer Approval
  TransferApprovalList = 'employee/transfer-approvals',
  TransferApprovalView = 'employee/transfer-approvals/:id',

  //Account Mutation
  AccountMutationList = 'employee/account-mutations',
  AccountMutationCreate = 'employee/account-mutations/create',
  AccountMutationView = 'employee/account-mutations/:id',

  //Reconciliation
  ReconciliationList = 'employee/reconciliations',
  ReconciliationCreate = 'employee/reconciliations/create',

  // Report
  AccountMutationReportList = 'employee/account-mutation-reports',
  ChartOfAccountReportList = 'employee/chart-of-account-reports',
  TransactionReportList = 'employee/transaction-reports',
  StockSimpleReportList = 'employee/stock-simple-reports',
  StockDetailReportList = 'employee/stock-detail-reports',

  //Request Forms
  PurchaseRequisitionsList = 'employee/purchase-requisitions',
  PurchaseRequisitionsCreate = 'employee/purchase-requisitions/create',
  PurchaseRequisitionsView = 'employee/purchase-requisitions/:id',

  // Approval
  ApprovalList = 'employee/approvals',
  ApprovalView = 'employee/approvals/:id',

  // Budgeting
  BudgetingList = 'employee/budgeting',
  BudgetingView = 'employee/budgeting/:id',
  BudgetingCreate = 'employee/budgeting/create',

  // Export
  ExportExcelList = 'employee/export-excel',
}
