import { MantineColor } from '@mantine/core';
import { ExportExcelStatusEnum, useGetExports } from 'api-hooks/export';
import { CancelIcon, CheckIcon, DownloadIcon } from 'common/assets';
import { AuthorizationRules } from 'common/constants';
import notification from 'common/helpers/notification';
import ListHeader from 'components/common/list-header';
import { useApplyQueryFilter } from 'components/common/query-filter/query-filter';
import SearchInput from 'components/common/search-input';
import Separator from 'components/common/separator';
import Badge from 'components/elements/badge';
import { ActionIcon } from 'components/elements/button';
import Pagination from 'components/elements/pagination';
import TableComponent, { IColumn } from 'components/elements/table';
import Tabs from 'components/elements/tabs';
import { format } from 'date-fns';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import { useAuthorization } from 'hooks/use-authorization';
import useComposedQuery from 'hooks/use-composed-query';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const getStatusBadgeColor = (status: ExportExcelStatusEnum): MantineColor => {
  switch (status) {
    case ExportExcelStatusEnum.processing:
      return 'orange';
    case ExportExcelStatusEnum.finished:
      return 'cyan';
    default:
      return 'cyan';
  }
};

enum StatusFilter {
  failed = 'failed',
  export_list = 'processing, finished',
}

export default function ExportExcelList() {
  const { t } = useTranslation();
  const { can } = useAuthorization();

  const [status, setStatus] = React.useState<string>(StatusFilter.export_list);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const [search, setSearch] = React.useState<string>('');

  const _columns = React.useMemo<IColumn<any>[]>(
    () => [
      ...(status === StatusFilter.export_list
        ? [
            {
              header: t('common:status'),
              accessorKey: 'status',
              minSize: 200,
              cell: ({ getValue }) => (
                <Badge
                  color={getStatusBadgeColor(
                    getValue() as ExportExcelStatusEnum,
                  )}
                >
                  {getValue() as string}
                </Badge>
              ),
            },
          ]
        : []),
      {
        header: t('common:created_at'),
        accessorKey: 'createdAt',
        minSize: 200,
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('common:created_by'),
        accessorKey: 'employee',
        minSize: 200,
      },
      {
        header: t('common:file_name'),
        accessorKey: 'name',
        minSize: 300,
      },
      ...(status === StatusFilter.export_list &&
      can(AuthorizationRules.ExportHistoriesExport)
        ? [
            {
              header: '',
              accessorKey: 'file',
              minSize: 50,
              cell: ({ getValue }) => {
                if (!getValue()) return;
                return (
                  <a
                    href={(getValue() as string) || ''}
                    style={{
                      width: 'fit-content',
                    }}
                  >
                    <ActionIcon variant="transparent">
                      {(size) => <DownloadIcon size={size} />}
                    </ActionIcon>
                  </a>
                );
              },
            },
          ]
        : []),
    ],
    [can, status, t],
  );

  const {
    data,
    refetch,
    isLoading,
    isFetching,
    error,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    extras: [_, { columns }],
  } = useComposedQuery(
    useGetExports,
    {
      params: {
        page,
        limit,
        filter: {
          status,
        },
        q: search,
      },
    },
    useApplyQueryFilter((data: any) => {
      return data.filters;
    }),
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
  );

  React.useEffect(() => {
    if (!!error && !!error.message) {
      notification.error({ message: error?.message });
    }
  }, [error]);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:export_excel')}
        showAdd={false}
        onRefresh={refetch}
      />
      <Separator gap={8} />
      <Tabs
        items={[
          {
            value: StatusFilter.export_list,
            header: {
              label: t('common:export_list'),
              icon: (size, color) => <CheckIcon size={size} color={color} />,
              onClick: () => setStatus(StatusFilter.export_list),
            },
            panel: {
              content: <></>,
            },
          },
          {
            value: StatusFilter.failed,
            header: {
              label: t('common:failed'),
              icon: (size, color) => <CancelIcon size={size} color={color} />,
              onClick: () => setStatus(StatusFilter.failed),
            },
            panel: {
              content: <></>,
            },
          },
        ]}
        defaultValue={StatusFilter.export_list}
      />
      <Separator gap={24} direction="vertical">
        <SearchInput onAfterChange={(val) => setSearch(val)} />
      </Separator>

      <div className={moduleStyles.tableContainer}>
        <TableComponent
          columns={columns}
          isLoading={isLoading || isFetching}
          data={data?.data || []}
        />
      </div>
      <Pagination
        {...{
          page,
          onPageChange: (page) => setPage(page),
          meta: data?.meta,
          limit,
          onLimitChange: (limit) => setLimit(limit),
        }}
      />
    </div>
  );
}
