import {
  ClosingTypeEnum,
  TransactionClosingLiteModel,
  TransactionClosingModel,
  transactionClosingKey,
  useCreateTransactionClosing,
  useDeleteTransactionClosing,
  useGetTransactionClosing,
  useGetTransactionClosings,
  useUpdateTransactionClosing,
} from 'api-hooks/transaction-closing';
import classNames from 'classnames';
import { CancelIcon, ClockPlusIcon, EditIcon, TrashIcon } from 'common/assets';
import { AuthorizationRules } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import { initialStartAt } from 'common/utils/date';
import Separator from 'components/common/separator';
import { ActionIcon, Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import Pagination from 'components/elements/pagination';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { format } from 'date-fns';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import { useAuthorization } from 'hooks/use-authorization';
import useComposedQuery from 'hooks/use-composed-query';
import useDialog from 'hooks/use-dialog';
import useDrawer from 'hooks/use-drawer';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { transactionCommonStyles } from '../../styles/index.css';

interface Props {
  type: ClosingTypeEnum;
}

type ClosingFormType = {
  startAt: Date;
  endAt: Date;
};

interface ClosingFormProps {
  t: Translate;
  type: ClosingTypeEnum;
  id?: string;
  data?: TransactionClosingModel;
  onClose: () => void;
}

interface ClosingHistoryProps {
  t: Translate;
  type: ClosingTypeEnum;
  onClickEdit: (id: string, data: TransactionClosingModel) => void;
  onClose: () => void;
  onAdd: () => void;
}
function ClosingForm(props: ClosingFormProps) {
  const { t, type, onClose, id, data } = props;
  const { mutateAsync: updateMutate, isLoading: isUpdateLoading } =
    useUpdateTransactionClosing();
  const { mutateAsync, isLoading } = useCreateTransactionClosing();

  const initialValues = React.useMemo<ClosingFormType>(
    () => ({
      startAt: data?.startAt || initialStartAt(),
      endAt: data?.endAt || initialStartAt(),
    }),
    [data?.endAt, data?.startAt],
  );
  const resolver = useYupValidationResolver(
    Yup.object().shape({
      startAt: Yup.date().required().nullable(),
      endAt: Yup.date().required().nullable(),
    }),
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver,
  });

  const onSubmit = React.useCallback(
    async (values: ClosingFormType) => {
      try {
        const { endAt, startAt } = values;
        const input = {
          endAt: format(endAt, 'yyyy-MM-dd'),
          startAt: format(startAt, 'yyyy-MM-dd'),
          type,
        };
        const res = await (id
          ? updateMutate({
              transactionClosingId: id,
              body: input,
            })
          : mutateAsync(input));
        onClose();
        notification.success({ message: res.message });
      } catch (e) {
        notification.error({ message: e.message });
      }
    },
    [id, mutateAsync, onClose, type, updateMutate],
  );

  return (
    <Form methods={methods} onSubmit={onSubmit} defaultEditable>
      <div className={moduleStyles.fullContainer}>
        <Input
          type="date"
          name="startAt"
          label={t('modal:closing_from')}
          required
        />
        <Input
          type="date"
          name="endAt"
          label={t('modal:closing_to')}
          required
        />
        <div
          className={moduleStyles.row({
            justify: 'end',
          })}
        >
          <Button
            error
            variant="secondary"
            leftIcon={(size) => <CancelIcon size={size} />}
            children={t('common:cancel')}
            onClick={onClose}
          />
          <Separator gap={16} direction="horizontal" />
          <Input type="submit" loading={isLoading || isUpdateLoading} />
        </div>
      </div>
    </Form>
  );
}

function ClosingHistory(props: ClosingHistoryProps) {
  const { t, type } = props;
  const { can } = useAuthorization();
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(15);
  const { mutateAsync } = useDeleteTransactionClosing();
  const { mutateAsync: mutateGetTransactionClosing } =
    useGetTransactionClosing();
  const dialog = useDialog();

  const onClickDelete = React.useCallback(
    (id: string) => {
      dialog.showConfirmation({
        message: t('common:confirmation_delete_text'),
        title: t('common:confirmation'),
        onPositiveAction: async (dismiss) => {
          try {
            await mutateAsync({
              transactionClosingId: id,
            });
            queryClient.refetchQueries([transactionClosingKey.listKey]);
            notification.success({
              message: t('common:successfully_deleted'),
            });
            dismiss();
          } catch (error) {
            notification.error({ message: error.message });
          }
        },
      });
    },
    [dialog, mutateAsync, t],
  );

  const onClickEdit = React.useCallback(
    async (id: string) => {
      try {
        const res = await mutateGetTransactionClosing({
          transactionClosingId: id,
        });
        props.onClickEdit(id, res.data);
      } catch {}
    },
    [mutateGetTransactionClosing, props],
  );

  const _columns = React.useMemo<IColumn<TransactionClosingLiteModel>[]>(
    () => [
      {
        header: t('common:created_at'),
        accessorKey: 'createdAt',
        minSize: 100,
        sortName: 'created_at',
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('modal:closing_from'),
        accessorKey: 'startAt',
        minSize: 100,
        sortName: 'start_at',
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy') + ', 00:00',
      },
      {
        header: t('modal:closing_to'),
        accessorKey: 'endAt',
        minSize: 100,
        sortName: 'end_at',
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy') + ', 00:00',
      },
      ...(can(AuthorizationRules.TransactionClosingsDelete) ||
      can(AuthorizationRules.TransactionClosingsUpdate)
        ? [
            {
              header: '',
              accessorKey: 'action',
              minSize: 80,
              cell: ({ row }) => {
                const { original } = row;
                return (
                  <div className={moduleStyles.row()}>
                    {can(AuthorizationRules.TransactionClosingsUpdate) && (
                      <>
                        <ActionIcon
                          variant="transparent"
                          children={(size) => (
                            <EditIcon
                              size={size}
                              color={colors.productNormal}
                            />
                          )}
                          onClick={() => onClickEdit(original.id)}
                        />
                        <Separator gap={8} direction="horizontal" />
                      </>
                    )}
                    {can(AuthorizationRules.TransactionClosingsDelete) && (
                      <ActionIcon
                        variant="transparent"
                        error
                        children={(size) => (
                          <TrashIcon
                            size={size}
                            color={colors.sentimentError}
                          />
                        )}
                        onClick={() => onClickDelete(original.id)}
                      />
                    )}
                  </div>
                );
              },
            },
          ]
        : []),
    ],
    [can, onClickDelete, onClickEdit, t],
  );
  const {
    data,
    isLoading,
    isFetching,
    extras: [{ columns }],
  } = useComposedQuery(
    useGetTransactionClosings,
    {
      params: {
        page,
        filter: {
          type,
        },
      },
    },
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
  );

  return (
    <div
      className={classNames(
        moduleStyles.container,
        transactionCommonStyles.closingTableContainer,
      )}
    >
      {can(AuthorizationRules.TransactionClosingsCreate) && (
        <>
          <div>
            <Button onClick={props.onAdd}>
              {t('common:add_extra', { extra: t('common:closing') })}
            </Button>
          </div>
          <Separator gap={32} direction="vertical" />
        </>
      )}
      <div className={moduleStyles.tableContainer}>
        <TableComponent
          columns={columns}
          isLoading={isLoading || isFetching}
          data={data?.data || []}
        />
      </div>
      <Pagination
        {...{
          page,
          onPageChange: (page) => setPage(page),
          meta: data?.meta,
          limit,
          onLimitChange: (limit) => setLimit(limit),
        }}
      />
    </div>
  );
}

export default function TransactionClosing(props: Props) {
  const { type } = props;
  const { t } = useTranslation();
  const drawer = useDrawer();

  const onClickAdd = React.useCallback(
    (id?: string, data?: TransactionClosingModel) => {
      drawer.showCustom({
        title: (
          <Text textVariant="HeadingLarge">{t('modal:closing_history')}</Text>
        ),
        render: (dismiss) => (
          <ClosingForm
            t={t}
            type={type}
            onClose={dismiss}
            id={id}
            data={data}
          />
        ),
        padding: 'sm',
        size: 500,
      });
    },
    [drawer, t, type],
  );

  const onClick = React.useCallback(() => {
    drawer.showCustom({
      title: (
        <Text textVariant="HeadingLarge">{t('modal:closing_history')}</Text>
      ),
      render: (dismiss) => (
        <ClosingHistory
          t={t}
          type={type}
          onClose={dismiss}
          onClickEdit={(id, data) => onClickAdd(id, data)}
          onAdd={() => onClickAdd()}
        />
      ),
      padding: 'sm',
      size: 500,
    });
  }, [drawer, onClickAdd, t, type]);

  return (
    <Button
      variant="secondary"
      onClick={onClick}
      leftIcon={(size) => <ClockPlusIcon size={size} />}
    >
      {t('common:closing')}
    </Button>
  );
}
