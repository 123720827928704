import { getEntitiesKey, useCreateEntity } from 'api-hooks/entity';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import EntityForm from './components/form';

const EntityCreate = (props: NavigationProps) => {
  const { t } = useTranslation();
  const { navigate, close } = useNavigation();
  const { can } = useAuthorization();
  const { mutateAsync, error } = useCreateEntity();

  return (
    <div>
      <EntityForm
        {...props}
        renderError={
          !!error &&
          !!error.message && (
            <>
              <Alert variant="error" description={error.message} />
              <Separator gap={8} />
            </>
          )
        }
        onSubmit={async (input, form) => {
          const result = await mutateAsync(input);
          close();
          form.reset();
          queryClient.refetchQueries([getEntitiesKey()[0]]);
          if (can(AuthorizationRules.EntitiesView)) {
            navigate(NavigationRoutes.EntityView, {
              params: { id: result.data?.id },
            });
          }
          notification.success({ message: result?.message });
          return result.data;
        }}
      />
      <Alert
        variant="warning"
        title={t('entity:alert_title')}
        description={t('entity:alert_description')}
      />
    </div>
  );
};

export default EntityCreate;
