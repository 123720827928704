import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import { StockItemModel, StockItemUpdateInput } from './model';

export function useUpdateStockItem(
  options?: UseMutationOptions<
    ApiResult<StockItemModel>,
    ApiError,
    StockItemUpdateInput
  >,
): UseMutationResult<
  ApiResult<StockItemModel>,
  ApiError,
  StockItemUpdateInput
> {
  return useMutation<ApiResult<StockItemModel>, ApiError, StockItemUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `stock-items/${body.stockId}`,
        method: 'PUT',
        classType: StockItemModel,
        body: body.body,
      });
    },
    options,
  );
}
