import { decamelize, camelize } from 'humps';

export function checkCurrencyName({ accessorKey, currencyAccessorKey }) {
  const decamelizeAccessor = decamelize(accessorKey);
  if (Array.isArray(currencyAccessorKey)) {
    let isFound = false;
    for (let i = 0; i < currencyAccessorKey.length; i++) {
      if (decamelizeAccessor.includes(currencyAccessorKey[i])) {
        isFound = true;
        break;
      }
    }
    return isFound;
  }
  return decamelizeAccessor.includes(currencyAccessorKey);
}

export function checkCurrency({ accessorKey, pivot }) {
  let val = '';
  let isFound = false;
  Object.keys(pivot.value).forEach((key) => {
    const childKey = pivot.value[key];
    for (let i = 0; i < childKey.length; i++) {
      if (childKey[i].name === decamelize(accessorKey)) {
        isFound = true;
        val = childKey[i].currency && camelize(childKey[i].currency);
        break;
      }
    }
    if (isFound) return true;
  });
  return val;
}
