import {
  BrandLiteModel,
  BrandModel,
  getBrandsKey,
  useGetBrands,
} from 'api-hooks/brand';
import MultiSelect from 'components/elements/multi-select';
import { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useMultiSelectInputHelper from 'hooks/use-multi-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface Props {
  required?: boolean;
  label?: string;
  placeholder?: string;
  value?: string[];
  disabled?: boolean;
  noMargin?: boolean;
  onSelected?: (value?: string[] | null) => void;
}

function transformer(item: BrandModel | BrandLiteModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
    color: item.color,
  };
}

export default function BrandMultiSelectInput(props: Props) {
  const { t } = useTranslation();
  const { label = t('common:brand'), placeholder = t('common:brand') } = props;

  const { entity } = useEntity();

  const selectProps = useMultiSelectInputHelper({
    value: props.value,
    onSelectItem: (value) => {
      props.onSelected?.(value);
    },
    useListQueryHook: useGetBrands,
    getListsKey: React.useCallback(
      (page, q) =>
        getBrandsKey({
          params: {
            q,
            page,
            filter: {
              entity_id: entity?.id!,
            },
            limit: -1,
          },
        }),
      [entity?.id],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            entity_id: entity?.id!,
          },
          limit: -1,
        },
      }),
      [entity?.id],
    ),
    renderCreate: false,

    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <MultiSelect
      required={props.required}
      label={label}
      placeholder={placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
