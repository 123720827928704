import {
  getOutcomesKey,
  useGetOutcomes,
  useImportOutcome,
  useImportTemplate,
} from 'api-hooks/outcome';
import { ClosingTypeEnum } from 'api-hooks/transaction-closing';
import classNames from 'classnames';
import { ClockIcon } from 'common/assets';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import filterReduce from 'common/helpers/filter-reduce';
import ListHeader from 'components/common/list-header';
import SearchInput from 'components/common/search-input';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import { GenericQueryTable, useGenericTableProps } from 'modules/common/table';
import { moduleStyles } from 'modules/styles.css';
import TransactionClosing from 'modules/transactions/common/components/closing';
import ImportModal from 'modules/transactions/common/components/import-modal';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { useOutcomeDefaultColumns } from './column';
import OutcomeExportComponent from './export';

interface Props {
  setIsUpdateRate: React.Dispatch<React.SetStateAction<boolean>>;
}

function OutcomeDefaultView({ setIsUpdateRate }: Props) {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const { can } = useAuthorization();
  const [search, setSearch] = React.useState<string>('');

  const dialog = useDialog();
  const navigation = useNavigation();
  const _columns = useOutcomeDefaultColumns();

  const table = useGenericTableProps(_columns, useGetOutcomes, {
    params: {
      filter: {
        entity_id: entity?.id!,
      },
      q: search,
    },
  });

  const _filter = filterReduce(table.filters);

  const onHandleImport = React.useCallback(
    () =>
      dialog.showCustom({
        render: (close) => (
          <ImportModal
            name={t('common:outcome')}
            rule="ExpensesImportTemplate"
            onClose={close}
            useImport={useImportOutcome}
            useImportTemplate={useImportTemplate}
            getKeys={getOutcomesKey as any}
            t={t}
          />
        ),
      }),
    [dialog, t],
  );

  return (
    <>
      <ListHeader
        title={t('common:outcome')}
        showAdd={can(AuthorizationRules.ExpensesCreate)}
        onCreate={() => navigation.navigate(NavigationRoutes.OutcomeCreate)}
        onRefresh={table.refetch}
        rightCustomComponent={
          <>
            {!!table.data?.length && (
              <OutcomeExportComponent
                filter={_filter}
                limit={table.limit}
                page={table.page}
                q={search}
              />
            )}
            {can(AuthorizationRules.TransactionClosingsList) && (
              <>
                <Separator gap={16} direction="horizontal" />
                <TransactionClosing type={ClosingTypeEnum.outcomes} />
              </>
            )}
            <Separator gap={16} direction="horizontal" />
            {can(AuthorizationRules.ExpensesImport) && (
              <>
                <Button
                  type="button"
                  onClick={onHandleImport}
                  variant="secondary"
                >
                  {t('modal:import_file')}
                </Button>
                <Separator gap={16} direction="horizontal" />
              </>
            )}
          </>
        }
      />

      <GenericQueryTable
        {...table}
        onRenderBesideQueryFilter={
          <div
            className={classNames(
              moduleStyles.row({ justify: 'between' }),
              moduleStyles.fullContainer,
            )}
          >
            <SearchInput setValue={setSearch} setPage={table.setPage} />
            {can(AuthorizationRules.ExpensesMassUpdateRate) && (
              <>
                <Separator gap={16} direction="horizontal" />
                <Button
                  variant="secondary"
                  leftIcon={(size) => <ClockIcon size={size} />}
                  onClick={() => setIsUpdateRate(true)}
                >
                  {t('transaction:update_rate')}
                </Button>
              </>
            )}
          </div>
        }
      />
    </>
  );
}

export default React.memo(OutcomeDefaultView);
