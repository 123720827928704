import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import {
  MutationFetchFunction,
  QueryFetchFunction,
  QueryTransformer,
} from 'common/helpers/common';
import { useEntity } from 'hooks/use-entities';

import {
  IncomeModel,
  getIncomeInput,
  getIncomesInput,
  IncomeLiteModel,
} from './model';

export function useGetIncomes(
  input?: getIncomesInput,
  options?: UseQueryOptions<ExtendedApiResult<IncomeLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<IncomeLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getIncomes', input?.params],
      () =>
        QueryFetchFunction({
          url: 'incomes',
          params: input?.params,
        }),
      options,
    ),
    IncomeLiteModel,
  );
}

export function useGetIncome(
  input?: getIncomeInput,
  options?: UseQueryOptions<ApiResult<IncomeModel>, ApiError>,
): UseQueryResult<ApiResult<IncomeModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getIncome', input?.id],
      () =>
        QueryFetchFunction({
          url: `incomes/${input?.id}`,
        }),
      options,
    ),
    IncomeModel,
  );
}

export function useImportIncomeTemplate() {
  const { entity } = useEntity();
  return useMutation<ApiResult<any>, ApiError>(() =>
    MutationFetchFunction({
      url: 'incomes/import/template',
      method: 'GET',
      params: {
        entity_id: entity?.id!,
      },
    }),
  );
}

export function getIncomesKey(input?: getIncomesInput) {
  return ['getIncomes', input?.params];
}

export function getIncomeKey(input?: getIncomeInput) {
  return ['getIncome', input?.id];
}
