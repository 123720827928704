import {
  WarehouseModel,
  WarehouseMutationInput,
  useDeleteWarehouse,
  warehouseKey,
} from 'api-hooks/warehouse';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import { WarehouseFormType, formSchema } from './form-type';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  warehouse?: WarehouseModel;
  onSubmit: (
    input: WarehouseMutationInput,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}

export default function WarehouseForm(props: Props) {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const { warehouse, navigation } = props;
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteWarehouse();

  const dialog = useDialog();
  const resolver = useYupValidationResolver(formSchema());

  const initialValues = React.useMemo<WarehouseFormType>(
    () => ({
      address: warehouse?.address || '',
      name: warehouse?.name || '',
      regional: warehouse?.regional || '',
    }),
    [warehouse],
  );

  const methods = useForm<WarehouseFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const _input = {
          ...values,
          entityId: entity?.id,
        };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            warehouseId: warehouse!.id,
          });
          queryClient.refetchQueries([warehouseKey.listKey]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, t, warehouse]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!warehouse}
      setNavigations={setNavigations}
      navigation={navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={warehouse}
          title={
            warehouse
              ? `${t('common:warehouse')} - ${warehouse?.name}`
              : t('common:create_extra', { extra: t('common:warehouse') })
          }
          noDelete={!can(AuthorizationRules.WarehousesDelete)}
          noEdit={!can(AuthorizationRules.WarehousesUpdate)}
        />
        <div className={moduleStyles.halfContainer}>
          <Input
            type="text"
            name="name"
            label={t('common:name')}
            placeholder={t('common:enter_extra', { extra: t('common:name') })}
            required
          />
          <Input
            type="text"
            name="regional"
            label={t('inventory:regional')}
            placeholder={t('common:enter_extra', {
              extra: t('inventory:regional'),
            })}
            required
          />
          <Input
            type="text"
            name="address"
            label={t('common:address')}
            placeholder={t('common:enter_extra', {
              extra: t('common:address'),
            })}
            required
          />
        </div>
      </FormContent>
    </Form>
  );
}
