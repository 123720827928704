import {
  ChartOfAccountsLiteModel,
  ChartOfAccountsModel,
  useGetChartOfAccounts,
} from 'api-hooks/chart-of-accounts';
import Select, { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useSelectInputWithoutDetailHelper from 'hooks/use-select-input-without-detail-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface Props {
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  noMargin?: boolean;
  isParent?: boolean;
  onChange: (val: OptionProps | undefined) => void;
  value: string;
  className?: string;
}

function transformer(
  item: ChartOfAccountsModel | ChartOfAccountsLiteModel,
): OptionProps {
  return {
    value: item.id,
    label: `${item.number} - ${item.name}`,
  };
}

// THIS SELECT INPUT HELPER USED FOR
// manually set state of value
// the onchange function return label as long as the id
// currentList is used for saving the current list of the options

export default function ChartOfAccountsSelectManualInput(props: Props) {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const {
    placeholder = t('common:enter_extra', {
      extra: t('common:parent_id'),
    }),
    label = t('common:parent_id'),
  } = props;
  const [currentList, setCurrentList] = React.useState<OptionProps[]>([]);

  const selectProps = useSelectInputWithoutDetailHelper({
    value: props.value,
    onSelectItem: (value) => {
      props.onChange(currentList.find((item) => item.value === value));
    },
    useListQueryHook: useGetChartOfAccounts,
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            entity_id: entity?.id!,
            is_parent: !!props.isParent,
          },
        },
      }),
      [entity?.id, props.isParent],
    ),
    renderCreate: false,

    listTransformer(result) {
      const curr = result.data.map((v) => transformer(v));
      setCurrentList(curr);
      return curr;
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Select
      label={label}
      required={props.required}
      placeholder={placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
      className={props.className}
    />
  );
}
