import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  ApprovalApproveInput,
  ApprovalMarkInput,
  ApprovalModel,
  ApprovalNeedRevisionInput,
  ApprovalUpdateInput,
} from './model';

export function useApproveApproval(
  options?: UseMutationOptions<
    ApiResult<ApprovalModel>,
    ApiError,
    ApprovalApproveInput
  >,
): UseMutationResult<ApiResult<ApprovalModel>, ApiError, ApprovalApproveInput> {
  return useMutation<ApiResult<ApprovalModel>, ApiError, ApprovalApproveInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `approvals/${body.id}/approve`,
        method: 'POST',
        body,
        classType: ApprovalModel,
      });
    },
    options,
  );
}

export function useRejectApproval(
  options?: UseMutationOptions<
    ApiResult<ApprovalModel>,
    ApiError,
    ApprovalUpdateInput
  >,
): UseMutationResult<ApiResult<ApprovalModel>, ApiError, ApprovalUpdateInput> {
  return useMutation<ApiResult<ApprovalModel>, ApiError, ApprovalUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `approvals/${body.id}/reject`,
        method: 'POST',
        classType: ApprovalModel,
      });
    },
    options,
  );
}

export function useCancelApproval(
  options?: UseMutationOptions<
    ApiResult<ApprovalModel>,
    ApiError,
    ApprovalUpdateInput
  >,
): UseMutationResult<ApiResult<ApprovalModel>, ApiError, ApprovalUpdateInput> {
  return useMutation<ApiResult<ApprovalModel>, ApiError, ApprovalUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `approvals/${body.id}/cancel`,
        method: 'POST',
        classType: ApprovalModel,
      });
    },
    options,
  );
}

export function useMarkApproval(
  options?: UseMutationOptions<
    ApiResult<ApprovalModel>,
    ApiError,
    ApprovalMarkInput
  >,
): UseMutationResult<ApiResult<ApprovalModel>, ApiError, ApprovalMarkInput> {
  return useMutation<ApiResult<ApprovalModel>, ApiError, ApprovalMarkInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `approvals/${body.approvalId}/mark-done`,
        method: 'PATCH',
        body: body.body,
        classType: ApprovalModel,
      });
    },
    options,
  );
}

export function useRequestRevisionApproval(
  options?: UseMutationOptions<
    ApiResult<ApprovalModel>,
    ApiError,
    ApprovalNeedRevisionInput
  >,
): UseMutationResult<
  ApiResult<ApprovalModel>,
  ApiError,
  ApprovalNeedRevisionInput
> {
  return useMutation<
    ApiResult<ApprovalModel>,
    ApiError,
    ApprovalNeedRevisionInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `approvals/${body.id}/request-revision`,
      method: 'POST',
      body,
      classType: ApprovalModel,
    });
  }, options);
}
