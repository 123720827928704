import colors from 'common/styles/colors';
import { string2moneyDefault } from 'common/utils/string';
import Separator from 'components/common/separator';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

interface Props {
  title: string;
  data: any[];
  mainKey: string;
}

const CustomCell = ({ currency, amount, transactions, t }) => {
  return (
    <>
      <Text textVariant="MonoDefault">
        {currency} {string2moneyDefault(amount)}
      </Text>
      <Text textVariant="MonoSmaller" style={{ color: colors.textLight }}>
        {transactions} {t('common:transactions')}
      </Text>
    </>
  );
};

export default function SummaryItem(props: Props) {
  const { t } = useTranslation();
  const columns = React.useMemo<IColumn<any>[]>(
    () => [
      {
        header: t(`common:${props.mainKey}`),
        accessorKey: 'name',
        size: 250,
        stickyLeft: true,
      },
      {
        header: t('common:total_extra', { extra: t('common:income') }),
        accessorKey: 'income',
        textAlign: 'right',
        size: 175,
        cell: ({ row }) => {
          return (
            <CustomCell
              {...{
                currency: 'IDR',
                amount: row.original.income,
                transactions: row.original.totalIncome,
                t,
              }}
            />
          );
        },
      },
      {
        header: t('common:total_extra', { extra: t('common:outcome') }),
        accessorKey: 'outcome',
        textAlign: 'right',
        size: 175,
        cell: ({ row }) => {
          return (
            <CustomCell
              {...{
                currency: 'IDR',
                amount: row.original.outcome,
                transactions: row.original.totalOutcome,
                t,
              }}
            />
          );
        },
      },
      {
        header: t('common:total_extra', { extra: t('common:transaction') }),
        accessorKey: 'total',
        textAlign: 'right',
        size: 175,
        cell: ({ row }) => {
          return (
            <CustomCell
              {...{
                currency: 'IDR',
                amount: row.original.income - row.original.outcome,
                transactions:
                  row.original.totalIncome + row.original.totalOutcome,
                t,
              }}
            />
          );
        },
      },
    ],
    [props.mainKey, t],
  );

  return (
    <div style={{ width: '100%' }}>
      <Text textVariant="HeadingSmall">{t(`common:${props.mainKey}`)}</Text>
      <Separator gap={16} direction="vertical" />
      <TableComponent data={props.data} columns={columns} />
    </div>
  );
}
