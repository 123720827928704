import { PlusIcon } from 'common/assets';
import { DNDComponent } from 'components/common/dnd-component';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { FormState } from 'components/elements/form';
import Text from 'components/elements/text';
import EmployeeSelectInput from 'modules/master/employee/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

interface Field {
  customId: string;
  value: any;
  order: number;
}

export default function ApprovalLevelForm({
  idx,
  name,
}: {
  idx: number;
  name: string;
}) {
  const fieldName = `${name}.${idx}.details`;

  const { t } = useTranslation();
  const { control, setValue, getValues } = useFormContext<any>();
  const { fields, replace } = useFieldArray({
    control,
    name: fieldName,
    keyName: 'customId',
  });

  const onAdd = React.useCallback(() => {
    const _fields = fields.map((item: Field, index) => {
      return {
        employeeId: getValues(`${fieldName}.${index}.employeeId`),
        order: item.order,
        customId: item.customId!,
      };
    });

    _fields.push({
      employeeId: '',
      order: _fields.length,
    } as any);
    replace(_fields);
  }, [fieldName, fields, getValues, replace]);

  const onRemove = React.useCallback(
    (customId: string, fields) => {
      const _fields: Field[] = fields.map((item: Field, index) => {
        return {
          employeeId: getValues(`${fieldName}.${index}.employeeId`),
          order: item.order,
          customId: item.customId,
        };
      });

      const filtered = _fields.filter(
        (item: Field) => item.customId !== customId,
      );

      const sortFiltered = filtered.map((item: Field, index) => {
        return {
          ...item,
          order: index,
        };
      });
      replace(sortFiltered);
    },
    [fieldName, getValues, replace],
  );

  return (
    <>
      <Separator gap={16} direction="vertical" />
      <Text textVariant="BodyBoldDefault" className={moduleStyles.row()}>
        {t('common:approval_level')}
        <Separator gap={4} />
        <Text textVariant="BodyBoldDefault" color="red">
          *
        </Text>
      </Text>
      <Separator gap={16} direction="vertical" />
      <FormState>
        {({ editable }) => (
          <>
            <DNDComponent
              withNumbering
              init={fields.map((item, index) => ({
                children: (
                  <HOCInput
                    keys={[fieldName]}
                    key={`${fieldName}.${item.customId}`}
                  >
                    {(value) => (
                      <EmployeeSelectInput
                        name={`${fieldName}.${index}.employeeId`}
                        key={`${fieldName}.${index}`}
                        label=""
                        noMargin
                        placeholder={t('common:choose_extra', {
                          extra: t('common:employee'),
                        })}
                        removeArr={value[fieldName].map(
                          (item) => item.employeeId,
                        )}
                      />
                    )}
                  </HOCInput>
                ),
                customId: item.customId,
                name: `${fieldName}.${index}`,
              }))}
              setValue={setValue}
              onRemove={(id) => onRemove(id, fields)}
              editable={editable}
            />

            {editable && (
              <div>
                <Button
                  onClick={() => onAdd()}
                  type="button"
                  variant="secondary"
                  leftIcon={(size) => <PlusIcon size={size} />}
                  disabled={!editable}
                >
                  {t('common:add_extra', { extra: t('common:employee') })}
                </Button>
              </div>
            )}
          </>
        )}
      </FormState>
    </>
  );
}
