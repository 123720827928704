import { getTransfersKey } from 'api-hooks/transfer';
import {
  getTransferApprovalsKey,
  useApproveTransferApproval,
  useGetTransferApproval,
} from 'api-hooks/transfer-approval';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import React from 'react';

import TransferApprovalForm from './components/form';

const TransferApprovalView = (props: NavigationProps) => {
  const { data, error, isLoading, isFetching, refetch } =
    useGetTransferApproval({
      id: props.navigation.params.id,
    });
  const { can } = useAuthorization();
  const { mutateAsync, error: mutateError } = useApproveTransferApproval();

  const { close, navigate } = useNavigation();

  const transfer = data?.data;
  return (
    <>
      <FetchWrapperComponent
        onRetry={refetch}
        loadingComponent={<LoadingComponent />}
        error={!!error}
        errorComponent={
          <DetailComponent>
            <ErrorViewComponent refetch={refetch} />
          </DetailComponent>
        }
        isLoading={isLoading || isFetching}
        component={
          <TransferApprovalForm
            renderError={
              !!mutateError &&
              !!mutateError.message && (
                <>
                  <Alert variant="error" description={mutateError.message} />
                  <Separator gap={8} />
                </>
              )
            }
            transfer={transfer}
            onSubmit={async (input, form) => {
              const result = await mutateAsync({
                body: input,
                transferId: transfer!.id,
              });
              form.reset();
              close();
              if (can(AuthorizationRules.TransfersView)) {
                navigate(NavigationRoutes.TransferView, {
                  params: { id: transfer!.id },
                });
              }
              queryClient.refetchQueries([getTransferApprovalsKey()[0]]);
              queryClient.refetchQueries([getTransfersKey()[0]]);
              notification.success({ message: result?.message });

              return result.data;
            }}
          />
        }
      />
    </>
  );
};

export default TransferApprovalView;
