import { useGetMe } from 'api-hooks/auth';
import {
  EmployeeModel,
  EmployeeMutationInput,
  useUpdateEmployeeStatus,
} from 'api-hooks/employee';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import EmployeeActiveSelectInput from './employee-active-select';
import EmployeeInformationForm from './employee-information-form';

export type EmployeeFormType = {
  username?: string;
  email?: string;
  password?: string;
  passwordConfirmation?: string;
  roles?: string[] | [];
  entities: {
    id?: string;
    brands: string[];
  }[];
  divisionId: string;
  isActive: string;
};

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  employee?: EmployeeModel;
  onSubmit: (
    input: EmployeeMutationInput,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}

export default function EmployeeForm(props: Props) {
  const { t } = useTranslation();
  const { employee } = props;
  const dialog = useDialog();
  const { entity } = useEntity();
  const { data } = useGetMe();
  const { setNavigations } = useNavigation();
  const { can } = useAuthorization();

  const { mutateAsync: statusMutate } = useUpdateEmployeeStatus();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      username: Yup.string().required(),
      password: !employee
        ? Yup.string().required().min(6)
        : Yup.string().nullable(),
      passwordConfirmation: !employee
        ? Yup.string().required().min(6)
        : Yup.string().nullable(),
      email: Yup.string().email().required(),
      entities: Yup.array().of(
        Yup.object({
          id: Yup.string().nullable().required(),
          brands: Yup.array().of(Yup.string().required()).required().min(1),
        }),
      ),
      roles: Yup.array(Yup.string().required()).required().min(1),
      divisionId: Yup.string().nullable().required(),
    }),
  );

  const initialValues = React.useMemo<EmployeeFormType>(() => {
    return {
      username: employee?.name || '',
      email: employee?.email || '',
      roles: employee?.roles?.map((role) => role.id) || [],
      telegramChatId: employee?.telegramChatId || '',
      entities: employee?.entities?.length
        ? employee?.entities.map((item) => {
            return {
              id: item.id,
              brands: item.brands.map((brand) => brand.id),
            };
          })
        : [
            {
              id: '',
              brands: [],
            },
          ],
      isChangePassword: !employee,
      password: employee ? undefined : '',
      passwordConfirmation: employee ? undefined : '',
      divisionId: employee?.division.id || '',
      isActive: employee ? (employee.isActive ? '1' : '0') : '0',
    };
  }, [employee]);

  const methods = useForm<EmployeeFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onChangeStatus = React.useCallback(
    (isActive: boolean, input: EmployeeMutationInput) => {
      dialog.showConfirmation({
        message: t('employee:change_status_confirmation_desc', {
          extra: `"${t(`common:${isActive ? 'active' : 'disabled'}`)}"`,
        }),
        title: t('common:confirmation'),
        onPositiveAction: async (dismiss) => {
          try {
            const res = await statusMutate({
              isActive,
              employeeId: employee?.id!,
            });
            notification.success({ message: res.message });
            await props.onSubmit(input, methods as any);
          } catch (error) {
            notification.error({ message: error.message });
          } finally {
            dismiss();
          }
        },
        onNegativeAction: (dismiss) => {
          dismiss();
        },
      });
    },
    [dialog, employee?.id, methods, props, statusMutate, t],
  );

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const input = { name: values.username, ...values };
        if (
          employee &&
          employee.id === data?.data.id &&
          !values.entities.find((val) => val.id === entity?.id)
        ) {
          notification.error({
            message: t('employee:use_other_entities'),
          });
          return;
        }
        if (employee && !!Number(values.isActive) !== employee?.isActive) {
          onChangeStatus(!!Number(values.isActive), input);
          return;
        }
        await props.onSubmit(input as any, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [data?.data.id, employee, entity?.id, methods, onChangeStatus, props, t],
  );

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!employee}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          data={employee}
          noDelete
          noEdit={!can(AuthorizationRules.EmployeesUpdate)}
          title={
            <>
              {!employee ? (
                t('common:employee')
              ) : (
                <div className={moduleStyles.row()}>
                  {`${t('common:employee')} - ${employee.name}`}

                  {can(AuthorizationRules.EmployeesUpdateActiveStatus) && (
                    <>
                      <Separator gap={16} direction="horizontal" />
                      <Text textVariant="BodyDefault">
                        <EmployeeActiveSelectInput t={t} />
                      </Text>
                    </>
                  )}
                </div>
              )}
            </>
          }
        />
        <EmployeeInformationForm hasData={!!employee} />
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
}
