import { StockItemModel } from 'api-hooks/stock-item';
import * as Yup from 'yup';

export type StockUsageFormType = {
  stockUsageItems: {
    stockId: string;
    qty?: number | null;
    stockItemId?: string;
    stockItemDetail?: StockItemModel | null;
    isAsset?: boolean;
  }[];
};

export const formSchema = () =>
  Yup.object().shape({
    stockUsageItems: Yup.array().of(
      Yup.object({
        stockId: Yup.string().required().nullable(),
        qty: Yup.number().when('isAsset', {
          is: (isAsset) => !isAsset,
          then: Yup.number().required().nullable(),
          otherwise: Yup.number().strip(),
        }),
        stockItemId: Yup.string().nullable(),
      }),
    ),
  });
