import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import { MassUpdateInput } from './model';

export function useOutcomeMassUpdate(
  options?: UseMutationOptions<
    ApiResult<MessageResult>,
    ApiError,
    MassUpdateInput
  >,
): UseMutationResult<ApiResult<MessageResult>, ApiError, MassUpdateInput> {
  return useMutation<ApiResult<MessageResult>, ApiError, MassUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'expenses/mass-update-rate',
        method: 'PATCH',
        body,
      });
    },
    options,
  );
}

export function useIncomeMassUpdate(
  options?: UseMutationOptions<
    ApiResult<MessageResult>,
    ApiError,
    MassUpdateInput
  >,
): UseMutationResult<ApiResult<MessageResult>, ApiError, MassUpdateInput> {
  return useMutation<ApiResult<MessageResult>, ApiError, MassUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'incomes/mass-update-rate',
        method: 'PATCH',
        body,
      });
    },
    options,
  );
}

export function useTransferMassUpdate(
  options?: UseMutationOptions<
    ApiResult<MessageResult>,
    ApiError,
    MassUpdateInput
  >,
): UseMutationResult<ApiResult<MessageResult>, ApiError, MassUpdateInput> {
  return useMutation<ApiResult<MessageResult>, ApiError, MassUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'transfers/mass-update-rate',
        method: 'PATCH',
        body,
      });
    },
    options,
  );
}
