import * as Yup from 'yup';

export type StockFormType = {
  name: string;
  unit: string;
  isAsset: boolean;
  isUsable: boolean;
  assetId: string;
  description: string;
  stockItemCodeGroupId: string;
  stockItemCodeItemId: string;
  stockItemCodeStatedId: string;
  stockItemCodeComponentId: string;
};

export const formSchema = () =>
  Yup.object().shape({
    name: Yup.string().required().nullable(),
    unit: Yup.string().required().nullable(),
    isAsset: Yup.bool(),
    isUsable: Yup.bool(),
    assetId: Yup.string().required().nullable(),
    description: Yup.string().nullable(),
    stockItemCodeGroupId: Yup.string().required().nullable(),
    stockItemCodeItemId: Yup.string().required().nullable(),
    stockItemCodeStatedId: Yup.string().required().nullable(),
    stockItemCodeComponentId: Yup.string().required().nullable(),
  });
