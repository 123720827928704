import { Input } from 'components/elements/field';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const CurrencyInformationForm = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={moduleStyles.halfContainer}>
        <Input
          label={t('common:currency')}
          placeholder={t('common:enter_extra', {
            extra: t('common:currency'),
          })}
          name="currency"
          type="text"
          required
        />
      </div>
      <div className={moduleStyles.halfContainer}>
        <Input
          label={t('common:name')}
          placeholder={t('common:name_placeholder')}
          name="name"
          type="text"
          required
        />
      </div>
    </div>
  );
};

export default CurrencyInformationForm;
