import { EmployeeLiteModel, useGetEmployees } from 'api-hooks/employee';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import ListHeader from 'components/common/list-header';
import Badge from 'components/elements/badge';
import { IColumn } from 'components/elements/table';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import {
  GenericQueryTable,
  useGenericTableProps,
  useTableViewDetailColumn,
} from 'modules/common/table';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

export default function EmployeeList() {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const navigation = useNavigation();

  const _columns = React.useMemo<IColumn<EmployeeLiteModel>[]>(
    () => [
      {
        header: t('employee:employee_name'),
        accessorKey: 'name',
        minSize: 200,
        sortName: 'name',
      },
      {
        header: t('common:email'),
        accessorKey: 'email',
        minSize: 250,
        sortName: 'email',
      },
      {
        header: t('common:telegram_id'),
        accessorKey: 'telegramChatId',
        minSize: 250,
      },
      {
        header: t('common:role'),
        accessorKey: 'roles',
        minSize: 250,
        cell: ({
          row: {
            original: { roles },
          },
        }) => roles?.map((item) => item.name).join(', '),
      },
      {
        header: t('common:entity'),
        accessorKey: 'entities',
        minSize: 250,
        cell: ({
          row: {
            original: { entities },
          },
        }) => entities?.map((item) => item.name).join(', '),
      },
      {
        header: t('common:status'),
        accessorKey: 'isActive',
        minSize: 100,
        cell: ({ getValue }) => {
          const isActive = !!getValue();
          return (
            <Badge color={isActive ? 'green' : 'gray'}>
              {t(`common:${isActive ? 'active' : 'disabled'}`)}
            </Badge>
          );
        },
      },
    ],
    [t],
  );
  const columns = useTableViewDetailColumn(
    _columns,
    'EmployeesView',
    'EmployeesView',
  );

  const { refetch, ...table } = useGenericTableProps(columns, useGetEmployees, {
    params: {},
  });

  const onCreate = () => navigation.navigate(NavigationRoutes.EmployeesCreate);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:employee')}
        showAdd={can(AuthorizationRules.EmployeesCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
      />
      <GenericQueryTable {...table} />
    </div>
  );
}
