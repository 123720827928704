import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { ApiError } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  NotificationModel,
  NotificationResult,
  getNotificationsInput,
} from './model';

export function useGetNotifications(
  input?: getNotificationsInput,
  options?: UseInfiniteQueryOptions<
    NotificationResult<NotificationModel[]>,
    ApiError
  >,
): UseInfiniteQueryResult<NotificationResult<NotificationModel[]>, ApiError> {
  return QueryTransformer(
    useInfiniteQuery<NotificationResult<NotificationModel[]>, ApiError>(
      ['getNotifications', input?.params],
      async ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: 'notifications',
          params: {
            ...input?.params,
            page: pageParam,
          },
        }),
      options,
    ),
    NotificationModel,
  );
}
export function getNotificationsKey(input?: getNotificationsInput) {
  return ['getNotifications', input?.params];
}
