import {
  AccountModel,
  getAccountKey,
  getAccountsKey,
  useGetAccount,
  useGetAccounts,
} from 'api-hooks/account';
import { QueryTransformer } from 'common/helpers/common';
import Select, { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

function transformer(item: any): OptionProps {
  return {
    value: item.id,
    label: item.name,
    group: item.hasOwnProperty('entityCurrencyName')
      ? item.entityCurrencyName
      : item.currencyName,
  };
}

interface Props {
  required?: boolean;
  label?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  noMargin?: boolean;
  onSelected?: (value?: string | null) => void;
}

export default function AccountSelectInput(props: Props) {
  const { t } = useTranslation();
  const { label = t('common:account'), placeholder = t('common:account') } =
    props;
  const { entity } = useEntity();

  const selectProps = useSelectInputHelper({
    value: props.value,
    onSelectItem: (value) => {
      props.onSelected?.(value);
    },
    useListQueryHook: useGetAccounts,
    useDetailLazyQueryHook: useGetAccount,
    getListsKey: React.useCallback(
      (page, q) =>
        getAccountsKey({
          params: {
            q,
            page,
            filter: {
              entity_id: entity?.id!,
            },
          },
        }),
      [entity?.id],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            entity_id: entity?.id!,
          },
        },
      }),
      [entity?.id],
    ),
    getDetailKey: React.useCallback(
      (value) =>
        getAccountKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback((id) => {
      return { id };
    }, []),
    renderCreate: false,
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      const resultTransformer = QueryTransformer(result, AccountModel);
      return transformer(resultTransformer.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Select
      required={props.required}
      label={label}
      placeholder={placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
