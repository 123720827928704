import { useGetTransactionSummary } from 'api-hooks/report';
import filterReduce from 'common/helpers/filter-reduce';
import { DrawerProps } from 'components/common/drawer';
import Separator from 'components/common/separator';
import Text from 'components/elements/text';
import { useEntity } from 'hooks/use-entities';
import * as React from 'react';

import SummaryItem from './summary-item';

const container = {
  overflow: 'auto',
  height: 'calc( 100vh - 82px)',
};

function Summary({ filters }) {
  const { entity } = useEntity();
  const { data } = useGetTransactionSummary({
    params: {
      filter: {
        entity_id: entity?.id || undefined,
        ...(filterReduce(filters!) as any),
      },
    },
  });

  return (
    <div style={container}>
      {!!data?.data &&
        Object.keys(data?.data).map((item) => (
          <Separator gap={32} direction="vertical">
            <SummaryItem data={data?.data[item]} mainKey={item} title={item} />
          </Separator>
        ))}
      <Separator gap={16} direction="vertical" />
    </div>
  );
}

export default function TransactionReportSummary({ filters, t }): DrawerProps {
  return {
    title: <Text textVariant="HeadingLarge">{t('common:summary')}</Text>,
    render: (dismiss) => <Summary filters={filters} />,
    padding: 'sm',
    size: 800,
  };
}
