import {
  getBrandKey,
  getBrandsKey,
  useGetBrand,
  useUpdateBrand,
} from 'api-hooks/brand';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import BrandForm from './components/form';

const BrandView = (props: NavigationProps) => {
  const { data, error, isLoading, isFetching, refetch } = useGetBrand({
    id: props.navigation.params.id,
  });

  const { mutateAsync, error: mutateError } = useUpdateBrand();

  const brand = data?.data;

  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <BrandForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          brand={brand}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              brandId: brand!.id,
            });
            form.reset();
            queryClient.refetchQueries([getBrandsKey()[0]]);
            queryClient.refetchQueries(getBrandKey({ id: brand!.id }));
            notification.success({ message: result?.message });

            return result.data;
          }}
        />
      }
    />
  );
};

export default BrandView;
