import { Badge, Group } from '@mantine/core';
import { BrandLiteModel } from 'api-hooks/brand';
import {
  TransferApprovalLiteModel,
  useGetTransferApprovals,
} from 'api-hooks/transfer-approval';
import ListHeader from 'components/common/list-header';
import { IColumn } from 'components/elements/table';
import { format } from 'date-fns';
import { useEntity } from 'hooks/use-entities';
import {
  GenericQueryTable,
  useGenericTableProps,
  useTableViewDetailColumn,
} from 'modules/common/table';
import Balance from 'modules/entity-settings/common/components/balance';
import BalanceWithDetails from 'modules/entity-settings/common/components/balance-with-details';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

function useTransferApprovalColumns({ t, entity }) {
  const _columns = React.useMemo<IColumn<TransferApprovalLiteModel>[]>(
    () => [
      {
        header: t('common:requested_at'),
        accessorKey: 'requestedAt',
        minSize: 200,
        sortName: 'requested_at',
        cell: ({ getValue }) =>
          format(new Date(getValue() as Date), 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('common:transaction_at'),
        accessorKey: 'transactionAt',
        minSize: 200,
        sortName: 'transaction_at',
        cell: ({ getValue }) =>
          format(new Date(getValue() as Date), 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 200,
        sortName: 'description',
      },
      {
        header: t('transfer:from_account'),
        accessorKey: 'accountFrom',
        minSize: 200,
        sortName: 'account_from',
      },
      {
        header: t('transfer:to_account'),
        accessorKey: 'accountTo',
        minSize: 200,
        sortName: 'account_to',
      },
      {
        header: t('transfer:amount'),
        accessorKey: 'transferAmount',
        minSize: 200,
        cell: ({ row }) => {
          const {
            original: {
              transferCurrency: currency,
              transferAmount: balance,
              accountFromRate: rate,
            },
          } = row;
          return currency !== entity?.currency ? (
            <BalanceWithDetails
              currency={currency}
              balance={balance}
              rate={rate}
              entityCurrency={entity?.currency!}
            />
          ) : (
            <Balance currency={currency} balance={balance} />
          );
        },
      },
      {
        header: t('transfer:received_amount'),
        accessorKey: 'receiveAmount',
        minSize: 200,
        textAlign: 'right',
        cell: ({ row }) => {
          const {
            original: {
              receiveCurrency: currency,
              receiveAmount: balance,
              accountToRate: rate,
            },
          } = row;
          return currency !== entity?.currency ? (
            <BalanceWithDetails
              currency={currency}
              balance={balance}
              rate={rate}
              entityCurrency={entity?.currency!}
            />
          ) : (
            <Balance currency={currency} balance={balance} />
          );
        },
      },
      {
        header: t('common:brand'),
        accessorKey: 'brands',
        minSize: 200,
        cell: ({ getValue }) => {
          const data = getValue();
          return (
            <Group spacing={8}>
              {(data as BrandLiteModel[]).map((val) => (
                <Badge color={val.color} key={val.id}>
                  {val.name}
                </Badge>
              ))}
            </Group>
          );
        },
      },
    ],
    [entity?.currency, t],
  );
  return useTableViewDetailColumn(
    _columns,
    'TransferApprovalView',
    'TransferApprovalsView',
  );
}

export default function TransferApprovalList() {
  const { t } = useTranslation();
  const { entity } = useEntity();

  const columns = useTransferApprovalColumns({ t, entity });

  const { refetch, ...table } = useGenericTableProps(
    columns,
    useGetTransferApprovals,
    {
      params: {
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
  );

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:transfer_approval')}
        showAdd={false}
        onRefresh={refetch}
      />
      <GenericQueryTable {...table} />
    </div>
  );
}
