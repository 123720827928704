import { AccountLiteModel } from 'api-hooks/account/model';
import { BrandLiteModel } from 'api-hooks/brand/model';
import { CommonSimpleModel, getIndexInput } from 'api-hooks/common/model';
import { DivisionModel } from 'api-hooks/division';
import { EntityCurrencyModel } from 'api-hooks/entity-currency/model';
import { Expose, Type } from 'class-transformer';

// Model

export class IncomeLiteModel {
  id: string;
  code: string;
  description: string | null;
  division: DivisionModel;

  @Type(() => AccountLiteModel)
  account: AccountLiteModel;

  @Type(() => BrandLiteModel)
  brands: BrandLiteModel[];

  @Type(() => Number)
  rate: number;

  @Type(() => Number)
  amount: number;

  @Expose({ name: 'entity_id' })
  entityId: string;

  @Expose({ name: 'is_base_currency' })
  @Type(() => Boolean)
  isBaseCurrency: boolean;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'has_attachment' })
  hasAttachment: boolean;

  @Expose({ name: 'is_attachment_completed' })
  isAttachmentCompleted: boolean;

  @Expose({ name: 'created_by' })
  createdBy: CommonSimpleModel;

  @Expose({ name: 'updated_by' })
  updatedBy: CommonSimpleModel;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;
}

export class Files {
  id: string;

  @Expose({ name: 'name' })
  file: string;

  url: string;
}

export class ChartOfAccount {
  id: string;

  @Expose({ name: 'full_name' })
  fullName: string;
}
export class IncomeModel extends IncomeLiteModel {
  @Expose({ name: 'is_include_mutation' })
  isIncludeMutation: boolean;

  @Expose({ name: 'has_attachment' })
  hasAttachment: boolean;

  @Expose({ name: 'is_attachment_completed' })
  isAttachmentCompleted: boolean;

  @Expose({ name: 'files' })
  @Type(() => Files)
  files: Files[];

  @Expose({ name: 'chart_of_account' })
  @Type(() => ChartOfAccount)
  chartOfAccount: ChartOfAccount;

  @Expose({ name: 'entity_currency' })
  @Type(() => EntityCurrencyModel)
  entityCurrency: EntityCurrencyModel;

  @Expose({ name: 'transaction_rate' })
  @Type(() => Number)
  transactionRate: number;

  @Expose({ name: 'is_transaction_closed' })
  isTransactionClosed: boolean;
}

// Input

export type getIncomesInput = getIndexInput;

export type getIncomeInput = {
  id: string;
};

export type IncomeMutationInput = {
  amount: number;
  accountId: string;
  description: string | null;
  entityId?: string | null;
  isBaseCurrency: boolean;
  brands: string[];
  rate: number;
  transactionAt: Date;
  isIncludeMutation: boolean;
  isAttachmentCompleted: boolean;
  chartOfAccountId: boolean;
  files: string[] | undefined;
};

export type IncomeUpdateInput = {
  incomeId: string;
  body: IncomeMutationInput;
};

export type IncomeDeleteInput = {
  incomeId: string;
};
