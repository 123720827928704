import { useGetEntityCurrencies } from 'api-hooks/entity-currency/query';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import ListHeader from 'components/common/list-header';
import { useAuthorization } from 'hooks/use-authorization';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import { GenericQueryTable, useGenericTableProps } from 'modules/common/table';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import useEntityCurrencyColumns from './helpers/use-columns';

export default function EntityCurrencyList() {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const navigation = useNavigation();

  const columns = useEntityCurrencyColumns();

  const { refetch, ...table } = useGenericTableProps(
    columns,
    useGetEntityCurrencies,
    {
      params: {
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
  );

  const onCreate = () =>
    navigation.navigate(NavigationRoutes.EntityCurrencyCreate);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:entity_currency')}
        showAdd={can(AuthorizationRules.EntityCurrenciesCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
      />
      <GenericQueryTable {...table} />
    </div>
  );
}
