import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import { NotificationModel, NotificationUpdateInput } from './model';

export function useUpdateNotification(
  options?: UseMutationOptions<
    ApiResult<NotificationModel>,
    ApiError,
    NotificationUpdateInput
  >,
): UseMutationResult<
  ApiResult<NotificationModel>,
  ApiError,
  NotificationUpdateInput
> {
  return useMutation<
    ApiResult<NotificationModel>,
    ApiError,
    NotificationUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `notifications`,
      method: 'PATCH',
      classType: NotificationModel,
      body: body.body,
    });
  }, options);
}
