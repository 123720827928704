import colors from 'common/styles/colors';
import { string2moneyDefault } from 'common/utils/string';
import Text from 'components/elements/text';
import React from 'react';

interface Props {
  currency: string;
  entityCurrency: string;
  balance: number;
  rate: number;
}

const BalanceWithDetails = ({
  currency,
  balance,
  rate,
  entityCurrency,
}: Props) => {
  return (
    <>
      <Text textVariant="MonoDefault">
        {currency} {string2moneyDefault(balance)}
      </Text>
      <Text textVariant="MonoSmaller" style={{ color: colors.textLight }}>
        {`~ ${entityCurrency} ${string2moneyDefault(balance * rate)}`}
      </Text>
    </>
  );
};

export default BalanceWithDetails;
