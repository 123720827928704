import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { ApiError, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import { ExportLiteModel, getExportInput } from './model';

export function useGetExports(
  input?: getExportInput,
  options?: UseQueryOptions<ExtendedApiResult<ExportLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<ExportLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getExports', input?.params],
      () =>
        QueryFetchFunction({
          url: 'export-histories',
          params: input?.params,
        }),
      options,
    ),
    ExportLiteModel,
  );
}

export function getExportsKey(input?: getExportInput) {
  return ['getExports', input?.params];
}
