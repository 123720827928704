import React, { useContext } from 'react';

import DatePicker, { DatePickerProps } from './date-picker';
import { FormContext } from './form/context';
import TextInput, { TextInputProps } from './text-input';

export interface IDatePickerProps extends DatePickerProps {
  type: 'date';
}

export interface ITextInputFieldProps extends TextInputProps {
  type: 'text';
}
export default function FormStateRawField(
  props: ITextInputFieldProps | IDatePickerProps,
) {
  const context = useContext(FormContext);
  const rest = React.useMemo(
    () => ({
      disabled: true,
      readOnly: !context.editable,
      'aria-readonly': !context.editable,
    }),
    [context.editable],
  );

  switch (props.type) {
    case 'text':
      return <TextInput {...props} {...rest} />;
    case 'date':
      return <DatePicker {...props} {...rest} />;
    default:
      return <></>;
  }
}
