import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  ChartAccountCategoriesDeleteInput,
  ChartOfAccountCategoriesModel,
  ChartOfAccountCategoriesMutationInput,
  ChartOfAccountCategoriesUpdateInput,
} from './model';

export function useCreateChartOfAccountCategories(
  options?: UseMutationOptions<
    ApiResult<ChartOfAccountCategoriesModel>,
    ApiError,
    ChartOfAccountCategoriesMutationInput
  >,
): UseMutationResult<
  ApiResult<ChartOfAccountCategoriesModel>,
  ApiError,
  ChartOfAccountCategoriesMutationInput
> {
  return useMutation<
    ApiResult<ChartOfAccountCategoriesModel>,
    ApiError,
    ChartOfAccountCategoriesMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'chart-of-account-categories',
      method: 'POST',
      classType: ChartOfAccountCategoriesModel,
      body,
    });
  }, options);
}

export function useUpdateChartOfAccountCategories(
  options?: UseMutationOptions<
    ApiResult<ChartOfAccountCategoriesModel>,
    ApiError,
    ChartOfAccountCategoriesUpdateInput
  >,
): UseMutationResult<
  ApiResult<ChartOfAccountCategoriesModel>,
  ApiError,
  ChartOfAccountCategoriesUpdateInput
> {
  return useMutation<
    ApiResult<ChartOfAccountCategoriesModel>,
    ApiError,
    ChartOfAccountCategoriesUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `chart-of-account-categories/${body.id}`,
      method: 'PUT',
      classType: ChartOfAccountCategoriesModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteChartOfAccountCategories(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    ChartAccountCategoriesDeleteInput
  >,
): UseMutationResult<
  MessageResult,
  ApiError,
  ChartAccountCategoriesDeleteInput
> {
  return useMutation<
    MessageResult,
    ApiError,
    ChartAccountCategoriesDeleteInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `chart-of-account-categories/${body.id}`,
      method: 'DELETE',
    });
  }, options);
}
