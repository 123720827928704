import { PurchaseRequisitionsHistoryModel } from 'api-hooks/purchase-requisitions';
import Separator from 'components/common/separator';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { format } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { snakeToTitle } from '../helpers';

interface Props {
  histories: PurchaseRequisitionsHistoryModel[];
}

export default function PurchaseRequisitionsTableForm(props: Props) {
  const { histories } = props;
  const { t } = useTranslation();

  const columns = React.useMemo<IColumn<PurchaseRequisitionsHistoryModel>[]>(
    () => [
      {
        header: t('pr:date'),
        accessorKey: 'actionAt',
        minSize: 150,
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('pr:process'),
        accessorKey: 'action',
        minSize: 150,
        cell: ({ getValue }) => snakeToTitle(getValue() as string),
      },
      {
        header: t('common:status'),
        accessorKey: 'status',
        minSize: 200,
        cell: ({ getValue }) => snakeToTitle(getValue() as string),
      },
      {
        header: t('pr:user'),
        accessorKey: 'approvedBy.name',
        minSize: 150,
      },

      {
        header: t('pr:note'),
        accessorKey: 'note',
        minSize: 150,
        cell: ({ getValue }) => getValue() || '-',
      },
    ],
    [t],
  );
  return (
    <>
      <Separator gap={32} direction="vertical" />
      <Text textVariant="HeadingSmall">{t('pr:pr_history')}</Text>
      <Separator gap={16} direction="vertical" />
      <TableComponent columns={columns} data={histories || []} />
    </>
  );
}
