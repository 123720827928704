import classNames from 'classnames';
import { PlusIcon, TrashIcon } from 'common/assets';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { ActionIcon, Button } from 'components/elements/button';
import { FormContext } from 'components/elements/form';
import Text from 'components/elements/text';
import StockSelectInput from 'modules/stock/stock/components/select-input';
import StockItemSelectInput from 'modules/stock/stock-items/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import { Translate } from 'next-translate';
import React, { useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { StockGroupingFormType } from './form-type';
import StockGroupingItem from './item';
import { stockGroupingStyles } from '../styles.css';

interface Props {
  t: Translate;
}

const DEFAULT_VALUE = {
  stockId: '',
  stockItemId: '',
};

export default function StockGroupingSubItem(props: Props) {
  const context = useContext(FormContext);
  const { t } = props;
  const { control, setValue } = useFormContext<StockGroupingFormType>();
  const { fields, remove, append } = useFieldArray({
    name: 'stockGroupingItems',
    control,
    keyName: 'customId',
  });

  return (
    <>
      <Text textVariant="HeadingSmall">{t('inventory:sub_item')}</Text>
      <Separator gap={16} direction="vertical" />
      {fields.map((field, index) => (
        <div className={moduleStyles.fullContainer} key={field.customId}>
          <div
            className={classNames(
              moduleStyles.row({
                align: 'end',
              }),
              !context.editable && stockGroupingStyles.hide,
            )}
          >
            <div className={moduleStyles.flex1}>
              <div className={moduleStyles.sectionContainer}>
                <div className={moduleStyles.halfContainer}>
                  <StockSelectInput
                    name={`stockGroupingItems.${index}.stockId`}
                    isAsset
                  />
                </div>
                <div className={moduleStyles.halfContainer}>
                  <HOCInput keys={[`stockGroupingItems.${index}.stockId`]}>
                    {({ [`stockGroupingItems.${index}.stockId`]: stockId }) => (
                      <StockItemSelectInput
                        stockId={stockId}
                        name={`stockGroupingItems.${index}.stockItemId`}
                        label={t('inventory:barcode')}
                        placeholder={t('common:choose_extra', {
                          extra: t('inventory:barcode'),
                        })}
                        barcodeLabel
                        dependStockId
                        onAfterChange={(val) =>
                          setValue(
                            `stockGroupingItems.${index}.stockItemDetail`,
                            val,
                          )
                        }
                      />
                    )}
                  </HOCInput>
                </div>
              </div>
            </div>
            <Separator gap={32} direction="horizontal" />
            {context.editable && (
              <div>
                <ActionIcon
                  error
                  variant="transparent"
                  children={(size) => <TrashIcon size={size} />}
                  onClick={() => remove(index)}
                />
                <Separator gap={16} direction="vertical" />
              </div>
            )}
          </div>
          <StockGroupingItem
            keyName={`stockGroupingItems.${index}.stockItemDetail`}
          />
        </div>
      ))}
      {context.editable && (
        <Button
          leftIcon={(size) => <PlusIcon size={size} />}
          children={t('common:add_extra', { extra: t('inventory:item') })}
          onClick={() => append(DEFAULT_VALUE)}
          variant="secondary"
        />
      )}
    </>
  );
}
