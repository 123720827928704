import { Loader } from '@mantine/core';
import { TrashIcon } from 'common/assets';
import { Button } from 'components/elements/button';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { styles } from './style.css';

interface Props {
  disabled?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

export default function DeleteIconButton(props: Props) {
  const { t } = useTranslation();
  return (
    <Button
      onClick={() => {
        !props.disabled && props.onClick?.();
      }}
      variant="secondary"
      error
      disabled={props.disabled || props.isLoading}
      className={styles}
      leftIcon={(size) =>
        !props.isLoading ? (
          <TrashIcon width={size} height={size} />
        ) : (
          <Loader color="cyan" size={size} />
        )
      }
    >
      {t('common:delete')}
    </Button>
  );
}
