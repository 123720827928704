import { useGetAccountMutations } from 'api-hooks/account-mutation';
import { ClosingTypeEnum } from 'api-hooks/transaction-closing';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import filterReduce from 'common/helpers/filter-reduce';
import notification from 'common/helpers/notification';
import ListHeader from 'components/common/list-header';
import QueryFilter, {
  useApplyQueryFilter,
} from 'components/common/query-filter/query-filter';
import Separator from 'components/common/separator';
import { IColumn } from 'components/elements/table';
import { format } from 'date-fns';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import { useAuthorization } from 'hooks/use-authorization';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import Balance from 'modules/entity-settings/common/components/balance';
import BalanceWithDetails from 'modules/entity-settings/common/components/balance-with-details';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import AccountMutationTable from './components/table';
import TransactionClosing from '../common/components/closing';

const AccountMutationList = () => {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const navigation = useNavigation();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const _columns = React.useMemo<IColumn<any>[]>(
    () => [
      {
        header: t('common:transaction_at'),
        accessorKey: 'transactionAt',
        minSize: 200,
        sortName: 'transaction_at',
        cell: ({ getValue }) => (
          <>{format(getValue() as Date, 'dd MMM yyyy, HH:mm')}</>
        ),
      },
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 250,
        sortName: 'description',
      },
      {
        header: t('common:account'),
        accessorKey: 'account.name',
        minSize: 250,
        sortName: 'account',
      },
      {
        header: t('transfer:amount'),
        accessorKey: 'amount',
        minSize: 250,
        textAlign: 'right',
        cell: ({ row }) => {
          const {
            original: { isBaseCurrency, rate, amount, account },
          } = row;
          if (!isBaseCurrency) {
            return (
              <BalanceWithDetails
                currency={account.currency}
                balance={amount}
                entityCurrency={entity?.currency!}
                rate={rate}
              />
            );
          } else {
            return <Balance currency={account.currency} balance={amount} />;
          }
        },
      },
    ],
    [entity?.currency, t],
  );

  const {
    data,
    refetch,
    isLoading,
    isFetching,
    error,
    extras: [{ filters, setFilters }, { columns }],
  } = useComposedQuery(
    useGetAccountMutations,
    {
      params: {
        page,
        limit,
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
    useApplyQueryFilter((data: any) => {
      return data.filters;
    }),
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
  );
  const _filter = filterReduce(filters);

  const onCreate = React.useCallback(() => {
    navigation.navigate(NavigationRoutes.AccountMutationCreate);
  }, [navigation]);

  React.useEffect(() => {
    if (!!error && !!error.message) {
      notification.error({ message: error?.message });
    }
  }, [error]);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:account_mutation')}
        showAdd={can(AuthorizationRules.WalletMutationsCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
        rightCustomComponent={
          <>
            {can(AuthorizationRules.TransactionClosingsList) && (
              <>
                <TransactionClosing type={ClosingTypeEnum.accountMutation} />
                <Separator gap={16} direction="horizontal" />
              </>
            )}
          </>
        }
      />
      <Separator gap={8} />
      <div className={moduleStyles.topContainer}>
        <QueryFilter
          setPage={setPage}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <AccountMutationTable
        data={data?.data || []}
        loading={isLoading || isFetching}
        page={page}
        onPageChange={(page) => setPage(page)}
        meta={data?.meta}
        filter={_filter}
        sortColumns={columns || []}
        limit={limit}
        onLimitChange={(limit) => setLimit(limit)}
      />
    </div>
  );
};

export default AccountMutationList;
