import { PurchaseRequisitionsStatusEnum } from 'api-hooks/purchase-requisitions';

export const badgeColor = [
  {
    value: PurchaseRequisitionsStatusEnum.HEAD_DIVISION_APPROVAL,
    color: 'yellow',
  },
  { value: PurchaseRequisitionsStatusEnum.COMPLETED, color: 'green' },
  { value: PurchaseRequisitionsStatusEnum.REJECTED, color: 'red' },
];
