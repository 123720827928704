import { ChartOfAccountCategoriesLiteModel } from 'api-hooks/chart-of-account-categories';
import {
  getChartOfAccountCategoriesKey,
  getChartOfAccountCategoryKey,
  useGetChartOfAccountCategories,
  useGetChartOfAccountCategory,
} from 'api-hooks/chart-of-account-categories/query';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useAuthorization } from 'hooks/use-authorization';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  onAfterChange?: (value?: any) => void;
  overrideOnChange?: (prevValue: any, value: any) => void;
  noMargin?: boolean;
}

function transformer(item: ChartOfAccountCategoriesLiteModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}

export default function ChartOfAccountCategoriesSelectInput(props: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const { entity } = useEntity();
  const { navigate } = useNavigation();
  const { can } = useAuthorization();
  const {
    placeholder = t('common:enter_extra', {
      extra: t('common:category'),
    }),
    label = t('common:category'),
  } = props;

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetChartOfAccountCategories,
    useDetailLazyQueryHook: useGetChartOfAccountCategory,
    getListsKey: React.useCallback(
      (page, q) =>
        getChartOfAccountCategoriesKey({
          params: {
            q,
            page,
            filter: {
              entity_id: entity?.id!,
            },
          },
        }),
      [entity?.id],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            entity_id: entity?.id!,
          },
        },
      }),
      [entity],
    ),
    renderCreate: can(AuthorizationRules.ChartOfAccountCategoriesCreate),
    onClickCreate() {
      navigate(NavigationRoutes.ChartOfAccountCategoriesCreate);
      props.onClose && props.onClose();
    },
    getDetailKey: React.useCallback(
      (value) =>
        getChartOfAccountCategoryKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      label={label}
      required
      name={props.name}
      placeholder={placeholder}
      onAfterChange={props.onAfterChange}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
