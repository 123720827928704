import {
  ApprovalMasterLiteModel,
  useGetApprovalMasters,
} from 'api-hooks/approval-master';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import filterReduce from 'common/helpers/filter-reduce';
import notification from 'common/helpers/notification';
import ListHeader from 'components/common/list-header';
import QueryFilter, {
  useApplyQueryFilter,
} from 'components/common/query-filter/query-filter';
import Separator from 'components/common/separator';
import { IColumn } from 'components/elements/table';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import { useAuthorization } from 'hooks/use-authorization';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import ApprovalMasterTable from './components/table';

export default function ApprovalMasterList() {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const navigation = useNavigation();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const _columns = React.useMemo<IColumn<ApprovalMasterLiteModel>[]>(
    () => [
      {
        header: t('common:model'),
        accessorKey: 'type',
        minSize: 200,
      },
      {
        header: t('approval:matrix'),
        accessorKey: 'divisions',
        minSize: 400,
        cell: ({
          row: {
            original: { type, divisions, finances },
          },
        }) => {
          return divisions.length
            ? divisions.map((item) => item.division.name).join(', ')
            : finances
                .map(
                  (item) =>
                    `${item.lowerBound !== null ? `${item.lowerBound}≤` : ''}x${
                      item.upperBound !== null ? `≤${item.upperBound}` : ''
                    }`,
                )
                .join(' , ');
        },
      },
    ],
    [t],
  );

  const {
    data,
    refetch,
    isLoading,
    isFetching,
    error,
    extras: [{ filters, setFilters }, { columns }],
  } = useComposedQuery(
    useGetApprovalMasters,
    {
      params: {
        page,
        limit,
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
    useApplyQueryFilter((data: any) => {
      return data.filters;
    }),
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
  );

  const _filter = filterReduce(filters);

  const onCreate = React.useCallback(() => {
    navigation.navigate(NavigationRoutes.ApprovalMastersCreate);
  }, [navigation]);

  React.useEffect(() => {
    if (!!error && !!error.message) {
      notification.error({ message: error?.message });
    }
  }, [error]);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:approval')}
        showAdd={can(AuthorizationRules.ApprovalMastersCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
      />
      <Separator gap={8} />
      <div className={moduleStyles.topContainer}>
        <QueryFilter
          setPage={setPage}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <ApprovalMasterTable
        approvalMaster={data?.data || []}
        loading={isLoading || isFetching}
        page={page}
        onPageChange={(page) => setPage(page)}
        meta={data?.meta}
        filter={_filter}
        sortColumns={columns || []}
        limit={limit}
        onLimitChange={(limit) => setLimit(limit)}
      />
    </div>
  );
}
