import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import { AccountAccessModel, AccountAccessUpdateInput } from './model';

export function useUpdateAccountAccess(
  options?: UseMutationOptions<
    ApiResult<AccountAccessModel>,
    ApiError,
    AccountAccessUpdateInput
  >,
): UseMutationResult<
  ApiResult<AccountAccessModel>,
  ApiError,
  AccountAccessUpdateInput
> {
  return useMutation<
    ApiResult<AccountAccessModel>,
    ApiError,
    AccountAccessUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `account-accesses/${body.employeeId}`,
      method: 'PUT',
      classType: AccountAccessModel,
      body: body.body,
    });
  }, options);
}
