import {
  stockItemKey,
  useGetStockItem,
  useUpdateStockItem,
} from 'api-hooks/stock-item';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import StockItemForm from './components/form';

export default function StockItemView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } = useGetStockItem({
    stockItemId: props.navigation.params.id,
  });

  const { mutateAsync, error: mutateError } = useUpdateStockItem();

  const stockItem = data?.data;

  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <StockItemForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          stockItem={stockItem}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              stockId: stockItem?.id!,
            });
            form.reset();
            queryClient.refetchQueries([stockItemKey.list]);
            queryClient.refetchQueries(
              stockItemKey.detail({ stockItemId: stockItem?.id! }),
            );
            notification.success({ message: result?.message });
            return result.data;
          }}
        />
      }
    />
  );
}
