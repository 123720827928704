import classNames from 'classnames';
import { NavigationRoutes } from 'common/constants';
import colors from 'common/styles/colors';
import Text from 'components/elements/text';
import useNavigation from 'hooks/use-navigation';
import * as React from 'react';

import { styles } from './styles.css';
import { MappedRoute } from '../side-navigation';

interface Props {
  route: MappedRoute;
  depth: number;
  badges?: any;
  withLeftBorder?: boolean;
}

export default function LinkComponent(props: Props) {
  const { route, depth, badges, withLeftBorder } = props;
  const { navigate, activeKey } = useNavigation();

  const _onChange = React.useCallback(() => {
    !!route.itemId && navigate(route.itemId as NavigationRoutes);
  }, [navigate, route.itemId]);

  const style = React.useMemo(() => {
    if (activeKey === route.itemId) {
      return {
        backgrondColor: colors.productLightHover,
        color: colors.productNormal,
      };
    }
    return {
      backgrondColor: 'transparent',
      color: colors.textProduct,
    };
  }, [activeKey, route.itemId]);

  return (
    <div
      className={classNames(
        `${depth === 1 ? '' : 'before-border'} ${
          activeKey === route.itemId ? 'before-border-active' : ''
        }`,
        styles.beforeBorder,
      )}
      onClick={_onChange}
      style={{ cursor: 'pointer' }}
    >
      <div
        className={classNames(
          styles.linkContainer,
          activeKey === route.itemId && styles.activeLinkContainer,
          !route.leftIcon && activeKey !== route.itemId && styles.childLink,
          withLeftBorder && styles.leftBorder,
        )}
        style={{ paddingLeft: `${depth === 1 ? '12px' : '20px'}` }}
      >
        <div className={styles.itemsCenter}>
          <span
            className={classNames(
              route.itemId === activeKey
                ? styles.activeIcon
                : styles.inactiveIcon,
              styles.itemsCenter,
            )}
          >
            {route?.leftIcon?.(style.color, 24)}
          </span>
          <Text
            textVariant={
              activeKey === route.itemId || !!route.leftIcon
                ? 'BodyBoldDefault'
                : 'BodyDefault'
            }
            className={styles.itemsCenter}
            style={{
              ...(route?.leftIcon && { marginLeft: 12 }),
            }}
          >
            {route.title}
          </Text>
        </div>
        {badges && route.ariaLabel && !!badges[route.ariaLabel] && (
          <>
            {Object.keys(badges[route.ariaLabel]).map((item, idx) => {
              if (badges[route.ariaLabel!][item] > 0) {
                return (
                  <Badges key={item} backgroundColor={item}>
                    {badges[route.ariaLabel!][item] > 99
                      ? 99
                      : badges[route.ariaLabel!][item]}
                  </Badges>
                );
              }
            })}
          </>
        )}
      </div>
    </div>
  );
}

interface BadgesProps extends React.HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string;
}

export const Badges = React.forwardRef<HTMLDivElement, BadgesProps>(
  (props, ref) => {
    const { backgroundColor: _backgroundColor = 'red', ...rest } = props;

    const backgroundColor = React.useMemo(() => {
      switch (_backgroundColor) {
        case 'green':
          return colors.textSuccess;
        case 'yellow':
          return colors.textWarning;
        case 'red':
          return colors.textError;
        default:
          return _backgroundColor;
      }
    }, [_backgroundColor]);

    return (
      <div
        ref={ref}
        {...rest}
        className={styles.badges}
        style={{ backgroundColor }}
      />
    );
  },
);
