import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  ApprovalLiteModel,
  ApprovalModel,
  getApprovalInput,
  getApprovalsInput,
} from './model';

export function useGetApprovals(
  input?: getApprovalsInput,
  options?: UseQueryOptions<ExtendedApiResult<ApprovalLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<ApprovalLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getApprovals', input?.params],
      () =>
        QueryFetchFunction({
          url: 'approvals',
          params: input?.params,
        }),
      options,
    ),
    ApprovalLiteModel,
  );
}

export function useGetApproval(
  input?: getApprovalInput,
  options?: UseQueryOptions<ApiResult<ApprovalModel>, ApiError>,
): UseQueryResult<ApiResult<ApprovalModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getApproval', input?.id],
      () =>
        QueryFetchFunction({
          url: `approvals/${input?.id}`,
        }),
      options,
    ),
    ApprovalModel,
  );
}

export function getApprovalsKey(input?: getApprovalsInput) {
  return ['getApprovals', input?.params];
}

export function getApprovalKey(input?: getApprovalInput) {
  return ['getApproval', input?.id];
}
