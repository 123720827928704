import { getIndexInput } from 'api-hooks/common/model';
import { StockItemModel } from 'api-hooks/stock-item';
import { StockSimpleModel } from 'api-hooks/stock-transfer';
import { Expose, Type } from 'class-transformer';

// Model
export class StockUsageLiteModel {
  id: string;
  usage: number;

  @Expose({ name: 'stock_item_count' })
  stockItemCount: number;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

export class StockUsageItemModel {
  id: string;

  @Type(() => StockSimpleModel)
  stock: StockSimpleModel;

  @Expose({ name: 'stock_item' })
  @Type(() => StockItemModel)
  stockItem: StockItemModel;

  qty: number | null;
}
export class StockUsageModel {
  id: string;

  @Expose({ name: 'stock_usage_items' })
  @Type(() => StockUsageItemModel)
  stockUsageItems: StockUsageItemModel[];
}

// Input
export type getStockUsageInput = {
  stockUsageId: string;
};

export type getStockUsagesInput = getIndexInput;

export type StockUsageMutationInput = {
  entityId: string;
  stockUsageItems: {
    stockId: string;
    qty?: number | null;
    stockItemId?: string;
  }[];
};

export type StockUsageUpdateInput = {
  stockUsageId: string;
  body: StockUsageMutationInput;
};

export type StockUsageDeleteInput = {
  stockUsageId: string;
};
