import { useGetEnums } from 'api-hooks/common';
import { Input } from 'components/elements/field';
import { SelectFieldProps } from 'components/elements/field/select';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface Props
  extends Omit<SelectFieldProps, 'data' | 'label' | 'placeholder' | 'type'> {}

export default function ItemTypeSelectInput(props: Props) {
  const { t } = useTranslation();
  const { data } = useGetEnums({
    class: 'PRItemType',
  });

  return (
    <Input
      {...props}
      type="select"
      data={data?.data || []}
      label={t('pr:item_type')}
      placeholder={t('common:choose_extra', { extra: t('pr:item_type') })}
    />
  );
}
