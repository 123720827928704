import {
  BrandLiteModel,
  BrandModel,
  getBrandKey,
  getBrandsKey,
  useGetBrand,
  useGetBrands,
} from 'api-hooks/brand';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(item: BrandLiteModel | BrandModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  noMargin?: boolean;
  entityId?: string;
  model?: 'expenses' | 'incomes' | 'request-forms';
  disabled?: boolean;
}

export default function BrandSelectInput(props: Props) {
  const { t } = useTranslation();
  const {
    label = t('common:brand'),
    placeholder = t('common:choose_extra', {
      extra: t('common:brand'),
    }),
  } = props;
  const { navigate } = useNavigation();
  const { can } = useAuthorization();
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetBrands,
    useDetailLazyQueryHook: useGetBrand,
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            entity_id: props.entityId,
            ...(!!props.model && { model: props.model }),
          },
        },
      }),
      [props.entityId, props.model],
    ),
    renderCreate: !!can(AuthorizationRules.BrandsCreate),
    getListsKey: React.useCallback(
      (page, q) =>
        getBrandsKey({
          params: {
            q,
            page,
            filter: {
              entity_id: props.entityId,
              ...(!!props.model && { model: props.model }),
            },
          },
        }),
      [props.entityId, props.model],
    ),
    getDetailKey: React.useCallback(
      (value) =>
        getBrandKey({
          id: value,
        }),
      [],
    ),
    onClickCreate: () => {
      navigate(NavigationRoutes.BrandCreate);
      props.onClose && props.onClose();
    },
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      name={props.name}
      required={props.required}
      label={label}
      placeholder={placeholder}
      onAfterChange={props.onAfterChange}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
