import { Modal } from '@mantine/core';
import { PaginationMeta } from 'api-hooks/common/model';
import {
  ReconciliationLiteModel,
  useDeleteReconciliation,
  getReconciliationsKey,
} from 'api-hooks/reconciliation';
import { TrashIcon } from 'common/assets';
import { AuthorizationRules } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import { ActionIcon, Button } from 'components/elements/button';
import Pagination from 'components/elements/pagination';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface Props {
  reconciliations: ReconciliationLiteModel[];
  loading?: boolean;
  page: number;
  onPageChange: (page: number) => void;
  meta?: PaginationMeta;
  sortColumns: IColumn<any>[];
  filter?: string;
  limit: number;
  onLimitChange: (limit: number) => void;
}
const ReconciliationTable = (props: Props) => {
  const { reconciliations, sortColumns, loading, page, onPageChange, meta } =
    props;

  const { mutateAsync } = useDeleteReconciliation();
  const { t } = useTranslation();
  const drawer = useDialog();
  const { can } = useAuthorization();

  const handleDelete = React.useCallback(
    async (id, dismiss) => {
      try {
        dismiss();
        const result = await mutateAsync({
          accountMutationId: id,
        });
        queryClient.refetchQueries([getReconciliationsKey()[0]]);
        notification.success({ message: result?.message });
      } catch (error) {
        notification.error({ message: error.message });
      }
    },
    [mutateAsync],
  );

  const onItemInvoked = React.useCallback(
    (id: string) => {
      drawer.showCustom({
        render: (dismiss) => (
          <Modal
            opened
            onClose={dismiss}
            title={
              <Text textVariant="HeadingLarge">{t('modal:recon_title')}</Text>
            }
          >
            <Separator direction="vertical" gap={32} />
            <Text textVariant="BodyDefault" align="center">
              {t('modal:recon_description')}
            </Text>

            <Separator direction="vertical" gap={32} />
            <Button
              onClick={() => handleDelete(id, dismiss)}
              className={moduleStyles.buttonDialog}
              error
            >
              {t('common:delete')}
            </Button>
            <Separator direction="vertical" gap={16} />

            <Button
              onClick={dismiss}
              variant="tertiary"
              className={moduleStyles.buttonDialog}
            >
              <Text color={colors.textLight} textVariant="ButtonDefault">
                {t('common:cancel')}
              </Text>
            </Button>
          </Modal>
        ),
      });
    },
    [drawer, handleDelete, t],
  );

  const columns = React.useMemo<IColumn<any>[]>(
    () => [
      ...sortColumns,
      ...(can(AuthorizationRules.ReconciliationsDelete)
        ? [
            {
              header: '',
              accessorKey: 'action',
              minSize: 80,
              maxSize: 80,
              stickyLeft: true,
              cell: ({ row }) => {
                const { original } = row;
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ActionIcon
                      variant="transparent"
                      onClick={() => onItemInvoked(original.id)}
                    >
                      {(size) => <TrashIcon size={size} />}
                    </ActionIcon>
                  </div>
                );
              },
            },
          ]
        : []),
    ],
    [can, onItemInvoked, sortColumns],
  );

  return (
    <>
      <div className={moduleStyles.tableContainer}>
        <TableComponent
          columns={columns}
          isLoading={loading}
          data={reconciliations || []}
        />
      </div>
      <Pagination
        {...{
          page,
          onPageChange,
          meta,
          limit: props.limit,
          onLimitChange: props.onLimitChange,
        }}
      />
    </>
  );
};

export default ReconciliationTable;
