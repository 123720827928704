import {
  ForwardedDivisionApprovalModel,
  useCancelApproval,
} from 'api-hooks/approval';
import notification from 'common/helpers/notification';
import useDialog from 'hooks/use-dialog';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import ActionModal from './action-modal';
import ApprovalModal from './approval-modal';
import { onAfterAction } from './utils';

export default function useApprovalActions(id: string) {
  const { t } = useTranslation();

  const dialog = useDialog();

  const { mutateAsync: cancelMutate } = useCancelApproval();

  const [errMessage, setErrorMessage] = React.useState<string | null>('');

  const onCancel = React.useCallback(async () => {
    try {
      await cancelMutate({ id });
      notification.success({ message: t('common:successfully_cancel') });
      onAfterAction(id);
    } catch (e: any) {
      notification.error({ message: e?.message });
      setErrorMessage(e?.message as string);
    }
  }, [cancelMutate, id, t]);

  const onReject = React.useCallback(
    () =>
      dialog.showCustom({
        render: (close) => (
          <ActionModal
            id={id}
            onClose={close}
            variant="reject"
            onCatch={(val) => {
              setErrorMessage(val);
              close();
            }}
          />
        ),
      }),
    [dialog, id],
  );

  const onRequestRevision = React.useCallback(
    () =>
      dialog.showCustom({
        render: (close) => (
          <ActionModal
            id={id}
            onClose={close}
            variant="revision"
            onCatch={(val) => {
              setErrorMessage(val);
              close();
            }}
          />
        ),
      }),
    [dialog, id],
  );

  const onApprove = React.useCallback(
    (forwardedDivision: ForwardedDivisionApprovalModel) => {
      dialog.showCustom({
        render: (close) => (
          <ApprovalModal
            {...{
              onClose: close,
              id,
              details: forwardedDivision,
              refetch: () => onAfterAction(id),
              onCatch: (val) => {
                setErrorMessage(val);
                close();
              },
            }}
          />
        ),
      });
    },
    [dialog, id],
  );

  return { onApprove, onRequestRevision, onReject, onCancel, errMessage };
}
