import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  ChartOfAccountCategoriesLiteModel,
  ChartOfAccountCategoriesModel,
  getChartOfAccountCategoriesInput,
  getChartOfAccountCategoryInput,
} from './model';

export function useGetChartOfAccountCategories(
  input?: getChartOfAccountCategoriesInput,
  options?: UseQueryOptions<
    ExtendedApiResult<ChartOfAccountCategoriesLiteModel[]>,
    ApiError
  >,
): UseQueryResult<
  ExtendedApiResult<ChartOfAccountCategoriesLiteModel[]>,
  ApiError
> {
  return QueryTransformer(
    useQuery(
      ['getChartOfAccountCategories', input?.params],
      () =>
        QueryFetchFunction({
          url: 'chart-of-account-categories',
          params: input?.params,
        }),
      options,
    ),
    ChartOfAccountCategoriesLiteModel,
  );
}

export function useGetChartOfAccountCategory(
  input?: getChartOfAccountCategoryInput,
  options?: UseQueryOptions<ApiResult<ChartOfAccountCategoriesModel>, ApiError>,
): UseQueryResult<ApiResult<ChartOfAccountCategoriesModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getChartOfAccountCategory', input?.id],
      () =>
        QueryFetchFunction({
          url: `chart-of-account-categories/${input?.id}`,
        }),
      options,
    ),
    ChartOfAccountCategoriesModel,
  );
}

export function getChartOfAccountCategoriesKey(
  input?: getChartOfAccountCategoriesInput,
) {
  return ['getChartOfAccountCategories', input?.params];
}

export function getChartOfAccountCategoryKey(
  input?: getChartOfAccountCategoryInput,
) {
  return ['getChartOfAccountCategory', input?.id];
}
