import { useTransferMassUpdate } from 'api-hooks/mass-update';
import { useGetTransfers } from 'api-hooks/transfer';
import UpdateRateComponent from 'modules/transactions/components/update-rate-component';
import React from 'react';

import { useTransferUpdateRateColumns } from './column';

interface Props {
  setIsUpdateRate: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TransferUpdateRate({ setIsUpdateRate }: Props) {
  const columns = useTransferUpdateRateColumns();

  return (
    <UpdateRateComponent
      columns={columns}
      setIsUpdateRate={setIsUpdateRate}
      useGetData={useGetTransfers}
      useMassUpdate={useTransferMassUpdate}
      type="transfer"
    />
  );
}
