import {
  EmployeeLiteModel,
  EmployeeModel,
  getEmployeesKey,
  useGetEmployees,
} from 'api-hooks/employee';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import useMultiSelectInputHelper from 'hooks/use-multi-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(item: EmployeeModel | EmployeeLiteModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}
interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  onListChange?: (value?: any) => void;
  isAllAccount?: boolean;
  disabled?: boolean;
  noMargin?: boolean;
}

export function EmployeeMultiSelectInput(props: Props) {
  const { t } = useTranslation();
  const {
    label = t('common:employee'),
    placeholder = t('common:choose_extra', { extra: t('common:employee') }),
  } = props;

  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });

  const selectProps = useMultiSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value || []);
    },
    useListQueryHook: useGetEmployees,
    getListsKey: React.useCallback(
      (page, q) =>
        getEmployeesKey({
          params: {
            q,
            page,
          },
        }),
      [],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
        },
      }),
      [],
    ),
    renderCreate: false,
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select-multi"
      name={props.name}
      required={props.required}
      label={label}
      placeholder={placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
