import { getIndexInput } from 'api-hooks/common/model';

// Enum

export enum StockItemCodeTypeEnum {
  group = 'group',
  item = 'item',
  component = 'component',
  stated = 'stated',
}

// Model
export class StockItemCodeLiteModel {
  type: string;
  name: string;
  code: string;
  id: string;
}

export class StockItemCodeModel extends StockItemCodeLiteModel {}

// Input
export type getStockItemCodeInput = {
  stockItemCodeId: string;
};

export type getStockItemCodesInput = getIndexInput;

export type StockItemCodeMutationInput = {
  entityId: string;
  name: string;
  type: string;
  code: string;
};

export type StockItemCodeUpdateInput = {
  stockItemCodeId: string;
  body: StockItemCodeMutationInput;
};

export type StockItemCodeDeleteInput = {
  stockItemCodeId: string;
};
