import { BudgetingLiteModel } from 'api-hooks/budgeting';
import { LabelIcon, RoleIcon } from 'common/assets';
import colors from 'common/styles/colors';
import Badge from 'components/elements/badge';
import Text from 'components/elements/text';
import { format } from 'date-fns';
import React from 'react';

import { statsStyles } from './styles.css';
import Progress from '../progress';

interface Props extends BudgetingLiteModel {
  viewOnly?: boolean;
  onClick?: (id: string) => void;
}

export default function Stats(props: Props) {
  const {
    id,
    name,
    brands,
    division,
    activeBudgetingItem,
    employeeToNotifies,
    onClick,
    viewOnly,
  } = props;

  const isDisabled = !activeBudgetingItem;
  return (
    <div
      className={statsStyles.container}
      onClick={() => onClick?.(id)}
      style={{
        cursor: onClick ? 'pointer' : 'default',
      }}
    >
      <div className={statsStyles.header}>
        <Text textVariant="HeadingExtraSmall">{name}</Text>

        <Text textVariant="BodyDefault" color={colors.textLight}>
          {isDisabled ? (
            ''
          ) : (
            <>
              {format(activeBudgetingItem.startFrom, 'dd/MM/yyyy')} -{' '}
              {format(activeBudgetingItem.endAt, 'dd/MM/yyyy')}
            </>
          )}
        </Text>
      </div>
      <div className={statsStyles.detail}>
        <div className={statsStyles.division}>
          <RoleIcon size={24} color={colors.textLight} />
          <Text textVariant="BodyBoldDefault">{division.name}</Text>
        </div>
        <div className={statsStyles.brands}>
          <LabelIcon size={24} color={colors.textLight} />
          {brands.slice(0, 3).map((brand) => (
            <Badge
              {...{
                key: brand.id,
                children: brand.name,
                color: brand.color,
                size: 'md',
              }}
            />
          ))}
          {brands.length > 3 && (
            <Badge color="gray">{brands.length - 3}+</Badge>
          )}
        </div>
      </div>
      <Progress
        variant="secondary"
        disabled={isDisabled}
        budgetingId={id}
        {...activeBudgetingItem!}
        viewOnly={viewOnly}
        employeeToNotifies={employeeToNotifies}
      />
    </div>
  );
}
