import classNames from 'classnames';
import { PlusIcon, TrashIcon } from 'common/assets';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import { FormState } from 'components/elements/form';
import { OptionProps } from 'components/elements/select';
import EmployeeSelectInput from 'modules/master/employee/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import ApprovalLevelForm from './approval-level-form';
import CCForm from './cc-form';
import { AmountTypeEnum, ApprovalMasterFormType } from './form-type';
import { approvalMasterStyles } from './styles.css';

export default function ApprovalFinanceForm() {
  const { control, setValue } = useFormContext<any>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray<ApprovalMasterFormType>({
    control,
    name: 'finances',
    keyName: 'id',
  });

  const onRemove = (customId: string) => {
    const temp = fields;
    remove();
    const filtered = temp.filter((item) => item.id !== customId);
    setValue('finances', filtered);
  };

  const onAdd = () =>
    append({
      paymentEmployeeId: '',
      lowerBound: null,
      upperBound: null,
      details: [],
      carbonCopies: [],
      amountType: AmountTypeEnum.between,
    });

  const amountTypeSelectOptions = React.useMemo<OptionProps[]>(
    () => [
      {
        value: AmountTypeEnum.less_than,
        label: t('approval:less_than'),
      },
      {
        value: AmountTypeEnum.more_than,
        label: t('approval:more_than'),
      },
      {
        value: AmountTypeEnum.between,
        label: t('approval:between'),
      },
    ],
    [t],
  );

  return (
    <>
      <Separator gap={32} direction="vertical" />
      {fields.map((item, index) => (
        <>
          <div
            key={`finances.${item.id}`}
            className={approvalMasterStyles.itemContainer}
          >
            <div className={moduleStyles.fullContainer}>
              <FormState>
                {({ editable }) =>
                  editable && (
                    <div className={approvalMasterStyles.headerButton}>
                      <Button
                        variant="tertiary"
                        error
                        type="button"
                        onClick={() => onRemove(item?.id)}
                      >
                        <TrashIcon size={24} />
                      </Button>
                    </div>
                  )
                }
              </FormState>

              <div className={moduleStyles.halfContainer}>
                <EmployeeSelectInput
                  name={`finances.${index}.paymentEmployeeId`}
                  label={t('approval:payment_staff')}
                />
              </div>
            </div>

            <div
              className={classNames(
                moduleStyles.sectionContainer,
                moduleStyles.row({ align: 'end' }),
              )}
            >
              <div className={moduleStyles.halfContainer}>
                <Input
                  type="select"
                  data={amountTypeSelectOptions}
                  name={`finances.${index}.amountType`}
                  label={t('common:amount')}
                  placeholder={t('common:choose_extra', {
                    extra: t('common:amount'),
                  })}
                  noMargin
                />
              </div>
              <div
                className={classNames(
                  moduleStyles.row({ gap: 16 }),
                  moduleStyles.halfContainer,
                )}
              >
                <HOCInput keys={[`finances.${index}.amountType`]}>
                  {(value) => {
                    const amountType = value[
                      `finances.${index}.amountType`
                    ] as unknown as AmountTypeEnum;
                    return (
                      <>
                        {amountType !== AmountTypeEnum.less_than && (
                          <Input
                            type="number"
                            hideControls
                            name={`finances.${index}.lowerBound`}
                            placeholder={t('common:enter_extra', {
                              extra: t('common:amount'),
                            })}
                            noMargin
                            isMoneyFormat
                            style={{ flex: 1 }}
                          />
                        )}

                        {amountType !== AmountTypeEnum.more_than && (
                          <Input
                            type="number"
                            hideControls
                            name={`finances.${index}.upperBound`}
                            placeholder={t('common:enter_extra', {
                              extra: t('common:amount'),
                            })}
                            noMargin
                            isMoneyFormat
                            style={{ flex: 1 }}
                          />
                        )}
                      </>
                    );
                  }}
                </HOCInput>
              </div>
            </div>
            <Separator gap={16} direction="vertical" />
            <div className={moduleStyles.sectionContainer}>
              <div className={moduleStyles.halfContainer}>
                <ApprovalLevelForm idx={index} name="finances" />
              </div>
              <div className={moduleStyles.halfContainer}>
                <CCForm idx={index} />
              </div>
            </div>
          </div>
          {index !== fields.length - 1 && (
            <Separator direction="vertical" gap={48} />
          )}
        </>
      ))}

      {!!fields.length && <Separator gap={48} direction="vertical" />}
      <FormState>
        {({ editable }) =>
          editable && (
            <div>
              <Button
                onClick={() => onAdd()}
                type="button"
                variant="secondary"
                leftIcon={(size) => <PlusIcon size={size} />}
                disabled={!editable}
              >
                {`${t('common:add')} ${t('common:division')}`}
              </Button>
            </div>
          )
        }
      </FormState>
    </>
  );
}
