import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import {
  MutationFetchFunction,
  QueryFetchFunction,
  QueryTransformer,
} from 'common/helpers/common';

import {
  getStockBarcodeInput,
  getStockInput,
  getStocksInput,
  StockBarcodeModel,
  StockLiteModel,
  StockModel,
} from './model';

export const stockKey = {
  listKey: 'getStocks',
  detailKey: 'getStock',
  list(input?: getStocksInput) {
    return [stockKey.listKey, input?.params];
  },
  detail(input?: getStockInput) {
    return [stockKey.detailKey, input?.stockId];
  },
};

export function useGetStocks(
  input?: getStocksInput,
  options?: UseQueryOptions<ExtendedApiResult<StockLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<StockLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockKey.list(input),
      () =>
        QueryFetchFunction({
          url: 'stocks',
          params: input?.params,
        }),
      options,
    ),
    StockLiteModel,
  );
}

export function useGetStock(
  input?: getStockInput,
  options?: UseQueryOptions<ApiResult<StockModel>, ApiError>,
): UseQueryResult<ApiResult<StockModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `stocks/${input?.stockId}`,
        }),
      options,
    ),
    StockModel,
  );
}

export function useGetStockBarcode(
  options?: UseMutationOptions<
    ApiResult<StockBarcodeModel>,
    ApiError,
    getStockBarcodeInput
  >,
): UseMutationResult<
  ApiResult<StockBarcodeModel>,
  ApiError,
  getStockBarcodeInput
> {
  return useMutation<
    ApiResult<StockBarcodeModel>,
    ApiError,
    getStockBarcodeInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `stocks/${body?.stockId}/preview-barcodes`,
      method: 'POST',
      classType: StockBarcodeModel,
      body: body?.body,
    });
  }, options);
}
