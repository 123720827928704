import classNames from 'classnames';
import Separator from 'components/common/separator';
import React from 'react';

import PopoverButton from './popover-button';
import { popoverButtonStyles } from './styles.css';
import Button from '../default';

type DefaultItem = {
  hide?: boolean;
  hideOnSmallScreen?: boolean;
  icon?: (size: number) => React.ReactNode;
  action?: () => void;
  buttonVariant?: 'primary' | 'secondary' | 'tertiary';
  label?: string;
};
type Item =
  | DefaultItem
  | { content?: React.ReactNode; hide?: boolean; hideOnSmallScreen?: boolean };

export interface ResponsivePopoverButtonProps {
  items: Item[];
  parent?: Omit<Item, 'hideOnSmallScreen' | 'hide' | 'action'>;
}

export default function ResponsivePopoverButton(
  props: ResponsivePopoverButtonProps,
) {
  const { items: _items, parent } = props;
  const items = _items.filter((item) => !item.hide);

  const itemsContent = React.useMemo<React.ReactNode[]>(
    () =>
      items.map((item) => {
        if (!Object.keys(item).includes('content')) {
          const _item = item as DefaultItem;
          const content = (
            <>
              <Button
                leftIcon={_item.icon}
                onClick={_item.action}
                variant={_item.buttonVariant}
              >
                {_item.label}
              </Button>
              <div className={popoverButtonStyles.smallScreen}>
                <Separator gap={4} direction="vertical" />
              </div>
            </>
          );
          return _item.hideOnSmallScreen ? (
            <div className={popoverButtonStyles.largeScreen}>{content}</div>
          ) : (
            content
          );
        }

        return (item as any).content;
      }),
    [items],
  );

  const itemsContentCopy = [...itemsContent];

  if (items.filter(({ hideOnSmallScreen }) => !hideOnSmallScreen).length < 2) {
    return <>{itemsContent}</>;
  }
  return (
    <>
      <div className={classNames(popoverButtonStyles.largeScreenContainer)}>
        {itemsContent}
      </div>
      {!!items.filter(({ hideOnSmallScreen }) => !hideOnSmallScreen).length && (
        <div className={popoverButtonStyles.smallScreen}>
          <PopoverButton
            component={itemsContentCopy.reverse()}
            width={200}
            {...parent}
          />
        </div>
      )}
    </>
  );
}
