import { EntityCurrencyModel } from 'api-hooks/entity-currency/model';
import {
  PurchaseRequisitionsLiteModel,
  PurchaseRequisitionsModel,
  getPurchaseRequisitionKey,
  getPurchaseRequisitionsKey,
  useGetPurchaseRequisition,
  useGetPurchaseRequisitionsExpense,
} from 'api-hooks/purchase-requisitions';
import { QueryTransformer } from 'common/helpers/common';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(
  item: PurchaseRequisitionsLiteModel | PurchaseRequisitionsModel,
): OptionProps {
  return {
    value: item.id,
    label: item.title,
  };
}

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  onAfterChange?: (value?: any) => void;
  overrideOnChange?: (prevValue: any, value: any) => void;
  noMargin?: boolean;
  divisionId?: string;
  brandsId?: string[];
}

const PurchaseRequisitionsOutcomeSelectInput = (props: Props) => {
  const { t } = useTranslation();
  const {
    placeholder = `${t('common:choose_extra', {
      extra: t('common:purchase_requisitions'),
    })}`,
    label = t('common:purchase_requisitions'),
    divisionId,
    brandsId,
  } = props;
  const { entity } = useEntity();

  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetPurchaseRequisitionsExpense,
    useDetailLazyQueryHook: useGetPurchaseRequisition,

    renderCreate: false,
    renderNoData: true,
    enabled: !!divisionId && !!brandsId?.length,
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            division_id: divisionId,
            brand_ids: brandsId,
            entity_id: entity?.id!,
          },
        },
      }),
      [brandsId, divisionId, entity?.id],
    ),
    getListsKey: React.useCallback(
      (page, q) =>
        getPurchaseRequisitionsKey({
          params: {
            q,
            page,
            filter: {
              division_id: divisionId,
              brand_ids: brandsId,
              entity_id: entity?.id!,
            },
          },
        }),
      [brandsId, divisionId, entity?.id],
    ),
    getDetailKey: React.useCallback(
      (value) =>
        getPurchaseRequisitionKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      const newResult = QueryTransformer(result, EntityCurrencyModel);
      props.onAfterChange?.(newResult.data);
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      label={label}
      required={props.required}
      name={props.name}
      placeholder={placeholder}
      onAfterChange={props.onAfterChange}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
};

export default PurchaseRequisitionsOutcomeSelectInput;
