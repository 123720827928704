import { AccountLiteModel } from 'api-hooks/account/model';
import { BrandLiteModel } from 'api-hooks/brand';
import { FileResultModel } from 'api-hooks/common';
import {
  CommonSimpleModel,
  Filter,
  PaginationMeta,
  Sort,
  getIndexInput,
} from 'api-hooks/common/model';
import { DivisionModel } from 'api-hooks/division';
import { EntityCurrencyModel } from 'api-hooks/entity-currency/model';
import { ChartOfAccount, Files } from 'api-hooks/income';
import { PurchaseRequisitionsModel } from 'api-hooks/purchase-requisitions';
import { WarehouseModelWithEntity } from 'api-hooks/stock-adjustment';
import { StockGroupingModel } from 'api-hooks/stock-grouping';
import { StockSimpleModel } from 'api-hooks/stock-transfer';
import { SupplierModel } from 'api-hooks/supplier';
import { Expose, Type } from 'class-transformer';

export enum NotifyEnum {
  amount = 'amount',
  description = 'description',
  attachments = 'attachments',
  account = 'account',
}

// Model

export class OutcomeLiteModelData {
  id: string;
  code: string;
  description: string | null;
  division: DivisionModel;
  supplier: SupplierModel;

  @Type(() => AccountLiteModel)
  account: AccountLiteModel;

  @Type(() => Number)
  amount: number;

  @Expose({ name: 'entity_id' })
  entityId: string;

  @Type(() => BrandLiteModel)
  brands: BrandLiteModel[];

  @Type(() => Number)
  rate: number;

  @Expose({ name: 'is_base_currency' })
  isBaseCurrency: boolean;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'has_attachment' })
  hasAttachment: boolean;

  @Expose({ name: 'is_attachment_completed' })
  isAttachmentCompleted: boolean;

  @Expose({ name: 'created_by' })
  createdBy: CommonSimpleModel;

  @Expose({ name: 'updated_by' })
  updatedBy: CommonSimpleModel;
}
export class OutcomeLiteModel {
  message?: string;

  @Type(() => OutcomeLiteModelData)
  data: OutcomeLiteModelData[];

  @Type(() => PaginationMeta)
  meta: PaginationMeta;

  @Type(() => Filter)
  filters: Filter[];

  @Type(() => Sort)
  sorts: Sort;
}

export class StockItemModel {
  id: string;
  name: string;
  merk: string;
  barcode: string;
  description: string;

  @Expose({ name: 'is_used' })
  isUsed: boolean;

  @Type(() => WarehouseModelWithEntity)
  warehouse: WarehouseModelWithEntity;

  @Type(() => FileResultModel)
  files: FileResultModel[];

  @Expose({ name: 'stock_grouping' })
  @Type(() => StockGroupingModel)
  stockGrouping: StockGroupingModel | null;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;

  @Expose({ name: 'received_at' })
  @Type(() => Date)
  receivedAt: Date;
}
export class StockPurchaseItemDetail {
  id: string;

  @Expose({ name: 'stock_item' })
  @Type(() => StockItemModel)
  stockItem: StockItemModel;
}
export class StockPurchaseItemModel {
  id: string;
  qty: number;

  @Type(() => Number)
  price: number;

  @Type(() => StockSimpleModel)
  stock: StockSimpleModel;

  @Type(() => WarehouseModelWithEntity)
  warehouse: WarehouseModelWithEntity;

  @Expose({ name: 'stock_purchase_item_details' })
  @Type(() => StockPurchaseItemDetail)
  stockPurchaseItemDetails: StockPurchaseItemDetail[];
}
export class OutcomeModel extends OutcomeLiteModelData {
  @Expose({ name: 'is_include_mutation' })
  isIncludeMutation: boolean;

  @Expose({ name: 'is_transaction_closed' })
  isTransactionClosed: boolean;

  @Expose({ name: 'has_attachment' })
  hasAttachment: boolean;

  @Expose({ name: 'is_attachment_completed' })
  isAttachmentCompleted: boolean;

  @Expose({ name: 'files' })
  @Type(() => Files)
  files: Files[];

  @Expose({ name: 'chart_of_account' })
  @Type(() => ChartOfAccount)
  chartOfAccount: ChartOfAccount;

  @Expose({ name: 'request_form' })
  @Type(() => PurchaseRequisitionsModel)
  requestForm: PurchaseRequisitionsModel;

  @Expose({ name: 'entity_currency' })
  @Type(() => EntityCurrencyModel)
  entityCurrency: EntityCurrencyModel;

  @Expose({ name: 'transaction_rate' })
  @Type(() => Number)
  transactionRate: number;

  @Expose({ name: 'stock_purchase_items' })
  @Type(() => StockPurchaseItemModel)
  stockPurchaseItems: StockPurchaseItemModel[];
}

// Input

export type getOutcomesInput = getIndexInput;

export type getOutcomeInput = {
  id: string;
};

export type OutcomeMutationInput = {
  amount: number;
  accountId: string;
  description: string | null;
  entityId?: string | null;
  isBaseCurrency: boolean;
  brands: string[];
  rate: number;
  transactionAt: Date;
  isIncludeMutation: boolean;
  isAttachmentCompleted: boolean;
  chartOfAccountId: boolean;
  files: string[] | undefined;
  requestFormId: string;
  supplierId: string;
  divisionId: string;
  entityCurrencyId: string;
  transactionRate: number;
  isStockPurchase: boolean;
  notifyType: string | null;
  notifies?: string[] | null;
  // required only when is stock purchase is true
  stockPurchaseItems?: {
    stockId: string;
    warehouseId: string;
    qty: number | null;
    price: number | null;
    items?: { merk: string; description?: string; files?: string[] }[];
  }[];
};

export type OutcomeUpdateInput = {
  outcomeId: string;
  body: OutcomeMutationInput;
};

export type OutcomeDeleteInput = {
  outcomeId: string;
};

export type OutcomeNotifyMutationInput = {
  notifyType: string;
  notifies: string[];
  notifyOptions: string[];
};

export type OutcomeNotifyInput = {
  outcomeId: string;
  body: OutcomeNotifyMutationInput;
};

export type OutcomeImportInput = {
  entityId: string;
  file: any;
};
