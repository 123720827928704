import {
  AccountMutationLiteModel,
  useGetAccountMutations,
} from 'api-hooks/account-mutation';
import { QueryTransformer } from 'common/helpers/common';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import TableComponent, { IColumn } from 'components/elements/table';
import TextInput from 'components/elements/text-input';
import { format } from 'date-fns';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import Balance from 'modules/entity-settings/common/components/balance';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

const ReconciliationAccountListTable = (props: { disabled: boolean }) => {
  const { control, setValue, getValues } = useFormContext();
  const accountId = useWatch({ control, name: 'accountId' });
  const [page, setPage] = React.useState<number>(1);
  const { t } = useTranslation();
  const { entity } = useEntity();
  const [state, setState] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [accountList, setAccountList] = React.useState<
    AccountMutationLiteModel[]
  >([]);

  const _columns = React.useMemo<IColumn<any>[]>(
    () => [
      {
        header: t('common:transaction_at'),
        accessorKey: 'transactionAt',
        minSize: 200,
        sortName: 'transaction_at',
        cell: ({ getValue }) => (
          <>{format(getValue() as Date, 'dd MMM yyyy, HH:mm')}</>
        ),
      },
      {
        header: t('transfer:amount'),
        accessorKey: 'amount',
        minSize: 200,
        sortName: 'amount',
        cell: ({ row }) => {
          const {
            original: { amount, account },
          } = row;

          return <Balance currency={account.currency} balance={amount} />;
        },
      },
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 200,
        sortName: 'description',
      },
    ],
    [t],
  );

  const { data, isLoading } = useComposedQuery(
    useGetAccountMutations,
    {
      params: {
        page,
        limit: 5,
        filter: {
          entity_id: entity?.id!,
        },
        account_id: accountId,
      },
    },
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
    {
      input: {
        params: {
          page,
          limit: 5,
          filter: {
            entity_id: entity?.id!,
            description,
          },
          account_id: accountId,
        },
      },
      onSuccess(data) {
        const temp = data.data.map((item) => {
          return { id: item.id, value: false };
        });
        const result = QueryTransformer(data, AccountMutationLiteModel);
        if (accountList[0]?.account.id !== accountId) {
          setAccountList(
            Object.keys(result?.data).map((key) => result?.data[key]),
          );
          setValue('accounts', temp);
        } else {
          const _temp = [
            ...accountList,
            ...Object.keys(result?.data).map((key) => result?.data[key]),
          ];
          const uniqueList = new Map(
            _temp.map((item) => [item.id, item]),
          ).values();
          const accounts = [...temp, ...getValues('accounts')];
          const uniqueAccounts = new Map(
            accounts.map((item) => [item.id, item]),
          ).values();

          if (!state) {
            setAccountList((prev) => Array.from(uniqueList));
          } else {
            setAccountList([
              ...Object.keys(result?.data).map((key) => result?.data[key]),
            ]);
          }
          setValue('accounts', Array.from(uniqueAccounts));
        }
      },
      extra: {},
    },
  );

  const onChange = useDebouncedCallback(() => {
    setPage(1);
    setDescription(state);
  }, 800);

  React.useEffect(() => {
    setPage(1);
  }, [accountId]);

  return (
    <div>
      <TextInput
        value={state}
        onChange={(e) => {
          setState(e.target.value);
          onChange();
        }}
        label={t('common:account_mutation')}
        placeholder={t('common:find_description')}
      />

      <Separator direction="vertical" gap={6} />
      <TableComponent
        columns={[
          {
            header: '',
            accessorKey: 'action',
            minSize: 50,
            maxSize: 50,
            stickyLeft: true,
            cell: ({ row }) => {
              const { original, index } = row;
              return (
                <Input
                  type="checkbox"
                  key={original.id}
                  name={`accounts.${index}.value`}
                  disabled={
                    !getValues(`accounts.${index}.value`) && props.disabled
                  }
                />
              );
            },
          },
          ..._columns,
        ]}
        data={accountList || []}
        columnMaxHeight={250}
      />
      {data?.meta?.lastPage !== page && isLoading === false && (
        <Button
          variant="tertiary"
          onClick={() => setPage((prev) => prev + 1)}
          className={moduleStyles.fullContainer}
        >
          {t('common:show_more')}
        </Button>
      )}
    </div>
  );
};

export default ReconciliationAccountListTable;
