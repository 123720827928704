import { Modal } from '@mantine/core';
import {
  ForwardedDivisionApprovalModel,
  useApproveApproval,
} from 'api-hooks/approval';
import notification from 'common/helpers/notification';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import DivisionSelectInput from 'modules/master/division/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import { onAfterAction } from './utils';

interface Props {
  onClose: () => void;
  id: string;
  refetch: () => void;
  details: ForwardedDivisionApprovalModel;
  onCatch: (e: string) => void;
}

export default function ApprovalModal(props: Props) {
  const { onClose, id, refetch, details, onCatch } = props;
  const { t } = useTranslation();

  const { mutateAsync: approveMutate, isLoading } = useApproveApproval();

  const initialValues = React.useMemo(
    () => ({
      divisionId: details?.division.id || null,
      description: null,
    }),
    [details],
  );

  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const divisionId = details?.division?.id ? null : values.divisionId;
        await approveMutate({ id, ...values, divisionId });
        onClose();
        onAfterAction(id);
        refetch();
      } catch (e: any) {
        notification.error({ message: e?.message });
        onCatch(e?.message);
      }
    },
    [details?.division?.id, approveMutate, id, onClose, refetch, onCatch],
  );

  return (
    <Modal
      onClose={onClose}
      opened
      centered
      title={<Text textVariant="HeadingSmall">{t('common:approve')}</Text>}
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <div className={moduleStyles.fullContainer}>
          <DivisionSelectInput
            name="divisionId"
            label={t('common:forward_to_division')}
            required={false}
            disabled={!!details?.division.id}
            noMargin={!!details?.division?.name}
          />

          {details?.division?.name && (
            <>
              <Text
                textVariant="BodySmaller"
                style={{ color: colors.textLight }}
              >
                {`${t('modal:forwarded_by')}: ${details?.employee?.name}`}
              </Text>
              <Separator gap={8} direction="vertical" />
            </>
          )}
          <Input
            type="text-area"
            label={t('modal:approval_note')}
            placeholder={t('common:enter_extra', {
              extra: t('modal:approval_note'),
            })}
            name="description"
          />
        </div>
        <Separator gap={16} direction="vertical" />
        <Button type="submit" success loading={isLoading}>
          {t('common:approve')}
        </Button>
        <Separator gap={16} direction="vertical" />
        <Button type="button" variant="tertiary" onClick={onClose}>
          {t('common:cancel')}
        </Button>
      </Form>
    </Modal>
  );
}
