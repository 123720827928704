import * as Yup from 'yup';

export type WarehouseFormType = {
  name: string;
  regional: string;
  address: string;
};

export const formSchema = () =>
  Yup.object().shape({
    name: Yup.string().required().nullable(),
    regional: Yup.string().required().nullable(),
    address: Yup.string().required().nullable(),
  });
