import { StockItemLiteModel, useGetStockItems } from 'api-hooks/stock-item';
import notification from 'common/helpers/notification';
import { capitalize } from 'common/utils/string';
import ListHeader from 'components/common/list-header';
import QueryFilter, {
  useApplyQueryFilter,
} from 'components/common/query-filter/query-filter';
import Separator from 'components/common/separator';
import { IColumn } from 'components/elements/table';
import { format } from 'date-fns';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { StockItemTable } from './components/table';

export default function StockItemList() {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const _columns = React.useMemo<IColumn<StockItemLiteModel>[]>(
    () => [
      {
        header: t('common:created_at'),
        accessorKey: 'createdAt',
        minSize: 200,
        sortName: 'createdAt',
        cell: ({ getValue }) => format(getValue() as Date, 'dd MMM yyyy'),
      },
      {
        header: t('common:updated_at'),
        accessorKey: 'updatedAt',
        minSize: 200,
        sortName: 'updatedAt',
        cell: ({ getValue }) =>
          getValue() ? format(getValue() as Date, 'dd MMM yyyy') : '-',
      },
      {
        header: t('inventory:barcode'),
        accessorKey: 'barcode',
        minSize: 250,
      },
      {
        header: t('common:name'),
        accessorKey: 'name',
        minSize: 200,
      },
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 200,
        cell: ({ getValue }) => getValue() || '-',
      },
      {
        header: t('inventory:merk'),
        accessorKey: 'merk',
        minSize: 100,
      },
      {
        header: t('inventory:regional'),
        accessorKey: 'regional',
        minSize: 100,
      },

      {
        header: t('common:warehouse'),
        accessorKey: 'warehouse.name',
        minSize: 150,
      },
      {
        header: t('inventory:is_used'),
        accessorKey: 'isUsed',
        minSize: 150,
        cell: ({ getValue }) => capitalize((getValue() as boolean).toString()),
      },
    ],
    [t],
  );

  const {
    data,
    refetch,
    isLoading,
    isFetching,
    error,
    extras: [{ filters, setFilters }, { columns }],
  } = useComposedQuery(
    useGetStockItems,
    {
      params: {
        page,
        limit,
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
    useApplyQueryFilter((data: any) => {
      return data.filters;
    }),
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
  );

  React.useEffect(() => {
    if (!!error && !!error.message) {
      notification.error({ message: error?.message });
    }
  }, [error]);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:stock_items')}
        showAdd={false}
        onRefresh={refetch}
      />
      <Separator gap={8} />
      <div className={moduleStyles.topContainer}>
        <QueryFilter
          setPage={setPage}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <StockItemTable
        stockItems={data?.data || []}
        loading={isLoading || isFetching}
        page={page}
        onPageChange={(page) => setPage(page)}
        meta={data?.meta}
        sortColumns={columns || []}
        limit={limit}
        onLimitChange={(limit) => setLimit(limit)}
      />
    </div>
  );
}
