import * as Yup from 'yup';

export type StockItemCodeFormType = {
  name: string;
  type: string;
  code: string;
};

export const formSchema = () =>
  Yup.object().shape({
    name: Yup.string().required().nullable(),
    type: Yup.string().required().nullable(),
    code: Yup.string().required().nullable(),
  });
