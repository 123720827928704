import { Modal } from '@mantine/core';
import { ExportCategoryEnum, getExportsKey } from 'api-hooks/export';
import { useExport } from 'api-hooks/export/mutation';
import { ExportIcon } from 'common/assets';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import useDialog from 'hooks/use-dialog';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export type Params = {
  [key: string]: string | number | { [key: string]: string | number };
};
interface ExportHookProps {
  category: keyof typeof ExportCategoryEnum;
  params: Params;
  t: Translate;
}
interface ExportButtonProps {
  category: keyof typeof ExportCategoryEnum;
  params: Params;
}

function ExportNamingModal({ onClose, t, category, params }) {
  const { mutateAsync } = useExport();
  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().required().nullable(),
    }),
  );

  const methods = useForm({
    resolver,
    mode: 'onChange',
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const res = await mutateAsync({
          name: values.name,
          category,
          params,
        });
        notification.success({ message: res.message });
        queryClient.refetchQueries([getExportsKey()[0]]);
        onClose();
      } catch (e) {
        notification.error({ message: e.mesage });
      }
    },
    [category, mutateAsync, onClose, params],
  );

  return (
    <Modal size={500} onClose={onClose} opened withCloseButton={false}>
      <Text textVariant="HeadingMedium" align="center">
        {t('common:export_to_excel')}
      </Text>
      <Separator gap={32} direction="vertical" />
      <Form methods={methods} onSubmit={onSubmit}>
        <Input
          type="text"
          name="name"
          required
          description={t('common:file_will_be_exported')}
          placeholder={t('common:enter_extra', {
            extra: t('common:file_name'),
          })}
        />
        <Separator gap={16} direction="vertical" />
        <div className={moduleStyles.row()}>
          <Button type="submit" className={moduleStyles.flex1}>
            {t('common:export')}
          </Button>
          <Separator gap={16} direction="horizontal" />
          <Button
            error
            variant="secondary"
            className={moduleStyles.flex1}
            onClick={onClose}
          >
            {t('common:cancel')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export function useExportHook({ category, t, params }: ExportHookProps) {
  const dialog = useDialog();
  const onHandleExport = React.useCallback(() => {
    dialog.showCustom({
      render: (onClose) => (
        <ExportNamingModal
          {...{
            t,
            onClose,
            category: ExportCategoryEnum[category],
            params,
          }}
        />
      ),
    });
  }, [dialog, t, category, params]);

  return { onHandleExport };
}

export default function ExportButton(props: ExportButtonProps) {
  const { t } = useTranslation();
  const { onHandleExport } = useExportHook({ ...props, t });

  return (
    <Button
      onClick={onHandleExport}
      leftIcon={(size) => <ExportIcon size={size} />}
      variant="secondary"
    >
      {t('common:export')}
    </Button>
  );
}
