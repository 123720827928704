import { getApprovalsKey } from 'api-hooks/approval';
import { getNotificationsKey } from 'api-hooks/notification';
import {
  getPurchaseRequisitionKey,
  getPurchaseRequisitionsKey,
} from 'api-hooks/purchase-requisitions';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import { startCase } from 'lodash';

export default function refetchDetail(res: any, id: string) {
  queryClient.refetchQueries([getPurchaseRequisitionsKey()[0]]);
  queryClient.refetchQueries(getPurchaseRequisitionKey({ id }));
  notification.success({ message: res?.message });
  queryClient.refetchQueries([getApprovalsKey()[0]]);
  queryClient.refetchQueries([getNotificationsKey()[0]]);
}

export function snakeToTitle(text: string) {
  return startCase(text.replace('_', ' '));
}
