import { useGetIncomes } from 'api-hooks/income';
import { useIncomeMassUpdate } from 'api-hooks/mass-update';
import UpdateRateComponent from 'modules/transactions/components/update-rate-component';
import React from 'react';

import { useIncomeUpdateRateColumns } from './column';

interface Props {
  setIsUpdateRate: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function IncomeUpdateRate({ setIsUpdateRate }: Props) {
  const columns = useIncomeUpdateRateColumns();

  return (
    <UpdateRateComponent
      columns={columns}
      setIsUpdateRate={setIsUpdateRate}
      useGetData={useGetIncomes}
      useMassUpdate={useIncomeMassUpdate}
    />
  );
}
