import { Badge, Group } from '@mantine/core';
import { BrandLiteModel } from 'api-hooks/brand';
import { TransferLiteModel } from 'api-hooks/transfer';
import { string2moneyDefault } from 'common/utils/string';
import { Input } from 'components/elements/field';
import { IColumn } from 'components/elements/table';
import { format } from 'date-fns';
import { useEntity } from 'hooks/use-entities';
import { useTableViewDetailColumn } from 'modules/common/table';
import Balance from 'modules/entity-settings/common/components/balance';
import BalanceWithDetails from 'modules/entity-settings/common/components/balance-with-details';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

export function useTransferDefaultColumns() {
  const { t } = useTranslation();
  const { entity } = useEntity();

  const _columns = React.useMemo<IColumn<TransferLiteModel>[]>(
    () => [
      {
        header: t('common:transaction_code'),
        accessorKey: 'code',
        minSize: 200,
      },
      {
        header: t('common:transaction_at'),
        accessorKey: 'transactionAt',
        minSize: 200,
        sortName: 'transaction_at',
        cell: ({ getValue }) => {
          return (
            <>{format(new Date(getValue() as Date), 'dd MMM yyyy, HH:mm')}</>
          );
        },
      },
      {
        header: t('common:received_at'),
        accessorKey: 'receiveAt',
        minSize: 200,
        sortName: 'receive_at',
        cell: ({ getValue }) =>
          getValue()
            ? format(new Date(getValue() as Date), 'dd MMM yyyy, HH:mm')
            : '',
      },

      {
        header: t('common:created_by'),
        accessorKey: 'createdBy.name',
        minSize: 200,
      },
      {
        header: t('common:updated_by'),
        accessorKey: 'updatedBy.name',
        minSize: 200,
        cell: ({ getValue }) => getValue() || '-',
      },
      {
        header: t('transfer:from_account'),
        accessorKey: 'accountFrom',
        minSize: 200,
        sortName: 'account_from',
      },
      {
        header: t('transfer:to_account'),
        accessorKey: 'accountTo',
        minSize: 200,
        sortName: 'account_to',
      },
      {
        header: t('transfer:amount'),
        accessorKey: 'transferAmount',
        minSize: 200,
        textAlign: 'right',
        cell: ({
          row: {
            original: { transferAmount, accountFromRate, transferCurrency },
          },
        }) =>
          transferCurrency !== entity?.currency ? (
            <BalanceWithDetails
              currency={transferCurrency}
              balance={transferAmount}
              rate={accountFromRate}
              entityCurrency={entity?.currency!}
            />
          ) : (
            <Balance currency={transferCurrency} balance={transferAmount} />
          ),
      },
      {
        header: t('transfer:received_amount'),
        accessorKey: 'receiveAmount',
        minSize: 200,
        sortName: 'receive_amount',
        cell: ({
          row: {
            original: { receiveCurrency, receiveAmount, accountToRate },
          },
        }) =>
          receiveCurrency !== entity?.currency ? (
            <BalanceWithDetails
              currency={receiveCurrency}
              balance={receiveAmount}
              rate={accountToRate}
              entityCurrency={entity?.currency!}
            />
          ) : (
            <Balance currency={receiveCurrency} balance={receiveAmount} />
          ),
      },
      {
        header: t('common:brand'),
        accessorKey: 'brands',
        minSize: 200,
        cell: ({ getValue }) => {
          const data = getValue();
          return (
            <Group spacing={8}>
              {(data as BrandLiteModel[]).map((val) => (
                <Badge color={val.color} key={val.id}>
                  {val.name}
                </Badge>
              ))}
            </Group>
          );
        },
      },
      {
        header: t('common:has_attachment'),
        accessorKey: 'hasAttachment',
        minSize: 160,
      },
      {
        header: t('common:attachment_completed'),
        accessorKey: 'isAttachmentCompleted',
        minSize: 190,
      },
    ],
    [entity?.currency, t],
  );

  return useTableViewDetailColumn(_columns, 'TransferView', 'TransfersView');
}

export function useTransferUpdateRateColumns() {
  const { t } = useTranslation();
  const { entity } = useEntity();

  const _columns = React.useMemo<IColumn<TransferLiteModel>[]>(
    () => [
      {
        header: '',
        accessorKey: 'checkbox',
        size: 40,
        cell: ({ row: { original } }) => (
          <Input type="checkbox" name={`ids.${original.id}`} />
        ),
      },
      {
        header: t('common:transaction_at'),
        accessorKey: 'transactionAt',
        minSize: 200,
        sortName: 'transaction_at',
        cell: ({ getValue }) =>
          format(new Date(getValue() as Date), 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('transfer:from_account'),
        accessorKey: 'accountFrom',
        minSize: 200,
        sortName: 'account_from',
      },

      {
        header: t('transfer:to_account'),
        accessorKey: 'accountTo',
        minSize: 200,
        sortName: 'account_to',
      },
      {
        header: t('transfer:amount'),
        accessorKey: 'transferAmount',
        minSize: 200,
        textAlign: 'right',
        cell: ({
          row: {
            original: { transferCurrency, transferAmount, accountFromRate },
          },
        }) =>
          transferCurrency !== entity?.currency ? (
            <BalanceWithDetails
              currency={transferCurrency}
              balance={transferAmount}
              rate={accountFromRate}
              entityCurrency={entity?.currency!}
            />
          ) : (
            <Balance currency={transferCurrency} balance={transferAmount} />
          ),
      },
      {
        header: t('transfer:from_rate'),
        accessorKey: 'accountFromRate',
        minSize: 200,
        cell: ({ getValue }) => string2moneyDefault(getValue() as number),
      },
      {
        header: t('transfer:received_amount'),
        accessorKey: 'receiveAmount',
        minSize: 200,
        sortName: 'receive_amount',
        cell: ({
          row: {
            original: { receiveCurrency, receiveAmount, accountToRate },
          },
        }) =>
          receiveCurrency !== entity?.currency ? (
            <BalanceWithDetails
              currency={receiveCurrency}
              balance={receiveAmount}
              rate={accountToRate}
              entityCurrency={entity?.currency!}
            />
          ) : (
            <Balance currency={receiveCurrency} balance={receiveAmount} />
          ),
      },
      {
        header: t('transfer:to_rate'),
        accessorKey: 'accountToRate',
        minSize: 200,
        cell: ({ getValue }) => string2moneyDefault(getValue() as number),
      },
    ],
    [entity?.currency, t],
  );
  return _columns;
}
