import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  StockGroupingDeleteInput,
  StockGroupingModel,
  StockGroupingMutationInput,
  StockGroupingUpdateInput,
} from './model';

export function useCreateStockGrouping(
  options?: UseMutationOptions<
    ApiResult<StockGroupingModel>,
    ApiError,
    StockGroupingMutationInput
  >,
): UseMutationResult<
  ApiResult<StockGroupingModel>,
  ApiError,
  StockGroupingMutationInput
> {
  return useMutation<
    ApiResult<StockGroupingModel>,
    ApiError,
    StockGroupingMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'stock-groupings',
      method: 'POST',
      classType: StockGroupingModel,
      body,
    });
  }, options);
}

export function useUpdateStockGrouping(
  options?: UseMutationOptions<
    ApiResult<StockGroupingModel>,
    ApiError,
    StockGroupingUpdateInput
  >,
): UseMutationResult<
  ApiResult<StockGroupingModel>,
  ApiError,
  StockGroupingUpdateInput
> {
  return useMutation<
    ApiResult<StockGroupingModel>,
    ApiError,
    StockGroupingUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `stock-groupings/${body.stockGroupingId}`,
      method: 'PUT',
      classType: StockGroupingModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteStockGrouping(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    StockGroupingDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, StockGroupingDeleteInput> {
  return useMutation<MessageResult, ApiError, StockGroupingDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `stock-groupings/${body.stockGroupingId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
