import * as Yup from 'yup';

export type StockItemFormType = {
  description: string;
  merk: string;
};

export const formSchema = () =>
  Yup.object().shape({
    description: Yup.string().nullable(),
    merk: Yup.string().required().nullable(),
  });
