import {
  DivisionLiteModel,
  DivisionModel,
  getDivisionKey,
  getDivisionsKey,
  useGetDivision,
  useGetDivisions,
} from 'api-hooks/division';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  onAfterDetailChange?: (value: any) => void;
  disabled?: boolean;
  noMargin?: boolean;
  className?: string;
  filter?: { [key: string]: any };
}

function transformer(item: DivisionLiteModel | DivisionModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}

export default function DivisionSelectInput(props: Props) {
  const { t } = useTranslation();
  const {
    label = t('common:division'),
    placeholder = t('common:choose_extra', { extra: t('common:division') }),
    onAfterChange,
    filter,
    ...rest
  } = props;
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetDivisions,
    useDetailLazyQueryHook: useGetDivision,
    getListsKey: React.useCallback(
      (page, q) =>
        getDivisionsKey({
          params: {
            q,
            page,
            ...(!!filter && { filter }),
          },
        }),
      [filter],
    ),
    renderCreate: false,
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          ...(!!filter && { filter }),
        },
      }),
      [filter],
    ),
    getDetailKey: React.useCallback(
      (value) =>
        getDivisionKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      props?.onAfterChange?.(result.data);
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      label={label}
      required
      {...rest}
      name={props.name}
      placeholder={placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
