import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getStockItemCodeInput,
  getStockItemCodesInput,
  StockItemCodeLiteModel,
  StockItemCodeModel,
} from './model';

export const stockItemCodeKey = {
  listKey: 'getStockItemCodes',
  detailKey: 'getStockItemCode',
  list(input?: getStockItemCodesInput) {
    return [stockItemCodeKey.listKey, input?.params];
  },
  detail(input?: getStockItemCodeInput) {
    return [stockItemCodeKey.detailKey, input?.stockItemCodeId];
  },
};

export function useGetStockItemCodes(
  input?: getStockItemCodesInput,
  options?: UseQueryOptions<
    ExtendedApiResult<StockItemCodeLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<StockItemCodeLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockItemCodeKey.list(input),
      () =>
        QueryFetchFunction({
          url: 'stock-item-codes',
          params: input?.params,
        }),
      options,
    ),
    StockItemCodeLiteModel,
  );
}

export function useGetStockItemCode(
  input?: getStockItemCodeInput,
  options?: UseQueryOptions<ApiResult<StockItemCodeLiteModel>, ApiError>,
): UseQueryResult<ApiResult<StockItemCodeLiteModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockItemCodeKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `stock-item-codes/${input?.stockItemCodeId}`,
        }),
      options,
    ),
    StockItemCodeModel,
  );
}
