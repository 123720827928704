import * as Yup from 'yup';

export type AccountAccessFormType = {
  entities: {
    id: string;
    isAllAccount: boolean;
    accounts: string[];
  }[];
};

export const formSchema = () =>
  Yup.object().shape({
    entities: Yup.array(
      Yup.object({
        accounts: Yup.array(Yup.string().required().nullable())
          .required()
          .min(1),
        id: Yup.string().required().nullable(),
        isAllAccount: Yup.bool(),
      }),
    ),
  });
