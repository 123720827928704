import { TransferModel, TransferTypeEnum } from 'api-hooks/transfer';
import { NavigationProps } from 'containers/navigation';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export interface TransferFormProps extends NavigationProps {
  transfer?: TransferModel;
  renderError?: React.ReactNode;
  onSubmit: (
    input: any,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}
export interface TransferInformationFormprops {
  hasValue: boolean;
  status?: string;
  receivedAmount?: number;
  receivedAt?: Date;
  code?: string;
}

export interface AccountInformation {
  id: string;
  isBaseCurrency: boolean;
  balance: number;
  currency: string;
}

export type TransferFormType = {
  accountFromId: string;
  accountFromRate: number | null;
  accountFrom: AccountInformation;
  accountToId: string;
  accountToRate: number | null;
  accountTo: AccountInformation;
  description: string;
  feeOn: string;
  isDelay: boolean;
  brands: string[];
  transactionAt: Date;
  transferAmount: number | null;
  transferFee: number | null;
  transferFeeRate: number;
  receivedAmount: number;
  hasAttachment: boolean;
  isAttachmentCompleted: boolean;
  files: any[];
  chartOfAccountFromId: string;
  chartOfAccountToId: string;
  requestFormId: string;
  type: string;
};

export const formSchema = () =>
  Yup.object().shape({
    accountFromId: Yup.string().nullable().required(),
    accountToId: Yup.string()
      .nullable()
      .required()
      .notOneOf([Yup.ref('accountFromId')]),
    accountToRate: Yup.number().when(['accountTo'], {
      is: (val) => !val.isBaseCurrency,
      then: Yup.number().positive().required(),
    }),
    accountFromRate: Yup.number().when(['accountFrom'], {
      is: (val) => !val.isBaseCurrency,
      then: Yup.number().positive().required(),
    }),
    chartOfAccountFromId: Yup.string().required().nullable(),
    chartOfAccountToId: Yup.string().required().nullable(),
    feeOn: Yup.string().nullable().required(),
    isDelay: Yup.boolean(),
    transferFeeRate: Yup.number().when(['feeOn'], {
      is: (feeOn) => feeOn === 'sender' || feeOn === 'receiver',
      then: Yup.number().positive().required(),
    }),
    brands: Yup.array(Yup.string().required().nullable()),
    transactionAt: Yup.date().nullable().required(),
    transferAmount: Yup.number().positive().required(),
    transferFee: Yup.mixed().when(['feeOn'], {
      is: (feeOn) => feeOn !== 'none',
      then: Yup.number().positive().required(),
      otherwise: Yup.mixed().nullable(),
    }),
    hasAttachment: Yup.bool(),
    isAttachmentCompleted: Yup.bool(),
    files: Yup.array().when(['hasAttachment', 'type'], {
      is: (hasAttachment, type) =>
        hasAttachment || type === TransferTypeEnum.top_up,
      then: Yup.array(
        Yup.object({
          url: Yup.string().when(['hasAttachment'], {
            is: (val) => val === true,
            then: Yup.string().required(),
          }),
          file: Yup.string().when(['hasAttachment'], {
            is: (val) => val === true,
            then: Yup.string().required(),
          }),
        }),
      ).min(1),
    }),
    type: Yup.string().required().nullable(),
  });
