import { PlusIcon, TrashIcon } from 'common/assets';
import colors from 'common/styles/colors';
import { dndStyles } from 'components/common/dnd-component/styles.css';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { FormState } from 'components/elements/form';
import Text from 'components/elements/text';
import EmployeeSelectInput from 'modules/master/employee/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

export default function CCForm({ idx }: { idx: number }) {
  const { control } = useFormContext<any>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `divisions.${idx}.carbonCopies`,
    keyName: 'customId',
  });

  const onAdd = React.useCallback(() => {
    append('');
  }, [append]);

  return (
    <>
      <Separator gap={16} direction="vertical" />
      <Text textVariant="BodyBoldDefault" className={moduleStyles.row()}>
        {t('common:cc_to')}
        <Separator gap={4} />
      </Text>
      <Separator gap={16} direction="vertical" />
      <FormState>
        {({ editable }) => (
          <>
            {!editable && !fields.length ? (
              <Text textVariant="BodyBoldDefault" color={colors.textLight}>
                {t('common:none')}
              </Text>
            ) : (
              fields.map((item, index) => (
                <HOCInput
                  keys={[`divisions.${idx}.carbonCopies`]}
                  key={`divisions.${idx}.details.${item.customId}`}
                >
                  {(value) => (
                    <>
                      <div className={moduleStyles.row()}>
                        <div className={dndStyles.container}>
                          <div className={moduleStyles.fullContainer}>
                            <EmployeeSelectInput
                              name={`divisions.${idx}.carbonCopies.${index}`}
                              key={`divisions.${idx}.carbonCopies.${index}`}
                              label=""
                              placeholder={t('common:choose_extra', {
                                extra: t('common:employee'),
                              })}
                              noMargin
                              removeArr={value[
                                `divisions.${idx}.carbonCopies`
                              ]?.map((item) => item?.employeeId)}
                            />
                          </div>
                        </div>
                        {editable && (
                          <Button
                            variant="tertiary"
                            error
                            type="button"
                            onClick={() => remove(index)}
                          >
                            <TrashIcon size={24} />
                          </Button>
                        )}
                      </div>

                      <Separator direction="vertical" gap={16} />
                    </>
                  )}
                </HOCInput>
              ))
            )}

            {editable && (
              <div>
                <Button
                  onClick={() => onAdd()}
                  type="button"
                  variant="secondary"
                  leftIcon={(size) => <PlusIcon size={size} />}
                  disabled={!editable}
                >
                  {t('common:add_extra', { extra: t('common:employee') })}
                </Button>
              </div>
            )}
          </>
        )}
      </FormState>
    </>
  );
}
