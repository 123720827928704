import { BrandModel } from 'api-hooks/brand';
import {
  PurchaseRequisitionsLiteModel,
  PurchaseRequisitionsStatusQueryEnum,
  useGetPurchaseRequisitions,
} from 'api-hooks/purchase-requisitions';
import {
  CheckIcon,
  CancelIcon,
  AlertIcon,
  EditIcon,
  ArrowRightIcon,
} from 'common/assets';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import ListHeader from 'components/common/list-header';
import Separator from 'components/common/separator';
import Badge from 'components/elements/badge';
import { IColumn } from 'components/elements/table';
import { format } from 'date-fns';
import { useAuthorization } from 'hooks/use-authorization';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import {
  GenericQueryTable,
  useGenericTableProps,
  useTableViewDetailColumn,
} from 'modules/common/table';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { snakeToTitle } from './helpers';

function usePurchaseRequisitionColumns({ t }) {
  const columns = React.useMemo<IColumn<PurchaseRequisitionsLiteModel>[]>(
    () => [
      {
        header: t('common:created_at'),
        accessorKey: 'createdAt',
        sortName: 'created_at',
        minSize: 150,
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('common:deadline_at'),
        accessorKey: 'deadlineAt',
        minSize: 150,
        cell: ({ getValue }) => format(getValue() as Date, 'dd MMM yyyy'),
      },
      {
        header: t('common:code'),
        accessorKey: 'code',
        minSize: 100,
        sortName: 'code',
      },
      {
        header: t('common:type'),
        accessorKey: 'requestType',
        minSize: 150,
        cell: ({ getValue }) => snakeToTitle((getValue() as string) || '-'),
      },
      {
        header: t('common:status'),
        accessorKey: 'status',
        minSize: 200,
        cell: ({ getValue }) => snakeToTitle((getValue() as string) || '-'),
      },
      {
        header: t('common:title_title'),
        accessorKey: 'title',
        minSize: 200,
        sortName: 'title',
      },
      {
        header: t('pr:forward_to'),
        accessorKey: 'forwardedDivision.name',
        minSize: 200,
      },
      {
        header: t('common:brand'),
        accessorKey: 'brand',
        sortName: 'brand',
        minSize: 150,
        cell: ({ getValue }) => {
          const val = getValue() as BrandModel;
          return (
            !!val && (
              <Badge color={val?.color} key={val?.id}>
                {val?.name}
              </Badge>
            )
          );
        },
      },
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 200,
        sortName: 'description',
        cell: ({ getValue }) => getValue() || '-',
      },
    ],
    [t],
  );

  return useTableViewDetailColumn(
    columns,
    'PurchaseRequisitionsView',
    'RequestFormsView',
  );
}

export default function PurchaseRequisitionsList() {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const navigation = useNavigation();
  const [status, setStatus] = React.useState<string>(
    PurchaseRequisitionsStatusQueryEnum.IN_PROGRESS,
  );
  const columns = usePurchaseRequisitionColumns({ t });
  const onCreate = () =>
    navigation.navigate(NavigationRoutes.PurchaseRequisitionsCreate);

  const tabItems = React.useMemo(
    () => [
      {
        value: PurchaseRequisitionsStatusQueryEnum.IN_PROGRESS,
        label: t('pr:in_progress'),
        icon: (size, color) => <ArrowRightIcon size={size} color={color} />,
      },
      {
        value: PurchaseRequisitionsStatusQueryEnum.NEED_REVISION,
        label: t('pr:need_revision'),
        icon: (size, color) => <EditIcon size={size} color={color} />,
      },

      {
        value: PurchaseRequisitionsStatusQueryEnum.COMPLETED,
        label: t('pr:completed'),
        icon: (size, color) => <CheckIcon size={size} color={color} />,
      },
      {
        value: PurchaseRequisitionsStatusQueryEnum.REJECTED,
        label: t('common:rejected'),
        icon: (size, color) => <CancelIcon size={size} color={color} />,
      },
      {
        value: PurchaseRequisitionsStatusQueryEnum.CANCELLED,
        label: t('common:cancelled'),
        icon: (size, color) => <AlertIcon size={size} color={color} />,
      },
    ],
    [t],
  );

  const { refetch, ...table } = useGenericTableProps(
    columns,
    useGetPurchaseRequisitions,
    {
      params: {
        status,
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
  );

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:purchase_requisitions')}
        showAdd={can(AuthorizationRules.RequestFormsCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
      />
      <Separator gap={8} />
      <GenericQueryTable
        {...table}
        tabs={{
          onChangeTab(tab) {
            setStatus(tab);
          },
          options: tabItems,
          tab: status,
        }}
        {...table}
      />
    </div>
  );
}
