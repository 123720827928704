import { FileResultModel } from 'api-hooks/common';
import { getIndexInput } from 'api-hooks/common/model';
import { WarehouseModelWithEntity } from 'api-hooks/stock-adjustment';
import { Expose, Type } from 'class-transformer';

// Model
export class StockItemLiteModel {
  id: string;
  name: string | null;
  merk: string | null;
  barcode: string;
  description: string;

  @Type(() => Number)
  price: number;

  @Expose({ name: 'stock_id' })
  stockId: string;

  @Expose({ name: 'is_used' })
  isUsed: boolean;

  @Expose({ name: 'is_void' })
  isVoid: boolean;

  @Expose({ name: 'stock_warehouse_id' })
  stockWarehouseId: string;

  @Type(() => WarehouseModelWithEntity)
  warehouse: WarehouseModelWithEntity;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;

  @Expose({ name: 'received_at' })
  @Type(() => Date)
  receivedAt: Date;
}

export class StockItemModel extends StockItemLiteModel {
  @Type(() => FileResultModel)
  files: FileResultModel[];
}

// Input

export type getStockItemInput = {
  stockItemId: string;
};

export type getStockItemsInput = getIndexInput;

export type StockItemUpdateBodyInput = {
  description: string;
  merk: string;
};

export type StockItemUpdateInput = {
  stockId: string;
  body: StockItemUpdateBodyInput;
};

// NOte: blm terpakai
export type StockItemVoidInput = {
  stockItemId: string;
};
