import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getStockTransferInput,
  getStockTransfersInput,
  StockTransferLiteModel,
  StockTransferModel,
} from './model';

export const stockTransferKey = {
  listKey: 'getWarehouses',
  detailKey: 'getWarehouse',
  list(input?: getStockTransfersInput) {
    return [stockTransferKey.listKey, input?.params];
  },
  detail(input?: getStockTransferInput) {
    return [stockTransferKey.detailKey, input?.stockTransferId];
  },
};

export function useGetStockTransfers(
  input?: getStockTransfersInput,
  options?: UseQueryOptions<
    ExtendedApiResult<StockTransferLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<StockTransferLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockTransferKey.list(input),
      () =>
        QueryFetchFunction({
          url: 'stock-transfers',
          params: input?.params,
        }),
      options,
    ),
    StockTransferLiteModel,
  );
}

export function useGetStockTransfer(
  input?: getStockTransferInput,
  options?: UseQueryOptions<ApiResult<StockTransferModel>, ApiError>,
): UseQueryResult<ApiResult<StockTransferModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockTransferKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `stock-transfers/${input?.stockTransferId}`,
        }),
      options,
    ),
    StockTransferModel,
  );
}
