import classNames from 'classnames';
import { PlusIcon, TrashIcon } from 'common/assets';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { outcomeTableStyles } from '../styles.css';

export function NotificationEmail({ name }: { name: string }) {
  const { control } = useFormContext<any>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
    keyName: 'customId',
  });

  return (
    <>
      <Separator gap={16} direction="vertical">
        {fields.map((item, idx) => (
          <div
            className={classNames(moduleStyles.flex1, moduleStyles.row())}
            key={item.customId}
          >
            <Input
              type="email"
              name={`${name}.${idx}`}
              key={`${name}.${idx}.${item.customId}`}
              label=""
              placeholder={t('common:enter_extra', {
                extra: t('common:email'),
              })}
              className={moduleStyles.flex1}
            />

            {idx ? (
              <Button
                variant="tertiary"
                error
                type="button"
                onClick={() => remove(idx)}
              >
                <TrashIcon size={24} />
              </Button>
            ) : (
              <div className={outcomeTableStyles.deleteContainer} />
            )}
          </div>
        ))}
        <div>
          <Button
            onClick={() => append('')}
            type="button"
            variant="secondary"
            leftIcon={(size) => <PlusIcon size={size} />}
          >
            {t('common:add_extra', { extra: t('common:email') })}
          </Button>
        </div>
      </Separator>
    </>
  );
}
