import { Modal } from '@mantine/core';
import {
  ActiveBudgetingItemLiteModel,
  getBudgetingKey,
  getBudgetingsKey,
  useCreateBudgetingTransfer,
} from 'api-hooks/budgeting';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import ProgressBar from '../../progress/bar';
import BudgetingSelectInput from '../../select-input-without-detail';
import { BudgetTransferFormType } from '../form-type';

interface Props extends ActiveBudgetingItemLiteModel {
  onClose: () => void;
  budgetingId: string;
}

export default function TransferBudgetModal(props: Props) {
  const { onClose, budgetingId, ...rest } = props;
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useCreateBudgetingTransfer();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      toBudgetingId: Yup.string().required().nullable(),
      amount: Yup.number().required().nullable(),
    }),
  );

  const methods = useForm<BudgetTransferFormType>({
    mode: 'onChange',
    resolver,
  });

  const onSubmit = React.useCallback(
    async (values: BudgetTransferFormType) => {
      try {
        const res = await mutateAsync({
          body: values,
          budgetingId,
        });
        onClose();
        queryClient.refetchQueries([getBudgetingsKey()[0]]);
        queryClient.refetchQueries(getBudgetingKey({ id: budgetingId }));
        notification.success({ message: res.message });
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [budgetingId, methods.setError, mutateAsync, onClose],
  );

  return (
    <Modal
      onClose={onClose}
      opened
      title={
        <Text textVariant="HeadingSmall">{t('budgeting:transfer_budget')}</Text>
      }
    >
      <div className={moduleStyles.fullContainer}>
        <ProgressBar {...rest} t={t} variant="secondary" showDetails />
        <Separator gap={24} direction="vertical" />
        <Form methods={methods} onSubmit={onSubmit}>
          <BudgetingSelectInput
            name="toBudgetingId"
            placeholder={t('common:choose_extra', {
              extra: t('common:budgeting'),
            })}
            label={t('budgeting:transfer_to')}
            required
          />

          <Input
            type="number"
            name="amount"
            label={t('budgeting:amount')}
            placeholder={t('common:enter_extra', {
              extra: t('common:amount'),
            })}
            required
            hideControls
            isMoneyFormat
          />

          <Separator gap={24} direction="vertical" />
          <Input
            type="submit"
            text={t('budgeting:save_budget')}
            loading={isLoading}
          />
        </Form>
      </div>
    </Modal>
  );
}
