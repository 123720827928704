import { useGetMe } from 'api-hooks/auth';
import {
  PurchaseRequisitionsTypeEnum,
  PurchaseRequisitionsModel,
  PurchaseRequisitionsStatusEnum,
} from 'api-hooks/purchase-requisitions';
import { HistoryIcon } from 'common/assets';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { ActionIcon } from 'components/elements/button';
import { Input } from 'components/elements/field';
import { FormState } from 'components/elements/form';
import FormStateRawField from 'components/elements/form-state-raw-field';
import Text from 'components/elements/text';
import useDrawer from 'hooks/use-drawer';
import { useEntity } from 'hooks/use-entities';
import AccountSelectInputByEntityId from 'modules/entity-settings/account/components/select-input-by-entity';
import BrandSelectInput from 'modules/entity-settings/brand/components/select-input';
import useRequestTypeOption from 'modules/hooks/use-request-type-option';
import DivisionSelectInput from 'modules/master/division/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import DeadlineChangeLog from './deadline-change-log';
import ItemTypeSelectInput from './item-type-select-input';

interface Props {
  purchaseRequisitions?: PurchaseRequisitionsModel;
}

export default function PurchaseRequisitionsInformationForm(props: Props) {
  const { purchaseRequisitions } = props;

  const { t } = useTranslation();
  const { data } = useGetMe();
  const { entity } = useEntity();

  const { requestTypeRadioOption } = useRequestTypeOption();

  const drawer = useDrawer();
  const handleViewChangeLog = React.useCallback(() => {
    // const canExtend= purchaseRequisitions.
    drawer.showCustom({
      size: 480,
      render: (close) => (
        <DeadlineChangeLog
          id={purchaseRequisitions?.id!}
          onClose={close}
          currentDeadline={purchaseRequisitions?.deadlineAt!}
          showExtend={
            purchaseRequisitions?.status ===
              PurchaseRequisitionsStatusEnum.COMPLETED ||
            purchaseRequisitions?.status ===
              PurchaseRequisitionsStatusEnum.NEED_TOP_UP
          }
        />
      ),
      title: (
        <Text textVariant="HeadingLarge">{t('modal:deadline_change_log')}</Text>
      ),
      padding: 'sm',
    });
  }, [
    drawer,
    purchaseRequisitions?.deadlineAt,
    purchaseRequisitions?.id,
    purchaseRequisitions?.status,
    t,
  ]);

  return (
    <>
      <div className={moduleStyles.fullContainer}>
        <Input
          type="radio-group"
          data={requestTypeRadioOption}
          name="requestType"
          label={t('pr:request_type')}
          withAsterisk
        />
        {!!purchaseRequisitions && (
          <div className={moduleStyles.halfContainer}>
            <FormStateRawField
              value={purchaseRequisitions?.code}
              type="text"
              label={t('common:code')}
            />
          </div>
        )}
        <div className={moduleStyles.sectionContainer}>
          <div className={moduleStyles.halfContainer}>
            <Input
              type="text"
              placeholder={t('common:enter_extra', {
                extra: t('common:title_title'),
              })}
              name="title"
              label={t('common:title_title')}
              required
            />
          </div>
          <div className={moduleStyles.halfContainer}>
            <div className={moduleStyles.row()} style={styles.center}>
              <Input
                type="date"
                name="deadlineAt"
                required
                label={t('common:deadline')}
                placeholder={t('common:select_extra', {
                  extra: t('common:date'),
                })}
                disabled={!!purchaseRequisitions}
                className={moduleStyles.flex1}
              />
              <FormState>
                {({ editable }) => (
                  <>
                    {!editable && (
                      <>
                        <Separator gap={16} direction="horizontal" />
                        <ActionIcon
                          variant="transparent"
                          onClick={handleViewChangeLog}
                        >
                          {(size) => <HistoryIcon size={size} />}
                        </ActionIcon>
                      </>
                    )}
                  </>
                )}
              </FormState>
            </div>
          </div>
        </div>

        <HOCInput keys={['requestType']}>
          {({ requestType }) =>
            requestType === PurchaseRequisitionsTypeEnum.top_up && (
              <div className={moduleStyles.sectionContainer}>
                <div className={moduleStyles.halfContainer}>
                  <ItemTypeSelectInput name="itemType" required />
                </div>
                <div className={moduleStyles.halfContainer}>
                  <AccountSelectInputByEntityId
                    name="accountId"
                    required
                    label={t('pr:account_destination')}
                    placeholder={t('common:choose_extra', {
                      extra: t('common:account'),
                    })}
                  />
                </div>
              </div>
            )
          }
        </HOCInput>

        <HOCInput keys={['requestType']}>
          {({ requestType }) =>
            requestType === PurchaseRequisitionsTypeEnum.top_up && (
              <div className={moduleStyles.sectionContainer}>
                <div className={moduleStyles.halfContainer}>
                  <Input
                    type="number"
                    hideControls
                    isMoneyFormat
                    name="amount"
                    required
                    label={t('pr:top_up_amount')}
                    placeholder={t('common:enter_extra', {
                      extra: t('common:amount'),
                    })}
                  />
                </div>
                <div className={moduleStyles.halfContainer}>
                  <Input
                    type="text-area"
                    name="description"
                    label={t('pr:justification')}
                    placeholder={t('common:enter_extra', {
                      extra: t('pr:justification'),
                    })}
                    required
                  />
                </div>
              </div>
            )
          }
        </HOCInput>
        <div className={moduleStyles.sectionContainer}>
          <div className={moduleStyles.halfContainer}>
            <HOCInput keys={['requestType']}>
              {({ requestType }) =>
                requestType === PurchaseRequisitionsTypeEnum.top_up ? (
                  <DivisionSelectInput name="divisionId" required />
                ) : (
                  <FormStateRawField
                    label={t('common:division')}
                    withAsterisk
                    type="text"
                    value={data?.data?.division?.name}
                  />
                )
              }
            </HOCInput>
          </div>
          <div className={moduleStyles.halfContainer}>
            <BrandSelectInput
              name="brandId"
              required
              entityId={entity?.id}
              model="request-forms"
            />
          </div>
        </div>
        <HOCInput keys={['requestType']}>
          {({ requestType }) =>
            requestType === PurchaseRequisitionsTypeEnum.others && (
              <div className={moduleStyles.halfContainer}>
                <Input
                  type="text-area"
                  name="description"
                  label={t('pr:justification')}
                  placeholder={t('common:enter_extra', {
                    extra: t('pr:justification'),
                  })}
                  required
                />
              </div>
            )
          }
        </HOCInput>
      </div>
      <div className={moduleStyles.halfContainer}>
        <Input
          name="files"
          previewKey="url"
          valueKey="file"
          type="files"
          label={t('common:attachment')}
        />
      </div>
    </>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  center: {
    alignItems: 'center',
  },
};
