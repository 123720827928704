import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  CurrencyLiteModel,
  CurrencyModel,
  getCurrenciesInput,
  getCurrencyInput,
} from './model';

export function useGetCurrencies(
  input?: getCurrenciesInput,
  options?: UseQueryOptions<ExtendedApiResult<CurrencyLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<CurrencyLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getCurrencies', input?.params],
      () => QueryFetchFunction({ url: 'currencies', params: input?.params }),
      options,
    ),
    CurrencyLiteModel,
  );
}

export function useGetCurrency(
  input?: getCurrencyInput,
  options?: UseQueryOptions<ApiResult<CurrencyModel>, ApiError>,
): UseQueryResult<ApiResult<CurrencyModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getCurrency', input?.id],
      () =>
        QueryFetchFunction({
          url: `currencies/${input?.id}`,
        }),
      options,
    ),
    CurrencyModel,
  );
}

export function getCurrenciesKey(input?: getCurrenciesInput) {
  return ['getCurrencies', input?.params];
}
export function getCurrencyKey(input?: getCurrencyInput) {
  return ['getCurrency', input?.id];
}
