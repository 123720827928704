import {
  useGetWarehouse,
  useUpdateWarehouse,
  warehouseKey,
} from 'api-hooks/warehouse';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import WarehouseForm from './components/form';

export default function WarehouseView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } = useGetWarehouse({
    warehouseId: props.navigation.params.id,
  });

  const { mutateAsync, error: mutateError } = useUpdateWarehouse();

  const warehouse = data?.data;
  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <WarehouseForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          warehouse={warehouse}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              warehouseId: warehouse?.id!,
            });
            form.reset();
            queryClient.refetchQueries([warehouseKey.listKey]);
            queryClient.refetchQueries(
              warehouseKey.detail({ warehouseId: warehouse?.id! }),
            );
            notification.success({ message: result?.message });
            return result.data;
          }}
        />
      }
    />
  );
}
