import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  TransactionClosingDeleteInput,
  TransactionClosingModel,
  TransactionClosingMutationInput,
  TransactionClosingUpdateInput,
} from './model';

export function useCreateTransactionClosing(
  options?: UseMutationOptions<
    ApiResult<TransactionClosingModel>,
    ApiError,
    TransactionClosingMutationInput
  >,
): UseMutationResult<
  ApiResult<TransactionClosingModel>,
  ApiError,
  TransactionClosingMutationInput
> {
  return useMutation<
    ApiResult<TransactionClosingModel>,
    ApiError,
    TransactionClosingMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'transaction-closings',
      method: 'POST',
      classType: TransactionClosingModel,
      body,
    });
  }, options);
}

export function useUpdateTransactionClosing(
  options?: UseMutationOptions<
    ApiResult<TransactionClosingModel>,
    ApiError,
    TransactionClosingUpdateInput
  >,
): UseMutationResult<
  ApiResult<TransactionClosingModel>,
  ApiError,
  TransactionClosingUpdateInput
> {
  return useMutation<
    ApiResult<TransactionClosingModel>,
    ApiError,
    TransactionClosingUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `transaction-closings/${body.transactionClosingId}`,
      method: 'PUT',
      classType: TransactionClosingModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteTransactionClosing(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    TransactionClosingDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, TransactionClosingDeleteInput> {
  return useMutation<MessageResult, ApiError, TransactionClosingDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `transaction-closings/${body.transactionClosingId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
