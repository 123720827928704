import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { EntityModel } from 'api-hooks/role';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  EntityDeleteInput,
  EntityMutationInput,
  EntityUpdateInput,
} from './model';

export function useCreateEntity(
  options?: UseMutationOptions<
    ApiResult<EntityModel>,
    ApiError,
    EntityMutationInput
  >,
): UseMutationResult<ApiResult<EntityModel>, ApiError, EntityMutationInput> {
  return useMutation<ApiResult<EntityModel>, ApiError, EntityMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'entities',
        method: 'POST',
        classType: EntityModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateEntity(
  options?: UseMutationOptions<
    ApiResult<EntityModel>,
    ApiError,
    EntityUpdateInput
  >,
): UseMutationResult<ApiResult<EntityModel>, ApiError, EntityUpdateInput> {
  return useMutation<ApiResult<EntityModel>, ApiError, EntityUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `entities/${body.entityId}`,
        method: 'PUT',
        classType: EntityModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteEntity(
  options?: UseMutationOptions<MessageResult, ApiError, EntityDeleteInput>,
): UseMutationResult<MessageResult, ApiError, EntityDeleteInput> {
  return useMutation<MessageResult, ApiError, EntityDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `entities/${body.entityId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
