import { StockItemCodeTypeEnum } from 'api-hooks/stock-item-code/model';
import { capitalize } from 'common/utils/string';
import { Input } from 'components/elements/field';
import React from 'react';

const selectOptions = Object.keys(StockItemCodeTypeEnum).map((item) => ({
  label: capitalize(item),
  value: item,
}));

export default function StockItemCodeTypeSelectInput({ t }) {
  return (
    <Input
      type="select"
      name="type"
      label={t('common:type')}
      placeholder={t('common:enter_extra', {
        extra: t('common:type'),
      })}
      required
      data={selectOptions}
    />
  );
}
