import {
  getSupplierKey,
  getSuppliersKey,
  useGetSupplier,
  useUpdateSupplier,
} from 'api-hooks/supplier';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import SupplierForm from './components/form';

export default function SupplierView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } = useGetSupplier({
    id: props.navigation.params.id,
  });

  const { mutateAsync, error: mutateError } = useUpdateSupplier();
  const supplier = data?.data;

  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <SupplierForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          supplier={supplier}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              supplierId: supplier?.id!,
            });
            form.reset();
            queryClient.refetchQueries([getSuppliersKey()[0]]);
            queryClient.refetchQueries(getSupplierKey({ id: supplier?.id! }));
            notification.success({ message: result?.message });

            return result.data;
          }}
        />
      }
    />
  );
}
