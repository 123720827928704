import { getSuppliersKey, useDeleteSupplier } from 'api-hooks/supplier';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import Map from 'components/common/map';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import DivisionSelectInput from 'modules/master/division/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { SupplierFormProps, SupplierFormType } from './form-type';

export default function SupplierForm(props: SupplierFormProps) {
  const { t } = useTranslation();
  const { supplier } = props;
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeleteSupplier();
  const { can } = useAuthorization();
  const dialog = useDialog();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().nullable().required(),
      address: Yup.string().nullable().required(),
      contact: Yup.string().nullable().required(),
      description: Yup.string().nullable().required(),
      divisionId: Yup.string().nullable().required(),
    }),
  );

  const intialValues = React.useMemo<SupplierFormType>(() => {
    return {
      name: supplier?.name || '',
      address: supplier?.address || '',
      contact: supplier?.contact || '',
      lat: supplier?.lat || '',
      lng: supplier?.lng || '',
      description: supplier?.description || '',
      divisionId: supplier?.division?.id || '',
    };
  }, [supplier]);

  const methods = useForm<SupplierFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values: SupplierFormType) => {
      try {
        const { lat, lng, ...rest } = values;
        const _input = {
          ...rest,
          lat: lat || null,
          lng: lng || null,
          entityId: entity?.id,
        };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            supplierId: supplier?.id!,
          });
          queryClient.refetchQueries([getSuppliersKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, supplier?.id, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!supplier}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={supplier}
          title={
            supplier
              ? `${t('common:supplier')} - ${supplier?.name}`
              : t('common:create_extra', { extra: t('common:supplier') })
          }
          noDelete={!can(AuthorizationRules.SuppliersDelete)}
          noEdit={!can(AuthorizationRules.SuppliersUpdate)}
        />
        <div className={moduleStyles.halfContainer}>
          <Input
            type="text"
            label={t('common:supplier')}
            placeholder={t('common:enter_extra', {
              extra: t('common:supplier'),
            })}
            name="name"
            required
          />
          <DivisionSelectInput required name="divisionId" />
          <Input
            type="text-area"
            label={t('common:address')}
            name="address"
            required
            placeholder={t('common:enter_extra', {
              extra: t('common:address'),
            })}
          />
          <Input
            type="text-area"
            label={t('common:contact')}
            name="contact"
            required
            placeholder={t('common:enter_extra', {
              extra: t('common:contact'),
            })}
          />
          <Input
            type="text-area"
            label={t('common:description')}
            name="description"
            required
            placeholder={t('common:enter_extra', {
              extra: t('common:description'),
            })}
          />
          <Map latName="lat" lngName="lng" />
          <Input type="number" name="lat" label={t('common:latitude')} />
          <Input type="number" name="lng" label={t('common:longitude')} />
        </div>
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
}
