import {
  PurchaseRequisitionsTypeEnum,
  PurchaseRequisitionsMutationInput,
  PurchaseRequisitionsStatusEnum,
  getPurchaseRequisitionsKey,
  useDeletePurchaseRequisitions,
} from 'api-hooks/purchase-requisitions';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import { removeUnderscore } from 'common/utils/string';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import Badge from 'components/elements/badge';
import Form, { FormState } from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import { badgeColor } from './badgeColor';
import {
  PurchaseRequisitionsFormProps,
  PurchaseRequisitionsFormType,
  formSchema,
} from './form-type';
import HistoryTableForm from './history-table-form';
import PurchaseRequisitionsInformationForm from './information-form';
import RequestExtend from './request-extend';

export default function PurchaseRequisitionsForm(
  props: PurchaseRequisitionsFormProps,
) {
  const { t } = useTranslation();
  const { purchaseRequisitions } = props;
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeletePurchaseRequisitions();
  const { can } = useAuthorization();

  const dialog = useDialog();

  const resolver = useYupValidationResolver(formSchema());

  const intialValues = React.useMemo<PurchaseRequisitionsFormType>(() => {
    return {
      title: purchaseRequisitions?.title || '',
      description: purchaseRequisitions?.description || '',
      deadlineAt: purchaseRequisitions?.deadlineAt || null,
      itemType: purchaseRequisitions?.itemType || '',
      requestType:
        purchaseRequisitions?.requestType ||
        PurchaseRequisitionsTypeEnum.top_up,
      accountId: purchaseRequisitions?.account?.id || '',
      amount: purchaseRequisitions?.amount || null,
      divisionId: purchaseRequisitions?.division?.id || '',
      files:
        purchaseRequisitions?.files.map((item) => {
          return {
            id: item.id,
            file: item.name,
            url: item.url,
          };
        }) || [],
      brandId: purchaseRequisitions?.brand?.id || '',
    };
  }, [purchaseRequisitions]);

  const methods = useForm<PurchaseRequisitionsFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const _input: PurchaseRequisitionsMutationInput = {
          ...values,
          entityId: entity!.id,
          files: values.files?.map((item) => item.file),
        };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            id: purchaseRequisitions!.id,
          });
          queryClient.refetchQueries([getPurchaseRequisitionsKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, purchaseRequisitions, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!purchaseRequisitions}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={purchaseRequisitions}
          title={
            purchaseRequisitions ? (
              <div className={moduleStyles.sectionContainer}>
                {t('common:purchase_requisitions')}
                <Separator gap={16} direction="horizontal" />
                <Badge
                  radius={100}
                  color={
                    badgeColor.find(
                      (item) => purchaseRequisitions.status === item.value,
                    )?.color
                  }
                >
                  {removeUnderscore(purchaseRequisitions.status)}
                </Badge>
              </div>
            ) : (
              t('common:create_extra', {
                extra: t('common:purchase_requisitions'),
              })
            )
          }
          noDelete={!can(AuthorizationRules.RequestFormsDelete)}
          noEdit={
            !can(AuthorizationRules.RequestFormsUpdate) ||
            purchaseRequisitions?.requestType ===
              PurchaseRequisitionsTypeEnum.others ||
            purchaseRequisitions?.status ===
              PurchaseRequisitionsStatusEnum.TOP_UP_DONE
          }
          onRenderRightComponent={
            <FormState>
              {({ editable }) => (
                <>
                  {!editable &&
                    (purchaseRequisitions?.status ===
                      PurchaseRequisitionsStatusEnum.COMPLETED ||
                      purchaseRequisitions?.status ===
                        PurchaseRequisitionsStatusEnum.NEED_TOP_UP) && (
                      <RequestExtend
                        currentDeadline={purchaseRequisitions?.deadlineAt!}
                        id={purchaseRequisitions?.id!}
                      />
                    )}
                </>
              )}
            </FormState>
          }
        />
        <PurchaseRequisitionsInformationForm
          purchaseRequisitions={purchaseRequisitions}
        />
        <Separator direction="vertical" gap={32} />
        {purchaseRequisitions?.requestType ===
          PurchaseRequisitionsTypeEnum.top_up && (
          <HistoryTableForm histories={purchaseRequisitions?.histories || []} />
        )}
      </FormContent>
    </Form>
  );
}
