import { useGetBudgeting } from 'api-hooks/budgeting';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import ViewBudgetingForm from './components/form/view';

export default function BudgetingView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } = useGetBudgeting({
    id: props.navigation.params.id,
  });

  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={<ViewBudgetingForm budgeting={data?.data} {...props} />}
    />
  );
}
