import { Modal } from '@mantine/core';
import colors from 'common/styles/colors';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Text from 'components/elements/text';
import useDialog from 'hooks/use-dialog';
import DivisionSelectInput from 'modules/master/division/components/select-input';
import RoleSelectMultiInput from 'modules/master/role/components/select-multi-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { EmployeeEntityAccess } from './employee-entity-access';
import ModalContent, { ModalNotificationContent } from './modal-content';
import { employeeStyle } from '../styles.css';

interface Props {
  hasData?: boolean;
}

export default function EmployeeInformationForm(props: Props) {
  const { t } = useTranslation();
  const { hasData } = props;

  const dialog = useDialog();

  const handleShowGuide = React.useCallback(
    (type: 'notif' | 'id') => {
      dialog.showCustom({
        render: (close) => (
          <Modal
            opened
            onClose={close}
            centered
            title={
              <Text textVariant="HeadingLarge">
                {type === 'id'
                  ? t('common:how_to_get_telegram_id')
                  : t('common:how_to_get_telegram_notifications')}
              </Text>
            }
            size={500}
            style={{ textAlign: 'center' }}
          >
            {type === 'id' ? <ModalContent /> : <ModalNotificationContent />}
          </Modal>
        ),
      });
    },
    [dialog, t],
  );

  return (
    <div className={moduleStyles.fullContainer}>
      <Separator gap={32} direction="vertical" />
      <Text textVariant="HeadingSmall">
        {t('employee:employee_information')}
      </Text>
      <Separator gap={32} direction="vertical" />
      <div className={moduleStyles.halfContainer}>
        <Input
          label={t('common:user_name')}
          placeholder={t('common:enter_extra', {
            extra: t('common:user_name'),
          })}
          name="username"
          type="text"
          required
        />
      </div>
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <Input
            label={t('common:email')}
            placeholder={t('common:email')}
            name="email"
            type="text"
            required
          />

          {hasData && (
            <>
              <Input
                label={t('employee:change_password')}
                name="isChangePassword"
                type="checkbox"
              />
              <Separator gap={16} direction="vertical" />
            </>
          )}
        </div>
        <div className={moduleStyles.halfContainer}>
          <Input
            label={t('common:telegram_id')}
            placeholder={t('common:enter_extra', {
              extra: t('common:telegram_id'),
            })}
            name="telegramChatId"
            type="text"
            required
            noMargin
          />
          <Separator gap={7} direction="vertical" />
          <span onClick={() => handleShowGuide('id')}>
            <Text color={colors.textProduct} className={employeeStyle.textLink}>
              {t('common:how_to_get_telegram_id')}
            </Text>
          </span>
          <Separator gap={7} direction="vertical" />
          <span onClick={() => handleShowGuide('notif')}>
            <Text color={colors.textProduct} className={employeeStyle.textLink}>
              {t('common:how_to_get_telegram_notifications')}
            </Text>
          </span>
          <Separator gap={16} direction="vertical" />
        </div>
      </div>

      <HOCInput keys={['isChangePassword']}>
        {({ isChangePassword }) => {
          return (
            isChangePassword && (
              <div className={moduleStyles.sectionContainer}>
                <div className={moduleStyles.halfContainer}>
                  <Input
                    label={t('common:password')}
                    placeholder={t('common:password')}
                    name="password"
                    type="password"
                    required={!hasData}
                  />
                </div>
                <div className={moduleStyles.halfContainer}>
                  <Input
                    label={t('common:password_confirmation')}
                    placeholder={t('common:enter_extra', {
                      extra: t('common:password_confirmation'),
                    })}
                    name="passwordConfirmation"
                    type="password"
                    required={!hasData}
                  />
                </div>
              </div>
            )
          );
        }}
      </HOCInput>
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <DivisionSelectInput name="divisionId" required />
        </div>
        <div className={moduleStyles.halfContainer}>
          <RoleSelectMultiInput
            label={t('common:role')}
            placeholder={t('common:choose_extra', { extra: t('common:role') })}
            name="roles"
            required
          />
        </div>
      </div>
      <Separator
        gap={54}
        direction="vertical"
        style={{ borderTop: `1px solid ${colors.dividerDefault}` }}
      />
      <EmployeeEntityAccess />
    </div>
  );
}
