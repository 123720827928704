export enum AuthorizationRules {
  RolesPage = 'employee.roles.page',
  RolesList = 'employee.roles.list',
  RolesView = 'employee.roles.view',
  RolesCreate = 'employee.roles.create',
  RolesUpdate = 'employee.roles.update',
  RolesDelete = 'employee.roles.delete',
  EmployeesPage = 'employee.employees.page',
  EmployeesList = 'employee.employees.list',
  EmployeesView = 'employee.employees.view',
  EmployeesCreate = 'employee.employees.create',
  EmployeesUpdate = 'employee.employees.update',
  EmployeesUpdateActiveStatus = 'employee.employees.update-active-status',
  EmployeesDelete = 'employee.employees.delete',
  AccountAccessesPage = 'employee.account-accesses.page',
  AccountAccessesList = 'employee.account-accesses.list',
  AccountAccessesView = 'employee.account-accesses.view',
  AccountAccessesUpdate = 'employee.account-accesses.update',
  CurrenciesPage = 'employee.currencies.page',
  CurrenciesList = 'employee.currencies.list',
  CurrenciesView = 'employee.currencies.view',
  CurrenciesCreate = 'employee.currencies.create',
  CurrenciesUpdate = 'employee.currencies.update',
  CurrenciesDelete = 'employee.currencies.delete',
  EntitiesPage = 'employee.entities.page',
  EntitiesList = 'employee.entities.list',
  EntitiesView = 'employee.entities.view',
  EntitiesCreate = 'employee.entities.create',
  EntitiesUpdate = 'employee.entities.update',
  EntitiesDelete = 'employee.entities.delete',
  EntitiesGetAccount = 'employee.entities.get-account',
  EntitiesGetEntity = 'employee.entities.get-entity',
  AccountsPage = 'employee.accounts.page',
  AccountsList = 'employee.accounts.list',
  AccountsView = 'employee.accounts.view',
  AccountsCreate = 'employee.accounts.create',
  AccountsUpdate = 'employee.accounts.update',
  AccountsDelete = 'employee.accounts.delete',
  BrandsPage = 'employee.brands.page',
  BrandsList = 'employee.brands.list',
  BrandsView = 'employee.brands.view',
  BrandsCreate = 'employee.brands.create',
  BrandsUpdate = 'employee.brands.update',
  BrandsDelete = 'employee.brands.delete',
  IncomesPage = 'employee.incomes.page',
  IncomesList = 'employee.incomes.list',
  IncomesView = 'employee.incomes.view',
  IncomesCreate = 'employee.incomes.create',
  IncomesUpdate = 'employee.incomes.update',
  IncomesDelete = 'employee.incomes.delete',
  IncomesCore = 'employee.incomes.core',
  IncomesImport = 'employee.incomes.import',
  IncomesImportTemplate = 'employee.incomes.import-template',
  IncomesMassUpdateRate = 'employee.incomes.mass-update-rate',
  ExpensesPage = 'employee.expenses.page',
  ExpensesList = 'employee.expenses.list',
  ExpensesView = 'employee.expenses.view',
  ExpensesCreate = 'employee.expenses.create',
  ExpensesUpdate = 'employee.expenses.update',
  ExpensesDelete = 'employee.expenses.delete',
  ExpensesCore = 'employee.expenses.core',
  ExpensesImport = 'employee.expenses.import',
  ExpensesImportTemplate = 'employee.expenses.import-template',
  ExpensesMassUpdateRate = 'employee.expenses.mass-update-rate',
  TransfersPage = 'employee.transfers.page',
  TransfersList = 'employee.transfers.list',
  TransfersView = 'employee.transfers.view',
  TransfersCreate = 'employee.transfers.create',
  TransfersUpdate = 'employee.transfers.update',
  TransfersDelete = 'employee.transfers.delete',
  TransfersImport = 'employee.transfers.import',
  TransferMassUpdateRate = 'employee.transfers.mass-update-rate',
  TransfersImportTemplate = 'employee.transfers.import-template',
  TransferApprovalsPage = 'employee.transfer-approvals.page',
  TransferApprovalsList = 'employee.transfer-approvals.list',
  TransferApprovalsView = 'employee.transfer-approvals.view',
  TransferApprovalsApprove = 'employee.transfer-approvals.approve',
  EntityCurrenciesPage = 'employee.entity-currencies.page',
  EntityCurrenciesList = 'employee.entity-currencies.list',
  EntityCurrenciesView = 'employee.entity-currencies.view',
  EntityCurrenciesCreate = 'employee.entity-currencies.create',
  EntityCurrenciesUpdate = 'employee.entity-currencies.update',
  EntityCurrenciesDelete = 'employee.entity-currencies.delete',
  WalletMutationsPage = 'employee.wallet-mutations.page',
  WalletMutationsList = 'employee.wallet-mutations.list',
  WalletMutationsView = 'employee.wallet-mutations.view',
  WalletMutationsCreate = 'employee.wallet-mutations.create',
  WalletMutationsUpdate = 'employee.wallet-mutations.update',
  WalletMutationsDelete = 'employee.wallet-mutations.delete',
  WalletMutationsTransactionAt = 'employee.wallet-mutations.transaction-at',
  ReconciliationsPage = 'employee.reconciliations.page',
  ReconciliationsList = 'employee.reconciliations.list',
  ReconciliationsCreate = 'employee.reconciliations.create',
  ReconciliationsDelete = 'employee.reconciliations.delete',
  ReconciliationsSystems = 'employee.reconciliations.systems',
  ChartOfAccountCategoriesPage = 'employee.chart-of-account-categories.page',
  ChartOfAccountCategoriesList = 'employee.chart-of-account-categories.list',
  ChartOfAccountCategoriesView = 'employee.chart-of-account-categories.view',
  ChartOfAccountCategoriesCreate = 'employee.chart-of-account-categories.create',
  ChartOfAccountCategoriesUpdate = 'employee.chart-of-account-categories.update',
  ChartOfAccountCategoriesDelete = 'employee.chart-of-account-categories.delete',
  ChartOfAccountsPage = 'employee.chart-of-accounts.page',
  ChartOfAccountsList = 'employee.chart-of-accounts.list',
  ChartOfAccountsView = 'employee.chart-of-accounts.view',
  ChartOfAccountsCreate = 'employee.chart-of-accounts.create',
  ChartOfAccountsUpdate = 'employee.chart-of-accounts.update',
  ChartOfAccountsDelete = 'employee.chart-of-accounts.delete',
  ChartOfAccountsGetChildren = 'employee.chart-of-accounts.get-children',
  RequestFormsPage = 'employee.request-forms.page',
  RequestFormsList = 'employee.request-forms.list',
  RequestFormsView = 'employee.request-forms.view',
  RequestFormsCreate = 'employee.request-forms.create',
  RequestFormsUpdate = 'employee.request-forms.update',
  RequestFormsDelete = 'employee.request-forms.delete',
  RequestFormsCore = 'employee.request-forms.core',
  RequestFormsDeadline = 'employee.request-forms.deadline',
  RequestFormsChangeLog = 'employee.request-forms.change-log',
  RequestFormsExpense = 'employee.request-forms.expense',
  ApprovalMastersPage = 'employee.approval-masters.page',
  ApprovalMastersList = 'employee.approval-masters.list',
  ApprovalMastersView = 'employee.approval-masters.view',
  ApprovalMastersCreate = 'employee.approval-masters.create',
  ApprovalMastersUpdate = 'employee.approval-masters.update',
  ApprovalMastersDelete = 'employee.approval-masters.delete',
  ApprovalsPage = 'employee.approvals.page',
  ApprovalsList = 'employee.approvals.list',
  ApprovalsView = 'employee.approvals.view',
  ApprovalsApprove = 'employee.approvals.approve',
  ApprovalsReject = 'employee.approvals.reject',
  ApprovalsRequestRevision = 'employee.approvals.request-revision',
  ApprovalsCancel = 'employee.approvals.cancel',
  ApprovalsMarkDone = 'employee.approvals.mark-done',
  ApprovalsCore = 'employee.approvals.core',
  ReportsAccount = 'employee.reports.account',
  ReportsChartOfAccount = 'employee.reports.chart-of-account',
  ReportsTransaction = 'employee.reports.transaction',
  ReportsCore = 'employee.reports.core',
  ReportsTransactionSummary = 'employee.reports.transaction-summary',
  ReportsStockSimple = 'employee.reports.stock-simple',
  ReportsStockDetail = 'employee.reports.stock-detail',
  SuppliersPage = 'employee.suppliers.page',
  SuppliersList = 'employee.suppliers.list',
  SuppliersView = 'employee.suppliers.view',
  SuppliersCreate = 'employee.suppliers.create',
  SuppliersUpdate = 'employee.suppliers.update',
  SuppliersDelete = 'employee.suppliers.delete',
  DivisionsPage = 'employee.divisions.page',
  DivisionsList = 'employee.divisions.list',
  DivisionsView = 'employee.divisions.view',
  DivisionsCreate = 'employee.divisions.create',
  DivisionsUpdate = 'employee.divisions.update',
  DivisionsDelete = 'employee.divisions.delete',
  DivisionsEmployee = 'employee.divisions.employee',
  ExportHistoriesPage = 'employee.export-histories.page',
  ExportHistoriesCore = 'employee.export-histories.core',
  ExportHistoriesList = 'employee.export-histories.list',
  ExportHistoriesExport = 'employee.export-histories.export',
  BudgetingsCore = 'employee.budgetings.core',
  BudgetingsPage = 'employee.budgetings.page',
  BudgetingsList = 'employee.budgetings.list',
  BudgetingsView = 'employee.budgetings.view',
  BudgetingsCreate = 'employee.budgetings.create',
  BudgetingsDelete = 'employee.budgetings.delete',
  BudgetingsShowBudgetingItem = 'employee.budgetings.show-budgeting-item',
  BudgetingsStoreBudgetingItem = 'employee.budgetings.store-budgeting-item',
  BudgetingsUpdateBudgetingItem = 'employee.budgetings.update-budgeting-item',
  BudgetingsStoreBudgetingTransfer = 'employee.budgetings.store-budgeting-transfer',
  TransactionClosingsPage = 'employee.transaction-closings.page',
  TransactionClosingsList = 'employee.transaction-closings.list',
  TransactionClosingsView = 'employee.transaction-closings.view',
  TransactionClosingsCreate = 'employee.transaction-closings.create',
  TransactionClosingsUpdate = 'employee.transaction-closings.update',
  TransactionClosingsDelete = 'employee.transaction-closings.delete',
  WarehousesPage = 'employee.warehouses.page',
  WarehousesList = 'employee.warehouses.list',
  WarehousesView = 'employee.warehouses.view',
  WarehousesCreate = 'employee.warehouses.create',
  WarehousesUpdate = 'employee.warehouses.update',
  WarehousesDelete = 'employee.warehouses.delete',
  StockItemCodesPage = 'employee.stock-item-codes.page',
  StockItemCodesList = 'employee.stock-item-codes.list',
  StockItemCodesView = 'employee.stock-item-codes.view',
  StockItemCodesCreate = 'employee.stock-item-codes.create',
  StockItemCodesUpdate = 'employee.stock-item-codes.update',
  StockItemCodesDelete = 'employee.stock-item-codes.delete',
  StocksCore = 'employee.stocks.core',
  StocksPage = 'employee.stocks.page',
  StocksList = 'employee.stocks.list',
  StocksView = 'employee.stocks.view',
  StocksCreate = 'employee.stocks.create',
  StocksUpdate = 'employee.stocks.update',
  StocksDelete = 'employee.stocks.delete',
  StocksBarcodePreviews = 'employee.stocks.barcode-previews',
  StockItemsPage = 'employee.stock-items.page',
  StockItemsList = 'employee.stock-items.list',
  StockItemsView = 'employee.stock-items.view',
  StockAdjustmentsPage = 'employee.stock-adjustments.page',
  StockAdjustmentsList = 'employee.stock-adjustments.list',
  StockAdjustmentsView = 'employee.stock-adjustments.view',
  StockAdjustmentsCreate = 'employee.stock-adjustments.create',
  StockAdjustmentsUpdate = 'employee.stock-adjustments.update',
  StockAdjustmentsDelete = 'employee.stock-adjustments.delete',
  StockTransfersPage = 'employee.stock-transfers.page',
  StockTransfersList = 'employee.stock-transfers.list',
  StockTransfersView = 'employee.stock-transfers.view',
  StockTransfersCreate = 'employee.stock-transfers.create',
  StockTransfersUpdate = 'employee.stock-transfers.update',
  StockTransfersDelete = 'employee.stock-transfers.delete',
  StockGroupingsPage = 'employee.stock-groupings.page',
  StockGroupingsList = 'employee.stock-groupings.list',
  StockGroupingsView = 'employee.stock-groupings.view',
  StockGroupingsCreate = 'employee.stock-groupings.create',
  StockGroupingsUpdate = 'employee.stock-groupings.update',
  StockGroupingsDelete = 'employee.stock-groupings.delete',
  StockUsagesPage = 'employee.stock-usages.page',
  StockUsagesList = 'employee.stock-usages.list',
  StockUsagesView = 'employee.stock-usages.view',
  StockUsagesCreate = 'employee.stock-usages.create',
  StockUsagesUpdate = 'employee.stock-usages.update',
  StockUsagesDelete = 'employee.stock-usages.delete',
  NoAuthorization = 'noauth',
}
