import { IColumn } from 'components/elements/table';
import { camelize } from 'humps';
import React from 'react';

import { CellComponent } from './cell-component';
import { PivotData, PivotTableProps } from './pivot-table';
import ScrollablePivotTable from './scrollable-pivot-table';

interface Props<T extends PivotData> extends PivotTableProps<T> {
  fetchNextPage?: () => void;
  isLoading?: boolean;
  onRenderHeader?: React.ReactNode;
  headerHeight?: number;
}
const initialState = {
  data: [],
  keyMap: [],
};

export default function ReportContainer<T extends PivotData>(props: Props<T>) {
  const { queryResultData: result, isLoading, fetchNextPage } = props;

  const [lastScroll, setLastScroll] = React.useState(0);
  const [isScrollToNext, setIsScrollToNext] = React.useState(false);
  const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(
    null,
  );
  const [tableContainerRef, setTableContainerRef] =
    React.useState<HTMLDivElement | null>(null);
  const [tableRef, setTableRef] = React.useState<HTMLTableElement | null>(null);
  const exportTableRef = React.useRef<HTMLDivElement | null>(null);

  const prevRef = React.useRef<{
    data: any[];
    keyMap: any[];
    columns: any[];
    pivot: any;
  }>({
    ...initialState,
    columns: [],
    pivot: undefined,
  });
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [isResizing, setIsResizing] = React.useState(false);

  // const { setPage } = header;

  const columns = React.useMemo(() => {
    if (!result?.pivot) {
      return [];
    }
    if (!Array.isArray(result.pivot)) {
      const cols: IColumn<any>[] = result.pivot.value.rows.map((row) => {
        return {
          header: row.label,
          accessorKey: !row.array_accessor
            ? camelize(row.name)
            : camelize(row.array_accessor),
          objectAccessor: row.name,
        };
      });

      return cols.concat(
        result.pivot.value.aggregates.map((aggr, index) => ({
          header: aggr.label,
          accessorKey: !aggr.array_accessor
            ? camelize(aggr.name)
            : camelize(aggr.array_accessor),
          link: aggr.link && camelize(aggr.link),
          badge: aggr.badge && camelize(aggr.badge),
          arrayAccessor: aggr.array_accessor && camelize(aggr.array_accessor),

          valueFormat: aggr.format,
          cell: ({ getValue }) => {
            if (aggr.array_accessor) {
              return (getValue() as any[])
                ?.map((item) => item[aggr.name])
                .join(', ');
            }
            return CellComponent({
              format: aggr.format,
              value: getValue() as any,
            });
          },
        })),
      );
    }
    return [];
  }, [result]);

  React.useEffect(() => {
    if (!isLoading) {
      prevRef.current = { ...initialState, pivot: result?.pivot, columns };
    }
  }, [isLoading, columns, result?.pivot]);

  const meta = (result as any)?.meta;

  const onCallNextPage = React.useCallback(
    (isResizing?: boolean) => {
      if (containerRef && tableContainerRef && tableRef && !isScrollToNext) {
        const headerHeight = props.headerHeight || 0;
        const exportTableHeight = exportTableRef.current?.offsetHeight || 0;
        if (
          containerRef.offsetHeight - headerHeight - exportTableHeight - 48 >
            tableRef.offsetHeight &&
          meta &&
          meta?.currentPage < meta?.lastPage
        ) {
          setLastScroll(
            tableContainerRef.scrollHeight - tableContainerRef.clientHeight,
          );
          fetchNextPage?.();
        }
        if (isResizing !== undefined) {
          setIsResizing(isResizing);
        }
        setIsScrollToNext(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      containerRef,
      meta,
      tableContainerRef,
      tableRef,
      isScrollToNext,
      fetchNextPage,
    ],
  );

  React.useEffect(() => {
    if (initialLoad && !isLoading) {
      setInitialLoad(false);
    }
  }, [initialLoad, isLoading]);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && !isLoading) {
      window.addEventListener('resize', () => onCallNextPage(true), true);
      return () => {
        window.removeEventListener('resize', () => onCallNextPage(false), true);
      };
    }
  }, [isLoading, onCallNextPage]);

  React.useEffect(() => {
    if (!isResizing && !isLoading) {
      onCallNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    containerRef?.offsetHeight,
    tableRef?.offsetHeight,
    isResizing,
    isLoading,
  ]);

  React.useEffect(() => {
    if (!isLoading && isScrollToNext) {
      setIsScrollToNext(false);
    }
  }, [isLoading, isScrollToNext]);

  const [headerRef, setHeaderRef] = React.useState<
    HTMLDivElement | undefined
  >();

  return (
    <>
      {/* @ts-ignore */}
      <div ref={setHeaderRef}>{props.onRenderHeader}</div>
      <div
        ref={setContainerRef}
        style={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          marginTop: (props.headerHeight || 0) + (headerRef?.offsetHeight || 0),
        }}
      >
        <div
          style={{
            height: `calc(100vh - ${
              (props.headerHeight || 0) + (headerRef?.offsetHeight || 0) || 0
            })`,
            position: 'relative',
            width: '100vw',
          }}
        >
          {!initialLoad && isLoading && !result?.data ? (
            <ScrollablePivotTable
              columns={prevRef.current.columns}
              data={prevRef.current.data}
              pivot={prevRef.current.pivot}
              lastFocus={lastScroll}
              isLoading
              //@ts-ignore
              hiddenRows={props.hiddenRows}
            />
          ) : (
            <ScrollablePivotTable
              ref={setTableContainerRef}
              tableRef={setTableRef}
              columns={columns}
              data={result?.data}
              pivot={result?.pivot}
              lastFocus={lastScroll}
              //@ts-ignore
              renderAll
              hiddenRows={props.hiddenRows}
              onNextPage={(bottom) => {
                if (meta && meta?.currentPage < meta?.lastPage) {
                  setLastScroll(bottom);
                  fetchNextPage?.();
                  setIsScrollToNext(true);
                }
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}
