import { RedDotSVG } from 'common/assets';
import { AuthorizationRules } from 'common/constants/authorization-rules';
import { NavigationRoutes } from 'common/constants/navigation-routes';
import { layoutStyles } from 'common/layouts/styles.css';
import NavigationTab from 'components/common/navigation-tab';
import Header from 'components/widgets/header';
import SideNavigation from 'components/widgets/side-navigation/side-navigation';
import useDialog, { DialogProvider } from 'hooks/use-dialog';
import { DrawerProvider } from 'hooks/use-drawer';
import AttachmentListModal from 'modules/common/attachment-list-modal';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { compile } from 'path-to-regexp';
import * as React from 'react';

import NavigationContextMenu from './navigation-context-menu';
import { navigationStyle } from './style.css';
import styles from '../styles/Navigation.module.css';
const maxTabSizeVar = 20;

export interface Navigation {
  routeName: NavigationRoutes;
  key: string;
  isActive: boolean;
  params?: any;
  search?: any;
  isWarn: boolean;
}

export interface NavigationProps {
  navigation: Navigation;
}

interface NavgiationOptions {
  params?: any;
  search?: any;
}
interface NavigationState {
  navigate: (route: NavigationRoutes, options?: NavgiationOptions) => void;
  close: () => void;
  activeKey?: string;
  setNavigations?: React.Dispatch<
    React.SetStateAction<{
      navigations: Omit<Navigation, 'isActive'>[];
      activeKey?: string | undefined;
    }>
  >;
}

export interface Route {
  routeName?: NavigationRoutes;
  permission?: AuthorizationRules;
  component?: React.ComponentType<NavigationProps>;
  title: string;
  hide?: boolean;
  children?: Route[];
  leftIcon?: (color: string, size: number) => React.ReactNode;
}

type RoutesObj = { [key in NavigationRoutes]: Route };

export const NavigationContext = React.createContext<NavigationState>({
  navigate: () => {},
  close: () => {},
});

function RouteObjectMap(route: Route) {
  if (route.children) {
    let obj = {};

    route?.children
      ?.map((cur) => RouteObjectMap(cur))
      ?.forEach((res) => {
        obj = {
          ...obj,
          ...res,
        };
      });

    return obj;
  }

  return {
    [route.routeName!]: route,
  };
}

export type NavigationContextMenuPoint =
  | { x: number; y: number; key: string }
  | undefined;

export default function NavigationComponent(props: {
  routes: Route[];
  badges?: any;
}) {
  const { t } = useTranslation();
  const { routes, badges } = props;
  const { asPath, push } = useRouter();
  const [contextMenuPoint, setContextMenuPoint] =
    React.useState<NavigationContextMenuPoint>(undefined);
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const [{ navigations, activeKey }, setNavigations] = React.useState<{
    navigations: Omit<Navigation, 'isActive'>[];
    activeKey?: string;
  }>({ navigations: [] });
  const dialog = useDialog();

  const navigate = React.useCallback(
    (route: NavigationRoutes, options?: NavgiationOptions) => {
      setNavigations((prev) => {
        try {
          const key = compile(route)(options?.params);
          let matchedNav: boolean = false;
          const updatedNavigations = prev.navigations.map((n) => {
            if (n.key === key) {
              matchedNav = true;
              return {
                ...n,
                params: options?.params,
                search: options?.search,
              };
            }
            return n;
          });
          if (matchedNav) {
            return {
              navigations: updatedNavigations,
              activeKey: key,
            };
          }

          if (maxTabSizeVar && prev.navigations.length >= maxTabSizeVar) {
            alert(
              t('common:max_tab_message', {
                maxSize: maxTabSizeVar,
              }),
            );
            return prev;
          } else {
            return {
              navigations: prev.navigations.concat({
                key,
                routeName: route,
                params: options?.params,
                search: options?.search,
                isWarn: false,
              }),
              activeKey: key,
            };
          }
        } catch (e) {
          console.error(e);
          return prev;
        }
      });
    },
    [t],
  );

  const exec = React.useCallback(
    (path) => {
      dialog.showCustom({
        render: (close) => <AttachmentListModal onClose={close} path={path} />,
      });
    },
    [dialog],
  );

  React.useEffect(() => {
    if (asPath !== '/') {
      const temp = asPath.replace('/#', 'employee').split('/');
      if (temp.length === 2) {
        if (temp[1]?.includes('files')) {
          exec(temp[1]);
          push('/#');
        }
      }
      if (temp.length > 2) {
        // eslint-disable-next-line no-extra-boolean-cast
        if (!!temp[2]) {
          if (temp[2] !== 'create') {
            const idPath = temp[2];
            temp[2] = ':id';
            const deepPath = temp.join('/');
            navigate(deepPath as NavigationRoutes, { params: { id: idPath } });
          } else {
            navigate(temp.join('/') as NavigationRoutes);
          }
        } else {
          if (temp[2] === '') {
            temp.pop();
          }
          navigate(temp.join('/') as NavigationRoutes);
        }
        push('/#');
      }
    }
  }, [asPath, exec, navigate, push]);

  const closeWithKey = React.useCallback(
    (key: string | undefined = activeKey) => {
      let newActiveKey: string | undefined = undefined;
      setNavigations((prev) => ({
        navigations: prev.navigations.filter((nav, index) => {
          const whitelisted = nav.key !== key;
          if (!whitelisted) {
            newActiveKey =
              prev.navigations[index - 1]?.key ||
              prev.navigations[index + 1]?.key;
          }
          return whitelisted;
        }),
        activeKey: activeKey !== key ? activeKey : newActiveKey,
      }));
    },
    [activeKey],
  );

  const sensitiveCloseWithKey = React.useCallback(
    (key: string | undefined = activeKey) => {
      const isWarnCurrentNav = !!navigations.find((nav) => nav.key === key)
        ?.isWarn;

      if (isWarnCurrentNav) {
        dialog.showConfirmation({
          title: t('common:warning'),
          message: t('common:warning_close_this_tab'),
          negativeLabel: t('common:cancel'),
          positiveLabel: t('common:continue'),
          onPositiveAction: (dismiss) => {
            closeWithKey(key);
            dismiss();
          },
          onNegativeAction: (dismiss) => dismiss(),
        });
      } else {
        closeWithKey(key);
      }
    },
    [activeKey, closeWithKey, dialog, navigations, t],
  );

  const routesObj = React.useMemo<RoutesObj>(() => {
    let obj = {};

    routes
      .map((route) => RouteObjectMap(route))
      .forEach((res) => {
        obj = {
          ...obj,
          ...res,
        };
      });

    return obj as any;
  }, [routes]);

  const handleContextMenu = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string) => {
      e.preventDefault();
      setContextMenuPoint({ x: e.pageX, y: e.pageY, key });
    },
    [],
  );

  const handleCloseOtherTabs = React.useCallback(
    (key: string) => {
      const isHasWarn = navigations.some((nav) => nav.isWarn);
      const onClose = () => {
        setNavigations((prev) => ({
          navigations: prev.navigations.filter((nav, index) => {
            return nav.key === key;
          }),
          activeKey: key,
        }));
      };
      if (isHasWarn) {
        dialog.showConfirmation({
          title: t('common:warning'),
          message: t('common:warning_close_other_tab'),
          negativeLabel: t('common:cancel'),
          positiveLabel: t('common:continue'),
          onPositiveAction: (dismiss) => {
            dismiss();
            onClose();
          },
          onNegativeAction: (dismiss) => {
            dismiss();
          },
        });
      } else {
        onClose();
      }
    },
    [dialog, navigations, t],
  );

  const handleCloseAllTabs = React.useCallback(() => {
    const isHasWarn = navigations.some((nav) => nav.isWarn);
    const onClose = () => {
      setNavigations((prev) => ({ navigations: [], activeKey: '' }));
    };
    if (isHasWarn) {
      dialog.showConfirmation({
        title: t('common:warning'),
        message: t('common:warning_close_all_tab'),
        negativeLabel: t('common:cancel'),
        positiveLabel: t('common:continue'),
        onPositiveAction: (dismiss) => {
          dismiss();
          onClose();
        },
        onNegativeAction: (dismiss) => {
          dismiss();
        },
      });
    } else {
      onClose();
    }
  }, [dialog, navigations, t]);

  React.useEffect(() => {
    const isHasWarn = navigations.some((nav) => nav.isWarn);

    const handleTabClose = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return (event.returnValue = t('common:warning_close_all_tab'));
    };
    if (isHasWarn) {
      window.addEventListener('beforeunload', handleTabClose);
    }

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [navigations, t]);

  const renderer = React.useCallback(() => {
    const onRemove = (key: string, evt?: Event) => {
      if (evt) {
        evt.stopPropagation();
      }
      sensitiveCloseWithKey(key);
    };

    const data = navigations.map((navigation) => {
      const Component =
        routesObj[navigation.routeName]?.component || ((<></>) as any);
      // let title = navigation.key.split('/').join(' ');
      let title = '';
      const route = navigation.key
        .replace('employee/', '')
        .split('/')
        .map((item) => item.toLowerCase());
      const routeName = route[0];
      if (route.length === 2) {
        let extraText = '';
        if (route[1] === 'create') {
          extraText = t('common:creation');
        } else {
          extraText = t('common:show');
        }
        title = t(`navigation:${routeName}`, { extra: extraText });
      } else {
        title = t(`navigation:${routeName}`, { extra: '' });
      }
      const close = () => {
        closeWithKey(navigation.key);
      };

      // const hasID = !!navigation?.params?.id;

      // if (hasID) {
      //   title = title + ` ${t('common:detail').toLowerCase()}`;
      // }

      return {
        title: (
          <>
            <div
              className={navigationStyle.contextMenuTrigger}
              onContextMenu={(e) => handleContextMenu(e, navigation.key)}
            />
            {navigation.isWarn && (
              <>
                <RedDotSVG /> &nbsp;
              </>
            )}
            <span className={navigationStyle.label}>{title}</span>
          </>
        ),
        getContent: () => (
          <NavigationContext.Provider
            value={{ navigate, close, setNavigations }}
          >
            {/* <div
              style={{
                width: '100%',
                height: '100%',
                position: 'fixed',
                zIndex: 99,
                pointerEvents: 'none',
              }}
            /> */}
            <div className={navigationStyle.tabContent}>
              <Component
                navigation={{
                  ...navigation,
                  isActive: activeKey === navigation.key,
                }}
              />
            </div>
          </NavigationContext.Provider>
        ),
        key: navigation.key,
        className: {
          panel: styles.tabContent,
        },
      };
    });

    return (
      <NavigationTab
        items={data}
        onRemove={onRemove}
        activeKey={activeKey}
        onChange={(key) =>
          setNavigations((prev) => ({ ...prev, activeKey: key }))
        }
        containerClass={styles.content}
      />
    );
  }, [
    navigations,
    activeKey,
    sensitiveCloseWithKey,
    routesObj,
    t,
    closeWithKey,
    handleContextMenu,
    navigate,
  ]);

  const value = React.useMemo<NavigationState>(
    () => ({
      navigate,
      close: () => {},
      activeKey,
    }),
    [activeKey, navigate],
  );

  const _onCloseNav = React.useCallback(() => {
    setIsNavOpen(false);
  }, []);

  return (
    <NavigationContext.Provider value={value}>
      <NavigationContextMenu
        contextMenuPoint={contextMenuPoint}
        setContextMenuPoint={setContextMenuPoint}
        onCloseAllTabs={handleCloseAllTabs}
        onCloseOtherTabs={handleCloseOtherTabs}
        onCloseTab={closeWithKey}
      />

      <DrawerProvider>
        <DialogProvider>
          <div className={layoutStyles.layoutContainer}>
            <Header
              isNavOpen={isNavOpen}
              setIsNavOpen={setIsNavOpen}
              onCloseAllTabs={handleCloseAllTabs}
            />
            <div className={navigationStyle.mobileWrapper}>
              <SideNavigation
                routes={routes}
                setIsNavOpen={setIsNavOpen}
                isNavOpen={isNavOpen}
                badges={badges}
              />
              <div
                onClick={_onCloseNav}
                className={layoutStyles.overlayContainer({
                  isActive: isNavOpen,
                })}
              />
              <div className={layoutStyles.content}>
                <div className={layoutStyles.scrollArea}>{renderer()}</div>
              </div>
            </div>
          </div>
        </DialogProvider>
      </DrawerProvider>
    </NavigationContext.Provider>
  );
}
