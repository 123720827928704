import { AuthorizationRules } from 'common/constants/authorization-rules';
import { authSelector } from 'models/auth';
import * as React from 'react';
import { useSelector } from 'react-redux';

export function useAuthorization() {
  const permissions = useSelector(authSelector.permissionSelector);

  const can = React.useCallback(
    (rule: AuthorizationRules) =>
      rule === AuthorizationRules.NoAuthorization
        ? true
        : permissions?.includes(rule),
    [permissions],
  );

  return {
    can,
  };
}
