import { Menu as RawMenu, Modal } from '@mantine/core';
import { useGetMe } from 'api-hooks/auth';
import { ArrowRightIcon, ChevronDownIcon, EntityIcon } from 'common/assets';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { menuStyles } from './styles.css';
import Text from '../text';

const Menu = ({ onCloseAllTabs }: { onCloseAllTabs: () => void }) => {
  const { data } = useGetMe();
  const { entity, setEntity } = useEntity();
  const listItem = data?.data?.entities;
  const dialog = useDialog();
  const { t } = useTranslation();

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>, value) => {
      dialog.showCustom({
        render: (dismiss) => (
          <Modal
            opened
            onClose={dismiss}
            title={
              <Text textVariant="HeadingLarge">{t('modal:change_entity')}</Text>
            }
            size={500}
            style={{ textAlign: 'center' }}
          >
            <Separator direction="vertical" gap={32} />
            <div className={menuStyles.dialogUpperContainer}>
              <div>
                <EntityIcon size={32} color={colors.textLighter} />
                <Text textVariant="ButtonDefault">{entity?.name}</Text>
              </div>
              <Separator direction="horizontal" gap={32} />
              <ArrowRightIcon size={24} />
              <Separator direction="horizontal" gap={32} />
              <div>
                <EntityIcon size={32} color={colors.textLighter} />
                <Text textVariant="ButtonDefault">{value.name}</Text>
              </div>
            </div>
            <Separator direction="vertical" gap={32} />
            <Text textVariant="BodyDefault">
              {t('modal:change_entity_confirmation')}
            </Text>
            <Separator direction="vertical" gap={32} />
            <div className={moduleStyles.fullContainer}>
              <Button
                onClick={() => {
                  setEntity(value);
                  dismiss();
                  onCloseAllTabs();
                }}
                className={moduleStyles.buttonDialog}
              >
                {t('common:confirm')}
              </Button>
              <Separator direction="vertical" gap={16} />

              <Button
                onClick={dismiss}
                variant="tertiary"
                className={moduleStyles.buttonDialog}
              >
                <Text color={colors.textLight} textVariant="ButtonDefault">
                  {t('common:cancel')}
                </Text>
              </Button>
            </div>
          </Modal>
        ),
      });
    },
    [dialog, entity?.name, onCloseAllTabs, setEntity, t],
  );
  React.useEffect(() => {
    if (!entity?.id && listItem?.length) {
      setEntity({ ...listItem[0] });
    }
  }, [entity, listItem, setEntity]);

  return (
    <RawMenu position="bottom-end" offset={20}>
      <RawMenu.Target>
        <div>
          <Button
            variant="tertiary"
            leftIcon={(size) => <EntityIcon size={size} color={colors.black} />}
            rightIcon={() => <ChevronDownIcon size={12} color={colors.black} />}
            className={menuStyles.button}
          >
            {listItem?.find((item) => item.id === entity?.id)?.name}
          </Button>
        </div>
      </RawMenu.Target>
      <RawMenu.Dropdown className={menuStyles.container}>
        {listItem?.map(({ id, name, ...rest }) => (
          <RawMenu.Item
            onClick={(e) => handleClick(e, { id, name, ...rest })}
            key={id}
            className={
              id === entity?.id
                ? menuStyles.listStyle['active']
                : menuStyles.listStyle['inactive']
            }
            disabled={id === entity?.id}
          >
            {name}
          </RawMenu.Item>
        ))}
      </RawMenu.Dropdown>
    </RawMenu>
  );
};

export default Menu;
