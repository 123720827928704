import { Modal } from '@mantine/core';
import { UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import { DownloadIcon } from 'common/assets';
import { AuthorizationRules } from 'common/constants';
import { downloadContent } from 'common/helpers/common';
import { getFormData } from 'common/helpers/form-data';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import toApiError from 'common/repositories/to-api-error';
import { capitalize } from 'common/utils/string';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import { useAuthorization } from 'hooks/use-authorization';
import { useEntity } from 'hooks/use-entities';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import { Translate } from 'next-translate';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface Props {
  onClose: () => void;
  useImport: (
    options?: UseMutationOptions<any, unknown, void, unknown> | undefined,
  ) => UseMutationResult<any>;
  useImportTemplate: () => UseMutationResult<any>;
  getKeys: (input?: any) => string[];
  t: Translate;
  name: string;
  rule: keyof typeof AuthorizationRules;
}

type ImportFormType = {
  file: any;
  fileUrl: string;
};

export default function ImportModal(props: Props) {
  const { onClose, t, useImport, useImportTemplate, getKeys, name, rule } =
    props;
  const { entity } = useEntity();
  const { can } = useAuthorization();
  const { mutateAsync, isLoading } = useImport();
  const { mutateAsync: mutateTemplate, isLoading: isDownloadLoad } =
    useImportTemplate();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      fileUrl: Yup.string().strip(true),
      file: Yup.mixed().required(),
    }),
  );

  const intialValues = React.useMemo<ImportFormType>(
    () => ({
      fileUrl: '',
      file: {},
    }),
    [],
  );

  const methods = useForm<ImportFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values: ImportFormType) => {
      try {
        const input = {
          ...values,
          entityId: entity?.id!,
        };
        const res = await mutateAsync(getFormData(input, []));
        queryClient.refetchQueries([getKeys()[0]]);
        notification.success({ message: res?.message });
        props.onClose();
      } catch (e) {
        const error = await toApiError(e as Error);
        notification.error({ message: error?.message });
      }
    },
    [entity?.id, getKeys, mutateAsync, props],
  );

  const handleDownloadTemplate = React.useCallback(async () => {
    try {
      //@ts-ignore
      const res = await mutateTemplate();
      res && downloadContent(`${capitalize(name)}ImportTemplate`, res);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  }, [name, mutateTemplate]);

  return (
    <Modal
      onClose={onClose}
      opened
      centered
      title={
        <Text textVariant="HeadingSmall">
          {t('modal:import_extra', {
            extra: name,
          })}
        </Text>
      }
    >
      <Separator gap={16} direction="vertical" />
      <div className={moduleStyles.fullContainer}>
        <Text textVariant="BodyDefault" align="center">
          {t('modal:import_extra_template_desc', {
            extra: name,
          })}
        </Text>
        {can(AuthorizationRules[rule]) && (
          <Separator gap={16} direction="vertical">
            <Button
              type="button"
              leftIcon={(size) => <DownloadIcon size={size} />}
              variant="secondary"
              className={moduleStyles.fullContainer}
              onClick={handleDownloadTemplate}
              loading={isDownloadLoad}
            >
              {t('modal:download_template')}
            </Button>
          </Separator>
        )}
      </div>
      <Separator gap={16} direction="vertical" />

      <Form methods={methods} onSubmit={onSubmit} defaultEditable>
        <Input
          type="file"
          name="file"
          previewName="fileUrl"
          label={t('modal:import_from')}
          required
          isFile
        />
        <Separator gap={16} direction="vertical" />
        <Button
          type="submit"
          className={moduleStyles.fullContainer}
          loading={isLoading}
        >
          {t('modal:import')}
        </Button>
      </Form>
    </Modal>
  );
}
