import { SelectItem } from '@mantine/core';
import { getBudgetingsKey, useDeleteBudgeting } from 'api-hooks/budgeting';
import { PlusIcon } from 'common/assets';
import { AuthorizationRules } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DeleteIconButton from 'components/common/delete-icon-button';
import Separator from 'components/common/separator';
import Badge from 'components/elements/badge';
import { Button } from 'components/elements/button';
import MultiSelect from 'components/elements/multi-select';
import Text from 'components/elements/text';
import TextInput from 'components/elements/text-input';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import useDrawer from 'hooks/use-drawer';
import useNavigation from 'hooks/use-navigation';
import { moduleStyles } from 'modules/styles.css';
import { budgetingStyles } from 'modules/transactions/budgeting/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import BudgetHistory from './budget-history';
import SetBudgetModal from './set-budget-modal';
import TransactionHistoryTable from './transaction-history-table';
import Progress from '../../progress';
import { BudgetingViewFormProps } from '../form-type';

export default function ViewBudgetingForm(props: BudgetingViewFormProps) {
  const { t } = useTranslation();
  const { budgeting } = props;
  const { can } = useAuthorization();
  const { close } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteBudgeting();
  const drawer = useDrawer();
  const dialog = useDialog();

  const brands = React.useMemo<SelectItem[] | undefined>(
    () =>
      budgeting?.brands?.map((item) => ({
        label: item.name,
        value: item.id,
        color: item.color,
      })),
    [budgeting?.brands],
  );

  const notifies = React.useMemo<SelectItem[] | undefined>(
    () =>
      budgeting?.employeeToNotifies?.map((item) => ({
        label: item.name,
        value: item.id,
        color: 'cyan',
      })),
    [budgeting?.employeeToNotifies],
  );

  const isDisabled = !budgeting?.activeBudgetingItem;

  const onSetBudget = React.useCallback(() => {
    drawer.showCustom({
      render: (onClose) => (
        <SetBudgetModal onClose={onClose} id={props.budgeting?.id!} />
      ),
      title: (
        <Text textVariant="HeadingSmall">{t('budgeting:set_budget')}</Text>
      ),
      size: 500,
      padding: 'md',
    });
  }, [drawer, props.budgeting?.id, t]);

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            budgetingId: budgeting!.id,
          });
          queryClient.refetchQueries([getBudgetingsKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [budgeting, close, deleteMutate, dialog, t]);

  return (
    <div>
      <div className={moduleStyles.topFormContainer}>
        <Text textVariant="HeadingSmall">{`${t('common:budgeting')} - ${
          budgeting?.name
        }`}</Text>
        <div className={moduleStyles.row()}>
          <BudgetHistory
            data={budgeting?.budgetingItems || []}
            coaIds={budgeting?.chartOfAccounts.map(({ id }) => id) || []}
            brandIds={budgeting?.brands.map(({ id }) => id) || []}
          />
          {can(AuthorizationRules.BudgetingsStoreBudgetingItem) && (
            <>
              <Separator direction="horizontal" gap={16} />

              <Button
                leftIcon={(size) => <PlusIcon size={size} />}
                onClick={onSetBudget}
              >
                {t('budgeting:set_budget')}
              </Button>
              <Separator direction="horizontal" gap={16} />
            </>
          )}
          {can(AuthorizationRules.BudgetingsDelete) && (
            <DeleteIconButton onClick={onDelete} />
          )}
        </div>
      </div>

      <Separator gap={16} direction="vertical" />
      <div className={moduleStyles.fullContainer}>
        <Progress
          variant="primary"
          disabled={isDisabled}
          budgetingId={budgeting?.id || ''}
          {...budgeting?.activeBudgetingItem!}
          employeeToNotifies={budgeting?.employeeToNotifies!}
          showDetails
        />
        <div className={moduleStyles.halfContainer}>
          <TextInput
            value={budgeting?.name}
            label={t('common:name')}
            withAsterisk
            readOnly
            disabled
          />
        </div>
        <div className={moduleStyles.sectionContainer}>
          <div className={moduleStyles.halfContainer}>
            <MultiSelect
              data={brands || []}
              value={budgeting?.brands?.map((item) => item.id)}
              label={t('common:brand')}
              disabled
              withAsterisk
            />
          </div>
          <div className={moduleStyles.halfContainer}>
            <MultiSelect
              data={notifies || []}
              value={budgeting?.employeeToNotifies?.map((item) => item.id)}
              label={t('budgeting:notify_to')}
              withAsterisk
              disabled
            />
          </div>
        </div>
        <Separator gap={16} direction="vertical" />
        <div>
          <Text textVariant="BodyBoldDefault">
            {t('budgeting:list_of_coa')}
          </Text>
          <div className={budgetingStyles.listCoaBadgeContainer}>
            {!!budgeting?.chartOfAccounts?.length &&
              budgeting?.chartOfAccounts?.map((item) => (
                <Badge key={item.id}>{item.name} </Badge>
              ))}
          </div>
        </div>
      </div>
      <Separator gap={48} direction="vertical" />
      <TransactionHistoryTable
        data={budgeting?.activeBudgetingItem?.budgetingUsages || []}
      />
    </div>
  );
}
