import { useChangePassword } from 'api-hooks/employee/mutation';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form/index';
import Text from 'components/elements/text';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

type FormType = {
  oldPassword: string;
  password: string;
  passwordConfirmation: string;
};

export default function ChangePassword({
  handleLogout,
}: {
  handleLogout: () => void;
}) {
  const { t } = useTranslation();
  const { close } = useNavigation();
  const { mutateAsync } = useChangePassword();

  const yupSchema = React.useMemo(
    () =>
      Yup.object()
        .shape({
          oldPassword: Yup.string().nullable().required(),
          password: Yup.string().nullable().required(),
          passwordConfirmation: Yup.string().nullable().required(),
        })
        .required(),
    [],
  );

  const resolver = useYupValidationResolver(yupSchema);

  const methods = useForm<FormType>({
    resolver,
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const result = await mutateAsync(values);
        close();
        notification.success({ message: result.message });
        handleLogout();
      } catch (error) {
        if (error.errors) {
          formSetErrors(error.errors, methods.setError);
        }
        notification.error({ message: error.message });
      }
    },
    [close, handleLogout, methods.setError, mutateAsync],
  );

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <div className={moduleStyles.fullContainer}>
        <Input
          type="password"
          name="oldPassword"
          label={t('common:current_password')}
          placeholder={t('common:current_password')}
          required
        />
        <Input
          type="password"
          name="password"
          label={t('common:password')}
          placeholder={t('common:password')}
          required
        />
        <Input
          type="password"
          name="passwordConfirmation"
          label={t('common:password_confirmation')}
          placeholder={t('common:password_confirmation')}
          required
        />
        <Separator direction="vertical" gap={32} />

        <Button type="submit" className={moduleStyles.buttonDialog}>
          {t('common:change_password')}
        </Button>
        <Separator direction="vertical" gap={16} />
        <Text
          textVariant="BodyDefault"
          sx={{ textAlign: 'center' }}
          color={colors.textLight}
        >
          {t('modal:change_password_information')}
        </Text>
      </div>
    </Form>
  );
}
