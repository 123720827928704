import {
  StockAdjustmentModel,
  StockAdjustmentMutationInput,
  stockAdjustmentKey,
  useDeleteStockAdjustment,
} from 'api-hooks/stock-adjustment';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import { initialDate } from 'common/utils/date';
import FormContent from 'components/common/form-content';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import WarehouseSelectInput from 'modules/master/warehouse/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import StockAdjustmentFormItem from './form-item';
import { StockAdjustmentFormType, formSchema } from './form-type';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  stockAdjustment?: StockAdjustmentModel;
  onSubmit: (
    input: StockAdjustmentMutationInput,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}

export default function StockAdjustmentForm(props: Props) {
  const { t } = useTranslation();
  const { can } = useAuthorization();

  const { entity } = useEntity();
  const { stockAdjustment, navigation } = props;
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteStockAdjustment();

  const dialog = useDialog();
  const resolver = useYupValidationResolver(formSchema());

  const initialValues = React.useMemo<StockAdjustmentFormType>(
    () => ({
      code: stockAdjustment?.code || '',
      description: stockAdjustment?.description || '',
      stockAdjustmentItems:
        stockAdjustment?.stockAdjustmentItems?.map((item) => ({
          stockId: item.stock.id,
          stockItemId: item.stockItem?.id || '',
          type: item.type,
          description: item?.stockItem?.description || '',
          files:
            item?.stockItem?.files?.map((file) => ({
              file: file.name,
              url: file.url,
            })) || [],
          merk: item?.stockItem?.merk || '',
          price: item.price || null,
          qty: item.qty || null,
        })) || [],
      transactionAt: stockAdjustment?.transactionAt || initialDate(),
      warehouseId: stockAdjustment?.warehouse?.id || '',
    }),
    [stockAdjustment],
  );

  const methods = useForm<StockAdjustmentFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = React.useCallback(
    async (values: StockAdjustmentFormType) => {
      try {
        const input: StockAdjustmentMutationInput = {
          ...values,
          stockAdjustmentItems: values.stockAdjustmentItems.map((item) => ({
            ...item,
            files: item.files?.map((file) => file.file),
          })) as any,
          entityId: entity?.id!,
        };
        await props.onSubmit(input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            stockAdjustmentId: stockAdjustment!.id,
          });
          queryClient.refetchQueries([stockAdjustmentKey.listKey]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, t, stockAdjustment]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!stockAdjustment}
      setNavigations={setNavigations}
      navigation={navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={stockAdjustment}
          title={
            stockAdjustment
              ? t('common:stock_adjustments')
              : t('common:create_extra', {
                  extra: t('common:stock_adjustments'),
                })
          }
          noDelete={!can(AuthorizationRules.StockAdjustmentsDelete)}
          noEdit={!can(AuthorizationRules.StockAdjustmentsUpdate)}
        />
        <div className={moduleStyles.fullContainer}>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <WarehouseSelectInput name="warehouseId" required />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="text"
                name="code"
                label={t('common:code')}
                placeholder={t('common:enter_extra', {
                  extra: t('common:code'),
                })}
                required
              />
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="date-time"
                name="transactionAt"
                required
                label={t('common:transaction_at')}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="text-area"
                name="description"
                placeholder={t('common:enter_extra', {
                  extra: t('common:description'),
                })}
                label={t('common:description')}
              />
            </div>
          </div>
          <StockAdjustmentFormItem />
        </div>
      </FormContent>
    </Form>
  );
}
