import { StockItemModel, StockItemUpdateBodyInput } from 'api-hooks/stock-item';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import colors from 'common/styles/colors';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import Checkbox from 'components/elements/checkbox';
import { Input } from 'components/elements/field';
import PreviewPicker from 'components/elements/file-picker/preview-picker';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import TextInput from 'components/elements/text-input';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { format } from 'date-fns';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import QRCode from 'react-qr-code';

import { StockItemFormType, formSchema } from './form-type';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  stockItem?: StockItemModel;
  onSubmit: (
    input: StockItemUpdateBodyInput,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}
export default function StockItemForm(props: Props) {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { stockItem, navigation } = props;
  const { setNavigations } = useNavigation();

  const resolver = useYupValidationResolver(formSchema());
  const initialValues = React.useMemo<StockItemFormType>(
    () => ({
      description: stockItem?.description || '',
      merk: stockItem?.merk || '',
    }),
    [stockItem?.description, stockItem?.merk],
  );
  const methods = useForm<StockItemFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        await props.onSubmit(values, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [methods, props],
  );

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={false}
      setNavigations={setNavigations}
      navigation={navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          data={stockItem}
          title={`${t('common:stock')} - ${stockItem?.name}`}
          noDelete
          noEdit={!can(AuthorizationRules.StocksUpdate)}
        />
        <div className={moduleStyles.fullContainer}>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <TextInput
                value={stockItem?.barcode}
                label={t('common:code')}
                withAsterisk
                disabled
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <TextInput
                value={format(stockItem?.createdAt!, 'dd MMM yyyy')}
                label={t('inventory:date_added')}
                withAsterisk
                disabled
              />
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <TextInput
                value={stockItem?.warehouse?.regional || ''}
                label={t('inventory:regional')}
                withAsterisk
                disabled
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <TextInput
                value={stockItem?.warehouse.name}
                label={t('common:warehouse')}
                withAsterisk
                disabled
              />
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <TextInput
                value={stockItem?.name || ''}
                label={t('common:name')}
                withAsterisk
                disabled
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                name="merk"
                type="text"
                label={t('inventory:merk')}
                placeholder={t('common:enter_extra', {
                  extra: t('inventory:merk'),
                })}
                required
              />
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                name="description"
                type="text-area"
                label={t('common:description')}
                placeholder={t('common:enter_extra', {
                  extra: t('common:description'),
                })}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Text textVariant="BodyBoldDefault">
                {t('inventory:qr_code')}
              </Text>
              <Separator gap={4} direction="vertical" />
              <QRCode value={stockItem?.barcode || ''} />
            </div>
          </div>
          <div className={moduleStyles.halfContainer}>
            <Checkbox
              checked={!!stockItem?.isUsed}
              label={t('inventory:is_used')}
              withMarginBottom
              disabled
            />
          </div>
          <div className={moduleStyles.halfContainer}>
            <Text textVariant="BodyBoldDefault" className={moduleStyles.row()}>
              {t('inventory:picture')}
              <Text textVariant="BodyBoldDefault" color={colors.textError}>
                *
              </Text>
            </Text>
            {!!stockItem?.files?.length &&
              stockItem.files.map((file) => (
                <PreviewPicker
                  disabled
                  preview={file.url}
                  isMultiple
                  onPicked={() => {}}
                />
              ))}
          </div>
        </div>
      </FormContent>
    </Form>
  );
}
