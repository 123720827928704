import {
  FeeOnEnum,
  TransferTypeEnum,
  getTransfersKey,
  useDeleteTransfer,
} from 'api-hooks/transfer';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import Text from 'components/elements/text';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import TransferAttachmentForm from './attachment-form';
import { TransferFormProps, TransferFormType, formSchema } from './form-type';
import TransactionDetailOthersForm from './transaction-detail-others-form';

const TransferForm = (props: TransferFormProps) => {
  const { t } = useTranslation();
  const { transfer } = props;
  const { entity } = useEntity();
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteTransfer();
  const { can } = useAuthorization();
  const dialog = useDialog();

  const resolver = useYupValidationResolver(formSchema());

  const intialValues = React.useMemo<TransferFormType>(() => {
    return {
      accountFromId: transfer?.accountFrom || '',
      accountFromRate: transfer?.accountFromRate || 0,
      accountFrom: {
        id: '',
        isBaseCurrency: false,
        balance: 0,
        currency: '',
      },
      accountToId: transfer?.accountTo || '',
      accountToRate: transfer?.accountToRate || 0,
      accountTo: {
        id: '',
        isBaseCurrency: false,
        balance: 0,
        currency: '',
      },
      description: transfer?.description || '',
      feeOn: transfer?.feeOn || FeeOnEnum.None,
      isDelay: transfer?.isDelay || false,
      brands: transfer?.brands?.map((label) => label.id) || [],
      transactionAt: transfer?.transactionAt || new Date(),
      transferAmount: transfer?.transferAmount || null,
      transferFee: transfer?.transferFee || null,
      transferFeeRate: transfer?.transferFeeRate || 0,
      receivedAmount: transfer?.receiveAmount || 0,
      hasAttachment: transfer?.hasAttachment || false,
      isAttachmentCompleted: transfer?.isAttachmentCompleted || false,
      files: transfer?.files || [],
      chartOfAccountFromId: transfer?.chartOfAccountFrom || '',
      chartOfAccountToId: transfer?.chartOfAccountTo || '',
      requestFormId: transfer?.requestForm?.id || '',
      type: transfer
        ? transfer?.type || TransferTypeEnum.others
        : TransferTypeEnum.top_up,
    };
  }, [transfer]);

  const methods = useForm<TransferFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const transferFee =
          values.transferFee === null ? 0 : values.transferFee;
        const _files = values.files?.map((item) => item.file);

        const _input = {
          ...values,
          entityId: entity?.id!,
          transferFee,
          files: _files,
          ...(values.accountFrom?.currencyName ===
            values.accountTo?.currencyName && {
            accountToRate: values.accountFromRate,
          }),
        };

        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            transferId: transfer!.id,
          });
          queryClient.refetchQueries([getTransfersKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, t, transfer]);

  const transferTypeRadioOption = [
    {
      label: t('pr:top_up'),
      value: TransferTypeEnum.top_up,
    },
    {
      label: t('pr:others'),
      value: TransferTypeEnum.others,
    },
  ];

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!transfer}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={transfer}
          title={
            transfer
              ? `${t('common:transfer')}`
              : t('common:create_extra', { extra: t('common:transfer') })
          }
          noDelete={
            !!transfer?.isTransactionClosed ||
            !can(AuthorizationRules.TransfersDelete)
          }
          noEdit={
            !!transfer?.isTransactionClosed ||
            !can(AuthorizationRules.TransfersUpdate)
          }
        />
        <Text textVariant="HeadingSmall">
          {t('transfer:transaction_details')}
        </Text>
        <Separator gap={20} direction="vertical" />
        <div className={moduleStyles.halfContainer}>
          <Input
            type="radio-group"
            data={transferTypeRadioOption}
            name="type"
            label={t('pr:request_type')}
            withAsterisk
          />
        </div>

        <TransactionDetailOthersForm
          hasValue={!!transfer}
          status={transfer?.status}
          receivedAmount={transfer?.receiveAmount}
          receivedAt={transfer?.receiveAt}
          code={transfer?.code}
        />
        <TransferAttachmentForm />
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
};

export default TransferForm;
