import { Popover } from '@mantine/core';
import { MoreIcon } from 'common/assets';
import Separator from 'components/common/separator';
import React from 'react';

import { popoverButtonStyles } from './styles.css';
import ActionIcon from '../action';
import Button from '../default';

interface Props {
  component: React.ReactNode[];
  buttonVariant?: 'primary' | 'secondary' | 'tertiary';
  label?: string;
  icon?: (size: number) => React.ReactNode;
  width?: number;
}

export default function PopoverButton(props: Props) {
  const { buttonVariant, label, component } = props;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <>
      <Popover
        position="bottom"
        shadow="md"
        opened={isOpen}
        onChange={setIsOpen}
        withinPortal
        width={props.width}
      >
        <Popover.Target>
          {label ? (
            <Button
              variant={buttonVariant}
              onClick={() => setIsOpen(true)}
              rightIcon={props.icon}
            >
              {label}
            </Button>
          ) : (
            <ActionIcon onClick={() => setIsOpen(true)} variant="secondary">
              {(size) => <MoreIcon size={size} />}
            </ActionIcon>
          )}
        </Popover.Target>
        <Popover.Dropdown
          className={popoverButtonStyles.showMoreContainer}
          onClick={() => setIsOpen(false)}
        >
          {component}
        </Popover.Dropdown>
      </Popover>
      <Separator gap={16} direction="horizontal" />
    </>
  );
}
