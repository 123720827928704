import { CellContext } from '@tanstack/react-table';
import { ApprovalHistory } from 'api-hooks/approval';
import { removeUnderscore } from 'common/utils/string';
import Separator from 'components/common/separator';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { format } from 'date-fns';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const generateNullableDate = ({
  getValue,
}: CellContext<ApprovalHistory, unknown>) => {
  const val = getValue();
  return val ? format(val as Date, 'dd MMM yyyy, HH:mm') : '-';
};

const generateNullable = ({
  getValue,
}: CellContext<ApprovalHistory, unknown>) => {
  const val = getValue();
  return val || '-';
};

export default function ApprovalHistoryTable({
  data,
  user,
}: {
  data: ApprovalHistory[];
  user: string;
}) {
  const { t } = useTranslation();

  const _columns = React.useMemo<IColumn<ApprovalHistory>[]>(
    () => [
      {
        header: t('common:date'),
        accessorKey: 'actionAt',
        minSize: 200,
        cell: generateNullableDate,
      },
      {
        header: t('approval:process'),
        accessorKey: 'process',
        minSize: 200,
        cell: ({ getValue }) => {
          return removeUnderscore((getValue() as string) || '-');
        },
      },

      {
        header: t('common:status'),
        accessorKey: 'status',
        minSize: 200,
        cell: ({ getValue }) => {
          return removeUnderscore((getValue() as string) || '-');
        },
      },
      {
        header: t('common:user'),
        accessorKey: 'user',
        minSize: 200,
        cell: () => user,
      },
      {
        header: t('pr:approved_by'),
        accessorKey: 'employee.name',
        minSize: 200,
        cell: generateNullable,
      },
      {
        header: t('common:notes'),
        accessorKey: 'description',
        minSize: 300,
        sortName: 'description',
        cell: generateNullable,
      },
    ],
    [t, user],
  );
  return (
    <div className={moduleStyles.tableContainer}>
      <Text textVariant="HeadingSmall">{t('approval:pr_history')}</Text>
      <Separator direction="vertical" gap={28} />
      <TableComponent columns={_columns} data={data || []} />
    </div>
  );
}
