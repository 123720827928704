import { FeeOnEnum, useDeleteTransfer } from 'api-hooks/transfer';
import { getTransferApprovalsKey } from 'api-hooks/transfer-approval';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import { initialDate } from 'common/utils/date';
import DeleteIconButton from 'components/common/delete-icon-button';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import SplitButton from 'components/elements/button/split';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { TransferApprovalFormProps } from './form-type';
import TransferApprovalInformationForm from './transfer-approval-information-form';

const TransferApprovalForm = (props: TransferApprovalFormProps) => {
  const { t } = useTranslation();
  const { transfer } = props;
  const dialog = useDialog();
  const { close } = useNavigation();
  const { can } = useAuthorization();

  const { mutateAsync: deleteMutate } = useDeleteTransfer();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      accountFromId: Yup.string().nullable().required(),
      accountToId: Yup.string().nullable().required(),
      accountToRate: Yup.string().when(['accountTo'], {
        is: (val) => !val.isBaseCurrency,
        then: Yup.string().nullable().required(),
      }),
      accountFromRate: Yup.string().when(['accountFrom'], {
        is: (val) => !val.isBaseCurrency,
        then: Yup.string().nullable().required(),
      }),
      feeOn: Yup.string().nullable().required(),
      isDelay: Yup.boolean(),
      transferFeeRate: Yup.number().when(['feeOn'], {
        is: (feeOn) => feeOn === 'sender' || feeOn === 'receiver',
        then: Yup.number().min(0).required(),
      }),
      brands: Yup.array(Yup.string()),
      transactionAt: Yup.date().nullable().required(),
      receiveAt: Yup.date().nullable().required(),
      transferAmount: Yup.number().min(0),
      transferFee: Yup.number().when(['feeOn'], {
        is: (feeOn) => feeOn !== 'none',
        then: Yup.number().min(0).required(),
      }),
    }),
  );

  const intialValues = React.useMemo<any>(() => {
    return {
      receiveAt: new Date(),
      accountFromId: transfer?.accountFrom || '',
      accountFromRate: transfer?.accountFromRate || 0,
      accountFrom: {
        id: '',
        isBaseCurrency: false,
        balance: 0,
        currency: '',
      },
      accountToId: transfer?.accountTo || '',
      accountToRate: transfer?.accountToRate || 0,
      accountTo: {
        id: '',
        isBaseCurrency: false,
        balance: 0,
        currency: '',
      },
      description: transfer?.description || '',
      feeOn: transfer?.feeOn || FeeOnEnum.None,
      isDelay: transfer?.isDelay || false,
      brands: transfer?.brands?.map((label) => label.id) || [],
      transactionAt: transfer?.transactionAt || initialDate(),
      transferAmount: transfer?.transferAmount || 0,
      transferFee: transfer?.transferFee || 0,
      transferFeeRate: transfer?.transferFeeRate || 0,
      chartOfAccountFromId: transfer?.chartOfAccountFrom || '',
      chartOfAccountToId: transfer?.chartOfAccountTo || '',
    };
  }, [transfer]);

  const methods = useForm<any>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const _input = { receiveAt: values.receiveAt };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            transferId: transfer!.id,
          });
          queryClient.refetchQueries([getTransferApprovalsKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, t, transfer]);

  const menus = React.useCallback(() => {
    const temp: any[] = [];

    can(AuthorizationRules.TransfersDelete) &&
      temp.push({ key: t('common:delete'), onClick: () => onDelete() });
    return temp;
  }, [can, onDelete, t]);

  return (
    <Form methods={methods} onSubmit={onSubmit} defaultEditable>
      <FormContent>
        {props.renderError}
        <FormHeader
          noDelete
          onDelete={onDelete}
          data={transfer}
          noCancel
          onRenderRightComponent={
            <>
              <div
                className={
                  menus().length ||
                  !can(AuthorizationRules.TransferApprovalsApprove)
                    ? moduleStyles.viewScreenLargeFlex
                    : ''
                }
              >
                {can(AuthorizationRules.TransfersDelete) && (
                  <div
                    className={moduleStyles.actionContainer}
                    style={{
                      paddingRight: '16px',
                      borderRight: `1px solid ${colors.borderLight}`,
                    }}
                  >
                    <DeleteIconButton onClick={onDelete} />
                  </div>
                )}
                {can(AuthorizationRules.TransferApprovalsApprove) && (
                  <Input type="submit" text={t('common:approve')} />
                )}
              </div>
              {can(AuthorizationRules.TransferApprovalsApprove) &&
                !!menus().length && (
                  <div className={moduleStyles.viewScreenSmall}>
                    <SplitButton
                      button={{
                        text: t('common:approve'),
                        onClick: onSubmit,
                      }}
                      rightButton={{
                        menus: menus(),
                      }}
                    />
                  </div>
                )}
            </>
          }
          noSubmit
          title={
            transfer
              ? `${t('common:transfer')}  `
              : t('common:create_extra', { extra: t('common:transfer') })
          }
        />
        <TransferApprovalInformationForm
          receivedAmount={transfer?.receiveAmount!}
        />
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
};

export default TransferApprovalForm;
