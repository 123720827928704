import classNames from 'classnames';
import React from 'react';
import ReactResizeDetector from 'react-resize-detector';

import styles from './styles.module.css';

interface State {
  tabDimension: number[];
  totalTabsWidth: number;
}

type Item = {
  key: string;
  title: string | React.ReactNode;
  getContent: () => React.ReactNode;
  className?: {
    title?: string;
    tab?: string;
    panel?: string;
    icon?: string;
  };
};

interface Props {
  items: Item[];
  onRemove: (key: string) => void;
  onChange: (key: string) => void;
  activeKey?: string;
  containerClass?: any;
}

interface SeparatorProps {
  height: number;
  isActive: boolean;
}

interface ShowMoreProps {
  items: Item[];
  activeKey: string;
  onChange: (key: string) => void;
  onRemove: (key: string) => void;
  init: () => void;
}

interface TabItemProps {
  onHandleChange: (key: string) => void;
  onHandleRemove: (key: string) => void;
  isActive: boolean;
  item: Item;
}

function Separator(props: SeparatorProps) {
  const { height, isActive } = props;
  return (
    <div style={{ height }} className={styles.tab__separator}>
      <div
        style={{
          height: isActive ? height : 0.6 * height!,
        }}
        className={styles[`separator--${isActive ? 'inactive' : 'active'}`]}
      />
    </div>
  );
}

function CloseIcon(props: any) {
  const { size = 12 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.854 3.146a.5.5 0 010 .708l-9 9a.5.5 0 01-.708-.708l9-9a.5.5 0 01.708 0z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.146 3.146a.5.5 0 01.708 0l9 9a.5.5 0 01-.708.708l-9-9a.5.5 0 010-.708z"
        clipRule="evenodd"
      />
    </svg>
  );
}

const TabItem = React.forwardRef<HTMLDivElement, TabItemProps>((props, ref) => {
  const { item, onHandleChange, isActive, onHandleRemove } = props;
  return (
    <div
      key={item.key}
      onClick={() => onHandleChange(item.key)}
      ref={ref}
      onKeyDown={(e) => {
        e.key === 'Enter' && onHandleChange(item.key);
      }}
      tabIndex={0}
      // tabIndex={isActive ? 0 : -1}
      aria-selected={isActive}
      role="tab"
      className={classNames(
        styles[`tabs__tab--${isActive ? 'active' : 'inactive'}`],
        styles.tabs__tab,
        item.className?.tab,
      )}
    >
      <span
        className={classNames(
          styles.tab_item_title,
          item.className?.panel,
          item.className?.title,
        )}
        style={{ backgroundColor: 'transparent' }}
      >
        {item.title}
      </span>
      <div
        onClick={(e) => {
          e.stopPropagation();
          onHandleRemove(item.key);
        }}
        className={classNames(styles.tabs__button, item.className?.icon)}
      >
        <CloseIcon />
      </div>
    </div>
  );
});

function ShowMoreIcon(props: any) {
  const { size = 24 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 256 256"
    >
      <path
        d="M144 128a16 16 0 11-16-16 16 16 0 0116 16zm-84-16a16 16 0 1016 16 16 16 0 00-16-16zm136 0a16 16 0 1016 16 16 16 0 00-16-16z"
        fill="currentColor"
      />
    </svg>
  );
}

function ShowMore(props: ShowMoreProps) {
  const { activeKey, items, onRemove, onChange, init } = props;
  return (
    <div
      className={classNames(
        styles.tabs__container,
        styles.tab__showmore_container,
      )}
    >
      {items.map((item) => {
        const isActive = activeKey === item.key;
        return (
          <TabItem
            key={item.key}
            onHandleChange={(key) => {
              init();
              onChange?.(key);
            }}
            isActive={isActive}
            item={{
              ...item,
              className: {
                ...item.className,
                tab: classNames(styles.tab__showmore_item, item.className?.tab),
              },
            }}
            onHandleRemove={onRemove}
          />
        );
      })}
    </div>
  );
}

export default function NavigationTab(props: Props) {
  const currentRef = React.useRef<HTMLDivElement | null>(null);
  const { items, onRemove, activeKey, containerClass, onChange } = props;
  const [isShowMore, setIsShowMore] = React.useState<boolean>(false);
  const [{ tabDimension, totalTabsWidth }, setState] = React.useState<State>({
    tabDimension: [],
    totalTabsWidth: 0,
  });
  const prevTabLength = React.useRef(0);
  const tabRefs = React.useRef<HTMLDivElement[]>([]);

  const init = React.useCallback(() => {
    let tabsTotalWidth = 0;
    const tabDimension: number[] = [];
    tabRefs?.current?.forEach((value) => {
      const width = value.offsetWidth;
      tabsTotalWidth += Number(width);
      tabDimension.push(width);
    });
    setState({
      tabDimension,
      totalTabsWidth: tabsTotalWidth,
    });
  }, []);

  React.useEffect(() => {
    tabRefs.current = tabRefs.current.slice(0, items.length);
  }, [items, tabRefs]);

  React.useEffect(() => {
    init();
  }, [init, items]);

  const getTabs = React.useCallback(
    (width) => {
      const availableWidth = width - (totalTabsWidth > width ? 10 : 0);
      const visible: any[] = [];
      const invisible: any[] = [];
      let usedWidth = 48;
      if (prevTabLength.current !== items.length) {
        setIsShowMore(false);
      }
      prevTabLength.current = items.length;
      for (let i = 0; i < items.length; i++) {
        const prevUsedWidth = usedWidth;
        usedWidth += tabDimension[i];
        if (availableWidth - prevUsedWidth > tabDimension[i]) {
          visible.push(items[i]);
        } else {
          invisible.push(items[i]);
        }
      }
      return { visible, invisible };
    },
    [items, prevTabLength, tabDimension, totalTabsWidth],
  );

  const onHandleChange = React.useCallback(
    (key: string) => {
      init();
      onChange(key);
    },
    [init, onChange],
  );

  const onHandleRemove = React.useCallback(
    (key: string) => {
      init();
      onRemove(key);
    },
    [init, onRemove],
  );

  return (
    <div className={containerClass} onClick={() => setIsShowMore(false)}>
      <ReactResizeDetector handleHeight handleWidth>
        {({ width, height }) => {
          const { visible, invisible } = getTabs(width);
          const visibleKey = visible.map((item) => item.key);
          const invisibleKey = invisible.map((item) => item.key);
          return (
            <div
              className={styles.tabs__container}
              ref={currentRef}
              style={{ position: 'relative' }}
            >
              {items.map((item, index, array) => {
                const isActive = activeKey === item.key;
                if (!visibleKey.includes(item.key)) {
                  return (
                    <TabItem
                      ref={(ref) => ((tabRefs.current[index] as any) = ref)}
                      onHandleChange={onHandleChange}
                      onHandleRemove={onHandleRemove}
                      item={{
                        ...item,
                        className: {
                          ...item.className,
                          tab: classNames(
                            styles.tab__invisibleTab,
                            item.className?.tab,
                          ),
                        },
                      }}
                      isActive={isActive}
                    />
                  );
                }
                return (
                  <>
                    <TabItem
                      ref={(ref) => ((tabRefs.current[index] as any) = ref)}
                      onHandleChange={onHandleChange}
                      onHandleRemove={onHandleRemove}
                      item={item}
                      isActive={isActive}
                    />
                    {index !== array.length - 1 &&
                      index !== visibleKey?.length - 1 && (
                        <Separator height={height || 0} isActive={false} />
                      )}
                  </>
                );
              })}

              {!!invisible.length && (
                <div
                  className={classNames(
                    styles[
                      `tabs__tab--${
                        invisibleKey.includes(activeKey) ? 'active' : 'inactive'
                      }`
                    ],
                    styles.tab__showmore,
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsShowMore((prev) => !prev);
                  }}
                >
                  <ShowMoreIcon />
                  {isShowMore && (
                    <ShowMore
                      init={init}
                      activeKey={activeKey!}
                      items={invisible}
                      onChange={onHandleChange}
                      onRemove={onHandleRemove}
                    />
                  )}
                </div>
              )}
            </div>
          );
        }}
      </ReactResizeDetector>

      {items.map((item) => (
        <div
          className={classNames(item?.className?.panel, containerClass) || ''}
          role="tabpanel"
          key={`${item.key}-tabpanel-item`}
          {...(activeKey !== item.key && { style: { display: 'none' } })}
        >
          {item.getContent()}
        </div>
      ))}
    </div>
  );
}
