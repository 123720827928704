import { BrandLiteModel } from 'api-hooks/brand';
import { CommonSimpleModel, getIndexInput } from 'api-hooks/common/model';
import { DivisionModel } from 'api-hooks/division';
import { Expose, Type } from 'class-transformer';

// Enum
export enum ApprovalMasterTypeEnum {
  head_division = 'head_division',
  finance = 'finance',
}

// Model
export class CarbonCopiesModel {
  id: string;
  employee: CommonSimpleModel;
}

export class DivisionApprovalModel {
  id: string;
  division: DivisionModel;

  @Expose({ name: 'carbon_copies' })
  carbonCopies: CarbonCopiesModel[];

  @Type(() => BrandLiteModel)
  brands: BrandLiteModel[];
}

export class ApprovalDetailsModel {
  id: string;
  order: number;
  employee: CommonSimpleModel;
}

export class ApprovalDivisionModel extends DivisionApprovalModel {
  details: ApprovalDetailsModel[];
}

export class ApprovalMasterFinanceSimpleModel {
  id: string;

  @Expose({ name: 'lower_bound' })
  @Type(() => Number)
  lowerBound: number;

  @Expose({ name: 'upper_bound' })
  @Type(() => Number)
  upperBound: number;
}

export class ApprovalMasterFinanceModel {
  id: string;
  details: ApprovalDetailsModel[];

  @Expose({ name: 'carbon_copies' })
  carbonCopies: CarbonCopiesModel[];

  @Expose({ name: 'payment_employee' })
  paymentEmployee: CommonSimpleModel;

  @Expose({ name: 'lower_bound' })
  @Type(() => Number)
  lowerBound: number;

  @Expose({ name: 'upper_bound' })
  @Type(() => Number)
  upperBound: number;
}

export class ApprovalMasterLiteModel {
  id: string;
  type: string;

  divisions: { id: string; division: CommonSimpleModel }[];

  @Type(() => ApprovalMasterFinanceSimpleModel)
  finances: ApprovalMasterFinanceSimpleModel[];
}

export class ApprovalMasterModel {
  id: string;
  type: string;

  @Expose({ name: 'chief_employee' })
  chiefEmployee?: CommonSimpleModel;

  @Type(() => ApprovalDivisionModel)
  divisions: ApprovalDivisionModel[];

  @Type(() => ApprovalMasterFinanceModel)
  finances: ApprovalMasterFinanceModel[];
}

// Input

export type getApprovalMastersInput = getIndexInput;

export type getApprovalMasterInput = {
  id: string;
};

export type DetailInput = {
  employeeId: string;
  order: number;
};

export type ApprovalDetailsInputModel = {
  divisionId: string;
  details: DetailInput[];
  carbonCopies: string[];
};

export type ApprovalFinancesInputModel = {
  paymentEmployeeId: string;
  lowerBound: number | null;
  upperBound: number | null;
  details: DetailInput[];
  carbonCopies: string[];
};

export type ApprovalMasterMutationInput = {
  entityId: string;
  type: string;
  chiefEmployeeId?: string;
  divisions?: ApprovalDetailsInputModel[];
  finances?: ApprovalFinancesInputModel[];
};

export type ApprovalMasterUpdateInput = {
  id: string;
  body: ApprovalMasterMutationInput;
};

export type ApprovalMasterDeleteInput = {
  id: string;
};
