import { RoleModel } from 'api-hooks/role';
import { UserIcon } from 'common/assets';
import colors from 'common/styles/colors';
import Badge from 'components/elements/badge';
import Text from 'components/elements/text';
import React from 'react';

import { styles } from './styles.css';

interface UserNavProps {
  user?: string;
  email?: string;
  position?: RoleModel[];
  type?: 'primary' | 'secondary';
}
const UserNav = (props: UserNavProps) => {
  const {
    user = 'User',
    email = '',
    position = [{ id: 1, name: 'admin' }],
    type = 'primary',
  } = props;
  return (
    <div className={styles.container[type]}>
      <div className={styles.innerContainer[type]}>
        <div className={styles.iconWrapper}>
          <UserIcon color={colors.productLight} size={20} />
        </div>
        <div className={styles.textWrapper[type]}>
          <Text textVariant="BodyBoldDefault">{user}</Text>
          <Text textVariant="BodySmaller" className={styles.emailText}>
            {email.length > 25 && type === 'primary'
              ? `${email.substring(0, 25)}...`
              : email}
          </Text>
        </div>
      </div>
      {type === 'primary' && (
        <div className={styles.badge}>
          {position.map((data) => (
            <Badge badgeType="tagging" key={data.id}>
              {data.name}
            </Badge>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserNav;
