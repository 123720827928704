import colors from 'common/styles/colors';
import Badge from 'components/elements/badge';
import Text from 'components/elements/text';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { budgetingStyles } from '../../styles.css';

interface Props {
  name: string;
  readOnly?: boolean;
  label?: string;
}
export default function ListOfCOAInput(props: Props) {
  const { name, label } = props;

  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({ control, name });

  const error = fieldState.error?.message;

  const onClear = React.useCallback(
    (_id: string) =>
      field.onChange(field.value?.filter(({ value }) => value !== _id)),
    [field],
  );

  return (
    <div>
      {!!label && <Text textVariant="BodyBoldDefault">{label}</Text>}
      <div className={budgetingStyles.listCoaBadgeContainer}>
        {!!field.value?.length &&
          field.value.map((item) => (
            <Badge onClear={() => onClear(item.value)}>{item.label} </Badge>
          ))}
      </div>
      {!!error && (
        <Text textVariant="BodySmaller" color={colors.textError}>
          {error}
        </Text>
      )}
    </div>
  );
}
