import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  ApprovalMasterLiteModel,
  ApprovalMasterModel,
  getApprovalMasterInput,
  getApprovalMastersInput,
} from './model';

export function useGetApprovalMasters(
  input?: getApprovalMastersInput,
  options?: UseQueryOptions<
    ExtendedApiResult<ApprovalMasterLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<ApprovalMasterLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getApprovalMasters', input?.params],
      () =>
        QueryFetchFunction({
          url: 'approval-masters',
          params: input?.params,
        }),
      options,
    ),
    ApprovalMasterLiteModel,
  );
}

export function useGetApprovalMaster(
  input?: getApprovalMasterInput,
  options?: UseQueryOptions<ApiResult<ApprovalMasterModel>, ApiError>,
): UseQueryResult<ApiResult<ApprovalMasterModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getApprovalMaster', input?.id],
      () =>
        QueryFetchFunction({
          url: `approval-masters/${input?.id}`,
        }),
      options,
    ),
    ApprovalMasterModel,
  );
}

export function getApprovalMastersKey(input?: getApprovalMastersInput) {
  return ['getApprovalMasters', input?.params];
}

export function getApprovalMasterKey(input?: getApprovalMasterInput) {
  return ['getApprovalMaster', input?.id];
}
