import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getStockAdjustmentInput,
  getStockAdjustmentsInput,
  StockAdjustmentLiteModel,
  StockAdjustmentModel,
} from './model';

export const stockAdjustmentKey = {
  listKey: 'getStockAdjustments',
  detailKey: 'getStockAdjustment',
  list(input?: getStockAdjustmentsInput) {
    return [stockAdjustmentKey.listKey, input?.params];
  },
  detail(input?: getStockAdjustmentInput) {
    return [stockAdjustmentKey.detailKey, input?.stockAdjustmentId];
  },
};

export function useGetStockAdjustments(
  input?: getStockAdjustmentsInput,
  options?: UseQueryOptions<
    ExtendedApiResult<StockAdjustmentLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<StockAdjustmentLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockAdjustmentKey.list(input),
      () =>
        QueryFetchFunction({
          url: 'stock-adjustments',
          params: input?.params,
        }),
      options,
    ),
    StockAdjustmentLiteModel,
  );
}

export function useGetStockAdjustment(
  input?: getStockAdjustmentInput,
  options?: UseQueryOptions<ApiResult<StockAdjustmentModel>, ApiError>,
): UseQueryResult<ApiResult<StockAdjustmentModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockAdjustmentKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `stock-adjustments/${input?.stockAdjustmentId}`,
        }),
      options,
    ),
    StockAdjustmentModel,
  );
}
