import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  StockTransferDeleteInput,
  StockTransferModel,
  StockTransferMutationInput,
  StockTransferUpdateInput,
} from './model';

export function useCreateStockTransfer(
  options?: UseMutationOptions<
    ApiResult<StockTransferModel>,
    ApiError,
    StockTransferMutationInput
  >,
): UseMutationResult<
  ApiResult<StockTransferModel>,
  ApiError,
  StockTransferMutationInput
> {
  return useMutation<
    ApiResult<StockTransferModel>,
    ApiError,
    StockTransferMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'stock-transfers',
      method: 'POST',
      classType: StockTransferModel,
      body,
    });
  }, options);
}

export function useUpdateStockTransfer(
  options?: UseMutationOptions<
    ApiResult<StockTransferModel>,
    ApiError,
    StockTransferUpdateInput
  >,
): UseMutationResult<
  ApiResult<StockTransferModel>,
  ApiError,
  StockTransferUpdateInput
> {
  return useMutation<
    ApiResult<StockTransferModel>,
    ApiError,
    StockTransferUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `stock-transfers/${body.stockTransferId}`,
      method: 'PUT',
      classType: StockTransferModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteStockTransfer(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    StockTransferDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, StockTransferDeleteInput> {
  return useMutation<MessageResult, ApiError, StockTransferDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `stock-transfers/${body.stockTransferId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
