import { PlusCircleIcon, TrashIcon } from 'common/assets';
import colors from 'common/styles/colors';
import HOCInput from 'components/common/hoc-input';
import { ActionIcon, Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import { FormContext } from 'components/elements/form';
import { OptionProps } from 'components/elements/select';
import AccountMultiSelectInput from 'modules/entity-settings/account/components/multi-select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React, { useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { AccountAccessFormType } from './form-type';

interface Props {
  entitiesSelectOptions: OptionProps[];
}

export default function AccountAccessItemForm(props: Props) {
  const { t } = useTranslation();
  const { entitiesSelectOptions } = props;
  const { editable } = useContext(FormContext);
  const { control, setValue } = useFormContext<AccountAccessFormType>();

  const { fields, append, remove } = useFieldArray({
    name: 'entities',
    control,
    keyName: 'customId',
  });

  return (
    <>
      {fields.map((entity, index) => (
        <div className={moduleStyles.boxContainer} key={entity.customId}>
          <div
            className={moduleStyles.row({ justify: 'between', align: 'start' })}
          >
            <div className={moduleStyles.halfContainer}>
              <Input
                type="select"
                name={`entities.${index}.id`}
                data={entitiesSelectOptions}
                label={t('common:entity')}
                placeholder={t('common:choose_extra', {
                  extra: t('common:entity'),
                })}
                required
              />
            </div>
            {editable && !!index && (
              <ActionIcon
                variant="transparent"
                error
                onClick={() => remove(index)}
              >
                {(size) => (
                  <TrashIcon size={size} color={colors.sentimentError} />
                )}
              </ActionIcon>
            )}
          </div>
          <div className={moduleStyles.halfContainer}>
            <HOCInput keys={[`entities.${index}`]}>
              {(values) => {
                const val = values[`entities.${index}`];
                return (
                  <AccountMultiSelectInput
                    name={`entities.${index}.accounts`}
                    entityId={val?.id}
                    disabled={!val.id}
                    required
                    isAllAccount={val?.isAllAccount}
                    onListChange={(_value) => {
                      setValue(`entities.${index}.accounts`, _value, {
                        shouldValidate: true,
                        shouldTouch: true,
                      });
                    }}
                    onAfterChange={() =>
                      setValue(`entities.${index}.isAllAccount`, false, {
                        shouldTouch: true,
                        shouldValidate: true,
                      })
                    }
                  />
                );
              }}
            </HOCInput>
            <Input
              type="checkbox"
              name={`entities.${index}.isAllAccount`}
              label={t('employee:access_all_account')}
              onAfterChange={(val) =>
                !val.target.checked &&
                setValue(`entities.${index}.accounts`, [], {
                  shouldValidate: true,
                  shouldTouch: true,
                })
              }
            />
          </div>
        </div>
      ))}

      {editable && (
        <Button
          variant="tertiary"
          leftIcon={(size) => <PlusCircleIcon size={size} />}
          onClick={() =>
            append({
              accounts: [],
              id: '',
              isAllAccount: false,
            })
          }
        >
          {t('common:add')}
        </Button>
      )}
    </>
  );
}
