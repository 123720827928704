import {
  DivisionLiteModel,
  DivisionModel,
  getDivisionKey,
  getDivisionsByEmployeeKey,
  useGetDivision,
  useGetDivisionsByEmployee,
} from 'api-hooks/division';
import { AuthorizationRules } from 'common/constants';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useAuthorization } from 'hooks/use-authorization';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  onAfterDetailChange?: (value: any) => void;
  disabled?: boolean;
  noMargin?: boolean;
  className?: string;
}

function transformer(item: DivisionLiteModel | DivisionModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}

export default function DivisionByEmployeeSelectInput(props: Props) {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const {
    label = t('common:division'),
    placeholder = t('common:choose_extra', { extra: t('common:division') }),
    ...rest
  } = props;
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetDivisionsByEmployee,
    useDetailLazyQueryHook: useGetDivision,
    getListsKey: React.useCallback(
      (page, q) =>
        getDivisionsByEmployeeKey({
          params: {
            q,
            page,
          },
        }),
      [],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
        },
      }),
      [],
    ),
    renderCreate: false,
    getDetailKey: React.useCallback(
      (value) =>
        getDivisionKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),

    listTransformer(result) {
      return can(AuthorizationRules.DivisionsEmployee)
        ? result.data.map((v) => transformer(v))
        : [];
    },
    detailTransformer(result) {
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      label={label}
      required
      {...rest}
      name={props.name}
      placeholder={placeholder}
      onAfterChange={props.onAfterChange}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
