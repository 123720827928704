import {
  getAccountKey,
  getAccountsKey,
  useGetAccount,
  useUpdateAccount,
} from 'api-hooks/account';
import { getEntityAccountsKey, getEntityKey } from 'api-hooks/entity';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import { useEntity } from 'hooks/use-entities';
import React from 'react';

import AccountForm from './components/form';

const AccountView = (props: NavigationProps) => {
  const { data, error, isLoading, isFetching, refetch } = useGetAccount({
    id: props.navigation.params.id,
  });
  const { mutateAsync, error: mutateError } = useUpdateAccount();
  const { entity } = useEntity();

  const account = data?.data;

  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <AccountForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          account={account}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              accountId: account!.id,
            });
            form.reset();
            queryClient.refetchQueries([getAccountsKey()[0]]);
            queryClient.refetchQueries(getAccountKey({ id: account!.id }));
            queryClient.refetchQueries(getEntityKey({ id: entity?.id || '' }));
            queryClient.refetchQueries([getEntityAccountsKey()[0]]);
            notification.success({ message: result?.message });

            return result.data;
          }}
        />
      }
    />
  );
};

export default AccountView;
