import { StockAdjustmentTypeEnum } from 'api-hooks/stock-adjustment';
import * as Yup from 'yup';

export type StockAdjustmentFormType = {
  warehouseId: string;
  transactionAt: Date;
  code: string;
  description: string;
  stockAdjustmentItems: {
    type: StockAdjustmentTypeEnum | string;
    stockId: string;
    stockItemId: string;
    isAsset?: boolean;
    //TYPE IN
    price?: number | null;
    // IF THIS IS ASSET AND TYPE IN REQUIRED
    qty?: number | null;
    merk?: string;
    description?: string;
    files?: { file: string; url: string }[];
  }[];
};

export const formSchema = () =>
  Yup.object().shape({
    warehouseId: Yup.string().required().nullable(),
    transactionAt: Yup.date().required().nullable(),
    code: Yup.string().required().nullable(),
    description: Yup.string().nullable(),
    stockAdjustmentItems: Yup.array().of(
      Yup.object({
        stockId: Yup.string().required().nullable(),
        type: Yup.string().required().nullable(),
        stockItemId: Yup.string().when(['type', 'isAsset'], {
          is: (type, isAsset) =>
            type === StockAdjustmentTypeEnum.out && isAsset,
          then: Yup.string().required().nullable(),
          otherwise: Yup.string().nullable().strip(),
        }),
        price: Yup.string().when('type', {
          is: (type) => type === StockAdjustmentTypeEnum.in,
          then: Yup.string().required().nullable(),
          otherwise: Yup.string().nullable().strip(),
        }),
        qty: Yup.number().when('isAsset', {
          is: (isAsset) => !isAsset,
          then: Yup.number().required().nullable(),
          otherwise: Yup.number().nullable().strip(),
        }),
        merk: Yup.string().when(['type', 'isAsset'], {
          is: (type, isAsset) => type === StockAdjustmentTypeEnum.in && isAsset,
          then: Yup.string().required().nullable(),
          otherwise: Yup.string().nullable().strip(),
        }),
        description: Yup.string().when(['type', 'isAsset'], {
          is: (type, isAsset) => type === StockAdjustmentTypeEnum.in && isAsset,
          then: Yup.string().nullable(),
          otherwise: Yup.string().nullable().strip(),
        }),
        files: Yup.array().when(['type', 'isAsset'], {
          is: (type, isAsset) => type === StockAdjustmentTypeEnum.in && isAsset,
          then: Yup.array().of(
            Yup.object({
              url: Yup.string().nullable().required(),
              file: Yup.string().nullable().required(),
            }),
          ),
          otherwise: Yup.array().strip(),
        }),
      }),
    ),
  });
