import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';
import { client } from 'hooks/use-ky';

import {
  OutcomeDeleteInput,
  OutcomeModel,
  OutcomeMutationInput,
  OutcomeNotifyInput,
  OutcomeUpdateInput,
} from './model';

export function useCreateOutcome(
  options?: UseMutationOptions<
    ApiResult<OutcomeModel>,
    ApiError,
    OutcomeMutationInput
  >,
): UseMutationResult<ApiResult<OutcomeModel>, ApiError, OutcomeMutationInput> {
  return useMutation<ApiResult<OutcomeModel>, ApiError, OutcomeMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'expenses',
        method: 'POST',
        classType: OutcomeModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateOutcome(
  options?: UseMutationOptions<
    ApiResult<OutcomeModel>,
    ApiError,
    OutcomeUpdateInput
  >,
): UseMutationResult<ApiResult<OutcomeModel>, ApiError, OutcomeUpdateInput> {
  return useMutation<ApiResult<OutcomeModel>, ApiError, OutcomeUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `expenses/${body.outcomeId}`,
        method: 'PUT',
        classType: OutcomeModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteOutcome(
  options?: UseMutationOptions<MessageResult, ApiError, OutcomeDeleteInput>,
): UseMutationResult<MessageResult, ApiError, OutcomeDeleteInput> {
  return useMutation<MessageResult, ApiError, OutcomeDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `expenses/${body.outcomeId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}

export function useNotifyOutcome(
  options?: UseMutationOptions<MessageResult, ApiError, OutcomeNotifyInput>,
): UseMutationResult<MessageResult, ApiError, OutcomeNotifyInput> {
  return useMutation<MessageResult, ApiError, OutcomeNotifyInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `expenses/${body.outcomeId}/notify`,
        method: 'POST',
        body: body.body,
      });
    },
    options,
  );
}

export function useImportOutcome(
  options?: UseMutationOptions<any>,
): UseMutationResult<any> {
  return useMutation<ApiResult<MessageResult>>(async function (body) {
    return await client
      .post('expenses/import', {
        body: body as any,
      })
      .json<ApiResult<MessageResult>>();
  }, options);
}
