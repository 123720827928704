import { Input } from 'components/elements/field';
import CurrencySelectInput from 'modules/master/currency/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

const EntityCurrencyInformationForm = ({ isBase }: { isBase: boolean }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={moduleStyles.halfContainer}>
        <CurrencySelectInput
          label={t('common:currency')}
          placeholder={t('common:choose_extra', {
            extra: t('common:currency'),
          })}
          name="currencyId"
        />
      </div>
      <div className={moduleStyles.halfContainer}>
        <Input
          type="text"
          label={t('common:name')}
          placeholder={t('common:name_placeholder')}
          name="name"
          required
        />
      </div>
      <div className={moduleStyles.halfContainer}>
        <Input
          type="number"
          label={t('common:rate')}
          placeholder={t('common:rate_placeholder')}
          name="rate"
          inputMode="decimal"
          step={5}
          isMoneyFormat
          required
          disabled={isBase}
        />
      </div>
    </div>
  );
};

export default EntityCurrencyInformationForm;
