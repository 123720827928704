import { getIndexInput } from 'api-hooks/common/model';
import { WarehouseModelWithEntity } from 'api-hooks/stock-adjustment';
import { Expose, Type } from 'class-transformer';

// Model
export class StockTransferLiteModel {
  id: string;
  code: string;
  description: string | null;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;
}

export class StockSimpleModel {
  id: string;
  code: string;
  name: string;
}

export class StockItemModel {
  id: string;
  barcode: string;
}
export class StockTransferItem {
  id: string;
  qty: number | null;

  @Type(() => StockSimpleModel)
  stock: StockSimpleModel;

  @Expose({ name: 'stock_item' })
  @Type(() => StockItemModel)
  stockItem: StockItemModel;

  @Expose({ name: 'from_warehouse' })
  @Type(() => WarehouseModelWithEntity)
  fromWarehouse: WarehouseModelWithEntity;

  @Expose({ name: 'to_warehouse' })
  @Type(() => WarehouseModelWithEntity)
  toWarehouse: WarehouseModelWithEntity;
}

export class StockTransferModel extends StockTransferLiteModel {
  @Expose({ name: 'stock_transfer_items' })
  @Type(() => StockTransferItem)
  stockTransferItems: StockTransferItem[];
}

// Input

export type getStockTransferInput = {
  stockTransferId: string;
};

export type getStockTransfersInput = getIndexInput;

export type StockTransferItemModelInput = {
  stockId: string;
  fromWarehouseId: string;
  toWarehouseId: string;
  stockItemId: string;
  qty: number;
};

export type StockTransferMutationInput = {
  entityId: string;
  transactionAt: Date;
  description: string;
  stockTransferItems: StockTransferItemModelInput[];
  code: string;
};

export type StockTransferUpdateInput = {
  stockTransferId: string;
  body: StockTransferMutationInput;
};

export type StockTransferDeleteInput = {
  stockTransferId: string;
};
