import { Group } from '@mantine/core';
import { BrandLiteModel } from 'api-hooks/brand';
import { OutcomeLiteModelData } from 'api-hooks/outcome';
import { string2moneyDefault } from 'common/utils/string';
import Badge from 'components/elements/badge';
import { Input } from 'components/elements/field';
import { IColumn } from 'components/elements/table';
import { format } from 'date-fns';
import { useEntity } from 'hooks/use-entities';
import { useTableViewDetailColumn } from 'modules/common/table';
import Balance from 'modules/entity-settings/common/components/balance';
import BalanceWithDetails from 'modules/entity-settings/common/components/balance-with-details';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

export function useOutcomeDefaultColumns() {
  const { t } = useTranslation();
  const { entity } = useEntity();

  const _columns = React.useMemo<IColumn<OutcomeLiteModelData>[]>(
    () => [
      {
        header: t('common:transaction_code'),
        accessorKey: 'code',
        minSize: 250,
        sortName: 'code',
      },
      {
        header: t('common:created_at'),
        accessorKey: 'createdAt',
        minSize: 200,
        sortName: 'created_at',
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('common:transaction_at'),
        accessorKey: 'transactionAt',
        minSize: 200,
        sortName: 'transaction_at',
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('common:created_by'),
        accessorKey: 'createdBy.name',
        minSize: 200,
      },
      {
        header: t('common:updated_by'),
        accessorKey: 'updatedBy.name',
        minSize: 200,
        cell: ({ getValue }) => getValue() || '-',
      },
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 250,
        sortName: 'description',
      },
      {
        header: t('common:account'),
        accessorKey: 'account.name',
        minSize: 250,
        sortName: 'account',
      },
      {
        header: t('transfer:amount'),
        accessorKey: 'amount',
        minSize: 250,
        textAlign: 'right',
        cell: ({ row }) => {
          const {
            original: { isBaseCurrency, rate, amount, account },
          } = row;
          if (!isBaseCurrency) {
            return (
              <BalanceWithDetails
                currency={account.currency}
                balance={amount}
                entityCurrency={entity?.currency!}
                rate={rate}
              />
            );
          } else {
            return <Balance currency={account.currency} balance={amount} />;
          }
        },
      },
      {
        header: t('common:division'),
        accessorKey: 'division.name',
        minSize: 200,
        sortName: 'division',
      },
      {
        header: t('common:brand'),
        accessorKey: 'brands',
        minSize: 200,
        cell: ({ getValue }) => {
          const data = getValue();
          return (
            <Group spacing={8}>
              {(data as BrandLiteModel[]).map((val) => (
                <Badge color={val.color} key={val.id}>
                  {val.name}
                </Badge>
              ))}
            </Group>
          );
        },
      },
      {
        header: t('common:supplier'),
        accessorKey: 'supplier.name',
        minSize: 200,
        sortName: 'supplier',
      },
      {
        header: t('common:needs_attachment'),
        accessorKey: 'hasAttachment',
        minSize: 160,
      },
      {
        header: t('common:attachment_completed'),
        accessorKey: 'isAttachmentCompleted',
        minSize: 190,
      },
    ],
    [entity?.currency, t],
  );

  return useTableViewDetailColumn(_columns, 'OutcomeView', 'ExpensesView');
}

export function useOutcomeUpdateRateColumns() {
  const { t } = useTranslation();
  const { entity } = useEntity();

  const _columns = React.useMemo<IColumn<OutcomeLiteModelData>[]>(
    () => [
      {
        header: '',
        accessorKey: 'checkbox',
        size: 40,
        cell: ({ row: { original } }) => (
          <Input type="checkbox" name={`ids.${original.id}`} />
        ),
      },

      {
        header: t('common:created_at'),
        accessorKey: 'createdAt',
        minSize: 200,
        sortName: 'created_at',
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('common:transaction_at'),
        accessorKey: 'transactionAt',
        minSize: 200,
        sortName: 'transaction_at',
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('common:transaction_code'),
        accessorKey: 'code',
        minSize: 200,
        sortName: 'code',
      },
      {
        header: t('common:account'),
        accessorKey: 'account.name',
        minSize: 200,
        sortName: 'account',
      },
      {
        header: t('common:rate'),
        accessorKey: 'rate',
        minSize: 200,
        cell: ({ getValue }) => string2moneyDefault(getValue() as number),
      },
      {
        header: t('transfer:amount'),
        accessorKey: 'amount',
        minSize: 200,
        textAlign: 'right',
        cell: ({ row }) => {
          const {
            original: { isBaseCurrency, rate, amount, account },
          } = row;
          if (!isBaseCurrency) {
            return (
              <BalanceWithDetails
                currency={account.currency}
                balance={amount}
                entityCurrency={entity?.currency!}
                rate={rate}
              />
            );
          } else {
            return <Balance currency={account.currency} balance={amount} />;
          }
        },
      },
    ],
    [entity?.currency, t],
  );

  return _columns;
}
