import { AccountLiteModel, useGetAccounts } from 'api-hooks/account';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import ListHeader from 'components/common/list-header';
import { IColumn } from 'components/elements/table';
import { useAuthorization } from 'hooks/use-authorization';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import {
  GenericQueryTable,
  useGenericTableProps,
  useTableViewDetailColumn,
} from 'modules/common/table';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import Balance from '../common/components/balance';
import BalanceWithDetails from '../common/components/balance-with-details';

function useAccountListColumns({ t, entity }) {
  const _columns = React.useMemo<IColumn<AccountLiteModel>[]>(
    () => [
      {
        header: t('common:account_name'),
        accessorKey: 'name',
        minSize: 200,
        sortName: 'name',
      },
      {
        header: t('common:currency'),
        accessorKey: 'currencyName',
        minSize: 250,
        sortName: 'currency',
      },
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 250,
        sortName: 'description',
      },
      {
        header: t('common:balance'),
        accessorKey: 'balance',
        minSize: 250,
        textAlign: 'right',
        cell: ({ row }) => {
          const {
            original: { isBaseCurrency, balance, rate, currency },
          } = row;
          return !isBaseCurrency ? (
            <BalanceWithDetails
              currency={currency}
              balance={balance}
              entityCurrency={entity?.currency!}
              rate={rate}
            />
          ) : (
            <Balance currency={currency} balance={balance} />
          );
        },
      },
    ],
    [t, entity?.currency],
  );
  return useTableViewDetailColumn(_columns, 'AccountView', 'AccountsView');
}

export default function AccountList() {
  const { t } = useTranslation();

  const { can } = useAuthorization();
  const { entity } = useEntity();
  const navigation = useNavigation();

  const columns = useAccountListColumns({ t, entity });

  const { refetch, ...table } = useGenericTableProps(columns, useGetAccounts, {
    params: {
      filter: {
        entity_id: entity?.id,
      },
    },
  });

  const onCreate = () => navigation.navigate(NavigationRoutes.AccountCreate);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:account')}
        showAdd={can(AuthorizationRules.AccountsCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
      />
      <GenericQueryTable {...table} />
    </div>
  );
}
