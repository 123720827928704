import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  BudgetingDeleteInput,
  BudgetingModel,
  BudgetingMutationInput,
  BudgetingMutationItemInput,
  BudgetingMutationTransferInput,
  BudgetingUpdateItemInput,
} from './model';

export function useCreateBudgeting(
  options?: UseMutationOptions<
    ApiResult<BudgetingModel>,
    ApiError,
    BudgetingMutationInput
  >,
): UseMutationResult<
  ApiResult<BudgetingModel>,
  ApiError,
  BudgetingMutationInput
> {
  return useMutation<
    ApiResult<BudgetingModel>,
    ApiError,
    BudgetingMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'budgetings',
      method: 'POST',
      classType: BudgetingModel,
      body,
    });
  }, options);
}

export function useUpdateBudgetingItem(
  options?: UseMutationOptions<
    ApiResult<BudgetingModel>,
    ApiError,
    BudgetingUpdateItemInput
  >,
): UseMutationResult<
  ApiResult<BudgetingModel>,
  ApiError,
  BudgetingUpdateItemInput
> {
  return useMutation<
    ApiResult<BudgetingModel>,
    ApiError,
    BudgetingUpdateItemInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `budgetings/${body.budgetingId}/budgeting-items/${body.budgetingItem}`,
      method: 'PUT',
      classType: BudgetingModel,
      body: body.body,
    });
  }, options);
}

export function useCreateBudgetingItem(
  options?: UseMutationOptions<
    ApiResult<BudgetingModel>,
    ApiError,
    BudgetingMutationItemInput
  >,
): UseMutationResult<
  ApiResult<BudgetingModel>,
  ApiError,
  BudgetingMutationItemInput
> {
  return useMutation<
    ApiResult<BudgetingModel>,
    ApiError,
    BudgetingMutationItemInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `budgetings/${body.budgetingId}/budgeting-items`,
      method: 'POST',
      classType: BudgetingModel,
      body: body.body,
    });
  }, options);
}

export function useCreateBudgetingTransfer(
  options?: UseMutationOptions<
    ApiResult<BudgetingModel>,
    ApiError,
    BudgetingMutationTransferInput
  >,
): UseMutationResult<
  ApiResult<BudgetingModel>,
  ApiError,
  BudgetingMutationTransferInput
> {
  return useMutation<
    ApiResult<BudgetingModel>,
    ApiError,
    BudgetingMutationTransferInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `budgetings/${body.budgetingId}/budgeting-transfers`,
      method: 'POST',
      classType: BudgetingModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteBudgeting(
  options?: UseMutationOptions<MessageResult, ApiError, BudgetingDeleteInput>,
): UseMutationResult<MessageResult, ApiError, BudgetingDeleteInput> {
  return useMutation<MessageResult, ApiError, BudgetingDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `budgetings/${body.budgetingId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
