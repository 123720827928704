import { getIndexInput } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';

// Model

export enum AccountTypeEnum {
  bank = 'bank',
  cash = 'cash',
}
export class AccountLiteModel {
  id: string;
  name: string;
  currency: string;
  description: string;

  @Type(() => Number)
  balance: number;

  @Expose({ name: 'currency_name' })
  currencyName: string;

  @Expose({ name: 'is_base_currency' })
  isBaseCurrency: boolean;

  @Expose({ name: 'rate' })
  @Type(() => Number)
  rate: number;
}

export class AccountModel extends AccountLiteModel {
  type: string;

  @Expose({ name: 'entity_currency_id' })
  entityCurrencyId: string;

  @Expose({ name: 'bank_type' })
  bankType: string | null;

  @Type(() => Number)
  interval: number;

  @Expose({ name: 'is_bank_connected' })
  isBankConnected: boolean;
}

// Input
export class getAccountInput {
  id: string;
}

export class AccountMutationInput {
  name: string;
  description: string;
  isBankConnected: boolean;
  bankType: string;
  isChangeCredential: boolean;
  bankUsername: string;
  bankPassword: string;
  interval: number;
  entityId: string;
  entityCurrencyId: string;
  balance: number;
  accountType: string;
}
export type getAccountsInput = getIndexInput;

export type AccountUpdateInput = {
  accountId: string;
  body: AccountMutationInput;
};

export type AccountDeleteInput = {
  accountId: string;
};
