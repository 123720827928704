import { useGetEnums } from 'api-hooks/common';
import { Input } from 'components/elements/field';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  onAfterDetailChange?: (value: any) => void;
  disabled?: boolean;
  noMargin?: boolean;
}

export default function BankSelectInput(props: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext<any>();
  const { field, fieldState } = useController({ control, name: props.name });
  const {
    label = t('common:type'),
    placeholder = t('common:choose_extra', { extra: t('common:type') }),
  } = props;
  const error = fieldState.error?.message;
  const { data } = useGetEnums({ class: 'BankType' });

  return (
    <Input
      type="select"
      required={props.required}
      label={label}
      placeholder={placeholder}
      noMargin={props.noMargin}
      data={data?.data || []}
      {...field}
      error={error}
      disabled={props.disabled}
    />
  );
}
