import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  CurrencyDeleteInput,
  CurrencyModel,
  CurrencyMutationInput,
  CurrencyUpdateInput,
} from './model';

export function useCreateCurrency(
  options?: UseMutationOptions<
    ApiResult<CurrencyModel>,
    ApiError,
    CurrencyMutationInput
  >,
): UseMutationResult<
  ApiResult<CurrencyModel>,
  ApiError,
  CurrencyMutationInput
> {
  return useMutation<ApiResult<CurrencyModel>, ApiError, CurrencyMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'currencies',
        method: 'POST',
        classType: CurrencyModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateCurrency(
  options?: UseMutationOptions<
    ApiResult<CurrencyModel>,
    ApiError,
    CurrencyUpdateInput
  >,
): UseMutationResult<ApiResult<CurrencyModel>, ApiError, CurrencyUpdateInput> {
  return useMutation<ApiResult<CurrencyModel>, ApiError, CurrencyUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `currencies/${body.currencyId}`,
        method: 'PUT',
        classType: CurrencyModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteCurrency(
  options?: UseMutationOptions<MessageResult, ApiError, CurrencyDeleteInput>,
): UseMutationResult<MessageResult, ApiError, CurrencyDeleteInput> {
  return useMutation<MessageResult, ApiError, CurrencyDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `currencies/${body.currencyId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
