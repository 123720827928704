import { ApiResult } from 'api-hooks/common/model';
import {
  PurchaseRequisitionsChangeLogModel,
  useGetPurchaseRequisitionsChangeLog,
} from 'api-hooks/purchase-requisitions';
import Separator from 'components/common/separator';
import TableComponent, { IColumn } from 'components/elements/table';
import { format } from 'date-fns';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import RequestExtend from './request-extend';

interface Props {
  onClose: () => void;
  id: string;
  showExtend: boolean;
  currentDeadline: Date;
}

export default function DeadlineChangeLog(props: Props) {
  const { showExtend, ...rest } = props;
  const { t } = useTranslation();
  const [data, setData] =
    React.useState<ApiResult<PurchaseRequisitionsChangeLogModel>>();
  const { mutateAsync: getChangeLog, isLoading } =
    useGetPurchaseRequisitionsChangeLog();

  const columns = React.useMemo<IColumn<any>[]>(
    () => [
      {
        header: t('modal:change_at'),
        accessorKey: 'changedAt',
        minSize: 150,
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      {
        header: t('modal:changed_by'),
        accessorKey: 'employee.name',
        minSize: 100,
      },
      {
        header: t('modal:deadline_at'),
        accessorKey: 'deadlineAt',
        minSize: 100,
        cell: ({ getValue }) => format(getValue() as Date, 'dd MMM yyyy'),
      },
    ],
    [t],
  );

  const init = React.useCallback(async () => {
    const res = await getChangeLog({ id: props.id });
    setData(res);
  }, [getChangeLog, props.id]);

  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <Separator gap={16} direction="vertical" />
      <TableComponent
        columns={columns}
        isLoading={isLoading}
        data={data?.data || []}
      />
      <Separator gap={32} direction="vertical" />
      {showExtend && <RequestExtend {...rest} />}
    </>
  );
}
