import { AccountModel } from 'api-hooks/account';
import { getIndexInput } from 'api-hooks/common';
import { EntityCurrencyLiteModel } from 'api-hooks/entity-currency/model';
import { Expose, Type } from 'class-transformer';

// Model
export class EntityLiteModel {
  id: string;
  name: string;
  description: string;
  currency: string;

  @Expose({ name: 'entity_currencies' })
  entityCurrencies: EntityCurrencyLiteModel[];
}
export class EntityModel {
  id: string;
  name: string;
  description: string;
  currency: string;

  @Type(() => AccountModel)
  accounts: AccountModel[];

  @Expose({ name: 'currency_id' })
  currencyId: string;

  @Expose({ name: 'entity_currencies' })
  @Type(() => EntityCurrencyLiteModel)
  entityCurrencies: EntityCurrencyLiteModel[];
}

export class EntityAccounts {
  id: string;
  name: string;
  currency: string;

  @Type(() => Number)
  balance: number;

  @Expose({ name: 'entity_currency_name' })
  entityCurrencyName: string;

  @Expose({ name: 'is_base_currency' })
  isBaseCurrency: boolean;
}

export class EntitiesGet {
  id: string;
  name: string;
}

// Input

export type getEntityInput = {
  id: string;
};

export type getEntitiesInput = getIndexInput;

export type EntityDeleteInput = {
  entityId: string;
};

export type getEntitiesAccountInput = {
  id: string;
  params: {
    q?: string;
    page?: number;
    limit?: number;
  };
};

export type getAllEntitiesInput = {
  params: {
    q?: string;
  };
};

export type EntityMutationInput = {
  name: string;
  description: string;
  currencyId: string;
};

export type EntityUpdateInput = {
  entityId: string;
  body: EntityMutationInput;
};
