import {
  StockTransferModel,
  StockTransferMutationInput,
  stockTransferKey,
  useDeleteStockTransfer,
} from 'api-hooks/stock-transfer';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import { initialDate } from 'common/utils/date';
import FormContent from 'components/common/form-content';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import { StockTransferFormType, formSchema } from './form-type';
import StockTransferItem from './item';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  stockTransfer?: StockTransferModel;
  onSubmit: (
    input: StockTransferMutationInput,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}
export default function StockTransferForm(props: Props) {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const { stockTransfer, navigation } = props;
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteStockTransfer();

  const dialog = useDialog();
  const resolver = useYupValidationResolver(formSchema({ t }));

  const initialValues = React.useMemo<StockTransferFormType>(
    () => ({
      description: stockTransfer?.description || '',
      stockTransferItems:
        stockTransfer?.stockTransferItems?.map((item) => ({
          fromWarehouseId: item.fromWarehouse.id,
          qty: item.qty,
          stockId: item.stock.id,
          stockItemId: item.stockItem?.id || '',
          toWarehouseId: item.toWarehouse.id,
        })) || [],
      transactionAt: stockTransfer?.transactionAt || initialDate(),
      code: stockTransfer?.code || '',
    }),
    [stockTransfer],
  );

  const methods = useForm<StockTransferFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const input = {
          ...values,
          entityId: entity?.id,
        };
        await props.onSubmit(input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            stockTransferId: stockTransfer!.id,
          });
          queryClient.refetchQueries([stockTransferKey.listKey]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [dialog, t, deleteMutate, stockTransfer, close]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!stockTransfer}
      setNavigations={setNavigations}
      navigation={navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={stockTransfer}
          title={t('common:stock_transfer')}
          noDelete={!can(AuthorizationRules.StockTransfersDelete)}
          noEdit={!can(AuthorizationRules.StockTransfersUpdate)}
        />
        <div className={moduleStyles.fullContainer}>
          <div className={moduleStyles.halfContainer}>
            <Input
              type="text"
              name="code"
              label={t('common:code')}
              placeholder={t('common:enter_extra', { extra: t('common:code') })}
              required
            />
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="date-time"
                name="transactionAt"
                label={t('common:transaction_at')}
                required
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="text-area"
                name="description"
                label={t('common:description')}
                placeholder={t('common:enter_extra', {
                  extra: t('common:description'),
                })}
              />
            </div>
          </div>
          <StockTransferItem t={t} />
        </div>
      </FormContent>
    </Form>
  );
}
