import { SearchIcon } from 'common/assets';
import Loader from 'components/common/loader';
import Text from 'components/elements/text';
import TextInput from 'components/elements/text-input';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  onAfterChange?: (val: string) => void;
  fill?: boolean;
  withLabel?: boolean;
}
export default function SearchInput(props: Props) {
  const { setValue, fill, setPage, withLabel } = props;
  const { t } = useTranslation();
  const [state, setState] = React.useState<string>('');
  const [isOnLoad, setIsOnLoad] = React.useState<boolean>(false);

  const onAfterChange = useDebouncedCallback((val) => {
    setPage?.(1);
    setValue?.(val);
    props.onAfterChange?.(val);
    setIsOnLoad(false);
  }, 1000);

  return (
    <div>
      {withLabel && (
        <Text textVariant="BodyBoldDefault">{t('common:search')}</Text>
      )}
      <div
        style={{
          ...styles.textField,
          ...(fill && {
            width: '100%',
          }),
        }}
      >
        <TextInput
          placeholder={t('common:search')}
          icon={<SearchIcon />}
          value={state}
          onChange={(e) => {
            const val = e.target.value;
            setState(val);
            setIsOnLoad(true);
            onAfterChange(val);
          }}
          {...(fill && {
            style: {
              width: '100%',
            },
          })}
          noMargin
        />
        <div style={styles.loaderContainer}>
          <Loader hide={!isOnLoad} />
        </div>
      </div>
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  textField: {
    position: 'relative',
    width: 'fit-content',
  },
  loaderContainer: {
    position: 'absolute',
    right: 24,
    top: 8,
  },
};
