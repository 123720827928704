import { PaginationMeta } from 'api-hooks/common/model';
import { StockAdjustmentLiteModel } from 'api-hooks/stock-adjustment';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import TableDetailButton from 'components/common/table-detail-button';
import Pagination from 'components/elements/pagination';
import TableComponent, { IColumn } from 'components/elements/table';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import { moduleStyles } from 'modules/styles.css';
import React from 'react';

interface Props {
  stockAdjustments: StockAdjustmentLiteModel[];
  loading?: boolean;
  page: number;
  onPageChange: (page: number) => void;
  meta?: PaginationMeta;
  sortColumns: IColumn<any>[];
  filter?: string;
  limit: number;
  onLimitChange: (limit: number) => void;
}

export default function StockAdjustmentTable(props: Props) {
  const { stockAdjustments, sortColumns, loading, page, onPageChange, meta } =
    props;
  const { can } = useAuthorization();
  const { navigate } = useNavigation();

  const onItemInvoked = React.useCallback(
    (id: string) => {
      //TODO:CHANGEACL
      can(AuthorizationRules.NoAuthorization) &&
        navigate(NavigationRoutes.StockAdjustmentView, {
          params: {
            id,
          },
        });
    },
    [can, navigate],
  );

  const columns = React.useMemo<IColumn<any>[]>(
    () => [
      {
        header: '',
        accessorKey: 'action',
        minSize: 80,
        maxSize: 80,
        stickyLeft: true,
        cell: ({ row }) => {
          const { original } = row;
          return (
            <TableDetailButton onClick={() => onItemInvoked(original.id)} />
          );
        },
      },
      ...sortColumns,
    ],
    [onItemInvoked, sortColumns],
  );

  return (
    <>
      <div className={moduleStyles.tableContainer}>
        <TableComponent
          columns={columns}
          isLoading={loading}
          data={stockAdjustments || []}
        />
      </div>
      <Pagination
        {...{
          page,
          onPageChange,
          meta,
          limit: props.limit,
          onLimitChange: props.onLimitChange,
        }}
      />
    </>
  );
}
