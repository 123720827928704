import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import {
  MutationFetchFunction,
  QueryFetchFunction,
  QueryTransformer,
} from 'common/helpers/common';

import {
  ChartOfAccountsLiteModel,
  ChartOfAccountsModel,
  getChartOfAccountsInput,
  getChartOfAccountInput,
  getChartOfAccountChildrenInput,
  ChartOfAccountChildrenModel,
} from './model';

export function useGetChartOfAccounts(
  input?: getChartOfAccountsInput,
  options?: UseQueryOptions<
    ExtendedApiResult<ChartOfAccountsLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<ChartOfAccountsLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getChartOfAccounts', input?.params],
      () =>
        QueryFetchFunction({
          url: 'chart-of-accounts',
          params: input?.params,
        }),
      options,
    ),
    ChartOfAccountsLiteModel,
  );
}

export function useGetChartOfAccount(
  input?: getChartOfAccountInput,
  options?: UseQueryOptions<ApiResult<ChartOfAccountsModel>, ApiError>,
): UseQueryResult<ApiResult<ChartOfAccountsModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getChartOfAccount', input?.id],
      () =>
        QueryFetchFunction({
          url: `chart-of-accounts/${input?.id}`,
        }),
      options,
    ),
    ChartOfAccountsModel,
  );
}

// export function useGetChartOfAccountChildren(
//   input?: getChartOfAccountChildrenInput,
//   options?: UseQueryOptions<ApiResult<ChartOfAccountChildrenModel>, ApiError>,
// ): UseQueryResult<ApiResult<ChartOfAccountChildrenModel>, ApiError> {
//   return QueryTransformer(
//     useQuery(
//       ['getChartOfAccount', input?.id],
//       () =>
//         QueryFetchFunction({
//           url: `chart-of-accounts/${input?.id}/children`,
//         }),
//       options,
//     ),
//     ChartOfAccountChildrenModel,
//   );
// }

export function useGetChartOfAccountChildren(
  options?: UseMutationOptions<
    ApiResult<ChartOfAccountChildrenModel[]>,
    ApiError,
    getChartOfAccountChildrenInput
  >,
): UseMutationResult<
  ApiResult<ChartOfAccountChildrenModel[]>,
  ApiError,
  getChartOfAccountChildrenInput
> {
  return useMutation<
    ApiResult<ChartOfAccountChildrenModel[]>,
    ApiError,
    getChartOfAccountChildrenInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `chart-of-accounts/${body?.id}/children`,
      method: 'GET',
      classType: ChartOfAccountChildrenModel,
    });
  }, options);
}

export function getChartOfAccountsKey(input?: getChartOfAccountsInput) {
  return ['getChartOfAccounts', input?.params];
}

export function getChartOfAccountKey(input?: getChartOfAccountInput) {
  return ['getChartOfAccount', input?.id];
}
