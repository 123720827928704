import { decamelize } from 'humps';

/**
 * Convert Object to Form Data
 * @param object value to convert
 * @param arrayKeys keys of object property that is an array
 * ex: images value is ["abc","bcd"] ==> arrayKeys=["images"]
 * the function will generate into images[0]: "abc" and so on
 * if images not includes in arrayKeys will be return as "abc,bcd"
 * @returns form data
 */
export function getFormData(object, arrayKeys: string[]): FormData {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (arrayKeys.includes(key)) {
      return object[key].map((item, index) =>
        formData.append(`${decamelize(key)}[${index}]`, item),
      );
    }
    return formData.append(decamelize(key), object[key]);
  });
  return formData;
}
