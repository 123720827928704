import { getCurrenciesKey, useCreateCurrency } from 'api-hooks/currency';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import React from 'react';

import CurrencyForm from './components/form';

const CurrencyCreate = () => {
  const { close, navigate } = useNavigation();
  const { can } = useAuthorization();
  const { mutateAsync, error } = useCreateCurrency();

  return (
    <CurrencyForm
      renderError={
        !!error &&
        !!error.message && (
          <>
            <Alert variant="error" description={error.message} />
            <Separator gap={8} />
          </>
        )
      }
      onSubmit={async (input, form) => {
        const result = await mutateAsync(input);
        close();
        form.reset();
        queryClient.refetchQueries([getCurrenciesKey()[0]]);
        if (can(AuthorizationRules.CurrenciesView)) {
          navigate(NavigationRoutes.CurrencyView, {
            params: { id: result.data?.id },
          });
        }
        notification.success({ message: result?.message });
        return result.data;
      }}
    />
  );
};

export default CurrencyCreate;
