import { PivotAggregateFormatEnum } from 'api-hooks/common';
import { money2number, string2moneyDefault } from 'common/utils/string';

export function CellComponent({ format, value }) {
  switch (format) {
    case PivotAggregateFormatEnum.money:
    case PivotAggregateFormatEnum.numeric:
      return value !== null
        ? string2moneyDefault(String(Number(value).toFixed(2)))
        : '';
    case PivotAggregateFormatEnum.percentage:
      return money2number(value) + '%';
    default:
      return String(value || '');
  }
}
