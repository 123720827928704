import { ApprovalModel } from 'api-hooks/approval';
import { PurchaseRequisitionsTypeEnum } from 'api-hooks/purchase-requisitions';
import { HistoryIcon } from 'common/assets';
import Separator from 'components/common/separator';
import { ActionIcon } from 'components/elements/button';
import DatePicker from 'components/elements/date-picker';
import PreviewPicker from 'components/elements/file-picker/preview-picker';
import NumberInput from 'components/elements/number-input';
import RadioGroup from 'components/elements/radio';
import Text from 'components/elements/text';
import TextareaInput from 'components/elements/text-area-input';
import TextInput from 'components/elements/text-input';
import useDrawer from 'hooks/use-drawer';
import { capitalize } from 'lodash';
import useRequestTypeOption from 'modules/hooks/use-request-type-option';
import { moduleStyles } from 'modules/styles.css';
import DeadlineChangeLog from 'modules/transactions/purchase-requisitions/components/deadline-change-log';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface Props {
  approval: ApprovalModel;
}

export default function ApprovalInformationForm(props: Props) {
  const drawer = useDrawer();

  const { t } = useTranslation();
  const { approval } = props;

  const { requestTypeRadioOption } = useRequestTypeOption();

  const handleViewChangeLog = React.useCallback(() => {
    drawer.showCustom({
      size: 480,
      render: (close) => (
        <DeadlineChangeLog
          id={approval?.approvalableId}
          onClose={close}
          currentDeadline={approval?.deadlineAt!}
          showExtend={false}
        />
      ),
      title: (
        <Text textVariant="HeadingLarge">{t('modal:deadline_change_log')}</Text>
      ),
      padding: 'sm',
    });
  }, [approval?.approvalableId, approval?.deadlineAt, drawer, t]);

  const Justification = () => (
    <TextareaInput
      label={t('pr:justification')}
      className={moduleStyles.halfContainer}
      withAsterisk
      disabled
      readOnly
      value={approval.requestForm.description}
    />
  );

  return (
    <div className={moduleStyles.fullContainer}>
      <div className={moduleStyles.halfContainer}>
        <RadioGroup
          value={approval.requestForm.requestType}
          data={requestTypeRadioOption}
          label={t('pr:request_type')}
        />
      </div>

      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <TextInput
            label={t('common:title_title')}
            withAsterisk
            value={approval.title}
            disabled
            readOnly
          />
        </div>
        <div className={moduleStyles.halfContainer}>
          <div className={moduleStyles.row()}>
            <DatePicker
              value={approval.deadlineAt}
              required
              disabled
              label={t('common:deadline_at')}
              className={moduleStyles.flex1}
              aria-readonly
            />
            <Separator gap={16} direction="horizontal" />
            <ActionIcon variant="transparent" onClick={handleViewChangeLog}>
              {(size) => <HistoryIcon size={size} />}
            </ActionIcon>
          </div>
        </div>
      </div>

      {approval.requestForm.requestType ===
        PurchaseRequisitionsTypeEnum.top_up && (
        <>
          <div className={moduleStyles.sectionContainer}>
            <TextInput
              label={t('pr:item_type')}
              className={moduleStyles.halfContainer}
              withAsterisk
              disabled
              readOnly
              value={capitalize(approval.requestForm.itemType)}
            />
            <TextInput
              label={t('pr:account_destination')}
              className={moduleStyles.halfContainer}
              withAsterisk
              disabled
              readOnly
              value={`${approval.requestForm.account?.name} - ${approval.requestForm.account?.currency} - ${approval.requestForm.account?.currencyName}`}
            />
          </div>

          <div className={moduleStyles.sectionContainer}>
            <NumberInput
              label={t('pr:top_up_amount')}
              value={approval.requestForm.amount}
              disabled
              readOnly
              hideControls
              isMoneyFormat
              className={moduleStyles.halfContainer}
              withAsterisk
            />
            <Justification />
          </div>
        </>
      )}
      <div className={moduleStyles.sectionContainer}>
        <TextInput
          label={t('common:division')}
          className={moduleStyles.halfContainer}
          withAsterisk
          disabled
          readOnly
          value={approval.division.name}
        />
        <TextInput
          label={t('common:brand')}
          className={moduleStyles.halfContainer}
          withAsterisk
          disabled
          readOnly
          value={approval.requestForm.brand?.name}
        />
      </div>

      {approval.requestForm.requestType ===
        PurchaseRequisitionsTypeEnum.others && <Justification />}

      <div className={moduleStyles.halfContainer}>
        <Text textVariant="BodyBoldDefault" className={moduleStyles.row()}>
          {t('common:attachment')}
        </Text>
        <div className={moduleStyles.row()}>
          {approval.files.map((item) => (
            <>
              <PreviewPicker onPicked={() => {}} preview={item.url} disabled />
              <Separator direction="horizontal" gap={8} />
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
