import {
  ChartOfAccountsLiteModel,
  ChartOfAccountsModel,
  getChartOfAccountKey,
  getChartOfAccountsKey,
  useGetChartOfAccount,
  useGetChartOfAccounts,
} from 'api-hooks/chart-of-accounts';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  onAfterChange?: (value?: any) => void;
  noMargin?: boolean;
  isParent?: boolean;
}

function transformer(
  item: ChartOfAccountsModel | ChartOfAccountsLiteModel,
): OptionProps {
  return {
    value: item.id,
    label: `${item.number} - ${item.name}`,
  };
}

export default function ChartOfAccountsSelectInput(props: Props) {
  const { t } = useTranslation();
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const { entity } = useEntity();
  const {
    placeholder = t('common:enter_extra', {
      extra: t('common:parent_id'),
    }),
    label = t('common:parent_id'),
    isParent = true,
  } = props;

  const filter = React.useMemo(() => {
    return isParent
      ? { entity_id: entity?.id! }
      : {
          entity_id: entity?.id!,
          is_parent: false,
        };
  }, [entity?.id, isParent]);

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetChartOfAccounts,
    useDetailLazyQueryHook: useGetChartOfAccount,
    getListsKey: React.useCallback(
      (page, q) =>
        getChartOfAccountsKey({
          params: {
            q,
            page,
            filter,
          },
        }),
      [filter],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter,
        },
      }),
      [filter],
    ),
    renderCreate: false,
    renderNoData: true,
    getDetailKey: React.useCallback(
      (value) =>
        getChartOfAccountKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      label={label}
      required={props.required}
      name={props.name}
      placeholder={placeholder}
      onAfterChange={props.onAfterChange}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
