import {
  WarehouseLiteModel,
  WarehouseModel,
  useGetWarehouse,
  useGetWarehouses,
  warehouseKey,
} from 'api-hooks/warehouse';
import { QueryTransformer } from 'common/helpers/common';
import Select, { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

function transformer(item: WarehouseLiteModel | WarehouseModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}
interface Props {
  required?: boolean;
  label?: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  noMargin?: boolean;
  onSelected?: (value?: string | null) => void;
}

export default function WarehouseSelectInput(props: Props) {
  const { t } = useTranslation();
  const {
    placeholder = t('common:choose_extra', { extra: t('common:warehouse') }),
  } = props;
  const { entity } = useEntity();
  const [state, setState] = React.useState<string | undefined | null>(
    props.value,
  );

  const selectProps = useSelectInputHelper({
    value: state,
    onSelectItem: (value) => {
      setState(value);
      props.onSelected?.(value);
    },
    useListQueryHook: useGetWarehouses,
    useDetailLazyQueryHook: useGetWarehouse,
    getDetailKey: React.useCallback(
      (value) =>
        warehouseKey.detail({
          warehouseId: value,
        }),
      [],
    ),
    getListsKey: React.useCallback(
      (page) =>
        warehouseKey.list({
          params: {
            page,
            filter: {
              entity_id: entity?.id!,
            },
          },
        }),
      [entity?.id],
    ),
    getMemoizedListVariables: React.useCallback(
      (page) => ({
        params: {
          page,
          filter: {
            entity_id: entity?.id!,
          },
        },
      }),
      [entity?.id],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (id) => ({ warehouseId: id }),
      [],
    ),
    renderCreate: false,
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      const resultTransformer = QueryTransformer(result, WarehouseModel);
      return transformer(resultTransformer.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Select
      required={props.required}
      label={t('common:warehouse')}
      placeholder={placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
