import {
  ApprovalIcon,
  EntityIcon,
  ExportIcon,
  GearIcon,
  ListDashesIcon,
  MutationIcon,
  TransactionsIcon,
} from 'common/assets';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import Navigation, { Route } from 'containers/navigation';
import Handler from 'handler';
import KYContainer from 'hooks/use-ky';
import {
  TransferCreate,
  TransferList,
  TransferView,
} from 'modules//transactions/transfer';
import { ApprovalList, ApprovalView } from 'modules/approval/approval';
import {
  TransferApprovalList,
  TransferApprovalView,
} from 'modules/approval/transfer-approval';
import {
  AccountCreate,
  AccountList,
  AccountView,
} from 'modules/entity-settings/account';
import {
  BrandCreate,
  BrandList,
  BrandView,
} from 'modules/entity-settings/brand';
import {
  EntityCurrencyCreate,
  EntityCurrencyList,
  EntityCurrencyView,
} from 'modules/entity-settings/entity-currency';
import ExportExcelList from 'modules/export/excel';
import {
  AccountAccessList,
  AccountAccessView,
} from 'modules/master/account-access';
import ApprovalMasterCreate from 'modules/master/approval/create';
import ApprovalMasterList from 'modules/master/approval/list';
import ApprovalMasterView from 'modules/master/approval/view';
import {
  ChartOfAccountCategoriesCreate,
  ChartOfAccountCategoriesList,
  ChartOfAccountCategoriesView,
} from 'modules/master/chart-of-account-categories';
import {
  ChartOfAccountsCreate,
  ChartOfAccountsList,
  ChartOfAccountsView,
} from 'modules/master/chart-of-accounts';
import {
  CurrencyCreate,
  CurrencyList,
  CurrencyView,
} from 'modules/master/currency';
import {
  DivisionCreate,
  DivisionList,
  DivisionView,
} from 'modules/master/division';
import {
  EmployeeCreate,
  EmployeeList,
  EmployeeView,
} from 'modules/master/employee';
import { EntityCreate, EntityList, EntityView } from 'modules/master/entity';
import { RoleCreate, RoleList, RoleView } from 'modules/master/role';
import {
  SupplierCreate,
  SupplierList,
  SupplierView,
} from 'modules/master/supplier';
import {
  WarehouseCreate,
  WarehouseList,
  WarehouseView,
} from 'modules/master/warehouse';
import ChartOfAccountReport from 'modules/report/chart-of-account-report';
import AccountMutationReport from 'modules/report/mutation-report';
import StockDetailReport from 'modules/report/stock-detail-report';
import StockSimpleReport from 'modules/report/stock-simple-report';
import TransactionReport from 'modules/report/transaction-report';
import {
  StockItemCodeCreate,
  StockItemCodeList,
  StockItemCodeView,
} from 'modules/stock/item-code';
import { StockCreate, StockList, StockView } from 'modules/stock/stock';
import {
  StockAdjustmentCreate,
  StockAdjustmentList,
  StockAdjustmentView,
} from 'modules/stock/stock-adjustment';
import {
  StockGroupingCreate,
  StockGroupingList,
  StockGroupingView,
} from 'modules/stock/stock-grouping';
import StockItemList from 'modules/stock/stock-items/list';
import StockItemView from 'modules/stock/stock-items/view';
import {
  StockTransferCreate,
  StockTransferList,
  StockTransferView,
} from 'modules/stock/stock-transfer';
import {
  StockUsageCreate,
  StockUsageList,
  StockUsageView,
} from 'modules/stock/stock-usage';
import {
  AccountMutationCreate,
  AccountMutationList,
  AccountMutationView,
} from 'modules/transactions/account-mutation';
import {
  BudgetingCreate,
  BudgetingList,
  BudgetingView,
} from 'modules/transactions/budgeting';
import {
  IncomeCreate,
  IncomeList,
  IncomeView,
} from 'modules/transactions/income';
import {
  OutcomeCreate,
  OutcomeList,
  OutcomeView,
} from 'modules/transactions/outcome';
import {
  PurchaseRequisitionsCreate,
  PurchaseRequisitionsList,
  PurchaseRequisitionsView,
} from 'modules/transactions/purchase-requisitions';
import {
  ReconciliationCreate,
  ReconciliationList,
} from 'modules/transactions/reconciliation';
import Head from 'next/head';
import useTranslation from 'next-translate/useTranslation';

const routes: Route[] = [
  {
    title: 'common:approval',
    leftIcon: (_, size) => <ApprovalIcon size={size} />,
    children: [
      {
        permission: AuthorizationRules.ApprovalsPage,
        routeName: NavigationRoutes.ApprovalList,
        component: ApprovalList,
        title: 'common:approval',
      },
      {
        permission: AuthorizationRules.TransferApprovalsPage,
        routeName: NavigationRoutes.TransferApprovalList,
        component: TransferApprovalList,
        title: 'common:transfer_approval',
      },
    ],
  },
  {
    title: 'common:entity_settings',
    leftIcon: (_, size) => <EntityIcon size={size} />,
    children: [
      {
        permission: AuthorizationRules.AccountsPage,
        routeName: NavigationRoutes.AccountList,
        component: AccountList,
        title: 'common:accounts',
      },
      {
        permission: AuthorizationRules.EntityCurrenciesPage,
        routeName: NavigationRoutes.EntityCurrencyList,
        component: EntityCurrencyList,
        title: 'common:entity_currency',
      },
      {
        permission: AuthorizationRules.BrandsPage,
        routeName: NavigationRoutes.BrandList,
        component: BrandList,
        title: 'common:brands',
      },
    ],
  },
  {
    title: 'common:transactions',
    leftIcon: (_, size) => <TransactionsIcon size={size} />,
    children: [
      {
        permission: AuthorizationRules.RequestFormsPage,
        routeName: NavigationRoutes.PurchaseRequisitionsList,
        component: PurchaseRequisitionsList,
        title: 'common:purchase_requisitions',
      },
      {
        permission: AuthorizationRules.BudgetingsPage,
        routeName: NavigationRoutes.BudgetingList,
        component: BudgetingList,
        title: 'common:budgeting',
      },
      {
        permission: AuthorizationRules.IncomesPage,
        routeName: NavigationRoutes.IncomeList,
        component: IncomeList,
        title: 'common:income',
      },

      {
        permission: AuthorizationRules.ExpensesPage,
        routeName: NavigationRoutes.OutcomeList,
        component: OutcomeList,
        title: 'common:outcome',
      },
      {
        permission: AuthorizationRules.TransfersPage,
        routeName: NavigationRoutes.TransferList,
        component: TransferList,
        title: 'common:transfer',
      },
      {
        permission: AuthorizationRules.WalletMutationsPage,
        routeName: NavigationRoutes.AccountMutationList,
        component: AccountMutationList,
        title: 'common:account_mutation',
      },
      {
        permission: AuthorizationRules.ReconciliationsPage,
        routeName: NavigationRoutes.ReconciliationList,
        component: ReconciliationList,
        title: 'common:reconciliation',
      },
    ],
  },
  {
    title: 'common:stock',
    leftIcon: (_, size) => <ListDashesIcon size={size} />,
    children: [
      {
        permission: AuthorizationRules.StockItemCodesPage,
        routeName: NavigationRoutes.StockItemCodeList,
        component: StockItemCodeList,
        title: 'common:item_code',
      },
      {
        permission: AuthorizationRules.StocksPage,
        routeName: NavigationRoutes.StockList,
        component: StockList,
        title: 'common:stock',
      },
      {
        permission: AuthorizationRules.StockItemsPage,
        routeName: NavigationRoutes.StockItemsList,
        component: StockItemList,
        title: 'common:stock_items',
      },
      {
        permission: AuthorizationRules.StockUsagesPage,
        routeName: NavigationRoutes.StockUsageList,
        component: StockUsageList,
        title: 'common:stock_usage',
      },
      {
        permission: AuthorizationRules.StockGroupingsPage,
        routeName: NavigationRoutes.StockGroupingList,
        component: StockGroupingList,
        title: 'common:stock_groupings',
      },
      {
        permission: AuthorizationRules.StockAdjustmentsPage,
        routeName: NavigationRoutes.StockAdjustmentList,
        component: StockAdjustmentList,
        title: 'common:stock_adjustments',
      },
      {
        permission: AuthorizationRules.StockTransfersPage,
        routeName: NavigationRoutes.StockTransferList,
        component: StockTransferList,
        title: 'common:stock_transfer',
      },
    ],
  },
  {
    title: 'common:reports',
    leftIcon: (_, size) => <MutationIcon size={size} />,
    children: [
      {
        permission: AuthorizationRules.ReportsTransaction,
        routeName: NavigationRoutes.TransactionReportList,
        component: TransactionReport,
        title: 'common:transaction_reports',
      },
      {
        permission: AuthorizationRules.ReportsAccount,
        routeName: NavigationRoutes.AccountMutationReportList,
        component: AccountMutationReport,
        title: 'common:mutation_reports',
      },
      {
        permission: AuthorizationRules.ReportsChartOfAccount,
        routeName: NavigationRoutes.ChartOfAccountReportList,
        component: ChartOfAccountReport,
        title: 'common:coa_transaction_reports',
      },
      {
        permission: AuthorizationRules.ReportsStockSimple,
        routeName: NavigationRoutes.StockSimpleReportList,
        component: StockSimpleReport,
        title: 'common:stock_simple_report',
      },
      {
        permission: AuthorizationRules.ReportsStockDetail,
        routeName: NavigationRoutes.StockDetailReportList,
        component: StockDetailReport,
        title: 'common:stock_detail_report',
      },
    ],
  },
  {
    title: 'common:export_excel',
    leftIcon: (_, size) => <ExportIcon size={size} />,
    routeName: NavigationRoutes.ExportExcelList,
    permission: AuthorizationRules.ExportHistoriesList,
    component: ExportExcelList,
  },
  {
    title: 'common:master',
    leftIcon: (_, size) => <GearIcon size={size} />,
    children: [
      {
        permission: AuthorizationRules.EmployeesPage,
        routeName: NavigationRoutes.EmployeesList,
        component: EmployeeList,
        title: 'common:employee',
      },
      {
        permission: AuthorizationRules.AccountAccessesPage,
        routeName: NavigationRoutes.AccountAccessList,
        component: AccountAccessList,
        title: 'common:account_access',
      },
      {
        permission: AuthorizationRules.RolesPage,
        routeName: NavigationRoutes.RolesList,
        component: RoleList,
        title: 'common:role',
      },
      {
        permission: AuthorizationRules.DivisionsPage,
        routeName: NavigationRoutes.DivisionList,
        component: DivisionList,
        title: 'common:division',
      },
      {
        permission: AuthorizationRules.SuppliersPage,
        routeName: NavigationRoutes.SupplierList,
        component: SupplierList,
        title: 'common:supplier',
      },
      {
        permission: AuthorizationRules.ChartOfAccountCategoriesPage,
        routeName: NavigationRoutes.ChartOfAccountCategoriesList,
        component: ChartOfAccountCategoriesList,
        title: 'common:chart_of_account_categories',
      },
      {
        permission: AuthorizationRules.ChartOfAccountsPage,
        routeName: NavigationRoutes.ChartOfAccountsList,
        component: ChartOfAccountsList,
        title: 'common:chart_of_accounts',
      },
      {
        permission: AuthorizationRules.CurrenciesPage,
        routeName: NavigationRoutes.CurrencyList,
        component: CurrencyList,
        title: 'common:currency',
      },
      {
        permission: AuthorizationRules.EntitiesPage,
        routeName: NavigationRoutes.EntityList,
        component: EntityList,
        title: 'common:entity',
      },
      {
        permission: AuthorizationRules.ApprovalMastersPage,
        routeName: NavigationRoutes.ApprovalMastersList,
        component: ApprovalMasterList,
        title: 'common:approval',
      },
      {
        permission: AuthorizationRules.WarehousesPage,
        routeName: NavigationRoutes.WarehouseList,
        component: WarehouseList,
        title: 'common:warehouse',
      },
    ],
  },
  //Account
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.AccountsCreate,
    routeName: NavigationRoutes.AccountCreate,
    component: AccountCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.AccountsView,
    routeName: NavigationRoutes.AccountView,
    component: AccountView,
  },
  //Entity Currency
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.EntityCurrenciesCreate,
    routeName: NavigationRoutes.EntityCurrencyCreate,
    component: EntityCurrencyCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.EntityCurrenciesView,
    routeName: NavigationRoutes.EntityCurrencyView,
    component: EntityCurrencyView,
  },
  //Transfer
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.TransfersCreate,
    routeName: NavigationRoutes.TransferCreate,
    component: TransferCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.TransfersView,
    routeName: NavigationRoutes.TransferView,
    component: TransferView,
  },

  // Income
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.IncomesCreate,
    routeName: NavigationRoutes.IncomeCreate,
    component: IncomeCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.IncomesView,
    routeName: NavigationRoutes.IncomeView,
    component: IncomeView,
  },

  // Expense
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ExpensesCreate,
    routeName: NavigationRoutes.OutcomeCreate,
    component: OutcomeCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ExpensesView,
    routeName: NavigationRoutes.OutcomeView,
    component: OutcomeView,
  },
  //Transfer Approval
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.TransferApprovalsView,
    routeName: NavigationRoutes.TransferApprovalView,
    component: TransferApprovalView,
  },
  //Account Mutation
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.WalletMutationsCreate,
    routeName: NavigationRoutes.AccountMutationCreate,
    component: AccountMutationCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.WalletMutationsView,
    routeName: NavigationRoutes.AccountMutationView,
    component: AccountMutationView,
  },
  //Reconciliation
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ReconciliationsCreate,
    routeName: NavigationRoutes.ReconciliationCreate,
    component: ReconciliationCreate,
  },
  // Purchase Requisitions
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.RequestFormsCreate,
    routeName: NavigationRoutes.PurchaseRequisitionsCreate,
    component: PurchaseRequisitionsCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.RequestFormsView,
    routeName: NavigationRoutes.PurchaseRequisitionsView,
    component: PurchaseRequisitionsView,
  },
  // Approval
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ApprovalsView,
    routeName: NavigationRoutes.ApprovalView,
    component: ApprovalView,
  },
  //Budgeting
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.BudgetingsCreate,
    routeName: NavigationRoutes.BudgetingCreate,
    component: BudgetingCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.BudgetingsView,
    routeName: NavigationRoutes.BudgetingView,
    component: BudgetingView,
  },

  //STOCK
  // Item Code
  {
    title: '',
    hide: true,

    permission: AuthorizationRules.StockItemCodesCreate,
    routeName: NavigationRoutes.StockItemCodeCreate,
    component: StockItemCodeCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockItemCodesView,
    routeName: NavigationRoutes.StockItemCodeView,
    component: StockItemCodeView,
  },
  // Stock
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StocksCreate,
    routeName: NavigationRoutes.StockCreate,
    component: StockCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StocksView,
    routeName: NavigationRoutes.StockView,
    component: StockView,
  },
  // Stock Items
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockItemsView,
    routeName: NavigationRoutes.StockItemsView,
    component: StockItemView,
  },
  // Stock Usage
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockUsagesView,
    routeName: NavigationRoutes.StockUsageView,
    component: StockUsageView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockUsagesCreate,
    routeName: NavigationRoutes.StockUsageCreate,
    component: StockUsageCreate,
  },
  // Stock Grouping
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockGroupingsView,
    routeName: NavigationRoutes.StockGroupingView,
    component: StockGroupingView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockGroupingsCreate,
    routeName: NavigationRoutes.StockGroupingCreate,
    component: StockGroupingCreate,
  },
  // Stock Adjustment
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockAdjustmentsCreate,
    routeName: NavigationRoutes.StockAdjustmentCreate,
    component: StockAdjustmentCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockAdjustmentsView,
    routeName: NavigationRoutes.StockAdjustmentView,
    component: StockAdjustmentView,
  },
  // Stock Transfer
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockTransfersCreate,
    routeName: NavigationRoutes.StockTransferCreate,
    component: StockTransferCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.StockTransfersView,
    routeName: NavigationRoutes.StockTransferView,
    component: StockTransferView,
  },

  // Master
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.EmployeesCreate,
    routeName: NavigationRoutes.EmployeesCreate,
    component: EmployeeCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.EmployeesView,
    routeName: NavigationRoutes.EmployeesView,
    component: EmployeeView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.AccountAccessesView,
    routeName: NavigationRoutes.AccountAccessView,
    component: AccountAccessView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.RolesCreate,
    routeName: NavigationRoutes.RolesCreate,
    component: RoleCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.RolesView,
    routeName: NavigationRoutes.RolesView,
    component: RoleView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.CurrenciesCreate,
    routeName: NavigationRoutes.CurrencyCreate,
    component: CurrencyCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.CurrenciesView,
    routeName: NavigationRoutes.CurrencyView,
    component: CurrencyView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.EntitiesCreate,
    routeName: NavigationRoutes.EntityCreate,
    component: EntityCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.EntitiesView,
    routeName: NavigationRoutes.EntityView,
    component: EntityView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.BrandsCreate,
    routeName: NavigationRoutes.BrandCreate,
    component: BrandCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.BrandsView,
    routeName: NavigationRoutes.BrandView,
    component: BrandView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ChartOfAccountCategoriesCreate,
    routeName: NavigationRoutes.ChartOfAccountCategoriesCreate,
    component: ChartOfAccountCategoriesCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ChartOfAccountCategoriesView,
    routeName: NavigationRoutes.ChartOfAccountCategoriesView,
    component: ChartOfAccountCategoriesView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ChartOfAccountsCreate,
    routeName: NavigationRoutes.ChartOfAccountsCreate,
    component: ChartOfAccountsCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ChartOfAccountsView,
    routeName: NavigationRoutes.ChartOfAccountsView,
    component: ChartOfAccountsView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ApprovalMastersCreate,
    routeName: NavigationRoutes.ApprovalMastersCreate,
    component: ApprovalMasterCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.ApprovalMastersView,
    routeName: NavigationRoutes.ApprovalMastersView,
    component: ApprovalMasterView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.DivisionsCreate,
    routeName: NavigationRoutes.DivisionCreate,
    component: DivisionCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.DivisionsView,
    routeName: NavigationRoutes.DivisionView,
    component: DivisionView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.SuppliersCreate,
    routeName: NavigationRoutes.SupplierCreate,
    component: SupplierCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.SuppliersView,
    routeName: NavigationRoutes.SupplierView,
    component: SupplierView,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.WarehousesCreate,
    routeName: NavigationRoutes.WarehouseCreate,
    component: WarehouseCreate,
  },
  {
    title: '',
    hide: true,
    permission: AuthorizationRules.WarehousesView,
    routeName: NavigationRoutes.WarehouseView,
    component: WarehouseView,
  },
];

export default function Home() {
  const { t } = useTranslation();
  return (
    <KYContainer>
      <Head>
        <title>{t('common:title')}</title>
      </Head>
      <Handler />
      <Navigation routes={routes} />
    </KYContainer>
  );
}
