import {
  ApprovalMasterTypeEnum,
  getApprovalMastersKey,
  useDeleteApprovalMaster,
} from 'api-hooks/approval-master';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import formSetValues from 'common/helpers/form-setValues';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import EmployeeSelectInput from 'modules/master/employee/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import ApprovalDivisionForm from './approval-division-form';
import ApprovalFinanceForm from './approval-finance-form';
import {
  AmountTypeEnum,
  ApprovalMasterFormProps,
  ApprovalMasterFormType,
  FinanceType,
  formSchema,
} from './form-type';
import SelectInputModel from './select-input-model';

const divisionDefaultValue = {
  divisionId: '',
  details: [
    {
      employeeId: '',
      order: 0,
    },
  ],
  carbonCopies: [],
  brands: [],
};

const financeDefaultValue: FinanceType = {
  paymentEmployeeId: '',
  lowerBound: null,
  upperBound: null,
  details: [],
  carbonCopies: [],
  amountType: AmountTypeEnum.between,
};

export default function ApprovalMasterForm(props: ApprovalMasterFormProps) {
  const { t } = useTranslation();
  const { approvalMaster } = props;
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeleteApprovalMaster();
  const { can } = useAuthorization();
  const dialog = useDialog();

  const resolver = useYupValidationResolver(formSchema());

  const intialValues = React.useMemo<ApprovalMasterFormType>(() => {
    return {
      divisions: approvalMaster?.divisions.map((item) => {
        return {
          divisionId: item.division.id,
          details: item.details.map((detail) => {
            return {
              employeeId: detail.employee.id,
              order: detail.order - 1,
            };
          }),
          carbonCopies: item.carbonCopies?.map((cc) => cc.employee.id),
          brands: item.brands?.map((brand) => brand.id),
        };
      }) || [divisionDefaultValue],
      chiefEmployeeId: approvalMaster?.chiefEmployee?.id || '',
      finances: approvalMaster?.finances.map((item) => {
        return {
          amountType:
            item.lowerBound !== null && item.upperBound !== null
              ? AmountTypeEnum.between
              : item.upperBound
              ? AmountTypeEnum.less_than
              : AmountTypeEnum.more_than,
          lowerBound: item.lowerBound,
          upperBound: item.upperBound,
          paymentEmployeeId: item.paymentEmployee.id,
          details: item.details.map((detail) => {
            return {
              employeeId: detail.employee.id,
              order: detail.order - 1,
            };
          }),
          carbonCopies: item.carbonCopies?.map((cc) => cc.employee.id),
        };
      }) || [financeDefaultValue],
      type: approvalMaster?.type || '',
    };
  }, [approvalMaster]);

  const methods = useForm<ApprovalMasterFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values: ApprovalMasterFormType) => {
      try {
        const input = {
          entityId: entity?.id!,
          ...values,
          divisions: values.divisions?.length
            ? values.divisions?.map(({ carbonCopies, ...rest }) => {
                return {
                  ...rest,
                  details: rest.details.map((item) => {
                    return { ...item, order: item.order + 1 };
                  }),
                  carbonCopies: carbonCopies.filter((cc) => !!cc)?.length
                    ? carbonCopies
                    : [],
                };
              })
            : [],
          finances: values.finances?.length
            ? values.finances?.map(({ carbonCopies, ...rest }) => {
                return {
                  ...rest,
                  details: rest.details.map((item) => {
                    return { ...item, order: item.order + 1 };
                  }),
                  carbonCopies: carbonCopies.filter((cc) => !!cc)?.length
                    ? carbonCopies
                    : [],
                };
              })
            : [],
        };

        await props.onSubmit(input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            id: approvalMaster!.id,
          });
          queryClient.refetchQueries([getApprovalMastersKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [approvalMaster, close, deleteMutate, dialog, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!approvalMaster}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={approvalMaster}
          title={
            approvalMaster
              ? `${t('common:approval')}`
              : t('common:create_extra', { extra: t('common:approval') })
          }
          noDelete={!can(AuthorizationRules.ApprovalMastersDelete)}
          noEdit={!can(AuthorizationRules.ApprovalMastersUpdate)}
        />
        <div className={moduleStyles.halfContainer}>
          <SelectInputModel
            name="type"
            label={t('common:approval')}
            placeholder={t('common:choose_extra', { extra: t('common:type') })}
            required
            onAfterChange={(val) =>
              formSetValues(
                val?.value === ApprovalMasterTypeEnum.finance
                  ? { finances: [financeDefaultValue] }
                  : { divisions: [divisionDefaultValue] },
                methods.setValue,
              )
            }
          />
        </div>
        <HOCInput keys={['type']}>
          {({ type }) =>
            type === ApprovalMasterTypeEnum.finance ? (
              <>
                <div className={moduleStyles.halfContainer}>
                  <EmployeeSelectInput
                    name="chiefEmployeeId"
                    label={t('approval:cfo')}
                    placeholder={t('common:choose_extra', {
                      extra: t('approval:cfo'),
                    })}
                    required
                  />
                </div>
                <ApprovalFinanceForm />
              </>
            ) : (
              <ApprovalDivisionForm />
            )
          }
        </HOCInput>
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
}
