import { getIndexInput } from 'api-hooks/common';

// Model
export class ChartOfAccountCategoriesLiteModel {
  id: string;
  name: string;
}
export class ChartOfAccountCategoriesModel {
  id: string;
  name: string;
}

// Input

export type getChartOfAccountCategoriesInput = getIndexInput;

export type getChartOfAccountCategoryInput = {
  id: string;
};

export type ChartOfAccountCategoriesMutationInput = {
  name: string;
  entityId: string;
};

export type ChartOfAccountCategoriesUpdateInput = {
  id: string;
  body: ChartOfAccountCategoriesMutationInput;
};
export type ChartAccountCategoriesDeleteInput = {
  id: string;
};
