import {
  ActiveBudgetingItemLiteModel,
  BudgetingUpdateItemBodyInput,
  getBudgetingKey,
  getBudgetingsKey,
  useUpdateBudgetingItem,
} from 'api-hooks/budgeting';
import { CommonSimpleModel } from 'api-hooks/common/model';
import { CancelIcon } from 'common/assets';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import { convertDateEnd, convertDateStart } from 'common/utils/date';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { EmployeeMultiSelectInput } from 'modules/master/employee/components/multi-select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import ProgressBar from '../../progress/bar';
import { BudgetEditFormType } from '../form-type';

interface Props extends ActiveBudgetingItemLiteModel {
  budgetingId: string;
  onClose: () => void;
  employeeToNotifies: CommonSimpleModel[];
}

export default function EditBudgetModal(props: Props) {
  const { onClose, ...rest } = props;
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useUpdateBudgetingItem();

  const initialValues = React.useMemo<BudgetEditFormType>(
    () => ({
      amount: rest.amount || 0,
      employeeToNotifyIds: rest.employeeToNotifies.map((item) => item.id) || [],
      endAt: rest.endAt,
      startFrom: rest.startFrom,
    }),
    [rest],
  );
  const resolver = useYupValidationResolver(
    Yup.object().shape({
      amount: Yup.number().required(),
      employeeToNotifyIds: Yup.array()
        .of(Yup.string().required())
        .min(1)
        .required(),
      endAt: Yup.date().required().nullable(),
      startFrom: Yup.date().required().nullable(),
    }),
  );

  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver,
  });

  const onSubmit = React.useCallback(
    async (values: BudgetEditFormType) => {
      try {
        const input: BudgetingUpdateItemBodyInput = {
          ...values,
          endAt: convertDateEnd(values.endAt),
          startFrom: convertDateStart(values.startFrom),
          // DEFAULT
          notifyThresholdPercent: 80,
        };
        const res = await mutateAsync({
          body: input,
          budgetingId: rest.budgetingId,
          budgetingItem: rest.id,
        });
        onClose();
        queryClient.refetchQueries([getBudgetingsKey()[0]]);
        queryClient.refetchQueries(getBudgetingKey({ id: rest.budgetingId }));
        notification.success({ message: res.message });
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [methods.setError, mutateAsync, onClose, rest.budgetingId, rest.id],
  );

  return (
    <div className={moduleStyles.panelContainer}>
      <div className={moduleStyles.fullContainer}>
        <Separator gap={16} direction="vertical">
          <ProgressBar {...rest} t={t} variant="secondary" showDetails />
        </Separator>
        <Form methods={methods} onSubmit={onSubmit}>
          <Input
            type="number"
            name="amount"
            label={t('budgeting:new_budget')}
            placeholder={t('common:enter_extra', {
              extra: t('common:amount'),
            })}
            required
            hideControls
            description={t('budgeting:new_budget_desc')}
            isMoneyFormat
          />

          <EmployeeMultiSelectInput
            name="employeeToNotifyIds"
            label={t('budgeting:notify_to')}
            required
          />
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="date"
                name="startFrom"
                required
                label={t('budgeting:starts_from')}
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="date"
                name="endAt"
                required
                label={t('budgeting:end_at')}
              />
            </div>
          </div>
          <Separator gap={24} direction="vertical" />

          <div className={moduleStyles.row()}>
            <Input type="submit" loading={isLoading} />
            <Separator gap={16} direction="horizontal" />
            <Button
              type="button"
              variant="secondary"
              onClick={onClose}
              error
              disabled={isLoading}
              leftIcon={(size) => <CancelIcon size={size} />}
            >
              {t('common:cancel')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
