import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  AccountMutationDeleteInput,
  AccountMutationModel,
  AccountMutationMutationInput,
  AccountMutationPatchInput,
  AccountMutationUpdateInput,
} from './model';

export function useCreateAccountMutation(
  options?: UseMutationOptions<
    ApiResult<AccountMutationModel>,
    ApiError,
    AccountMutationMutationInput
  >,
): UseMutationResult<
  ApiResult<AccountMutationModel>,
  ApiError,
  AccountMutationMutationInput
> {
  return useMutation<
    ApiResult<AccountMutationModel>,
    ApiError,
    AccountMutationMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'wallet-mutations',
      method: 'POST',
      classType: AccountMutationModel,
      body,
    });
  }, options);
}

export function useUpdateAccountMutation(
  options?: UseMutationOptions<
    ApiResult<AccountMutationModel>,
    ApiError,
    AccountMutationUpdateInput
  >,
): UseMutationResult<
  ApiResult<AccountMutationModel>,
  ApiError,
  AccountMutationUpdateInput
> {
  return useMutation<
    ApiResult<AccountMutationModel>,
    ApiError,
    AccountMutationUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `wallet-mutations/${body.accountMutationId}`,
      method: 'PUT',
      classType: AccountMutationModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteAccountMutation(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    AccountMutationDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, AccountMutationDeleteInput> {
  return useMutation<MessageResult, ApiError, AccountMutationDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `wallet-mutations/${body.accountMutationId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
export function useUpdateTimeAccountMutation(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    AccountMutationPatchInput
  >,
): UseMutationResult<MessageResult, ApiError, AccountMutationPatchInput> {
  return useMutation<MessageResult, ApiError, AccountMutationPatchInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `wallet-mutations/${body.accountMutationId}/transaction-at`,
        body: body.body,
        method: 'PATCH',
      });
    },
    options,
  );
}
