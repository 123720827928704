import { Popover } from '@mantine/core';
import { useExportPDFOutcome } from 'api-hooks/outcome';
import { ChevronDownIcon, ExportPDFIcon, ExportXLSIcon } from 'common/assets';
import { debugBase64 } from 'common/helpers/common';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import Text from 'components/elements/text';
import { useEntity } from 'hooks/use-entities';
import { useExportHook } from 'modules/report/components/export-button';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { outcomeStyles } from '../styles.css';

interface Props {
  filter: string;
  page: number;
  limit: number;
  q?: string;
}

function OutcomeExportComponent(props: Props) {
  const { filter, page, limit, q } = props;
  const { t } = useTranslation();
  const { entity } = useEntity();

  const [isPopoverOpen, setIsPopoverOpen] = React.useState<boolean>(false);

  const { mutateAsync, isLoading: loadingExport } = useExportPDFOutcome();

  const { onHandleExport } = useExportHook({
    t,
    params: {
      filter: {
        ...(filter as any),
        entity_id: entity?.id,
      },
      ...(!!q && { q }),
    },
    category: 'expense',
  });

  const handleExportPDF = async () => {
    try {
      const result = await mutateAsync({
        params: {
          page,
          limit,
          filter: {
            entity_id: entity?.id!,
            ...(filter as any),
          },
          q,
        },
      });
      result && debugBase64('Expense', result);
    } catch {}
  };

  return (
    <Popover opened={isPopoverOpen} onChange={setIsPopoverOpen}>
      <Popover.Target>
        <Button
          variant="secondary"
          rightIcon={(size) => <ChevronDownIcon {...{ size }} />}
          loading={loadingExport}
          onClick={() => setIsPopoverOpen((prev) => !prev)}
        >
          {t('common:export_as')}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <div className={outcomeStyles.dropdownContainer}>
          <Button
            onClick={() => {
              setIsPopoverOpen(false);
              handleExportPDF();
            }}
            variant="tertiary"
            leftIcon={(size) => (
              <ExportPDFIcon size={size} color={colors.black} />
            )}
          >
            <Text textVariant="BodyDefault" color={colors.black}>
              {t('common:export_as_pdf')}
            </Text>
          </Button>
          <Separator gap={8} direction="vertical" />
          <Button
            onClick={() => {
              setIsPopoverOpen(false);
              onHandleExport();
            }}
            variant="tertiary"
            leftIcon={(size) => (
              <ExportXLSIcon size={size} color={colors.black} />
            )}
          >
            <Text textVariant="BodyDefault" color={colors.black}>
              {t('common:export_as_excel')}
            </Text>
          </Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
}

export default React.memo(OutcomeExportComponent);
