import { authDispatcher, authSelector } from 'models/auth';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function Handler() {
  const dispatch = useDispatch();
  const isPermissionFetched = useSelector(
    authSelector.isPermissionFetchedSelector,
  );
  const { getPermission } = authDispatcher(dispatch);

  React.useEffect(() => {
    if (!isPermissionFetched) {
      getPermission();
    }
  }, [getPermission, isPermissionFetched]);

  return null;
}
