import {
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import {
  MutationFetchFunction,
  QueryFetchFunction,
  QueryTransformer,
} from 'common/helpers/common';

import {
  TransactionClosingLiteModel,
  TransactionClosingModel,
  getTransactionClosing,
  getTransactionClosings,
} from './model';

export const transactionClosingKey = {
  listKey: 'getTransactionClosings',
  detailKey: 'getTransactionClosing',
  list(input?: getTransactionClosings) {
    return [transactionClosingKey.listKey, input?.params];
  },
  detail(input?: getTransactionClosing) {
    return [transactionClosingKey.detailKey, input?.transactionClosingId];
  },
};

export function useGetTransactionClosings(
  input?: getTransactionClosings,
  options?: UseQueryOptions<
    ExtendedApiResult<TransactionClosingLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<TransactionClosingLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      transactionClosingKey.list(input),
      () =>
        QueryFetchFunction({
          url: 'transaction-closings',
          params: input?.params,
        }),
      options,
    ),
    TransactionClosingLiteModel,
  );
}

export function useGetTransactionClosing(
  options?: UseMutationOptions<
    ApiResult<TransactionClosingModel>,
    ApiError,
    getTransactionClosing
  >,
): UseMutationResult<
  ApiResult<TransactionClosingModel>,
  ApiError,
  getTransactionClosing
> {
  return useMutation<
    ApiResult<TransactionClosingModel>,
    ApiError,
    getTransactionClosing
  >(async function (body) {
    return await MutationFetchFunction({
      url: `transaction-closings/${body?.transactionClosingId}`,
      method: 'GET',
      classType: TransactionClosingModel,
    });
  }, options);
}
