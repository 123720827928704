import { AccountLiteModel } from 'api-hooks/account';
import { BrandLiteModel, BrandModel } from 'api-hooks/brand';
import { FileResultModel } from 'api-hooks/common';
import { CommonSimpleModel, getIndexInput } from 'api-hooks/common/model';
import { DivisionModel } from 'api-hooks/division';
import { EmployeeLiteModel } from 'api-hooks/employee';
import { Expose, Type } from 'class-transformer';

// Enum
export enum PurchaseRequisitionsStatusQueryEnum {
  IN_PROGRESS = 'in_progress',
  NEED_REVISION = 'need_revision',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum PurchaseRequisitionsStatusEnum {
  // others
  REJECTED = 'rejected',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',

  // top up
  HEAD_DIVISION_APPROVAL = 'head_division_approval',
  FINANCE_APPROVAL = 'finance_approval',
  CFO_APPROVAL = 'cfo_approval',
  NEED_REVISION = 'need_revision',
  NEED_TOP_UP = 'need_top_up',
  TOP_UP_DONE = 'top_up_done',
}

export enum PurchaseRequisitionsTypeEnum {
  top_up = 'top_up',
  others = 'others',
}

// Model
export class PurchaseRequisitionsLiteModel {
  id: string;
  title: string;
  code: string;
  status: string;
  description: string;
  division: DivisionModel;

  @Expose({ name: 'request_type' })
  requestType: string;

  @Type(() => BrandModel)
  brand: BrandModel;

  @Expose({ name: 'forwarded_division' })
  forwardedDivision: DivisionModel;

  @Expose({ name: 'approval_at' })
  @Type(() => Date)
  approvalAt: Date;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'deadline_at' })
  @Type(() => Date)
  deadlineAt: Date;
}

export class PurchaseRequisitionsHistoryModel {
  id: string;
  action: string;
  status: string;
  note: string | null;

  @Expose({ name: 'approved_by' })
  approvedBy: CommonSimpleModel;

  @Expose({ name: 'action_at' })
  @Type(() => Date)
  actionAt: Date;
}

export class PurchaseRequisitionsModel {
  id: string;
  code: string;
  title: string;
  description: string;
  status: string;

  division: DivisionModel;

  @Expose({ name: 'request_type' })
  requestType: string;

  @Expose({ name: 'item_type' })
  itemType?: string;

  @Type(() => AccountLiteModel)
  account?: AccountLiteModel;

  @Type(() => Number)
  amount?: number;

  @Type(() => BrandLiteModel)
  brand: BrandLiteModel;

  @Type(() => FileResultModel)
  files: FileResultModel[];

  @Type(() => PurchaseRequisitionsHistoryModel)
  histories: PurchaseRequisitionsHistoryModel[];

  @Expose({ name: 'forwarded_division' })
  fowardedDivision: DivisionModel;

  @Expose({ name: 'deadline_at' })
  @Type(() => Date)
  deadlineAt: Date;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;
}

export class PurchaseRequisitionsChangeLogModel {
  id: string;
  @Type(() => EmployeeLiteModel)
  employee: EmployeeLiteModel;

  @Type(() => Date)
  @Expose({ name: 'changed_at' })
  changedAt: Date;

  @Type(() => Date)
  @Expose({ name: 'deadline_at' })
  deadlineAt: Date;
}

// Input
export class getPurchaseRequisitionInput {
  id: string;
}

export type getPurchaseRequisitionsInput = getIndexInput<{
  status?: PurchaseRequisitionsStatusQueryEnum | string;
}>;

export type getPurchaseRequisitionsChangeLogInput = {
  id: string;
};

export type PurchaseRequisitionsMutationInput = {
  entityId: string;
  title: string;
  itemType: string;
  description: string;
  brandId: string;
  files: string[];
  requestType: string;
  deadlineAt: Date;

  // top up only
  divisionId?: string;
  accountId?: string;
  amount?: number;
};

export type PurchaseRequisitionsUpdateInput = {
  id: string;
  body: PurchaseRequisitionsMutationInput;
};

export type PurchaseRequisitionsDeleteInput = {
  id: string;
};

export type PurchaseRequisitionsExtendDeadlineInput = {
  id: string;
  body: {
    deadlineAt: Date | null;
  };
};
