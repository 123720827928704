import { string2moneyDefault } from 'common/utils/string';
import Text from 'components/elements/text';
import React from 'react';

const Balance = ({
  currency,
  balance,
}: {
  currency: string;
  balance: number;
}) => {
  return (
    <Text textVariant="MonoDefault">{`${currency} ${string2moneyDefault(
      balance,
    )}`}</Text>
  );
};

export default Balance;
