import { Loader, Modal } from '@mantine/core';
import { useGetMe, useRevokeToken } from 'api-hooks/auth';
import classNames from 'classnames';
import { LockIcon, SearchIcon, SignOutIcon } from 'common/assets';
import colors from 'common/styles/colors';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import Text from 'components/elements/text';
import TextInput from 'components/elements/text-input';
import { Route } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import useLogout from 'hooks/use-logout';
import { authDispatcher, authSelector } from 'models/auth';
import ChangePassword from 'modules/common/change-password';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import UserNav from './components/user-nav';
import NavigationComponent from './navigation-component';
import { sideNavigationStyles } from './style.css';

interface SideNavigationProps {
  isNavOpen: boolean;
  setIsNavOpen: React.Dispatch<React.SetStateAction<boolean>>;
  routes: Route[];
  badges?: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function NavStyle(props: any) {
  const { $active, $theme, $level, $selectable } = props;
  if (!$active) {
    if ($selectable) {
      return {
        borderLeftColor: '#787878',
        borderWidth: '2px',
        color: '#787878',
        ':hover': {
          color: '#707176',
          fontWeight: '400',
        },
      };
    } else {
      if ($level > 1) {
        return {
          color: 'white',
          fontWeight: '500',
        };
      } else {
        return {
          color: 'white',
          fontWeight: '500',
        };
      }
    }
  }

  return {
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    borderLeftColor: $theme.colors.secondary1,
    borderWidth: '4px',
    color: 'white',
    ':hover': {
      color: 'white',
    },
  };
}

export interface MappedRoute {
  title: string;
  subNav?: MappedRoute[];
  itemId?: string;
  leftIcon?: (color: string, size: number) => React.ReactNode | JSX.Element;
  ariaLabel?: string;
}

export default function SideNavigation(props: SideNavigationProps) {
  const dispatch = useDispatch();
  const isPermissionFetched = useSelector(
    authSelector.isPermissionFetchedSelector,
  );
  const searchInputRef = React.useRef<HTMLInputElement | null>(null);
  const [searchNavigation, setSearchNavigation] = React.useState('');
  const { getPermission } = authDispatcher(dispatch);
  const isFetchingPermission = useSelector(authSelector.isFetchingPermission);

  const { isNavOpen, routes, badges } = props;
  const { t } = useTranslation();
  const { can } = useAuthorization();

  const { data } = useGetMe();
  const drawer = useDialog();
  const { mutateAsync } = useRevokeToken();
  const { handleLogout } = useLogout();

  const onLogout = React.useCallback(async () => {
    try {
      await mutateAsync(undefined);
      await handleLogout();
    } catch (e) {
      console.log(e);
    }
  }, [handleLogout, mutateAsync]);

  const onClickLogout = React.useCallback(() => {
    drawer.showCustom({
      render: (dismiss) => (
        <Modal
          opened
          onClose={dismiss}
          title={<Text textVariant="HeadingLarge">{t('modal:log_out')}</Text>}
          size={500}
          style={{ textAlign: 'center' }}
        >
          <Separator direction="vertical" gap={32} />
          <UserNav
            email={data?.data?.email}
            position={data?.data?.roles}
            user={data?.data?.name}
            type="secondary"
          />
          <Text textVariant="BodyDefault">
            {t('modal:logout_confirmation')}
          </Text>
          <Separator direction="vertical" gap={32} />
          <div className={moduleStyles.fullContainer}>
            <Button
              onClick={onLogout}
              error
              className={moduleStyles.buttonDialog}
            >
              {t('modal:log_out')}
            </Button>
            <Separator direction="vertical" gap={16} />

            <Button
              onClick={dismiss}
              variant="tertiary"
              className={moduleStyles.buttonDialog}
            >
              <Text color={colors.textLight} textVariant="ButtonDefault">
                {t('common:cancel')}
              </Text>
            </Button>
          </div>
        </Modal>
      ),
    });
  }, [
    data?.data?.email,
    data?.data?.name,
    data?.data?.roles,
    drawer,
    onLogout,
    t,
  ]);

  const onClickChangePassword = () => {
    drawer.showCustom({
      render: (dismiss) => (
        <Modal
          opened
          onClose={dismiss}
          title={
            <Text textVariant="HeadingLarge">
              {t('common:change_password')}
            </Text>
          }
          size={500}
        >
          <ChangePassword handleLogout={handleLogout} />
        </Modal>
      ),
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function MapAllRoute(item: Route, searchValue) {
    if (item.children) {
      return {
        title: t(item.title),
        subNav: item?.children
          ?.map((child) => MapAllRoute(child, searchValue))
          .filter((x) => x !== undefined),
        leftIcon: item?.leftIcon,
      };
    }

    if (!item.permission || !can(item.permission) || item.hide) {
      return undefined;
    }
    const navigation = {
      title: t(item.title),
      itemId: item.routeName,
      leftIcon: item?.leftIcon,
      ariaLabel: item.permission,
    };
    if (searchValue) {
      const title = t(item.title);
      if (title.toLowerCase().includes(searchValue.toLowerCase())) {
        return navigation;
      }
      return undefined;
    }
    return navigation;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function FilterAllUnUsedNavigation(item: any[]) {
    return item.filter((currentItem) => {
      if (!('subNav' in currentItem)) {
        return true;
      }

      if (currentItem?.subNav?.length) {
        const current = FilterAllUnUsedNavigation(currentItem?.subNav);

        currentItem.subNav = current;

        return !!current.length;
      } else {
        return false;
      }
    });
  }

  const NAVIGATION = React.useMemo(() => {
    return routes
      .map((route) => MapAllRoute(route, searchNavigation))
      .filter((x) => x !== undefined);
  }, [MapAllRoute, routes, searchNavigation]);

  const FILTERED_NAVIGATION: MappedRoute[] = React.useMemo(() => {
    if (NAVIGATION) {
      return FilterAllUnUsedNavigation([...NAVIGATION]);
    }

    return [];
  }, [FilterAllUnUsedNavigation, NAVIGATION]);

  const onFocusSearch = (event: KeyboardEvent) => {
    if (
      (event.ctrlKey || event.metaKey) &&
      event.shiftKey &&
      event.code === 'KeyF'
    ) {
      searchInputRef.current?.focus();
    }
    return false;
  };

  React.useEffect(() => {
    document.addEventListener('keydown', onFocusSearch, false);

    return () => {
      document.removeEventListener('keydown', onFocusSearch, false);
    };
  }, []);

  const badgesObj = badges ?? {};

  return (
    <div
      className={classNames(
        'side-navigation-container',
        sideNavigationStyles.container({ isActive: isNavOpen }),
      )}
    >
      <FetchWrapperComponent
        errorVertical
        isLoading={isFetchingPermission}
        error={!isFetchingPermission && !isPermissionFetched}
        loadingComponent={
          <div className={sideNavigationStyles.loadingContainer}>
            <Loader color={colors.productNormal} />
          </div>
        }
        errorComponent={
          <div className={sideNavigationStyles.loadingContainer}>
            <ErrorViewComponent vertical refetch={getPermission} />
          </div>
        }
        component={
          <div
            className={sideNavigationStyles.navigationContainer({
              isActive: isNavOpen,
            })}
          >
            <UserNav
              email={data?.data?.email}
              position={data?.data?.roles}
              user={data?.data?.name}
            />
            <TextInput
              ref={searchInputRef}
              placeholder="Search: Ctrl / ⌘ + Shift + F"
              style={{ marginLeft: 16, marginRight: 16, marginTop: 16 }}
              rightSection={
                <div className={sideNavigationStyles.searchIconContainer}>
                  <SearchIcon
                    size={20}
                    color={searchNavigation ? colors.black : colors.bgMainHover}
                  />
                </div>
              }
              onChange={(e) => setSearchNavigation(e?.target?.value || '')}
            />
            {FILTERED_NAVIGATION.map((nav, idx) => (
              <NavigationComponent
                route={nav}
                depth={1}
                key={`parent-${idx}`}
                badges={badgesObj}
              />
            ))}
            <div className={sideNavigationStyles.logoutContainer}>
              <Separator
                gap={4}
                direction="vertical"
                style={{
                  backgroundColor: colors.dividerDefault,
                }}
              />
              <Separator gap={16} direction="vertical" />
              <Button
                variant="tertiary"
                className={sideNavigationStyles.actionButton}
                leftIcon={(size) => <LockIcon size={size} />}
                onClick={onClickChangePassword}
              >
                {t('common:change_password')}
              </Button>
              <Separator gap={4} direction="vertical" />
              <Button
                variant="tertiary"
                className={sideNavigationStyles.actionButton}
                leftIcon={(size) => <SignOutIcon size={size} />}
                onClick={onClickLogout}
              >
                {t('common:logout')}
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
}
