import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  StockItemCodeDeleteInput,
  StockItemCodeModel,
  StockItemCodeMutationInput,
  StockItemCodeUpdateInput,
} from './model';

export function useCreateStockItemCode(
  options?: UseMutationOptions<
    ApiResult<StockItemCodeModel>,
    ApiError,
    StockItemCodeMutationInput
  >,
): UseMutationResult<
  ApiResult<StockItemCodeModel>,
  ApiError,
  StockItemCodeMutationInput
> {
  return useMutation<
    ApiResult<StockItemCodeModel>,
    ApiError,
    StockItemCodeMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'stock-item-codes',
      method: 'POST',
      classType: StockItemCodeModel,
      body,
    });
  }, options);
}

export function useUpdateStockItemCode(
  options?: UseMutationOptions<
    ApiResult<StockItemCodeModel>,
    ApiError,
    StockItemCodeUpdateInput
  >,
): UseMutationResult<
  ApiResult<StockItemCodeModel>,
  ApiError,
  StockItemCodeUpdateInput
> {
  return useMutation<
    ApiResult<StockItemCodeModel>,
    ApiError,
    StockItemCodeUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `stock-item-codes/${body.stockItemCodeId}`,
      method: 'PUT',
      classType: StockItemCodeModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteStockItemCode(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    StockItemCodeDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, StockItemCodeDeleteInput> {
  return useMutation<MessageResult, ApiError, StockItemCodeDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `stock-item-codes/${body.stockItemCodeId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
