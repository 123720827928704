import { useGetChartOfAccountsReport } from 'api-hooks/report';
import { RefreshIcon } from 'common/assets';
import filterReduce from 'common/helpers/filter-reduce';
import EmptyView from 'components/common/empty-view';
import ListHeader from 'components/common/list-header';
import { useApplyQueryFilter } from 'components/common/query-filter/query-filter';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { Button } from 'components/elements/button';
import { PivotTable } from 'components/pivot';
import { NavigationProps } from 'containers/navigation';
import { sub } from 'date-fns';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import produce from 'immer';
import ReportQueryFilter from 'modules/report/components/query-filter';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import ExportButton from './components/export-button';
import { coaReportStyles } from './styles.css';

type Params = {
  transactionAtAfter: Date | null;
  transactionAtBefore: Date | null;
  coaIds: string[] | null;
  brandIds: string[] | null;
};

const REQUIRED_KEYS = [
  'chart_of_account_ids',
  'transaction_at_after',
  'transaction_at_before',
];

const HIDDEN_ROWS = ['file'];
const NUMBER_COLOR_KEYS = ['amount', 'baseAmount'];

const today = new Date();
const _30DayBefore = sub(today, { days: 30 });

export default function ChartOfAccountReport(props: NavigationProps) {
  const { t } = useTranslation();
  const { entity } = useEntity();

  const params: Params | undefined = props.navigation.params;

  const {
    data,
    refetch,
    extras: [{ filters, setFilters }],
    error,
  } = useComposedQuery(
    useGetChartOfAccountsReport,
    {
      params: {
        filter: {
          entity_id: entity?.id,
          chart_of_account_ids: params?.coaIds?.join('|') || undefined,
          brand_ids: params?.brandIds?.join('|') || undefined,
          transaction_at_before: params?.transactionAtBefore || today,
          transaction_at_after: params?.transactionAtAfter || _30DayBefore,
        },
      },
    },
    useApplyQueryFilter((data: any) => data?.filters),
  );

  React.useEffect(() => {
    const temp = {
      chart_of_account_ids: params?.coaIds?.join('|') || undefined,
      brand_ids: params?.brandIds?.join('|') || undefined,
      transaction_at_before:
        params?.transactionAtBefore?.toISOString() || today,
      transaction_at_after:
        params?.transactionAtAfter?.toISOString() || _30DayBefore,
    };

    Object.entries(temp).map((key) => {
      setFilters((prevFilter) =>
        produce(prevFilter, (draft) => {
          const matchedFilter = draft?.find((f) => f.name === key[0]);
          if (matchedFilter) {
            matchedFilter.value = String(key[1]);
          }
        }),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.navigation.params]);

  return (
    <div>
      <ListHeader
        showAdd={false}
        showRefresh={false}
        title={t('common:coa_transaction_report')}
        rightCustomComponent={
          <>
            {!!data?.data?.length && (
              <ExportButton
                params={{
                  filter: {
                    ...(filterReduce(filters || []) as any),
                    entity_id: entity?.id,
                  },
                }}
                category="chart_of_account"
              />
            )}

            <Separator gap={16} />
            <Button
              onClick={() => refetch()}
              leftIcon={(size) => <RefreshIcon size={size} />}
              variant="secondary"
            >
              {t('common:refresh')}
            </Button>
          </>
        }
      />
      {!!error && (
        <>
          <Alert
            variant="error"
            description={error.message}
            withCloseButton={false}
          />
          <Separator gap={16} direction="vertical" />
        </>
      )}
      {filters && (
        <div className={coaReportStyles.filterContainer}>
          <ReportQueryFilter
            filters={filters}
            setFilters={setFilters}
            compact={{
              isCompact: false,
              requiredKeys: REQUIRED_KEYS,
            }}
          />
        </div>
      )}
      {data?.data?.length ? (
        <PivotTable
          hiddenRows={HIDDEN_ROWS}
          queryResultData={data}
          numberColorKeys={NUMBER_COLOR_KEYS}
          currencyAccessorKey="amount"
        />
      ) : (
        <EmptyView />
      )}
    </div>
  );
}
