import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { Translate } from 'next-translate';
import React from 'react';

export default function EmployeeActiveSelectInput({ t }: { t: Translate }) {
  const selectOptions = React.useMemo<OptionProps[]>(
    () => [
      {
        value: '1',
        label: t('common:active'),
      },
      {
        value: '0',
        label: t('common:disabled'),
      },
    ],
    [t],
  );

  return (
    <Input
      type="select"
      data={selectOptions}
      name="isActive"
      noMargin
      clearable={false}
    />
  );
}
