import { CancelIcon, EditIcon } from 'common/assets';
import DeleteIconButton from 'components/common/delete-icon-button';
import Button from 'components/elements/button/default';
import PopoverButton from 'components/elements/button/responsive-popover-button/popover-button';
import { Input } from 'components/elements/field';
import { FormContext, FormState } from 'components/elements/form/context';
import Text from 'components/elements/text';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import { formHeaderStyle } from './styles.css';

interface FormHeaderProps {
  data?: any;
  onDelete?: () => void;
  title: string | React.ReactNode;
  isDeleteLoading?: boolean;
  submitComponent?: React.ReactNode;
  noDelete?: boolean;
  noSubmit?: boolean;
  noEdit?: boolean;
  noCancel?: boolean;
  onRenderComponent?: React.ReactNode;
  onRenderRightComponent?: React.ReactNode;
  onClickCancel?: () => void;
  noSubmitLarge?: boolean;
  noCancelLarge?: boolean;
}

function FormHeaderContent(props: FormHeaderProps) {
  const { t } = useTranslation();
  const {
    data,
    onDelete,
    title,
    isDeleteLoading,
    submitComponent,
    onRenderComponent,
    onRenderRightComponent,
    noDelete,
    noEdit,
    noCancel,
    noSubmit,
    noSubmitLarge,
    noCancelLarge,
    onClickCancel,
  } = props;
  const {
    formState: { isSubmitting },
    reset,
  } = useFormContext<any>();
  return (
    <>
      <Text textVariant="HeadingSmall">{title}</Text>
      <FormState>
        {({ editable, setIsEditable }) => (
          <div
            className={moduleStyles.buttonContainer}
            style={{ alignItems: 'center' }}
          >
            {onRenderComponent}
            {data && !noDelete && !editable && (
              <div
                className={moduleStyles.actionContainer}
                style={{
                  paddingRight: '16px',
                }}
              >
                <DeleteIconButton
                  onClick={onDelete}
                  isLoading={isDeleteLoading}
                />
              </div>
            )}
            {data && !noEdit && (
              <>
                {editable || isSubmitting ? (
                  !noCancel && !noCancelLarge ? (
                    <div className={moduleStyles.buttonContainer}>
                      <Button
                        onClick={() => {
                          reset();
                          setIsEditable(false);
                          onClickCancel?.();
                        }}
                        variant="secondary"
                        error
                        leftIcon={(size) => (
                          <CancelIcon width={size} height={size} />
                        )}
                      >
                        {t('common:cancel')}
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <div className={moduleStyles.buttonContainer}>
                    <Button onClick={() => setIsEditable(true)}>
                      <>
                        <div
                          className={moduleStyles.actionContainer}
                          style={{ marginRight: '8px' }}
                        >
                          <EditIcon size={20} color="white" />
                        </div>
                        {t('common:edit')}
                      </>
                    </Button>
                  </div>
                )}
              </>
            )}
            {onRenderRightComponent}
            {!noSubmit && (
              <FormState>
                {({ editable }) =>
                  (isSubmitting || editable) && !noSubmitLarge ? (
                    <div
                      className={moduleStyles.buttonContainer}
                      style={{
                        marginLeft: noDelete && !data ? '0px' : '16px',
                      }}
                    >
                      {submitComponent ? (
                        submitComponent
                      ) : (
                        <Input type="submit" />
                      )}
                    </div>
                  ) : (
                    <></>
                  )
                }
              </FormState>
            )}
          </div>
        )}
      </FormState>
    </>
  );
}

function FormBottomContent(props: FormHeaderProps) {
  const { t } = useTranslation();
  const {
    data,
    onDelete,
    isDeleteLoading,
    submitComponent,
    noDelete,
    noEdit,
    noCancel,
    noSubmit,
    onClickCancel,
    onRenderComponent,
    onRenderRightComponent,
  } = props;
  const {
    formState: { isSubmitting },
    reset,
  } = useFormContext<any>();
  const { editable, setIsEditable } = useContext(FormContext);

  const deleteIconButton = React.useCallback(() => {
    return (
      <div className={moduleStyles.actionContainer}>
        <DeleteIconButton onClick={onDelete} isLoading={isDeleteLoading} />
      </div>
    );
  }, [isDeleteLoading, onDelete]);

  const cancelButton = React.useCallback(
    (withMargin?: boolean) => {
      return (
        <Button
          onClick={() => {
            reset();
            setIsEditable(false);
            onClickCancel?.();
          }}
          variant="secondary"
          error
          leftIcon={(size) => <CancelIcon width={size} height={size} />}
          style={{
            marginRight: withMargin ? '16px' : '0px',
          }}
        >
          {t('common:cancel')}
        </Button>
      );
    },
    [onClickCancel, reset, setIsEditable, t],
  );

  const editButton = React.useCallback(() => {
    return (
      <Button onClick={() => setIsEditable(true)}>
        <>
          <div
            className={moduleStyles.actionContainer}
            style={{ marginRight: '8px' }}
          >
            <EditIcon size={20} color="white" />
          </div>
          {t('common:edit')}
        </>
      </Button>
    );
  }, [setIsEditable, t]);

  const submitButton = React.useCallback(() => {
    return (
      <div className={moduleStyles.buttonContainer}>
        {submitComponent ? submitComponent : <Input type="submit" />}
      </div>
    );
  }, [submitComponent]);

  const content = React.useMemo<React.ReactNode[]>(() => {
    const temp: React.ReactNode[] = [];
    if (data && !noEdit) {
      if (editable || isSubmitting) {
        !noCancel && temp.push(cancelButton());
      } else {
        temp.push(editButton());
      }
    }
    if (!editable && !noDelete && !!data) {
      temp.push(deleteIconButton());
    }
    onRenderComponent && temp.push(onRenderComponent);
    onRenderRightComponent && temp.push(onRenderRightComponent);
    return temp;
  }, [
    cancelButton,
    data,
    deleteIconButton,
    editButton,
    editable,
    isSubmitting,
    noCancel,
    noDelete,
    noEdit,
    onRenderComponent,
    onRenderRightComponent,
  ]);

  const submitHandlerComponent = React.useMemo<React.ReactNode[]>(() => {
    const temp: React.ReactNode[] = [];
    data &&
      !noEdit &&
      (editable || isSubmitting) &&
      !noCancel &&
      temp.push(cancelButton(!noSubmit && editable && !isSubmitting));
    !noSubmit && editable && !isSubmitting && temp.push(submitButton());

    return temp;
  }, [
    cancelButton,
    data,
    editable,
    isSubmitting,
    noCancel,
    noEdit,
    noSubmit,
    submitButton,
  ]);
  return (
    <div className={moduleStyles.fullContainer}>
      {editable ? (
        <div className={formHeaderStyle.submitHandler}>
          {submitHandlerComponent}
        </div>
      ) : (
        <div className={moduleStyles.row()}>
          <div className={formHeaderStyle.firstButton}>{content[0]}</div>
          {content.length > 1 && (
            <div className={formHeaderStyle.showMoreButton}>
              <PopoverButton component={content.slice(1, content.length + 1)} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default function FormHeader(props: FormHeaderProps) {
  const { title } = props;
  return (
    <>
      <div className={moduleStyles.viewScreenLarge}>
        <div className={moduleStyles.topFormContainer}>
          <FormHeaderContent {...props} />
        </div>
      </div>

      <div className={moduleStyles.viewScreenSmall}>
        <div className={moduleStyles.topFormContainer}>
          <Text textVariant="HeadingSmall">{title}</Text>
        </div>
        <div className={moduleStyles.bottomFormContainer}>
          <FormBottomContent {...props} />
        </div>
      </div>
    </>
  );
}
