import { getIndexInput } from 'api-hooks/common';
import { AccountLiteModel } from 'api-hooks/role';
import { Expose, Type } from 'class-transformer';

// Model
export class AccountMutationLiteModel {
  id: string;
  description: string | null;

  @Type(() => AccountLiteModel)
  account: AccountLiteModel;

  @Type(() => Number)
  amount: number;

  @Type(() => Number)
  rate: number;

  @Expose({ name: 'is_base_currency' })
  @Type(() => Boolean)
  isBaseCurrency: boolean;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;
}
export class AccountMutationModel extends AccountMutationLiteModel {
  @Expose({ name: 'is_transaction_closed' })
  isTransactionClosed: boolean;
}

// Input
export class getAccountMutationInput {
  id: string;
}

export type getAccountMutationsInput = getIndexInput<{
  account_id?: string;
}>;

export class AccountMutationMutationInput {
  entityId: string;
  accountId: string;
  description: string;
  amount: number;
  transactionAt: Date;
  rate: number;
}

export class AccountMutationUpdateInput {
  accountMutationId: string;
  body: AccountMutationMutationInput;
}

export class AccountMutationDeleteInput {
  accountMutationId: string;
}

export class AccountMutationPatchInput {
  accountMutationId: string;
  body: {
    transactionAt: Date;
  };
}
