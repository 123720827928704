import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getReportsInput,
  ReportingResultModel,
  StockDetailReportResultModel,
  StockSimpleDataModel,
  StockSimpleReportResultModel,
  TransactionReportResultModel,
  TransactionReportSummaryModel,
  TransactionReportSummaryResultModel,
} from './model';

export function useGetMutationReport(
  input?: getReportsInput,
  options?: UseQueryOptions<ReportingResultModel, ApiError>,
): UseQueryResult<ReportingResultModel, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getStockReport', input?.params],
      () =>
        QueryFetchFunction({
          url: `reports/account`,
          params: input?.params,
        }),
      options,
    ),
    ReportingResultModel,
  );
}

export function useGetChartOfAccountsReport(
  input?: getReportsInput,
  options?: UseQueryOptions<ReportingResultModel, ApiError>,
): UseQueryResult<ReportingResultModel, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getCOAStockReport', input?.params],
      () =>
        QueryFetchFunction({
          url: `reports/chart-of-accounts`,
          params: input?.params,
        }),
      options,
    ),
    ReportingResultModel,
  );
}

export function useGetInfiniteTransactionReport(
  input?: getReportsInput,
  options?: UseInfiniteQueryOptions<TransactionReportResultModel, ApiError>,
): UseInfiniteQueryResult<TransactionReportResultModel, ApiError> {
  return QueryTransformer(
    useInfiniteQuery(
      ['getTransactionReport', input?.params],
      async ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: `reports/transactions`,
          params: { ...input?.params, page: pageParam },
        }),
      options,
    ),
    TransactionReportResultModel,
  );
}

export function useGetTransactionSummary(
  input?: getReportsInput,
  options?: UseQueryOptions<TransactionReportSummaryResultModel, ApiError>,
): UseQueryResult<TransactionReportSummaryResultModel, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getTransactionSummaryReport', input?.params],
      () =>
        QueryFetchFunction({
          url: `reports/transactions/summary`,
          params: input?.params,
        }),
      options,
    ),
    TransactionReportSummaryModel,
  );
}

export function useGetInfiniteStockSimpleReport(
  input?: getReportsInput,
  options?: UseInfiniteQueryOptions<StockSimpleReportResultModel, ApiError>,
): UseInfiniteQueryResult<StockSimpleReportResultModel, ApiError> {
  return QueryTransformer(
    useInfiniteQuery(
      ['getStockSimpleReport', input?.params],
      async ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: `reports/stock-simple`,
          params: { ...input?.params, page: pageParam },
        }),
      options,
    ),
    StockSimpleDataModel,
  );
}

export function useGetInfiniteStockDetailReport(
  input?: getReportsInput,
  options?: UseInfiniteQueryOptions<StockDetailReportResultModel, ApiError>,
): UseInfiniteQueryResult<StockDetailReportResultModel, ApiError> {
  return QueryTransformer(
    useInfiniteQuery(
      ['getStockDetailReport', input?.params],
      async ({ pageParam = 1 }) =>
        QueryFetchFunction({
          url: `reports/stock-detail`,
          params: { ...input?.params, page: pageParam },
        }),
      options,
    ),
    StockDetailReportResultModel,
  );
}
