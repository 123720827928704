import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  AccountMutationLiteModel,
  AccountMutationModel,
  getAccountMutationInput,
  getAccountMutationsInput,
} from './model';

export function useGetAccountMutations(
  input?: getAccountMutationsInput,
  options?: UseQueryOptions<
    ExtendedApiResult<AccountMutationLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<AccountMutationLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getAccountMutations', input?.params],
      () =>
        QueryFetchFunction({
          url: 'wallet-mutations',
          params: input?.params,
        }),
      options,
    ),
    AccountMutationLiteModel,
  );
}

export function useGetAccountMutation(
  input?: getAccountMutationInput,
  options?: UseQueryOptions<ApiResult<AccountMutationModel>, ApiError>,
): UseQueryResult<ApiResult<AccountMutationModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getAccountMutation', input?.id],
      () =>
        QueryFetchFunction({
          url: `wallet-mutations/${input?.id}`,
        }),
      options,
    ),
    AccountMutationModel,
  );
}

export function getAccountMutationsKey(input?: getAccountMutationsInput) {
  return ['getAccountMutations', input?.params];
}
export function getAccountMutationKey(input?: getAccountMutationInput) {
  return ['getAccountMutation', input?.id];
}
