import {
  SupplierLiteModel,
  SupplierModel,
  getSupplierKey,
  getSuppliersKey,
  useGetSupplier,
  useGetSuppliers,
} from 'api-hooks/supplier';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  onAfterDetailChange?: (value: any) => void;
  disabled?: boolean;
  noMargin?: boolean;
  divisionId?: string;
}

function transformer(item: SupplierLiteModel | SupplierModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}

export default function SupplierSelectInput(props: Props) {
  const { t } = useTranslation();
  const {
    label = t('common:supplier'),
    placeholder = t('common:choose_extra', { extra: t('common:supplier') }),
    divisionId,
  } = props;
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const { entity } = useEntity();

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetSuppliers,
    useDetailLazyQueryHook: useGetSupplier,
    getListsKey: React.useCallback(
      (page, q) =>
        getSuppliersKey({
          params: {
            q,
            page,
            filter: {
              entity_id: entity?.id!,
              ...(divisionId && {
                division_id: divisionId,
              }),
            },
          },
        }),
      [divisionId, entity?.id],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            entity_id: entity?.id!,
            ...(divisionId && {
              division_id: divisionId,
            }),
          },
        },
      }),
      [divisionId, entity?.id],
    ),
    renderCreate: false,
    renderNoData: true,
    getDetailKey: React.useCallback(
      (value) =>
        getSupplierKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      label={label}
      required
      name={props.name}
      placeholder={placeholder}
      onAfterChange={props.onAfterChange}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
