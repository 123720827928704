import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction } from 'common/helpers/common';

import {
  EnumResultModel,
  FileResultModel,
  getEnumsInput,
  getFilesInput,
} from './model';

export function useGetEnums(
  input?: getEnumsInput,
  options?: UseQueryOptions<ApiResult<EnumResultModel[]>, ApiError>,
) {
  return useQuery<ApiResult<EnumResultModel[]>, ApiError>(
    ['getEnums', input?.class],
    () =>
      QueryFetchFunction({
        url: `enums/${input?.class}`,
      }),
    options,
  );
}

export function useGetHealth(
  input?: any,
  options?: UseQueryOptions<{ status: string }, ApiError>,
) {
  return useQuery<{ status: string }, ApiError>(
    ['getHealth', input?.class],
    () =>
      QueryFetchFunction({
        url: `health`,
      }),
    options,
  );
}

export function useGetFiles(
  input?: getFilesInput,
  options?: UseQueryOptions<ApiResult<FileResultModel[]>, ApiError>,
) {
  return useQuery<ApiResult<FileResultModel[]>, ApiError>(
    ['getFiles', input?.params],
    () =>
      QueryFetchFunction({
        url: `${input?.params}`,
      }),
    options,
  );
}
