import { PivotModel } from 'api-hooks/common';
import { MoreIcon } from 'common/assets';
import Checkbox from 'components/elements/checkbox';
import Text from 'components/elements/text';
import produce from 'immer';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';

import { pivotStyle } from './style.css';

export interface PivotControlProps {
  pivot: PivotModel;
  onPivotChange: (pivot: PivotModel) => void;
}

function moveArrayByIndex<T>(
  arr: T[],
  source: number,
  destination: number,
): T[] {
  let newArr: T[] = [];

  if (source === destination) {
    return arr;
  }

  for (let i = 0; i < arr.length; i++) {
    if (i === source) {
      continue;
    } else if (i === destination) {
      const temp: T[] = [arr[i], arr[source]];
      newArr = newArr.concat(source < destination ? temp : temp.reverse());
    } else {
      newArr.push(arr[i]);
    }
  }
  return newArr;
}

export default function PivotControl(props: PivotControlProps) {
  const { onPivotChange, pivot } = props;
  const { t } = useTranslation();

  const onDragEnd: OnDragEndResponder = React.useCallback(
    (result) => {
      const source = result.source.index;
      const destination = result.destination?.index || 0;

      onPivotChange(
        produce(pivot, (draftPivot) => {
          draftPivot.value.rows = moveArrayByIndex(
            draftPivot.value.rows,
            source,
            destination,
          );
        }),
      );
    },
    [onPivotChange, pivot],
  );

  const onChecked = React.useCallback(
    (index: number, checked: boolean) => {
      const newPivot = produce(pivot, (draftPivot) => {
        draftPivot.value.rows[index].showTotal = checked;
      });
      onPivotChange(newPivot);
    },
    [onPivotChange, pivot],
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="pivot-droppable">
        {(provided, snapshot) => (
          <div
            className={pivotStyle.pivotList}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {pivot.value.rows.map((row, index) => (
              <Draggable key={row.name} index={index} draggableId={row.name}>
                {(provided) => (
                  <div
                    className={pivotStyle.cardContainer}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Text
                      textVariant="BodyBoldDefault"
                      style={{ marginBottom: '8px' }}
                    >
                      {row.label}
                    </Text>
                    <Checkbox
                      checked={row.showTotal}
                      label={t('common:show_total')}
                      onChange={(e) =>
                        onChecked(index, (e?.target as any)?.checked)
                      }
                    />
                    <div className={pivotStyle.iconDrag}>
                      <MoreIcon />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
