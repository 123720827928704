import { StockItemModel } from 'api-hooks/stock-item';
import classNames from 'classnames';
import colors from 'common/styles/colors';
import Text from 'components/elements/text';
import { moduleStyles } from 'modules/styles.css';
import Image from 'next/image';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { stockGroupingStyles } from '../styles.css';

export default function StockGroupingItem({ keyName }: { keyName: string }) {
  const { control } = useFormContext();
  const value: StockItemModel = useWatch({ name: keyName, control });

  if (!value) {
    return <></>;
  }
  return (
    <div className={moduleStyles.itemContainer}>
      <div
        className={classNames(
          moduleStyles.row(),
          stockGroupingStyles.itemContainer,
        )}
      >
        <Image
          width={42}
          height={42}
          src={value?.files?.length ? value.files?.[0].url : '/assets/logo.svg'}
          alt="stockItemImage"
          className={stockGroupingStyles.itemImage}
        />
        <div className={stockGroupingStyles.itemRightContainer}>
          <Text textVariant="BodyBoldDefault">
            {`${value.name} ${value.merk ? `- ${value.merk}` : ''} ${
              value.description ? `- ${value.description}` : ''
            }`}
          </Text>
          <Text textVariant="BodySmaller" color={colors.textLight}>
            {value.barcode}
          </Text>
        </div>
      </div>
    </div>
  );
}
