import { Control, useWatch } from 'react-hook-form';

export default function HOCWatchValueCustomControl(props: {
  keys: string[];
  control: Control<any>;
}) {
  const { keys, control } = props;
  const arrValues = useWatch({ name: keys, control });

  const objValues = arrValues.reduce(
    (prev, next, idx) => ({
      ...prev,
      [keys[idx]]: next,
    }),
    {},
  );
  return objValues;
}
