import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import {
  MutationFetchFunction,
  QueryFetchFunction,
  QueryTransformer,
} from 'common/helpers/common';
import { useEntity } from 'hooks/use-entities';

import {
  getTransferInput,
  getTransfersInput,
  TransferLiteModel,
  TransferModel,
} from './model';

export function useGetTransfers(
  input?: getTransfersInput,
  options?: UseQueryOptions<ExtendedApiResult<TransferLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<TransferLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getTransfers', input?.params],
      () =>
        QueryFetchFunction({
          url: 'transfers',
          params: input?.params,
        }),
      options,
    ),
    TransferLiteModel,
  );
}

export function useGetTransfer(
  input?: getTransferInput,
  options?: UseQueryOptions<ApiResult<TransferModel>, ApiError>,
): UseQueryResult<ApiResult<TransferModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getTransfer', input?.id],
      () =>
        QueryFetchFunction({
          url: `transfers/${input?.id}`,
        }),
      options,
    ),
    TransferModel,
  );
}

export function useImportTransferTemplate() {
  const { entity } = useEntity();
  return useMutation<ApiResult<any>, ApiError>(() =>
    MutationFetchFunction({
      url: 'transfers/import/template',
      method: 'GET',
      params: {
        entity_id: entity?.id!,
      },
    }),
  );
}

export function getTransfersKey(input?: getTransfersInput) {
  return ['getTransfers', input?.params];
}

export function getTransferKey(input?: getTransferInput) {
  return ['getTransfer', input?.id];
}
