import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getStockUsageInput,
  getStockUsagesInput,
  StockUsageLiteModel,
  StockUsageModel,
} from './model';

export const stockUsageKey = {
  listKey: 'getStockUsages',
  detailKey: 'getStockUsage',
  list(input?: getStockUsagesInput) {
    return [stockUsageKey.listKey, input?.params];
  },
  detail(input?: getStockUsageInput) {
    return [stockUsageKey.detailKey, input?.stockUsageId];
  },
};

export function useGetStockUsages(
  input?: getStockUsagesInput,
  options?: UseQueryOptions<ExtendedApiResult<StockUsageLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<StockUsageLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockUsageKey.list(input),
      () =>
        QueryFetchFunction({
          url: 'stock-usages',
          params: input?.params,
        }),
      options,
    ),
    StockUsageLiteModel,
  );
}

export function useGetStockUsage(
  input?: getStockUsageInput,
  options?: UseQueryOptions<ApiResult<StockUsageModel>, ApiError>,
): UseQueryResult<ApiResult<StockUsageModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      stockUsageKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `stock-usages/${input?.stockUsageId}`,
        }),
      options,
    ),
    StockUsageModel,
  );
}
