import {
  getIncomeKey,
  getIncomesKey,
  useGetIncome,
  useUpdateIncome,
} from 'api-hooks/income';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import IncomeForm from './components/form';

export default function IncomeView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } = useGetIncome({
    id: props.navigation.params.id,
  });
  const { mutateAsync, error: mutateError } = useUpdateIncome();

  const income = data?.data;

  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <IncomeForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          income={income}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              incomeId: income!.id,
            });
            form.reset();

            queryClient.refetchQueries([getIncomesKey()[0]]);
            queryClient.refetchQueries(getIncomeKey({ id: income!.id }));
            notification.success({ message: result?.message });

            return result.data;
          }}
        />
      }
    />
  );
}
