import { Loader } from '@mantine/core';
import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import { ApiError, Filter } from 'api-hooks/common/model';
import { ExportCategoryEnum } from 'api-hooks/export';
import ListHeader from 'components/common/list-header';
import QueryFilter from 'components/common/query-filter/query-filter';
import Separator from 'components/common/separator';
import { tableStyles } from 'components/elements/table/styles.css';
import ReportContainer from 'components/pivot/report-container';
import React from 'react';

import ExportButton, { Params } from './export-button';
import ReportQueryFilter from './query-filter';

export const getNextPageParam = (lastPage) => {
  if (
    (lastPage.meta as any).last_page === (lastPage.meta as any).current_page
  ) {
    return null;
  }
  return (lastPage.meta as any).current_page + 1;
};

type ListHeaderComponentProps = {
  title: string;
  params: Params;
  category: keyof typeof ExportCategoryEnum;
};

interface Props {
  header: ListHeaderComponentProps;
  setHeaderRef: React.Dispatch<
    React.SetStateAction<HTMLDivElement | null | undefined>
  >;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  isFetching: boolean;
  hasNextPage?: boolean;
  data: InfiniteData<any> | undefined;
  upperFilterKey?: string[];
  filters: Filter[];
  requiredKeys?: string[];
  headerOffsetHeight?: number;
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<any, ApiError>>;
  onExtraRenderUpperHeader?: () => React.ReactNode;
  onExtraRenderBottomHeader?: (currentData: any) => React.ReactNode;
}

export default function InfiniteReportContainer(props: Props) {
  const {
    header,
    setHeaderRef,
    data,
    upperFilterKey,
    filters,
    requiredKeys,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    setFilters,
    headerOffsetHeight,
    hasNextPage,
  } = props;

  const currentData = React.useMemo<any>(() => {
    if (data?.pages) {
      return data.pages.reduce((prev, cur) => ({
        data: {
          list: [...prev.data.list, ...cur.data.list],
          total: cur.data.total,
        },
        filters: cur.filters,
        meta: cur.meta,
        pivot: cur.pivot,
      }));
    }
    return undefined;
  }, [data?.pages]);

  const bottomFilter = filters?.filter(
    (item) => !upperFilterKey?.includes(item.name),
  );

  const upperFilter = filters?.filter((item) =>
    upperFilterKey?.includes(item.name),
  );

  const Header = React.useCallback(
    () => (
      <ListHeader
        showAdd={false}
        showRefresh={false}
        title={header.title}
        rightCustomComponent={
          <>
            {!!currentData?.data.list.length && (
              <ExportButton category={header.category} params={header.params} />
            )}
          </>
        }
      />
    ),
    [currentData?.data.list.length, header],
  );

  return (
    <div>
      <div ref={setHeaderRef}>
        <Header />
      </div>
      {!!currentData?.data && !!headerOffsetHeight ? (
        <ReportContainer
          onRenderHeader={
            <div>
              {props.onExtraRenderUpperHeader?.()}
              {!!upperFilter.length && (
                <ReportQueryFilter
                  filters={upperFilter}
                  setFilters={setFilters}
                  compact={{
                    isCompact: false,
                    requiredKeys,
                  }}
                />
              )}
              <QueryFilter
                setPage={() => {}}
                filters={bottomFilter}
                setFilters={setFilters}
              />
              {props.onExtraRenderBottomHeader?.(currentData)}
              <Separator gap={102} direction="vertical" />
            </div>
          }
          queryResultData={{
            ...currentData,
            data: currentData?.data?.list! || [],
            pivot: currentData?.pivot!,
          }}
          isLoading={isLoading || isFetching || isFetchingNextPage}
          headerHeight={headerOffsetHeight || 0}
          currencyAccessorKey="amount"
          {...(!isFetchingNextPage &&
            !isFetching &&
            !isLoading &&
            !!hasNextPage && {
              fetchNextPage,
            })}
        />
      ) : (
        <div className={tableStyles.noDataContainer}>
          {(isLoading || isFetching) && <Loader />}
        </div>
      )}
    </div>
  );
}
