import {
  PurchaseRequisitionsModel,
  PurchaseRequisitionsStatusEnum,
  PurchaseRequisitionsTypeEnum,
} from 'api-hooks/purchase-requisitions';
import { FeeOnEnum, StatusEnum, TransferTypeEnum } from 'api-hooks/transfer';
import formSetValues from 'common/helpers/form-setValues';
import colors from 'common/styles/colors';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import DateTimePicker from 'components/elements/date-time-picker';
import { Input } from 'components/elements/field';
import FormStateRawField from 'components/elements/form-state-raw-field';
import { useEntity } from 'hooks/use-entities';
import BrandMultiAllSelect from 'modules/entity-settings/brand/components/multi-all-select-input';
import { moduleStyles } from 'modules/styles.css';
import PurchaseRequisitionsSelectInput from 'modules/transactions/purchase-requisitions/components/select-input';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TransferInformationFormprops } from './form-type';
import TransferDetailForm from './transfer-detail-form';

const TransferInformationForm = (props: TransferInformationFormprops) => {
  const { t } = useTranslation();
  const { entity } = useEntity();

  const { setValue } = useFormContext<any>();
  return (
    <div>
      <div className={moduleStyles.sectionContainer}>
        <HOCInput keys={['type']}>
          {({ type }) =>
            type === TransferTypeEnum.top_up && (
              <div className={moduleStyles.halfContainer}>
                <PurchaseRequisitionsSelectInput
                  name="requestFormId"
                  required
                  label={t('transfer:pr_id')}
                  placeholder={t('transfer:pr_id')}
                  onAfterChange={(val: PurchaseRequisitionsModel) =>
                    formSetValues(
                      {
                        brands: [val.brand.id],
                        accountTo: {
                          id: val.account?.id,
                          isBaseCurrency: val.account?.isBaseCurrency,
                          balance: val.account?.balance,
                          currency: val.account?.currency,
                        },
                        accountToId: val.account?.id,
                        transferAmount: val.amount,
                        feeOn: FeeOnEnum.Sender,
                      },
                      setValue,
                    )
                  }
                  extraFilter={{
                    status: PurchaseRequisitionsStatusEnum.NEED_TOP_UP,
                    request_type: PurchaseRequisitionsTypeEnum.top_up,
                  }}
                />
              </div>
            )
          }
        </HOCInput>
        {!!props.hasValue && (
          <div className={moduleStyles.halfContainer}>
            <FormStateRawField
              type="text"
              label={t('common:code')}
              value={props.code}
            />
          </div>
        )}
      </div>

      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <Input
            type="date-time"
            label={t('common:transaction_at')}
            name="transactionAt"
            required
            disabled={props.hasValue}
          />
        </div>
        <div className={moduleStyles.halfContainer}>
          {props.status === StatusEnum.finished && (
            <DateTimePicker
              label={t('common:received_at')}
              value={props.receivedAt}
              disabled
              required
            />
          )}
        </div>
      </div>
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <Input
            label={t('common:description')}
            placeholder={t('common:enter_extra', {
              extra: t('common:description'),
            })}
            name="description"
            type="text"
          />
        </div>
        <div className={moduleStyles.halfContainer}>
          <BrandMultiAllSelect
            name="brands"
            label={t('common:brand')}
            entityId={entity?.id!}
          />
        </div>
      </div>
      <Separator
        gap={54}
        direction="vertical"
        style={{ borderTop: `1px solid ${colors.dividerDefault}` }}
      />
      <TransferDetailForm {...props} />
    </div>
  );
};

export default TransferInformationForm;
