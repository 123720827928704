import { ApprovalActionEnum, ApprovalModel } from 'api-hooks/approval';
import { useGetMe } from 'api-hooks/auth';
import {
  PurchaseRequisitionsStatusEnum,
  PurchaseRequisitionsTypeEnum,
} from 'api-hooks/purchase-requisitions';
import { CheckIcon, CancelIcon, EditIcon } from 'common/assets';
import { AuthorizationRules } from 'common/constants';
import { removeUnderscore } from 'common/utils/string';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import Badge from 'components/elements/badge';
import { Button } from 'components/elements/button';
import Text from 'components/elements/text';
import { useAuthorization } from 'hooks/use-authorization';
import { moduleStyles } from 'modules/styles.css';
import { badgeColor } from 'modules/transactions/purchase-requisitions/components/badgeColor';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import useApprovalActions from '../modal/use-action';

interface Props {
  approval: ApprovalModel;
}

export default function ApprovalFormHeader(props: Props) {
  const { approval } = props;

  const { t } = useTranslation();
  const { can } = useAuthorization();

  const { data } = useGetMe();

  const { onApprove, onReject, onCancel, onRequestRevision, errMessage } =
    useApprovalActions(approval?.id!);

  const _menus = React.useMemo(() => {
    const temp = approval.action.map((item) => {
      if (
        item === ApprovalActionEnum.APPROVE &&
        can(AuthorizationRules.ApprovalsApprove)
      ) {
        return {
          key: t('common:approve'),
          onClick: () => onApprove(approval.forwardedDivision),
          variant: 'secondary',
          success: true,
          warning: false,
          error: false,
          icon: (size) => <CheckIcon size={size} />,
        };
      }
      if (
        item === ApprovalActionEnum.REJECT &&
        can(AuthorizationRules.ApprovalsReject)
      ) {
        return {
          key: t('common:reject'),
          onClick: () => onReject(),
          variant: 'secondary',
          error: true,
          icon: (size) => <CancelIcon size={size} />,
        };
      }
      if (
        item === ApprovalActionEnum.CANCEL &&
        can(AuthorizationRules.ApprovalsCancel)
      ) {
        return {
          key: t('common:cancel_approval'),
          onClick: () => onCancel(),
          variant: 'secondary',
          error: true,
          icon: (size) => <CancelIcon size={size} />,
        };
      }
    });

    return temp;
  }, [
    approval.action,
    approval.forwardedDivision,
    can,
    t,
    onApprove,
    onReject,
    onCancel,
  ]);

  const menus = React.useMemo(() => {
    const temp = _menus;
    const approvalHistories = [...approval.approvalHistories];
    approvalHistories.pop();
    const currStatus = approvalHistories.find(
      (value) => value.employee.id === data?.data.id,
    )?.status;
    if (
      approval.requestForm.requestType ===
        PurchaseRequisitionsTypeEnum.top_up &&
      approval.status !== PurchaseRequisitionsStatusEnum.COMPLETED &&
      approval.status !== PurchaseRequisitionsStatusEnum.NEED_REVISION &&
      !currStatus?.includes('approve') &&
      currStatus !== 'request_submission'
    ) {
      const needRevision = {
        key: t('approval:need_revision'),
        onClick: () => onRequestRevision(),
        variant: 'secondary',
        warning: true,
        success: false,
        error: false,
        icon: (size) => <EditIcon size={size} />,
      };
      // eslint-disable-next-line no-unused-expressions
      temp.length > 1
        ? temp.splice(1, 0, needRevision)
        : temp.push(needRevision);
    }
    return temp;
  }, [
    _menus,
    approval.approvalHistories,
    approval.requestForm.requestType,
    approval.status,
    data?.data.id,
    onRequestRevision,
    t,
  ]);

  return (
    <>
      {!!errMessage && (
        <Alert
          variant="error"
          description={errMessage}
          withCloseButton={false}
        />
      )}
      <Separator gap={8} />
      <div className={moduleStyles.topFormContainer}>
        <div className={moduleStyles.row()}>
          <Text textVariant="HeadingSmall">
            {t('common:approval')} - {approval.code}
          </Text>
          <Separator gap={16} direction="horizontal" />

          <Badge
            radius={100}
            color={
              badgeColor.find((item) => approval.status === item.value)?.color
            }
          >
            {removeUnderscore(approval.status)}
          </Badge>
          <div className={moduleStyles.viewScreenSmall}>
            <Separator direction="vertical" gap={48} />
          </div>
        </div>

        <div className={moduleStyles.row()}>
          {menus.map((item) => (
            <React.Fragment key={item?.key}>
              <Button
                {...{
                  ...item,
                  leftIcon: item?.icon!,
                  variant: (item?.variant as any) || 'primary',
                  children: item?.key,
                }}
              />
              <Separator gap={16} />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className={moduleStyles.viewScreenSmall}>
        <Separator direction="vertical" gap={12} />
      </div>
    </>
  );
}
