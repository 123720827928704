import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import HOCInput from 'components/common/hoc-input';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import { FormState } from 'components/elements/form';
import FormStateRawField from 'components/elements/form-state-raw-field';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import EntityCurrencySelectInput from 'modules/entity-settings/entity-currency/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import AccountTypeSelectInput from './account-type-select-input';
import { mutationButtonStyle } from './style.css';

interface Props {
  hasData?: boolean;
  balance?: string;
  id?: string;
}

const AccountInformationForm = (props: Props) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { can } = useAuthorization();
  const { hasData, balance } = props;

  return (
    <div className={moduleStyles.fullContainer}>
      <div className={moduleStyles.halfContainer}>
        <Input
          label={t('account:name')}
          placeholder={t('account:name_placeholder')}
          name="name"
          type="text"
          required
        />
        <AccountTypeSelectInput name="type" disabled={hasData} />
      </div>

      <div className={moduleStyles.halfContainer}>
        <Input
          label={t('common:description')}
          placeholder={t('common:description_placeholder')}
          name="description"
          type="text"
        />
      </div>
      <div className={moduleStyles.halfContainer}>
        <EntityCurrencySelectInput
          name="entityCurrencyId"
          label={t('common:currency')}
          disabled={!!hasData}
        />
        {!hasData && (
          <HOCInput keys={['isBankConnected']}>
            {({ isBankConnected }) => (
              <>
                <Input
                  type="number"
                  isMoneyFormat
                  name="balance"
                  label={t('common:balance')}
                  hideControls
                  disabled={isBankConnected}
                  placeholder={t('common:enter_extra', {
                    extra: t('common:initial_balance'),
                  })}
                  required={!isBankConnected}
                />
              </>
            )}
          </HOCInput>
        )}
      </div>

      {hasData && (
        <div className={moduleStyles.sectionContainer}>
          <div className={moduleStyles.halfContainer}>
            <FormStateRawField
              label={t('common:balance')}
              value={balance}
              type="text"
              required
            />
          </div>
          <FormState>
            {({ editable }) =>
              !editable &&
              can(AuthorizationRules.ReportsAccount) && (
                <div
                  className={moduleStyles.halfContainer}
                  style={{ alignSelf: 'center', marginTop: 10 }}
                >
                  <Button
                    variant="secondary"
                    onClick={() =>
                      navigate(NavigationRoutes.AccountMutationReportList, {
                        params: props.id,
                      })
                    }
                    className={mutationButtonStyle}
                  >
                    {t('account:view_mutation')}
                  </Button>
                </div>
              )
            }
          </FormState>
        </div>
      )}
    </div>
  );
};

export default AccountInformationForm;
