import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  ApiError,
  ApiResult,
  ExtendedApiResult,
  getIndexInput,
} from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  EmployeeModel,
  EmployeeLiteModel,
  getEmployeeInput,
  getEmployeesInput,
} from './model';

export function useGetEmployees(
  input?: getIndexInput,
  options?: UseQueryOptions<ExtendedApiResult<EmployeeLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<EmployeeLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getEmployees', input?.params],
      () => QueryFetchFunction({ url: 'employees', params: input?.params }),
      options,
    ),
    EmployeeLiteModel,
  );
}

export function useGetEmployee(
  input?: getEmployeeInput,
  options?: UseQueryOptions<ApiResult<EmployeeModel>, ApiError>,
): UseQueryResult<ApiResult<EmployeeModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getEmployee', input?.id],
      () =>
        QueryFetchFunction({
          url: `employees/${input?.id}`,
        }),
      options,
    ),
    EmployeeModel,
  );
}

export function getEmployeesKey(input?: getEmployeesInput) {
  return ['getEmployees', input?.params];
}

export function getEmployeeKey(input?: getEmployeeInput) {
  return ['getEmployee', input?.id];
}
