import { Modal } from '@mantine/core';
import { useExtendDeadlinePurchaseRequisitions } from 'api-hooks/purchase-requisitions';
import { ClockPlusIcon } from 'common/assets';
import notification from 'common/helpers/notification';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import DatePicker from 'components/elements/date-picker';
import Text from 'components/elements/text';
import useDialog from 'hooks/use-dialog';
import { moduleStyles } from 'modules/styles.css';
import { Translate } from 'next-translate';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import refetchDetail from '../helpers';

interface ModalProps {
  t: Translate;
  onClose: () => void;
  currentDeadline: Date | null;
  id: string;
}
interface Props {
  onAfterClick?: () => void;
  currentDeadline: Date | null;
  id: string;
}
export function RequestExtendFormModal(props: ModalProps) {
  const { t, onClose, currentDeadline, id } = props;
  const [date, setDate] = React.useState<Date | null>(null);
  const { mutateAsync } = useExtendDeadlinePurchaseRequisitions();

  const handleExtend = React.useCallback(async () => {
    try {
      const res = await mutateAsync({
        body: {
          deadlineAt: date,
        },
        id,
      });
      onClose();
      refetchDetail(res, id);
    } catch (e) {
      notification.error({ message: e?.message });
    }
  }, [date, id, mutateAsync, onClose]);

  return (
    <Modal
      opened
      onClose={onClose}
      title={
        <Text textVariant="HeadingSmall">{t('modal:extend_deadline')}</Text>
      }
      size={500}
    >
      <DatePicker
        value={currentDeadline}
        placeholder={t('common:select_extra', {
          extra: t('modal:current_deadline'),
        })}
        label={t('modal:current_deadline')}
        disabled
      />
      <DatePicker
        value={date}
        placeholder={t('common:select_extra', {
          extra: t('modal:new_deadline'),
        })}
        label={t('modal:new_deadline')}
        onChange={(date) => setDate(date)}
      />
      <Separator gap={16} direction="vertical" />
      <Button
        type="button"
        onClick={handleExtend}
        className={moduleStyles.fullContainer}
      >
        {t('modal:change')}
      </Button>
    </Modal>
  );
}

export default function RequestExtend(props: Props) {
  const { t } = useTranslation();
  const dialog = useDialog();

  const handleClick = React.useCallback(() => {
    props.onAfterClick?.();
    dialog.showCustom({
      render: (close) => (
        <RequestExtendFormModal
          t={t}
          onClose={close}
          id={props.id}
          currentDeadline={props.currentDeadline}
        />
      ),
    });
  }, [dialog, props, t]);

  return (
    <Button
      type="button"
      onClick={handleClick}
      leftIcon={(size) => <ClockPlusIcon size={size} />}
    >
      {t('common:request_extend')}
    </Button>
  );
}
