import { getIndexInput } from 'api-hooks/common';
import { DivisionModel } from 'api-hooks/division';
import { Type } from 'class-transformer';

// Model
export class SupplierLiteModel {
  id: string;
  name: string;
  address: string;
  description: string;
  contact: string;
  division: DivisionModel;
}

export class SupplierModel extends SupplierLiteModel {
  contact: string;
  address: string;
  description: string;

  @Type(() => Number)
  lat: number;

  @Type(() => Number)
  lng: number;
}

// Input
export type getSupplierInput = {
  id: string;
};

export type getSuppliersInput = getIndexInput;

export type SupplierMutationInput = {
  name: string;
  lng: number;
  lat: number;
  address: string;
  contact: string;
  entityId: string;
  description: string;
};

export type SupplierUpdateInput = {
  supplierId: string;
  body: SupplierMutationInput;
};
export type SupplierDeleteInput = {
  supplierId: string;
};
