import { Progress as RawProgress } from '@mantine/core';
import { ActiveBudgetingItemLiteModel } from 'api-hooks/budgeting';
import { AlertIcon } from 'common/assets';
import colors from 'common/styles/colors';
import { string2moneyDefault } from 'common/utils/string';
import Separator from 'components/common/separator';
import Text from 'components/elements/text';
import { format } from 'date-fns';
import { moduleStyles } from 'modules/styles.css';
import { Translate } from 'next-translate';
import React from 'react';

import { SPENT_COLOR, TOP_UP_COLOR, progressStyle } from './styles.css';

interface ProgressBarProps extends ActiveBudgetingItemLiteModel {
  disabled?: boolean;
  t: Translate;
  variant?: 'secondary' | 'primary';
  onRenderRight?: React.ReactNode;
  showDetails?: boolean;
}

export default function ProgressBar(props: ProgressBarProps) {
  const {
    disabled,
    isOverBudget,
    isOverThreshold,
    amount,
    usedAmount,
    t,
    variant = 'primary',
    onRenderRight,
    toppedUpAmount,
    startFrom,
    endAt,
  } = props;
  const font = variant === 'primary' ? 'HeadingSmall' : 'MonoBoldDefault';

  const spentPercentage = (usedAmount / amount) * 100;
  const topUpPercentage = (toppedUpAmount / amount) * 100;

  const {
    state,
    color,
  }: { state: 'warn' | 'error' | 'normal'; color: string } = React.useMemo(
    () =>
      !isOverBudget
        ? isOverThreshold
          ? { state: 'warn', color: colors.bgWarning }
          : { state: 'normal', color: colors.bgProduct }
        : { state: 'error', color: colors.bgError },
    [isOverBudget, isOverThreshold],
  );

  return (
    <>
      <div className={moduleStyles.row({ justify: 'between' })}>
        <span className={progressStyle.amount}>
          <Text textVariant={font}>
            {`IDR `}
            <span
              style={{
                color:
                  state === 'normal' || disabled ? colors.textLight : color,
              }}
            >
              {string2moneyDefault(usedAmount)}
            </span>

            {` / ${string2moneyDefault(amount)}`}
          </Text>

          {onRenderRight}
          {state === 'error' && (
            <AlertIcon color={colors.textError} weight="bold" />
          )}
        </span>
        <Text textVariant="BodyDefault" color={colors.textLight}>
          {!props.showDetails && !disabled
            ? ''
            : `${format(startFrom, 'dd/MM/yyyy')} -
              ${format(endAt, 'dd/MM/yyyy')}`}
        </Text>
      </div>
      <Separator direction="vertical" gap={12}>
        <RawProgress
          size="lg"
          radius="xl"
          className={progressStyle.progressBar}
          sections={[
            {
              value: spentPercentage,
              color: disabled ? '#D9D9D9' : SPENT_COLOR,
            },
            ...(topUpPercentage
              ? [
                  {
                    value: topUpPercentage,
                    color: disabled ? '#D9D9D9' : TOP_UP_COLOR,
                  },
                ]
              : []),
          ]}
        />
        {props.showDetails && (
          <>
            <Separator direction="vertical" gap={16} />
            <div className={progressStyle.totalContainer}>
              <div className={progressStyle.spentBox} />
              <Separator gap={8} />
              <Text textVariant="BodyDefault">
                {t('budgeting:total_spent')}:
              </Text>
              <Text
                textVariant="BodyBoldDefault"
                weight={700}
              >{`IDR ${string2moneyDefault(usedAmount)}`}</Text>
            </div>
            <div className={progressStyle.totalContainer}>
              <div className={progressStyle.topUpBox} />
              <Separator gap={8} />
              <Text textVariant="BodyDefault">
                {t('budgeting:total_top_up')}:
              </Text>
              <Text
                textVariant="BodyBoldDefault"
                color={toppedUpAmount > amount ? colors.textError : ''}
                weight={700}
              >{`IDR ${string2moneyDefault(toppedUpAmount)}`}</Text>
            </div>
          </>
        )}
      </Separator>
      {!disabled && !props.showDetails && (
        <Text textVariant="MonoBoldDefault" className={progressStyle.footer}>
          IDR{' '}
          {state !== 'error'
            ? `${string2moneyDefault(amount - usedAmount)} ${t('common:left')}`
            : `${string2moneyDefault(usedAmount - amount)} ${t('common:over')}`}
        </Text>
      )}
    </>
  );
}
