import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  ChartAccountsDeleteInput,
  ChartOfAccountsModel,
  ChartOfAccountsMutationInput,
  ChartOfAccountsUpdateInput,
} from './model';

export function useCreateChartOfAccounts(
  options?: UseMutationOptions<
    ApiResult<ChartOfAccountsModel>,
    ApiError,
    ChartOfAccountsMutationInput
  >,
): UseMutationResult<
  ApiResult<ChartOfAccountsModel>,
  ApiError,
  ChartOfAccountsMutationInput
> {
  return useMutation<
    ApiResult<ChartOfAccountsModel>,
    ApiError,
    ChartOfAccountsMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'chart-of-accounts',
      method: 'POST',
      classType: ChartOfAccountsModel,
      body,
    });
  }, options);
}

export function useUpdateChartOfAccount(
  options?: UseMutationOptions<
    ApiResult<ChartOfAccountsModel>,
    ApiError,
    ChartOfAccountsUpdateInput
  >,
): UseMutationResult<
  ApiResult<ChartOfAccountsModel>,
  ApiError,
  ChartOfAccountsUpdateInput
> {
  return useMutation<
    ApiResult<ChartOfAccountsModel>,
    ApiError,
    ChartOfAccountsUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `chart-of-accounts/${body.id}`,
      method: 'PUT',
      classType: ChartOfAccountsModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteChartOfAccounts(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    ChartAccountsDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, ChartAccountsDeleteInput> {
  return useMutation<MessageResult, ApiError, ChartAccountsDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `chart-of-accounts/${body.id}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
