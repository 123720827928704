import { Badge, Group, Tooltip } from '@mantine/core';
import {
  Row,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { NavigationRoutes } from 'common/constants';
import { string2moneyDefault } from 'common/utils/string';
import { IColumn } from 'components/elements/table';
import { format } from 'date-fns';
import useCombinedRefs from 'hooks/use-combined-refs';
import useNavigation from 'hooks/use-navigation';
import { camelizeKeys, camelize } from 'humps';
import React from 'react';

import { usePivotTable } from './hook';
import { pivotStyle } from './style.css';
import { checkCurrency, checkCurrencyName } from './utils';

interface Props {
  onNextPage?: (bottom: number) => void;
  columns: IColumn<any>[];
  pivot: any;
  data: any;
  lastFocus: number;
  isLoading?: boolean;
  tableRef?: React.LegacyRef<HTMLTableElement>;
  noKey?: boolean;
  hiddenRows?: string[];
  hideRows?: string[];
  numberFormatKeys?: string[];
  numberColorKeys?: string[];
  dateFormatKeys?: string[];
  renderAll?: boolean;
  currencyAccessorKey?: string | string[];
  noCurrencyKeys?: string[];
}

const ScrollablePivotTable = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const {
      columns,
      data: datas,
      pivot,
      onNextPage,
      lastFocus,
      tableRef: _tableRef,
    } = props;
    const { navigate } = useNavigation();
    const { tableId } = usePivotTable();

    const currencyAccessorKey = props.currencyAccessorKey;

    const tableRef = React.useRef<HTMLDivElement | null>(null);
    const combinedRef = useCombinedRefs(ref, tableRef);

    const [data, keyMap, linkMap, badgeMap, backgroundMap] =
      React.useMemo(() => {
        if (!datas) {
          return [[], [], [], []];
        }
        const _keyMap: string[][] = [];
        const _linkMap: any[][] = [];
        const _badgeMap: any[][] = [];
        const _backgroundMap: any[][] = [];
        const key = !props.noKey ? 'Key' : '';
        const _data = (datas || []).map((datum, parentIdx) => {
          const row = camelizeKeys(datum);
          // if is aggreagate row, set accessor as aggregate row's label
          if (Number.isInteger(row.position)) {
            //@ts-ignore
            row[String(columns[row.position].accessorKey)] = row.label;
            //@ts-ignore
            row[String(columns[row.position].accessorKey) + key] = row.label;
          }

          let _lastKey = '';
          const linkKeys: any = [];
          const badgeKeys: any = [];
          const backgroundKeys: any = [];

          _keyMap.push(
            pivot.value.rows.map((pivotRow, index) => {
              _lastKey += row[camelize(pivotRow.name) + key];
              linkKeys.push(
                pivotRow.link ? row[camelize(pivotRow.link)] : null,
              );
              if (pivotRow.array_accessor) {
                badgeKeys.push(
                  row[pivotRow.array_accessor].map(
                    (item) => item[pivotRow?.badge || ''],
                  ),
                );
              } else {
                badgeKeys.push(
                  pivotRow.badge ? row[camelize(pivotRow.badge)] : null,
                );
              }

              backgroundKeys.push(
                pivotRow.background ? row[camelize(pivotRow.background)] : null,
              );
              return _lastKey;
            }),
          );

          _linkMap.push(linkKeys);
          _badgeMap.push(badgeKeys);

          _backgroundMap.push(backgroundKeys[0]);

          return row;
        });

        return [_data, _keyMap, _linkMap, _badgeMap, _backgroundMap];
      }, [datas, props.noKey, pivot.value.rows, columns]);

    const table = useReactTable({
      data,
      columns,
      getSubRows: (row) => row.subRows,
      columnResizeMode: 'onChange',
      getCoreRowModel: getCoreRowModel(),
      getExpandedRowModel: getExpandedRowModel(),
      getSortedRowModel: getSortedRowModel(),
    });
    const { getRowModel, getHeaderGroups } = table;
    const rows: Row<any>[] = getRowModel().rows;
    const headerGroups = getHeaderGroups();

    React.useEffect(() => {
      if (tableRef.current && !!lastFocus) {
        tableRef.current.scrollTo({ top: lastFocus, behavior: 'auto' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastFocus, tableRef.current]);

    const handleScroll = (e) => {
      const temp =
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight;
      const check = temp < 3;
      const bottom =
        check &&
        e.target.scrollHeight - e.target.scrollTop - temp ===
          e.target.clientHeight;

      if (bottom) {
        onNextPage?.(e.target.scrollHeight - e.target.clientHeight);
      }
    };

    React.useEffect(() => {
      if (tableRef.current && !!lastFocus) {
        tableRef.current.scrollTo({ top: lastFocus, behavior: 'auto' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastFocus, tableRef.current]);

    return (
      <div
        style={{
          position: 'absolute',
          inset: 0,
          overflow: 'hidden',
        }}
      >
        <div
          ref={combinedRef as any}
          onScroll={handleScroll}
          className={pivotStyle.scrollablePivotTableContainer}
        >
          <table ref={_tableRef} id={tableId}>
            <thead className={pivotStyle.thead}>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, columnIdx) => {
                    const column: any = header.column.columnDef;
                    const isHide = !!(props.hideRows || []).find(
                      (item) => item === header.id,
                    );
                    const isHidden = !!(props.hiddenRows || []).find(
                      (item) => item === header.id,
                    );
                    if (isHide) {
                      return null;
                    }
                    return (
                      <th
                        className={`${pivotStyle.th} ${
                          isHidden ? 'hidden' : ''
                        }`}
                        colSpan={header.colSpan}
                        rowSpan={header.rowSpan}
                      >
                        {flexRender(column.header, header.getContext())}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className={pivotStyle.tbody}>
              {rows.map((row, rowIdx) => {
                let tdClassName: string | undefined = undefined;

                return (
                  <tr>
                    {row.getVisibleCells().map((cell, cellIdx) => {
                      let colSpan = 1;
                      let rowSpan = 1;
                      const isHidden = !!(props.hiddenRows || []).find(
                        (item) => item === cell.column.id,
                      );

                      const cellColumn: any = cell.column.columnDef;
                      let tdColorClass: string | undefined = undefined;

                      let linkText: { acl: string; id: string } | undefined;
                      let isNumber = false;
                      let isDate = false;

                      const badgeContents: { text: string; color: string }[] =
                        [];
                      const commaContents: string[] = [];
                      const link: any = cellColumn.link;

                      const find = (props.numberColorKeys || []).includes(
                        cell.column.id,
                      );

                      if (isHidden) {
                        tdClassName = 'hidden';
                      } else {
                        tdClassName = '';
                      }
                      // if cell is aggregate row, it's position is not null
                      if (Number.isInteger(row.original.position)) {
                        // set colspan according to position
                        tdClassName = '--aggregate';

                        if (find) {
                          tdClassName = '--aggregate --number';
                        }

                        if (row.original.position === cellIdx) {
                          colSpan =
                            pivot.value.rows.length -
                            (row.original.position as number);
                        } else if (
                          cellIdx > row.original.position &&
                          cellIdx < pivot.value.rows.length
                        ) {
                          return null;
                        }
                      } else if (props.numberColorKeys || [].length > 0) {
                        if (find) {
                          isNumber = true;
                          tdColorClass = '--number';
                          const val = parseFloat(
                            row.original[cell.column.id].toString(),
                          );
                          if (val) {
                            if (val < 0) {
                              tdColorClass = tdColorClass.concat(' --red');
                            }
                          }
                        }
                      }

                      if (props.dateFormatKeys?.includes(cell.column.id)) {
                        isDate = true;
                      }

                      if (!props.renderAll) {
                        if (cellIdx < keyMap[rowIdx].length) {
                          // dont render td when key was the same with previous
                          if (
                            rowIdx > 0 &&
                            keyMap[rowIdx][cellIdx] ===
                              keyMap[rowIdx - 1][cellIdx]
                          ) {
                            return null;
                          }

                          // check if future row is the same, increase rowspan
                          for (
                            let futureRowIndex = rowIdx + 1;
                            futureRowIndex < rows.length;
                            futureRowIndex++
                          ) {
                            if (
                              keyMap[rowIdx][cellIdx] ===
                              keyMap[futureRowIndex][cellIdx]
                            ) {
                              rowSpan++;
                            } else {
                              break;
                            }
                          }
                        }
                      }

                      if (link) {
                        const originalData = row.original[link];
                        if (originalData) {
                          linkText = {
                            id: originalData.id,
                            acl: originalData.acl
                              .replaceAll('.', '/')
                              .replace('show', ':id'),
                          };
                        }
                      }

                      if (linkMap[rowIdx].length && linkMap[rowIdx][cellIdx]) {
                        const link = linkMap[rowIdx][cellIdx];
                        linkText = {
                          id: link.id,
                          acl: link.acl
                            .replaceAll('.', '/')
                            .replace('show', ':id'),
                        };
                      }

                      const badge =
                        badgeMap[rowIdx].length && badgeMap[rowIdx][cellIdx];
                      if (badge) {
                        const _badge = badgeMap[rowIdx][cellIdx];
                        // originalDataBadge value is string

                        if (_badge) {
                          if (
                            typeof row.original[cellColumn.accessorKey] ===
                            'object'
                          ) {
                            const data: any[] =
                              row.original[cellColumn.accessorKey];

                            data.forEach((item, index) =>
                              commaContents.push(
                                item[cellColumn.objectAccessor],
                              ),
                            );
                          } else {
                            const data =
                              row.original[cellColumn.accessorKey].split(', ');
                            const splitBadge = _badge.split(', ');
                            for (let i = 0; i < splitBadge.length; i++) {
                              badgeContents.push({
                                text: data[i],
                                color: splitBadge[i],
                              });
                            }
                          }
                        }
                      }
                      if (backgroundMap![rowIdx]) {
                        tdClassName = '--background';
                      }

                      const isHide = !!(props.hideRows || []).find(
                        (item) => item === cellColumn.accessorKey,
                      );

                      if (isHide) {
                        return null;
                      }

                      const currency =
                        currencyAccessorKey &&
                        checkCurrencyName({
                          //@ts-ignore
                          accessorKey: cell.column.columnDef.accessorKey,
                          currencyAccessorKey,
                        }) &&
                        cell.row.original[
                          checkCurrency({
                            //@ts-ignore
                            accessorKey: cell.column.columnDef.accessorKey,
                            pivot,
                          })
                        ];

                      const isNumberFormat = (
                        props.numberFormatKeys || []
                      ).includes(cell.column.id);

                      return (
                        <td
                          className={[
                            pivotStyle.td,
                            tdClassName,
                            tdColorClass,
                            !isNumberFormat && 'tableexport-string',
                          ].join(' ')}
                          colSpan={colSpan}
                          rowSpan={rowSpan}
                          align={
                            cellIdx >= pivot.value.rows.length
                              ? 'right'
                              : 'left'
                          }
                        >
                          {isDate ? (
                            <>
                              {format(
                                cell.getContext().getValue() as Date,
                                'dd MMM yyyy, HH:mm',
                              )}
                            </>
                          ) : linkText ? (
                            <div
                              className={pivotStyle.linkText}
                              onClick={() => {
                                navigate(linkText?.acl as NavigationRoutes, {
                                  params: { id: linkText?.id! },
                                });
                              }}
                            >
                              {cell.getContext().getValue() as any}
                            </div>
                          ) : badge ? (
                            <Group spacing={8}>
                              {commaContents.length
                                ? commaContents.join(', ')
                                : badgeContents.map((item, idx) => (
                                    <Badge
                                      children={item.text}
                                      color={item.color}
                                      key={item.text + idx}
                                    />
                                  ))}
                            </Group>
                          ) : isNumber ? (
                            <Tooltip
                              label={string2moneyDefault(
                                row.original[cell.column.id].toString(),
                              )}
                              withArrow
                            >
                              <div>
                                <div>
                                  {!props.noCurrencyKeys?.includes(
                                    cell.column.id,
                                  ) && `${currency} `}
                                  {isNumberFormat
                                    ? Number(row.original[cell.column.id])
                                    : flexRender(
                                        cellColumn.cell,
                                        cell.getContext(),
                                      )}
                                </div>
                              </div>
                            </Tooltip>
                          ) : (
                            <>
                              {cell.getContext().getValue() !== null &&
                              typeof cell.getContext().getValue() ===
                                'number' &&
                              !isNaN(cell.getContext().getValue() as any)
                                ? `${currency || ''} `
                                : ''}
                              {flexRender(cellColumn.cell, cell.getContext())}
                            </>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  },
);

ScrollablePivotTable.displayName = 'PivotTable';

export default ScrollablePivotTable;
