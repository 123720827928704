import { AccountTypeEnum } from 'api-hooks/account';
import { Input } from 'components/elements/field';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface Props {
  name: string;
  disabled?: boolean;
}

export default function AccountTypeSelectInput(props: Props) {
  const { t } = useTranslation();

  const selectOptions = React.useMemo(
    () => [
      {
        value: AccountTypeEnum.bank,
        label: t('common:bank'),
      },
      {
        value: AccountTypeEnum.cash,
        label: t('common:cash'),
      },
    ],
    [t],
  );

  return (
    <Input
      type="select"
      data={selectOptions}
      name={props.name}
      required
      label={t('common:account_type')}
      placeholder={t('common:choose_extra', {
        extra: t('common:account_type'),
      })}
      disabled={props.disabled}
    />
  );
}
