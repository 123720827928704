import { ClosingTypeEnum } from 'api-hooks/transaction-closing';
import {
  getTransfersKey,
  useGetTransfers,
  useImportTransfer,
  useImportTransferTemplate,
} from 'api-hooks/transfer';
import classNames from 'classnames';
import { ClockIcon } from 'common/assets';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import ListHeader from 'components/common/list-header';
import SearchInput from 'components/common/search-input';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import { GenericQueryTable, useGenericTableProps } from 'modules/common/table';
import { moduleStyles } from 'modules/styles.css';
import TransactionClosing from 'modules/transactions/common/components/closing';
import ImportModal from 'modules/transactions/common/components/import-modal';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { useTransferDefaultColumns } from './column';

interface Props {
  setIsUpdateRate: React.Dispatch<React.SetStateAction<boolean>>;
}

function TransferDefaultView({ setIsUpdateRate }: Props) {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const { can } = useAuthorization();
  const [search, setSearch] = React.useState<string>('');

  const dialog = useDialog();
  const navigation = useNavigation();
  const _columns = useTransferDefaultColumns();

  const { refetch, ...table } = useGenericTableProps(
    _columns,
    useGetTransfers,
    {
      params: {
        filter: {
          entity_id: entity?.id,
        },
        q: search,
      },
    },
  );

  const onCreate = () => navigation.navigate(NavigationRoutes.TransferCreate);

  const onHandleImport = () =>
    dialog.showCustom({
      render: (close) => (
        <ImportModal
          name={t('common:transfer')}
          rule="TransfersImportTemplate"
          onClose={close}
          useImport={useImportTransfer}
          useImportTemplate={useImportTransferTemplate}
          getKeys={getTransfersKey as any}
          t={t}
        />
      ),
    });

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:transfer')}
        showAdd={can(AuthorizationRules.TransfersCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
        rightCustomComponent={
          <>
            {can(AuthorizationRules.TransfersImport) && (
              <>
                <Button
                  type="button"
                  onClick={onHandleImport}
                  variant="secondary"
                >
                  {t('modal:import_file')}
                </Button>
                <Separator gap={16} direction="horizontal" />
              </>
            )}
            {can(AuthorizationRules.TransactionClosingsList) && (
              <>
                <TransactionClosing type={ClosingTypeEnum.transfers} />
                <Separator gap={16} direction="horizontal" />
              </>
            )}
          </>
        }
      />
      <GenericQueryTable
        {...table}
        onRenderBesideQueryFilter={
          <div
            className={classNames(
              moduleStyles.row({ justify: 'between' }),
              moduleStyles.fullContainer,
            )}
          >
            <SearchInput setValue={setSearch} setPage={table.setPage} />
            {can(AuthorizationRules.TransferMassUpdateRate) && (
              <Button
                variant="secondary"
                leftIcon={(size) => <ClockIcon size={size} />}
                onClick={() => setIsUpdateRate(true)}
              >
                {t('transaction:update_rate')}
              </Button>
            )}
          </div>
        }
      />
    </div>
  );
}

export default React.memo(TransferDefaultView);
