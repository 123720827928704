import { useGetEnums } from 'api-hooks/common';
import { Input } from 'components/elements/field';
import React from 'react';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  disabled?: boolean;
  noMargin?: boolean;
  onAfterChange?: (value?: any) => void;
}

export default function SelectInputModel(props: Props) {
  const { data } = useGetEnums({ class: 'ApprovalMasterType' });

  return (
    <Input
      type="select"
      required={props.required}
      label={props.label}
      placeholder={props.placeholder}
      noMargin={props.noMargin}
      data={data?.data || []}
      onAfterChange={props.onAfterChange}
      name={props.name}
      disabled={props.disabled}
    />
  );
}
