import { PlusIcon, RefreshIcon } from 'common/assets';
import { ActionIcon, Button } from 'components/elements/button';
import Text from 'components/elements/text';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import { listHeaderStyle } from './style.css';
import Separator from '../separator';

interface Props {
  onRefresh?: () => void;
  onCreate?: () => void;
  title?: string;
  subtitle?: string;
  showRefresh?: boolean;
  showSmallRefresh?: boolean;
  showAdd?: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  rightCustomComponent?: React.ReactNode;
  leftCustomComponent?: React.ReactNode;
  showBorder?: boolean;
}

export default function ListHeader(props: Props) {
  const { t } = useTranslation();

  const {
    title,
    onRefresh,
    onCreate,
    rightCustomComponent,
    leftCustomComponent,
    showRefresh = true,
    showAdd = true,
    showBorder = false,
    showSmallRefresh,
  } = props;

  return (
    <>
      <div className={listHeaderStyle.topContainer}>
        <div
          className={listHeaderStyle.leftActionContainer({
            margin: showRefresh,
          })}
        >
          <div className={listHeaderStyle.textContainer}>
            <div className={moduleStyles.topFormContainer}>
              {!!title && <Text textVariant="HeadingSmall">{title}</Text>}
              {(showRefresh || showSmallRefresh) && (
                <div className={moduleStyles.viewScreenSmall}>
                  <ActionIcon
                    variant="transparent"
                    onClick={() => {
                      onRefresh?.();
                    }}
                  >
                    {(size) => <RefreshIcon size={size} />}
                  </ActionIcon>
                </div>
              )}
            </div>
          </div>
          {leftCustomComponent && <>{leftCustomComponent}</>}
        </div>
        <div className={listHeaderStyle.rightContainer}>
          {!!rightCustomComponent && (
            <>
              <Separator gap={16} />
              {rightCustomComponent}
            </>
          )}
          {showRefresh && (
            <div className={moduleStyles.viewScreenLarge}>
              <Button
                onClick={() => {
                  onRefresh?.();
                }}
                leftIcon={(size) => <RefreshIcon size={size} />}
                variant="secondary"
                style={styles.button}
              >
                {t('common:refresh')}
              </Button>
            </div>
          )}
          {showAdd && (
            <>
              <Separator gap={16} />
              <Button
                onClick={() => {
                  onCreate?.();
                }}
                leftIcon={(size) => <PlusIcon size={size} />}
                style={styles.button}
              >
                {t('common:create')}
              </Button>
            </>
          )}
        </div>
      </div>
      {showBorder && (
        <div className={listHeaderStyle.filterDivider({ showBorder })} />
      )}
    </>
  );
}

const styles = {
  button: {
    minWidth: '120px',
  },
};
