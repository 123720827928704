import { BrandModel, getBrandsKey, useGetBrands } from 'api-hooks/brand';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useAuthorization } from 'hooks/use-authorization';
import useMultiSelectInputHelper from 'hooks/use-multi-select-input-helper';
import useNavigation from 'hooks/use-navigation';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(item: BrandModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
    color: item.color,
  };
}

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  disabled?: boolean;
  noMargin?: boolean;
  entityId?: string;
  model?: 'expenses' | 'incomes' | 'request-forms' | 'employees';
}

const BrandMultiAllSelect = (props: Props) => {
  const { t } = useTranslation();
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const { navigate } = useNavigation();
  const { can } = useAuthorization();
  const {
    label = t('common:brand'),
    placeholder = t('common:choose_extra', { extra: t('common:brand') }),
  } = props;

  const [data, setData] = React.useState<OptionProps[]>([]);

  const { onSearchChange, ...selectProps } = useMultiSelectInputHelper({
    value: field.value,
    enabled: !!props.entityId,
    onSelectItem: async (value) => {
      field.onChange(value || []);
    },
    useListQueryHook: useGetBrands,
    getListsKey: React.useCallback(
      (page) =>
        getBrandsKey({
          params: {
            page,
            limit: -1,
            filter: {
              entity_id: props.entityId,
              ...(!!props.model && { model: props.model }),
            },
          },
        }),
      [props.entityId, props.model],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          page,
          limit: -1,
          filter: {
            entity_id: props.entityId,
            ...(!!props.model && { model: props.model }),
          },
        },
      }),
      [props.entityId, props.model],
    ),
    renderCreate: can(AuthorizationRules.BrandsCreate),
    renderNoData: true,
    renderSelectAll: true,
    onClickSelectAll: () => {
      field.onChange(data?.map(({ value }) => value));
    },
    onClickCreate() {
      navigate(NavigationRoutes.BrandCreate);
      !!props.onClose && props.onClose();
    },
    listTransformer(result) {
      const res = result.data.map((v) => transformer(v));
      setData(res);
      return res;
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select-multi"
      name={props.name}
      required={props.required}
      label={label}
      placeholder={placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
};

export default BrandMultiAllSelect;
