import { Filter, FilterType } from 'api-hooks/common/model';
import { Button } from 'components/elements/button';
import DatePicker from 'components/elements/date-picker';
import TextInput from 'components/elements/text-input';
import produce from 'immer';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import styles from 'styles/Filter.module.css';

import { reportQueryFilterContext } from './query-filter.context';
import AccountSelectInput from '../select/account-select-input';

interface Props {
  filter: Filter;
  onRequestClose: () => void;
}

export function isEmpty(value: any): boolean {
  return value === undefined || value === null || value === '';
}

export default function DefaultReportFilterControlInput(props: Props) {
  const { filter, onRequestClose } = props;
  const { setFilters } = React.useContext(reportQueryFilterContext);

  const initialValue =
    filter.value ||
    (filter.type === FilterType.Date ? new Date().toISOString() : undefined);

  const [value, setValue] = React.useState<string | undefined>(initialValue);

  const { t } = useTranslation();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onRequestClose();
        if (value !== undefined && value !== null) {
          setFilters((prevFilters) =>
            produce(prevFilters, (draft) => {
              const matchedFilter = draft?.find((f) => f.name === filter.name);
              if (matchedFilter) {
                matchedFilter.value = value;
              }
            }),
          );
        }
      }}
      className={styles.form}
    >
      <div className={styles.formContent}>
        {(() => {
          switch (filter.type) {
            case 'text':
              if (filter.name === 'account_id') {
                return (
                  <AccountSelectInput
                    onSelected={(value) => setValue(String(value))}
                    label={filter.label}
                    value={(value || filter.value || filter.default) as any}
                  />
                );
              }
              return (
                <TextInput
                  label={filter.label}
                  onChange={(e) => setValue(e.target?.value)}
                  value={(value || filter.value || filter.default) as any}
                />
              );
            case 'date':
              return (
                <DatePicker
                  label={filter.label}
                  onChange={(e) => setValue(e?.toISOString())}
                  defaultValue={null}
                  value={
                    filter.value
                      ? new Date(value || filter.value)
                      : filter.default
                      ? new Date(filter.default)
                      : undefined
                  }
                />
              );
            default:
              return null;
          }
        })()}
      </div>
      <div style={applyButtonStyle}>
        <Button disabled={isEmpty(value)} type="submit">
          {t('common:apply')}
        </Button>
      </div>
    </form>
  );
}

const applyButtonStyle: React.CSSProperties = {
  padding: 8,
  display: 'flex',
  flexDirection: 'column',
};
