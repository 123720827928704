import {
  StockItemCodeModel,
  StockItemCodeTypeEnum,
  stockItemCodeKey,
  useGetStockItemCode,
  useGetStockItemCodes,
} from 'api-hooks/stock-item-code';
import { QueryTransformer } from 'common/helpers/common';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(item: StockItemCodeModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}
interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  onAfterChange?: (value?: any) => void;
  overrideOnChange?: (prevValue: any, value: any) => void;
  noMargin?: boolean;
  type: keyof typeof StockItemCodeTypeEnum;
}

export default function StockItemCodeSelectInput(props: Props) {
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const { type } = props;

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetStockItemCodes,
    useDetailLazyQueryHook: useGetStockItemCode,
    getDetailKey: React.useCallback(
      (value) =>
        stockItemCodeKey.detail({
          stockItemCodeId: value,
        }),
      [],
    ),
    getListsKey: React.useCallback(
      (page, q) =>
        stockItemCodeKey.list({
          params: {
            q,
            page,
            filter: {
              type,
            },
          },
        }),
      [type],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            type,
          },
        },
      }),
      [type],
    ),
    renderCreate: false,
    renderNoData: true,
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        stockItemCodeId: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      const newResult = QueryTransformer(result, StockItemCodeModel);
      props.onAfterChange?.(newResult.data);
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      required
      label={props.label}
      placeholder={props.placeholder}
      name={props.name}
      onAfterChange={props.onAfterChange}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
}
