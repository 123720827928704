import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import BankSelectInput from './bank-select-input';

interface Props {
  hasData: boolean;
}
export default function ConnectBankForm(props: Props) {
  const { t } = useTranslation();
  const { hasData } = props;

  return (
    <div className={moduleStyles.halfContainer}>
      <Input
        type="checkbox"
        name="isBankConnected"
        label={t('account:connect_with_bank')}
        disabled={hasData}
      />
      <Separator gap={16} direction="vertical" />
      <HOCInput keys={['isBankConnected']}>
        {({ isBankConnected }) => (
          <>
            {isBankConnected && (
              <>
                <BankSelectInput name="bankType" required disabled={hasData} />
                {hasData && isBankConnected && (
                  <>
                    <Input
                      type="checkbox"
                      name="isChangeCredential"
                      label={t('account:change_credentials')}
                    />
                    <Separator gap={16} direction="vertical" />
                  </>
                )}
                <HOCInput keys={['isChangeCredential']}>
                  {({ isChangeCredential }) => (
                    <>
                      {(!hasData ||
                        (hasData && isChangeCredential && isBankConnected)) && (
                        <>
                          <Input
                            type="text"
                            name="bankUsername"
                            required
                            label={t('common:username')}
                            placeholder={t('common:enter_extra', {
                              extra: t('common:username'),
                            })}
                          />
                          <Input
                            type="password"
                            name="bankPassword"
                            required
                            label={t('common:password')}
                            placeholder={t('common:enter_extra', {
                              extra: t('common:password'),
                            })}
                          />
                        </>
                      )}
                    </>
                  )}
                </HOCInput>
                <Input
                  type="number"
                  name="interval"
                  required
                  label={t('account:interval_second')}
                  placeholder={t('common:enter_extra', {
                    extra: t('common:interval'),
                  })}
                  description={t('account:interval_min_second', { extra: 15 })}
                />
              </>
            )}
          </>
        )}
      </HOCInput>
    </div>
  );
}
