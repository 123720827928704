// Model

import { BrandModel } from 'api-hooks/brand';
import { getIndexInput } from 'api-hooks/common';
import { TransferModel } from 'api-hooks/transfer/model';
import { Expose, Type } from 'class-transformer';

export class TransferApprovalLiteModel {
  id: string;
  description: string;
  status: string;

  @Type(() => BrandModel)
  brands: BrandModel[];

  @Expose({ name: 'account_from' })
  accountFrom: string;

  @Expose({ name: 'account_to' })
  accountTo: string;

  @Expose({ name: 'transfer_currency' })
  transferCurrency: string;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  requestedAt: Date;

  @Expose({ name: 'transfer_amount' })
  @Type(() => Number)
  transferAmount: number;

  @Expose({ name: 'account_from_rate' })
  @Type(() => Number)
  accountFromRate: number;

  @Expose({ name: 'receive_currency' })
  receiveCurrency: string;

  @Expose({ name: 'receive_amount' })
  @Type(() => Number)
  receiveAmount: number;

  @Expose({ name: 'account_to_rate' })
  @Type(() => Number)
  accountToRate: number;

  @Expose({ name: 'is_account_from_base' })
  @Type(() => Number)
  isAccountFromBase: number;

  @Expose({ name: 'is_account_to_base' })
  @Type(() => Number)
  isAccountToBase: number;
}

export class TransferApprovalModel extends TransferModel {}

// Input

export type getTransferApprovalInput = {
  id: string;
};

export type getTransferApprovalsInput = getIndexInput;

export type TransferApprovalApproveInput = {
  transferId: string;
  body: {
    receivedAt: Date;
  };
};
