import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import {
  MutationFetchFunction,
  QueryFetchFunction,
  QueryTransformer,
} from 'common/helpers/common';

import {
  getPurchaseRequisitionsChangeLogInput,
  getPurchaseRequisitionsInput,
  getPurchaseRequisitionInput,
  PurchaseRequisitionsChangeLogModel,
  PurchaseRequisitionsLiteModel,
  PurchaseRequisitionsModel,
} from './model';

export function useGetPurchaseRequisitions(
  input?: getPurchaseRequisitionsInput,
  options?: UseQueryOptions<
    ExtendedApiResult<PurchaseRequisitionsLiteModel[]>,
    ApiError
  >,
): UseQueryResult<
  ExtendedApiResult<PurchaseRequisitionsLiteModel[]>,
  ApiError
> {
  return QueryTransformer(
    useQuery(
      ['getPurchaseRequisitions', input?.params],
      () => QueryFetchFunction({ url: 'request-forms', params: input?.params }),
      options,
    ),
    PurchaseRequisitionsLiteModel,
  );
}

export function useGetPurchaseRequisitionsExpense(
  //! must include division_id and brand_ids filter
  input?: getPurchaseRequisitionsInput,
  options?: UseQueryOptions<
    ExtendedApiResult<PurchaseRequisitionsLiteModel[]>,
    ApiError
  >,
): UseQueryResult<
  ExtendedApiResult<PurchaseRequisitionsLiteModel[]>,
  ApiError
> {
  return QueryTransformer(
    useQuery(
      ['getPurchaseRequisitions', input?.params],
      () =>
        QueryFetchFunction({
          url: 'request-forms/expense',
          params: input?.params,
        }),
      options,
    ),
    PurchaseRequisitionsLiteModel,
  );
}

export function useGetPurchaseRequisition(
  input?: getPurchaseRequisitionInput,
  options?: UseQueryOptions<ApiResult<PurchaseRequisitionsModel>, ApiError>,
): UseQueryResult<ApiResult<PurchaseRequisitionsModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getPurchaseRequisitions', input?.id],
      () =>
        QueryFetchFunction({
          url: `request-forms/${input?.id}`,
        }),
      options,
    ),
    PurchaseRequisitionsModel,
  );
}

export function useGetPurchaseRequisitionsChangeLog(
  input?: getPurchaseRequisitionsChangeLogInput,
  options?: UseMutationOptions<
    ApiResult<PurchaseRequisitionsChangeLogModel>,
    ApiError,
    getPurchaseRequisitionsChangeLogInput
  >,
): UseMutationResult<ApiResult<PurchaseRequisitionsChangeLogModel>, ApiError> {
  return QueryTransformer(
    useMutation<
      ApiResult<PurchaseRequisitionsChangeLogModel>,
      ApiError,
      getPurchaseRequisitionsChangeLogInput
    >(
      ['getPurchaseRequisitionsChangeLog', input?.id],
      async (body) =>
        await MutationFetchFunction({
          url: `request-forms/${body?.id}/extend-deadline`,
          method: 'GET',
          classType: PurchaseRequisitionsChangeLogModel,
        }),
      options,
    ),
  );
}

export function getPurchaseRequisitionsKey(
  input?: getPurchaseRequisitionsInput,
) {
  return ['getPurchaseRequisitions', input?.params];
}

export function getPurchaseRequisitionKey(input?: getPurchaseRequisitionInput) {
  return ['getPurchaseRequisitions', input?.id];
}
