import { IncomeModel, getIncomesKey, useDeleteIncome } from 'api-hooks/income';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import { initialDate } from 'common/utils/date';
import { string2moneyDefault } from 'common/utils/string';
import FormContent from 'components/common/form-content';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form, { FormState } from 'components/elements/form';
import Text from 'components/elements/text';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import AccountSelectInputByEntityId from 'modules/entity-settings/account/components/select-input-by-entity';
import BrandMultiAllSelect from 'modules/entity-settings/brand/components/multi-all-select-input';
import EntityCurrencySelectInput from 'modules/entity-settings/entity-currency/components/select-input';
import ChartOfAccountsSelectInput from 'modules/master/chart-of-accounts/components/select-input';
import DivisionByEmployeeSelectInput from 'modules/master/division/components/select-input-by-employee';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { IncomeFormType } from './form-type';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  income?: IncomeModel;
  onSubmit: (
    input: any,
    form: ReturnType<typeof useForm>,
  ) => Promise<IncomeModel | undefined>;
}

export default function IncomeForm(props: Props) {
  const { t } = useTranslation();
  const { income } = props;
  const dialog = useDialog();
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeleteIncome();
  const { can } = useAuthorization();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      amount: Yup.number().positive().required(),
      accountId: Yup.string().nullable().required(),
      description: Yup.string().nullable(),
      isBaseCurrency: Yup.bool(),
      brands: Yup.array().of(Yup.string()).required(),
      rate: Yup.number().min(0).required(),
      transactionRate: Yup.number().nullable().required(),
      entityCurrencyId: Yup.string().nullable().required(),
      transactionAt: Yup.date().nullable().required(),
      isIncludeMutation: Yup.bool(),
      hasAttachment: Yup.bool(),
      isAttachmentCompleted: Yup.bool(),
      divisionId: Yup.string().nullable().required(),
      files: Yup.array().when(['hasAttachment'], {
        is: (val) => val,
        then: Yup.array(
          Yup.object({
            url: Yup.string().when(['hasAttachment'], {
              is: (val) => val === true,
              then: Yup.string().required(),
            }),
            file: Yup.string().when(['hasAttachment'], {
              is: (val) => val === true,
              then: Yup.string().required(),
            }),
          }),
        ).min(1),
      }),
    }),
  );

  const intialValues = React.useMemo<IncomeFormType>(() => {
    return {
      amount: income?.amount || '',
      account: {
        id: '',
        isBaseCurrency: false,
        balance: 0,
        currency: '',
        entityCurrencyId: '',
      },
      accountId: income?.account?.id || null,
      description: income?.description || '',
      isBaseCurrency: income?.isBaseCurrency || false,
      brands: (income?.brands || []).map((item) => item.id),
      rate: income?.rate || 1,
      transactionAt: income?.transactionAt || initialDate(),
      entityId: income?.entityId || entity?.id || null,
      isIncludeMutation: income?.isIncludeMutation || false,
      hasAttachment: income?.hasAttachment || false,
      chartOfAccountId: income?.chartOfAccount?.id || null,
      isAttachmentCompleted: income?.isAttachmentCompleted || false,
      files: income?.files || [],
      divisionId: income?.division.id || '',
      entityCurrencyId: income?.entityCurrency?.id || '',
      transactionRate: income?.transactionRate || '',
    };
  }, [income, entity]);

  const methods = useForm<IncomeFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const { setValue } = methods;

  const onSubmit = React.useCallback(
    async (values: IncomeFormType) => {
      try {
        const { files, entityCurrencyId, account, transactionRate, ...rest } =
          values;
        const _input = {
          ...rest,
          entityCurrencyId,
          transactionRate:
            entityCurrencyId !== account.entityCurrencyId ? transactionRate : 1,
          files: files?.map((item) => item.file),
        };

        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
        notification.error({ message: e.message });
      }
    },
    [methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            incomeId: income!.id,
          });
          queryClient.refetchQueries([getIncomesKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, income, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!income}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={income}
          title={
            income
              ? t('common:income')
              : t('common:create_extra', { extra: t('common:income') })
          }
          noDelete={
            !!income?.isTransactionClosed ||
            !can(AuthorizationRules.IncomesDelete)
          }
          noEdit={
            !!income?.isTransactionClosed ||
            !can(AuthorizationRules.IncomesUpdate)
          }
        />
        <div>
          <div className={moduleStyles.halfContainer}>
            <Input
              label={t('common:transaction_at')}
              placeholder={t('common:transaction_at')}
              name="transactionAt"
              type="date-time"
              required
            />
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <FormState>
                {({ editable }) => (
                  <AccountSelectInputByEntityId
                    name="accountId"
                    required
                    onAfterDetailChange={(val) => {
                      setValue('account', val);
                      if (!income) {
                        setValue('transactionRate', 1);
                        setValue('entityCurrencyId', val.entityCurrencyId);
                      }

                      if (!val.isBaseCurrency) {
                        editable &&
                          setValue('rate', val.rate, { shouldValidate: true });
                      } else {
                        setValue('rate', 1);
                      }
                    }}
                    noMargin
                    description={
                      <HOCInput keys={['account']}>
                        {({ account }) =>
                          account.id ? (
                            <Text
                              textVariant="MonoSmaller"
                              color={colors.textLight}
                            >{`${t('common:balance')}: ${
                              account.currency
                            } ${string2moneyDefault(account.balance)}`}</Text>
                          ) : (
                            <></>
                          )
                        }
                      </HOCInput>
                    }
                  />
                )}
              </FormState>
            </div>
            <div className={moduleStyles.halfContainer}>
              <ChartOfAccountsSelectInput
                name="chartOfAccountId"
                required
                noMargin
                isParent={false}
                label={t('common:chart_of_accounts')}
                placeholder={t('common:choose_extra', {
                  extra: t('common:chart_of_accounts'),
                })}
              />
            </div>
          </div>

          <Separator gap={8} direction="vertical" />
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <DivisionByEmployeeSelectInput name="divisionId" />
            </div>
            <div className={moduleStyles.halfContainer}>
              <BrandMultiAllSelect
                label={t('common:brand')}
                placeholder={t('common:brand')}
                name="brands"
                entityId={entity?.id!}
                model="incomes"
              />
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <HOCInput keys={['account.entityCurrencyId']}>
                {({ 'account.entityCurrencyId': accountEntityCurrencyId }) => (
                  <EntityCurrencySelectInput
                    name="entityCurrencyId"
                    placeholder={t('common:currency')}
                    label={t('common:currency')}
                    onAfterChange={(val) => {
                      val.id !== accountEntityCurrencyId &&
                        setValue(
                          'transactionRate',
                          val.id !== income?.entityCurrency.id
                            ? val.rate
                            : income?.transactionRate,
                        );
                    }}
                  />
                )}
              </HOCInput>
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                label={t('transfer:amount')}
                placeholder={t('transfer:amount')}
                name="amount"
                type="number"
                isMoneyFormat
                required
                noMargin
              />
              <Separator gap={8} direction="vertical" />
              <HOCInput
                keys={['transactionRate', 'amount', 'account.currency']}
              >
                {({
                  transactionRate,
                  amount,
                  'account.currency': accountCurrency,
                }) =>
                  !!amount && !!transactionRate && !!accountCurrency ? (
                    <>
                      <Text
                        textVariant="MonoSmaller"
                        color={colors.textLight}
                      >{`~ ${accountCurrency} ${string2moneyDefault(
                        transactionRate * amount,
                      )}`}</Text>
                      <Separator gap={16} direction="vertical" />
                    </>
                  ) : (
                    <></>
                  )
                }
              </HOCInput>
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <HOCInput keys={['account.entityCurrencyId', 'entityCurrencyId']}>
                {({
                  'account.entityCurrencyId': accountEntityCurrencyId,
                  entityCurrencyId,
                }) =>
                  accountEntityCurrencyId !== entityCurrencyId ? (
                    <Input
                      label={t('common:transaction_rate')}
                      placeholder={t('common:transaction_rate')}
                      name="transactionRate"
                      type="number"
                      isMoneyFormat
                      inputMode="decimal"
                      required
                    />
                  ) : (
                    <></>
                  )
                }
              </HOCInput>
            </div>
            <div className={moduleStyles.halfContainer}>
              <HOCInput
                keys={['account.isBaseCurrency', 'account.currencyName']}
              >
                {({
                  'account.isBaseCurrency': isBase,
                  'account.currencyName': currencyName,
                }) =>
                  !isBase && currencyName ? (
                    <Input
                      label={t('common:base_rate')}
                      placeholder={t('common:base_rate')}
                      name="rate"
                      type="number"
                      inputMode="decimal"
                      isMoneyFormat
                      required
                    />
                  ) : (
                    <></>
                  )
                }
              </HOCInput>
            </div>
          </div>
          <Separator gap={8} direction="vertical" />

          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                label={t('common:description')}
                placeholder={t('common:description')}
                name="description"
                type="text"
              />
            </div>
          </div>
          <div className={moduleStyles.sectionContainer}>
            <Input
              type="checkbox"
              name="isIncludeMutation"
              label={t('common:include_account_mutation')}
            />
          </div>
          <div>
            <Separator gap={48} direction="vertical" />
            <Input
              type="checkbox"
              name="hasAttachment"
              label={t('common:has_attachment')}
              onAfterChange={() => methods.clearErrors()}
            />
            <HOCInput keys={['hasAttachment']}>
              {({ hasAttachment }) =>
                hasAttachment ? (
                  <div className={moduleStyles.halfContainer}>
                    <Separator gap={16} direction="vertical" />
                    <Input
                      type="checkbox"
                      name="isAttachmentCompleted"
                      label={t('common:attachment_completed')}
                    />
                    <Separator gap={16} direction="vertical" />
                    <Input
                      name="files"
                      previewKey="url"
                      valueKey="file"
                      type="files"
                      label={t('common:attachment')}
                      required
                    />
                  </div>
                ) : (
                  <></>
                )
              }
            </HOCInput>
          </div>
        </div>
      </FormContent>
      <Separator direction="vertical" gap={32} />
    </Form>
  );
}
