import {
  ChartOfAccountChildrenModel,
  useGetChartOfAccountChildren,
} from 'api-hooks/chart-of-accounts';
import classNames from 'classnames';
import { PlusIcon } from 'common/assets';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { OptionProps } from 'components/elements/select';
import ChartOfAccountsSelectManualInput from 'modules/master/chart-of-accounts/components/select-input-manual';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import ListOfCOAInput from './list-of-coa-input';
import { budgetingStyles } from '../../styles.css';

const FIELD_NAME = 'chartOfAccountIds';
interface Props {
  variant?: 'modal' | 'form';
}

const transformer = (item: ChartOfAccountChildrenModel): OptionProps => ({
  value: item.id,
  label: item.name,
});
export default function ListOfCOASelector(props: Props) {
  const { variant = 'form' } = props;
  const { setValue, getValues, clearErrors } = useFormContext<any>();
  const [childCOA, setChildCOA] = React.useState<OptionProps | undefined>();
  const [parentCOA, setParentCOA] = React.useState<OptionProps | undefined>();
  const [childOfParentCOA, setChildOfParentCOA] = React.useState<
    OptionProps[] | undefined
  >();
  const { mutateAsync, isLoading } = useGetChartOfAccountChildren();

  const { t } = useTranslation();

  const onAdd = React.useCallback(
    (action: 'child' | 'parent') => {
      const curr = (getValues(FIELD_NAME) as OptionProps[]) || [];
      const ids = (curr || []).map((item) => item.value);

      if (action === 'child') {
        if (!ids.includes(childCOA?.value || '')) {
          setValue(FIELD_NAME, [...curr, childCOA]);
          clearErrors(FIELD_NAME);
        }
        setChildCOA(undefined);
      } else {
        const temp = childOfParentCOA?.filter(
          (item) => !ids.includes(item.value) && item,
        );
        setValue(FIELD_NAME, [...curr, ...temp!], { shouldValidate: true });
        setParentCOA(undefined);
        setChildOfParentCOA([]);
      }
    },
    [childCOA, childOfParentCOA, clearErrors, getValues, setValue],
  );

  const onAfterParentChange = React.useCallback(
    async (id: string) => {
      try {
        const res = await mutateAsync({
          id,
        });
        setChildOfParentCOA(res?.data.map((item) => transformer(item)));
      } catch {}
    },
    [mutateAsync],
  );
  return (
    <div className={moduleStyles.fullContainer}>
      <div
        {...(variant === 'form' && {
          className: moduleStyles.sectionContainer,
        })}
      >
        <div
          className={classNames(
            variant === 'form' && moduleStyles.halfContainer,
            moduleStyles.row(),
          )}
        >
          <ChartOfAccountsSelectManualInput
            onChange={(v) => {
              setParentCOA(v);
              !!v && onAfterParentChange(v.value);
            }}
            value={parentCOA?.value || ''}
            label={t('budgeting:parent_coa')}
            placeholder={t('common:choose_extra', {
              extra: t('budgeting:parent_coa'),
            })}
            className={moduleStyles.flex1}
            isParent
          />
          <Separator gap={16} direction="horizontal" />
          <div className={budgetingStyles.addButton}>
            <Button
              type="button"
              variant="tertiary"
              leftIcon={(size) => <PlusIcon {...{ size }} />}
              onClick={() => onAdd('parent')}
              disabled={!childOfParentCOA || !childOfParentCOA?.length}
              loading={isLoading}
            >
              {t('budgeting:add_to_list')}
            </Button>
            <Separator gap={16} direction="vertical" />
          </div>
        </div>

        <div
          className={classNames(
            variant === 'form' && moduleStyles.halfContainer,
            moduleStyles.row(),
          )}
        >
          <ChartOfAccountsSelectManualInput
            onChange={(v) => setChildCOA(v)}
            value={childCOA?.value || ''}
            className={moduleStyles.flex1}
            label={t('budgeting:child_coa')}
            placeholder={t('common:choose_extra', {
              extra: t('budgeting:child_coa'),
            })}
          />
          <Separator gap={16} direction="horizontal" />
          <div className={budgetingStyles.addButton}>
            <Button
              type="button"
              variant="tertiary"
              leftIcon={(size) => <PlusIcon {...{ size }} />}
              onClick={() => onAdd('child')}
              disabled={!childCOA}
            >
              {t('budgeting:add_to_list')}
            </Button>
            <Separator gap={16} direction="vertical" />
          </div>
        </div>
      </div>
      <ListOfCOAInput name={FIELD_NAME} label={t('budgeting:list_of_coa')} />
    </div>
  );
}
