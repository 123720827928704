import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getReconciliationsInput,
  getReconciliationSystemListInput,
  ReconciliationLiteModel,
  ReconciliationSystemList,
} from './model';

export function useGetReconciliations(
  input?: getReconciliationsInput,
  options?: UseQueryOptions<
    ExtendedApiResult<ReconciliationLiteModel[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<ReconciliationLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getReconciliations', input?.params],
      () =>
        QueryFetchFunction({
          url: 'reconciliations',
          params: input?.params,
        }),
      options,
    ),
    ReconciliationLiteModel,
  );
}

export function useGetReconciliationSystemList(
  input?: getReconciliationSystemListInput,
  options?: UseQueryOptions<
    ExtendedApiResult<ReconciliationSystemList[]>,
    ApiError
  >,
): UseQueryResult<ExtendedApiResult<ReconciliationSystemList[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getReconciliationSystemList', input?.params],
      () =>
        QueryFetchFunction({
          url: `reconciliations/systems`,
          params: input?.params,
        }),
      options,
    ),
    ReconciliationSystemList,
  );
}

export function getReconciliationsKey(input?: getReconciliationsInput) {
  return ['getReconciliations', input?.params];
}
