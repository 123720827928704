import { FeeOnEnum, StatusEnum, TransferTypeEnum } from 'api-hooks/transfer';
import classNames from 'classnames';
import formSetValues from 'common/helpers/form-setValues';
import colors from 'common/styles/colors';
import { string2moneyDefault } from 'common/utils/string';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import Text from 'components/elements/text';
import { useEntity } from 'hooks/use-entities';
import AccountSelectInputByEntityId from 'modules/entity-settings/account/components/select-input-by-entity';
import ChartOfAccountsSelectInput from 'modules/master/chart-of-accounts/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

import { transferForm } from './styles.css';
import Loader from '../../../../components/common/loader';

const chargeSelectOptions: OptionProps[] = [
  { value: 'none', label: 'None' },
  { value: 'sender', label: 'Sender' },
  { value: 'receiver', label: 'Receiver' },
];

const TransferDetailForm = ({
  hasValue,
  status,
  isDisabled,
  receivedAmount,
}: {
  hasValue: boolean;
  status?: string;
  isDisabled?: boolean;
  receivedAmount?: number;
}) => {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const { setValue } = useFormContext<any>();

  const [isCalculating, setIsCalculating] = React.useState<boolean>(false);

  const onChangeValue = useDebouncedCallback(
    (
      value,
      _accountFromRateVal,
      _accountToRateVal,
      _isFromBase,
      _isToBase,
      toEntityCurrency,
      fromEntityCurrency,
    ) => {
      if (value === undefined) {
        return;
      }
      if (!toEntityCurrency || !fromEntityCurrency) {
        formSetValues({ receivedAmount: value }, setValue);
      } else {
        if (_isFromBase && _isToBase) {
          formSetValues(
            { receivedAmount: value, accountToRate: 0, accountFromRate: 0 },
            setValue,
          );
        }
        if (_isFromBase && !_isToBase) {
          formSetValues(
            { receivedAmount: value / _accountToRateVal, accountFromRate: 0 },
            setValue,
          );
        }
        if (!_isFromBase && _isToBase) {
          formSetValues(
            { receivedAmount: value * _accountFromRateVal, accountToRate: 0 },
            setValue,
          );
        }
        if (!_isFromBase && !_isToBase) {
          if (toEntityCurrency !== fromEntityCurrency) {
            formSetValues(
              {
                receivedAmount:
                  (_accountFromRateVal / _accountToRateVal) * value,
                accountToRate: 0,
              },
              setValue,
            );
          } else
            formSetValues(
              {
                receivedAmount: _accountFromRateVal * value,
              },
              setValue,
            );
        }
      }
      setIsCalculating(false);
    },
    800,
  );
  const handleCalculate = () => setIsCalculating(true);

  return (
    <div>
      <div className={moduleStyles.topFormContainer}>
        <Text textVariant="HeadingSmall">{t('transfer:transfer_details')}</Text>
        <div className={transferForm.topSeperator} />

        <Input
          label={t('transfer:delayed_transfer')}
          type="checkbox"
          name="isDelay"
          disabled={isDisabled || hasValue}
        />
      </div>
      <HOCInput keys={['isDelay']}>
        {({ isDelay }) => {
          const state = isDelay && status !== StatusEnum.finished;
          return (
            state && (
              <>
                <Separator gap={32} direction="vertical" />
                <Alert
                  title={t('transfer:alert_title')}
                  variant="warning"
                  description={
                    hasValue
                      ? t('transfer:alert_description_waiting')
                      : t('transfer:alert_description')
                  }
                />
                <Separator gap={32} direction="vertical" />
              </>
            )
          );
        }}
      </HOCInput>
      {/* ACCOUNT */}
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <HOCInput
            keys={[
              'accountToId',
              'accountTo',
              'transferAmount',
              'accountToRate',
            ]}
          >
            {({ accountToId, accountTo, transferAmount, accountToRate }) => (
              <AccountSelectInputByEntityId
                name="accountFromId"
                label={t('transfer:from_account')}
                required
                noMargin
                remove={accountToId}
                onAfterDetailChange={(val) => {
                  formSetValues({ accountFrom: val }, setValue);
                  if (!hasValue) {
                    formSetValues(
                      { accountFromRate: val.rate, transferFeeRate: val.rate },
                      setValue,
                    );
                    !!accountTo?.id && handleCalculate();
                    onChangeValue(
                      transferAmount,
                      val.rate,
                      accountToRate,
                      val.isBaseCurrency,
                      accountTo?.isBaseCurrency,
                      val.currencyName,
                      accountTo.currencyName,
                    );
                  }
                }}
                disabled={isDisabled}
                description={
                  <HOCInput keys={['accountFrom']}>
                    {({ accountFrom }) => (
                      <>
                        {accountFrom.id && (
                          <Text
                            textVariant="MonoSmaller"
                            color={colors.textLight}
                          >{`${t('common:balance')}: ${
                            accountFrom.currency
                          } ${string2moneyDefault(accountFrom.balance)}`}</Text>
                        )}
                      </>
                    )}
                  </HOCInput>
                }
              />
            )}
          </HOCInput>
          <Separator gap={8} direction="vertical" />
        </div>
        <div className={moduleStyles.halfContainer}>
          <HOCInput
            keys={[
              'accountFromId',
              'accountFrom',
              'transferAmount',
              'accountFromRate',
              'type',
            ]}
          >
            {({
              accountFromId,
              accountFrom,
              transferAmount,
              accountFromRate,
              type,
            }) => {
              return (
                <AccountSelectInputByEntityId
                  name="accountToId"
                  label={t('transfer:to_account')}
                  required
                  noMargin
                  remove={accountFromId}
                  disabled={
                    isDisabled || type === TransferTypeEnum.others
                      ? !accountFromId
                      : false
                  }
                  onAfterDetailChange={(val) => {
                    setValue('accountTo', val);
                    if (!hasValue) {
                      setValue('accountToRate', val.rate);
                      handleCalculate();
                      onChangeValue(
                        transferAmount,
                        accountFromRate,
                        val.rate,
                        accountFrom.isBaseCurrency,
                        val.isBaseCurrency,
                        accountFrom.currencyName,
                        val.currencyName,
                      );
                    }
                  }}
                  description={
                    <HOCInput keys={['accountTo']}>
                      {({ accountTo }) => (
                        <>
                          {accountTo.id && (
                            <Text
                              textVariant="MonoSmaller"
                              color={colors.textLight}
                            >{`${t('common:balance')}: ${
                              accountTo.currency
                            } ${string2moneyDefault(accountTo.balance)}`}</Text>
                          )}
                        </>
                      )}
                    </HOCInput>
                  }
                />
              );
            }}
          </HOCInput>
        </div>
      </div>
      <div className={moduleStyles.sectionContainer}>
        <HOCInput
          keys={['accountFrom', 'accountTo', 'transferAmount', 'accountToRate']}
        >
          {({ accountFrom, accountTo, transferAmount, accountToRate }) => {
            return (
              <>
                {!accountFrom.isBaseCurrency && accountFrom.currency && (
                  <div className={moduleStyles.halfContainer}>
                    <Input
                      label={`1 ${accountFrom.currencyName} =`}
                      name="accountFromRate"
                      type="number"
                      required
                      isMoneyFormat
                      defaultValue={accountFrom.rate}
                      inputMode="decimal"
                      disabled={isDisabled}
                      onAfterChange={(value) => {
                        if (!!accountTo && !!accountFrom) {
                          handleCalculate();
                          onChangeValue(
                            transferAmount,
                            value,
                            accountToRate,
                            accountFrom.isBaseCurrency,
                            accountTo.isBaseCurrency,
                            accountFrom.currencyName,
                            accountTo.currencyName,
                          );
                        }
                      }}
                    />
                  </div>
                )}
              </>
            );
          }}
        </HOCInput>
        <HOCInput
          keys={[
            'accountFrom',
            'accountTo',
            'transferAmount',
            'accountFromRate',
          ]}
        >
          {({ accountFrom, accountTo, transferAmount, accountFromRate }) => {
            return (
              <>
                {!accountTo.isBaseCurrency &&
                  accountTo.currency &&
                  accountTo.currencyName !== accountFrom.currencyName && (
                    <div className={moduleStyles.halfContainer}>
                      <Input
                        label={`1 ${accountTo.currencyName} =`}
                        name="accountToRate"
                        type="number"
                        required
                        isMoneyFormat
                        defaultValue={accountTo.rate}
                        inputMode="decimal"
                        disabled={isDisabled}
                        onAfterChange={(value) => {
                          handleCalculate();
                          onChangeValue(
                            transferAmount,
                            accountFromRate,
                            value,
                            accountFrom.isBaseCurrency,
                            accountTo.isBaseCurrency,
                            accountFrom.currencyName,
                            accountTo.currencyName,
                          );
                        }}
                      />
                    </div>
                  )}
              </>
            );
          }}
        </HOCInput>
      </div>
      <Separator gap={8} direction="vertical" />
      {/* COA INPUT */}
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <ChartOfAccountsSelectInput
            name="chartOfAccountFromId"
            required
            label={t('transfer:from_chart_of_account')}
            placeholder={t('common:choose_extra', {
              extra: t('transfer:from_chart_of_account'),
            })}
          />
        </div>
        <div className={moduleStyles.halfContainer}>
          <ChartOfAccountsSelectInput
            name="chartOfAccountToId"
            required
            label={t('transfer:to_chart_of_account')}
            placeholder={t('common:choose_extra', {
              extra: t('transfer:to_chart_of_account'),
            })}
          />
        </div>
      </div>
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <HOCInput
            keys={[
              'accountFrom.isBaseCurrency',
              'accountTo.isBaseCurrency',
              'accountFrom.currencyName',
              'accountTo.currencyName',
              'accountFromRate',
              'accountToRate',
            ]}
          >
            {({
              'accountFrom.isBaseCurrency': isFromBase,
              'accountTo.isBaseCurrency': isToBase,
              'accountFrom.currencyName': fromCurrency,
              'accountTo.currencyName': toCurrency,
              accountFromRate,
              accountToRate,
            }) => {
              return (
                <Input
                  label={t('transfer:transfer_amount')}
                  placeholder={t('common:enter_extra', {
                    extra: t('transfer:transfer_amount'),
                  })}
                  name="transferAmount"
                  type="number"
                  inputMode="decimal"
                  disabled={isDisabled}
                  required
                  isMoneyFormat
                  onAfterChange={(value) => {
                    handleCalculate();
                    onChangeValue(
                      value,
                      accountFromRate,
                      accountToRate,
                      isFromBase,
                      isToBase,
                      toCurrency,
                      fromCurrency,
                    );
                  }}
                />
              );
            }}
          </HOCInput>
        </div>
        <div className={moduleStyles.halfRelativeContainer}>
          <HOCInput
            keys={[
              'transferAmount',
              'accountTo.isBaseCurrency',
              'accountFrom.isBaseCurrency',
              'accountFromRate',
              'accountFrom.id',
              'accountTo.id',
            ]}
          >
            {({
              transferAmount,
              'accountTo.isBaseCurrency': toIsBase,
              'accountFrom.isBaseCurrency': fromIsBase,
              accountFromRate,
              'accountFrom.id': fromId,
              'accountTo.id': toId,
            }) => {
              return (
                <Input
                  label={t('transfer:received_amount')}
                  name="receivedAmount"
                  type="number"
                  inputMode="decimal"
                  required
                  isMoneyFormat
                  defaultValue={receivedAmount}
                  disabled={
                    !(fromId && toId) ||
                    !!(toIsBase || fromIsBase) ||
                    isDisabled
                  }
                  onAfterChange={(value) => {
                    setValue(
                      'accountToRate',
                      (accountFromRate / value!) * transferAmount,
                    );
                  }}
                />
              );
            }}
          </HOCInput>
          <div className={transferForm.loaderContainer}>
            <Loader hide={!isCalculating} />
          </div>
        </div>
      </div>
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <HOCInput keys={['accountFrom.rate', 'accountTo.rate']}>
            {({ 'accountFrom.rate': fromRate, 'accountTo.rate': toRate }) => (
              <Input
                type="select"
                data={chargeSelectOptions}
                name="feeOn"
                label={t('transfer:transfer_fee_charge_to')}
                disabled={isDisabled}
                onAfterChange={(value) =>
                  value?.value !== FeeOnEnum.None
                    ? value?.value === FeeOnEnum.Reciever
                      ? setValue('transferFeeRate', toRate)
                      : setValue('transferFeeRate', fromRate)
                    : setValue('transferFeeRate', 0)
                }
              />
            )}
          </HOCInput>
        </div>
        <div
          className={classNames(
            moduleStyles.halfContainer,
            transferForm.halfContainer,
            transferForm.sectionContainer,
          )}
        >
          <HOCInput
            keys={[
              'feeOn',
              'accountTo.currency',
              'accountFrom.currency',
              'accountTo.isBaseCurrency',
              'accountFrom.isBaseCurrency',
            ]}
          >
            {({
              feeOn,
              'accountTo.currency': toCurrency,
              'accountFrom.currency': fromCurrency,
              'accountTo.isBaseCurrency': isToBase,
              'accountFrom.isBaseCurrency': isFromBase,
            }) =>
              feeOn !== 'none' && (
                <>
                  <div className={moduleStyles.fullContainer}>
                    <Input
                      type="number"
                      name="transferFee"
                      label={t('transfer:transfer_fee')}
                      inputMode="decimal"
                      required
                      isMoneyFormat
                      disabled={isDisabled}
                      description={t('transfer:in_extra', {
                        extra: feeOn === 'sender' ? fromCurrency : toCurrency,
                      })}
                    />
                    <Separator gap={8} direction="vertical" />
                  </div>
                  {((!isFromBase && !isToBase) ||
                    (isFromBase && !isToBase) ||
                    (!isFromBase && isToBase)) && (
                    <div
                      className={classNames(
                        transferForm.rightField,
                        moduleStyles.fullContainer,
                      )}
                    >
                      <Input
                        type="number"
                        name="transferFeeRate"
                        label={t('transfer:extra_rate', {
                          extra: t('transfer:transfer_fee'),
                        })}
                        placeholder={t('common:enter_extra', {
                          extra: t('transfer:transfer_fee'),
                        })}
                        inputMode="decimal"
                        required
                        isMoneyFormat
                        disabled={
                          isDisabled || feeOn === 'sender'
                            ? isFromBase
                            : isToBase
                        }
                        description={t('transfer:in_extra', {
                          extra: entity?.currency,
                        })}
                      />
                    </div>
                  )}
                </>
              )
            }
          </HOCInput>
        </div>
      </div>
    </div>
  );
};

export default TransferDetailForm;
