import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  StockAdjustmentDeleteInput,
  StockAdjustmentModel,
  StockAdjustmentMutationInput,
  StockAdjustmentUpdateInput,
} from './model';

export function useCreateStockAdjustment(
  options?: UseMutationOptions<
    ApiResult<StockAdjustmentModel>,
    ApiError,
    StockAdjustmentMutationInput
  >,
): UseMutationResult<
  ApiResult<StockAdjustmentModel>,
  ApiError,
  StockAdjustmentMutationInput
> {
  return useMutation<
    ApiResult<StockAdjustmentModel>,
    ApiError,
    StockAdjustmentMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'stock-adjustments',
      method: 'POST',
      classType: StockAdjustmentModel,
      body,
    });
  }, options);
}

export function useUpdateStockAdjustment(
  options?: UseMutationOptions<
    ApiResult<StockAdjustmentModel>,
    ApiError,
    StockAdjustmentUpdateInput
  >,
): UseMutationResult<
  ApiResult<StockAdjustmentModel>,
  ApiError,
  StockAdjustmentUpdateInput
> {
  return useMutation<
    ApiResult<StockAdjustmentModel>,
    ApiError,
    StockAdjustmentUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `stock-adjustments/${body.stockAdjustmentId}`,
      method: 'PUT',
      classType: StockAdjustmentModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteStockAdjustment(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    StockAdjustmentDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, StockAdjustmentDeleteInput> {
  return useMutation<MessageResult, ApiError, StockAdjustmentDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `stock-adjustments/${body.stockAdjustmentId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
