import {
  UseMutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import { ExportMutationInput } from './model';

export function useExport(
  options?: UseMutationOptions<ApiResult<any>, ApiError, ExportMutationInput>,
): UseMutationResult<ApiResult<any>, ApiError, ExportMutationInput> {
  return useMutation<ApiResult<any>, ApiError, ExportMutationInput>(
    async function (body) {
      const { params, ...rest } = body;
      return await MutationFetchFunction({
        url: 'export-histories/export',
        method: 'POST',
        params,
        body: {
          ...rest,
        },
      });
    },
    options,
  );
}
