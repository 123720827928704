import { Loader } from '@mantine/core';
import { useGetBudgetings } from 'api-hooks/budgeting';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import colors from 'common/styles/colors';
import ListHeader from 'components/common/list-header';
import Separator from 'components/common/separator';
import Pagination from 'components/elements/pagination';
import { tableStyles } from 'components/elements/table/styles.css';
import Text from 'components/elements/text';
import { useAuthorization } from 'hooks/use-authorization';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import Stats from './components/stats';
import { budgetingStyles } from './styles.css';

export default function BudgetingList() {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const navigation = useNavigation();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);

  const { data, refetch, isLoading, isFetching, error } = useComposedQuery(
    useGetBudgetings,
    {
      params: {
        page,
        limit,
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
  );

  React.useEffect(() => {
    if (!!error && !!error.message) {
      notification.error({ message: error?.message });
    }
  }, [error]);

  const onCreate = React.useCallback(() => {
    navigation.navigate(NavigationRoutes.BudgetingCreate);
  }, [navigation]);

  const onView = React.useCallback(
    (id: string) =>
      navigation.navigate(NavigationRoutes.BudgetingView, {
        params: {
          id,
        },
      }),
    [navigation],
  );

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:budgeting')}
        showAdd={can(AuthorizationRules.BudgetingsCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
      />
      <Separator gap={16} direction="vertical" />
      <div className={moduleStyles.tableContainer}>
        {isLoading || isFetching ? (
          <div className={tableStyles.noDataContainer}>
            <Loader color={colors.productNormal} />
          </div>
        ) : (
          <div className={budgetingStyles.itemContainer}>
            {data?.data?.length ? (
              data?.data?.map((item) => (
                <Stats
                  {...item}
                  key={item.id}
                  onClick={() => onView(item.id)}
                  viewOnly={!can(AuthorizationRules.BudgetingsView)}
                />
              ))
            ) : (
              <>
                <Separator gap={32} direction="vertical" />
                <Text textVariant="BodyDefault" align="center">
                  {t('common:no_result_found')}
                </Text>
              </>
            )}
          </div>
        )}
      </div>
      <Pagination
        {...{
          page,
          onPageChange: (page) => setPage(page),
          meta: data?.meta,
          limit,
          onLimitChange: (limit) => setLimit(limit),
        }}
      />
    </div>
  );
}
