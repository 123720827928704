import { getIndexInput } from 'api-hooks/common/model';
import { StockItemModel } from 'api-hooks/stock-item';
import { StockSimpleModel } from 'api-hooks/stock-transfer';
import { Expose, Type } from 'class-transformer';

//Model
export class StockGroupingLiteModel {
  id: string;

  @Type(() => StockSimpleModel)
  stock: StockSimpleModel;

  @Expose({ name: 'stock_grouping_item_count' })
  stockGroupingItemCount: number;

  @Expose({ name: 'stock_item' })
  @Type(() => StockItemModel)
  stockItem: StockItemModel;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}
export class StockGroupItemModel {
  id: string;

  @Type(() => StockSimpleModel)
  stock: StockSimpleModel;

  @Expose({ name: 'stock_item' })
  @Type(() => StockItemModel)
  stockItem: StockItemModel;
}

export class StockGroupingModel {
  id: string;

  @Type(() => StockSimpleModel)
  stock: StockSimpleModel;

  @Expose({ name: 'stock_item' })
  @Type(() => StockItemModel)
  stockItem: StockItemModel;

  @Expose({ name: 'stock_grouping_items' })
  @Type(() => StockGroupItemModel)
  stockGroupingItems: StockGroupItemModel[];

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}

// Input
export class getStockGroupingInput {
  stockGroupingId: string;
}

export type getStockGroupingsInput = getIndexInput;

export type StockGroupingItemInput = {
  stockId: string;
  stockItemId: string;
  qty?: number;
};

export type StockGroupingMutationInput = {
  entityId: string;
  stockId: string;
  stockItemId: string;
  stockGroupingItems: StockGroupingItemInput[];
};

export type StockGroupingUpdateInput = {
  stockGroupingId: string;
  body: StockGroupingMutationInput;
};

export type StockGroupingDeleteInput = {
  stockGroupingId: string;
};
