import { useGetApproval } from 'api-hooks/approval';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import { NavigationProps } from 'containers/navigation';
import React from 'react';

import ApprovalForm from './components/form';

export default function ApprovalView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } = useGetApproval({
    id: props.navigation.params.id,
  });

  const approval = data?.data;

  return (
    <div>
      <FetchWrapperComponent
        onRetry={refetch}
        loadingComponent={<LoadingComponent />}
        error={!!error}
        errorComponent={
          <DetailComponent>
            <ErrorViewComponent refetch={refetch} />
          </DetailComponent>
        }
        isLoading={isLoading || isFetching}
        component={<ApprovalForm approval={approval!} />}
      />
    </div>
  );
}
