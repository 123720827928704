import { getIndexInput } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';

// Model
export class CurrencyLiteModel {
  id: string;
  currency: string;
  name: string;

  @Expose({ name: 'updated_at' })
  @Type(() => Date)
  updatedAt: Date;
}
export class CurrencyModel extends CurrencyLiteModel {}

// Input
export type getCurrencyInput = {
  id: string;
};

export type getCurrenciesInput = getIndexInput;

export type CurrencyMutationInput = {
  id: string;
  name: string;
};
export type CurrencyUpdateInput = {
  currencyId: string;
  body: CurrencyMutationInput;
};
export type CurrencyDeleteInput = {
  currencyId: string;
};
