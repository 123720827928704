// Enum

import { getIndexInput } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';

export enum ExportCategoryEnum {
  account = 'account',
  chart_of_account = 'chart_of_account',
  expense = 'expense',
  transaction = 'transaction',
  stock_simple = 'stock_simple',
  stock_detail = 'stock_detail',
}

export enum ExportExcelStatusEnum {
  processing = 'processing',
  finished = 'finished',
  failed = 'failed',
}
// Model
export class ExportLiteModel {
  id: string;
  employee: string;
  name: string;
  status: ExportExcelStatusEnum;
  file: string;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;
}

// Input
export type getExportInput = getIndexInput;

export type ExportMutationInput = {
  category: ExportCategoryEnum;
  name: string;
  params?: any;
};
