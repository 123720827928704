import formSetValues from 'common/helpers/form-setValues';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import { OptionProps } from 'components/elements/select';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { camelizeKeys } from 'humps';
import { isEmpty, snakeCase } from 'lodash';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface Props {
  onSubmit: (values: ReturnType) => void;
  filters: ReturnType;
}

enum FilterByEnum {
  transactionAt = 'Transaction At',
  createdAt = 'Created At',
}

type FilterFormType = {
  filterBy: FilterByEnum | string;
  transactionAtAfter: Date | null;
  transactionAtBefore: Date | null;
  createdAtAfter: Date | null;
  createdAtBefore: Date | null;
};

type ReturnType = { [key: string]: Date | null };

const formSchema = () =>
  Yup.object({
    filterBy: Yup.string().required(),
    transactionAtAfter: Yup.date().nullable().when('filterBy', {
      is: FilterByEnum.transactionAt,
      then: Yup.date().required().nullable(),
      otherwise: Yup.date().nullable().strip(),
    }),
    transactionAtBefore: Yup.date().nullable().when('filterBy', {
      is: FilterByEnum.transactionAt,
      then: Yup.date().nullable(),
      otherwise: Yup.date().nullable().strip(),
    }),
    createdAtAfter: Yup.date().nullable().when('filterBy', {
      is: FilterByEnum.createdAt,
      then: Yup.date().required().nullable(),
      otherwise: Yup.date().nullable().strip(),
    }),
    createdAtBefore: Yup.date().nullable().when('filterBy', {
      is: FilterByEnum.createdAt,
      then: Yup.date().nullable(),
      otherwise: Yup.date().nullable().strip(),
    }),
  });

const INITIAL_VALUES: FilterFormType = {
  createdAtAfter: null,
  createdAtBefore: null,
  filterBy: FilterByEnum.transactionAt,
  transactionAtAfter: null,
  transactionAtBefore: null,
};

const filterOptions: OptionProps[] = [
  {
    value: FilterByEnum.transactionAt,
    label: FilterByEnum.transactionAt,
  },
  {
    value: FilterByEnum.createdAt,
    label: FilterByEnum.createdAt,
  },
];

export default function TransactionReportUpperFilter(props: Props) {
  const { filters } = props;
  const { t } = useTranslation();

  const resolver = useYupValidationResolver(formSchema());
  const methods = useForm<FilterFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: INITIAL_VALUES,
  });

  const {
    setValue,
    formState: { errors },
  } = methods;

  React.useEffect(() => {
    const temp = {
      ...INITIAL_VALUES,
      filterBy: Object.keys(filters).some((filter) =>
        filter.includes('created'),
      )
        ? FilterByEnum.createdAt
        : FilterByEnum.transactionAt,
      ...camelizeKeys(filters),
    };

    formSetValues(temp, setValue);
  }, [filters, setValue]);

  const onSubmit = (values: FilterFormType) => {
    const temp: ReturnType = {};

    Object.keys(values).forEach(
      (key) =>
        key !== 'filterBy' &&
        !!values[key] &&
        (temp[snakeCase(key)] = values[key]),
    );

    props.onSubmit(temp);
  };

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <div
        className={moduleStyles.row({
          gap: 16,
          align: 'end',
        })}
      >
        <div
          className={moduleStyles.row({
            gap: 16,
            align: 'start',
          })}
        >
          <Input
            type="select"
            data={filterOptions}
            name="filterBy"
            label={t('common:filter_by')}
            clearable={false}
            required
            noMargin
          />

          <HOCInput keys={['filterBy']}>
            {({ filterBy }) =>
              filterBy === FilterByEnum.transactionAt ? (
                <>
                  <Input
                    key="transactionAtAfter"
                    type="date"
                    label={t('report:transaction_at_after')}
                    name="transactionAtAfter"
                    clearable={false}
                    required
                    noMargin
                  />
                  <Input
                    key="transactionAtBefore"
                    type="date"
                    label={t('report:transaction_at_before')}
                    name="transactionAtBefore"
                    noMargin
                  />
                </>
              ) : (
                <>
                  <Input
                    key="createdAtAfter"
                    type="date"
                    label={t('report:created_at_after')}
                    name="createdAtAfter"
                    clearable={false}
                    required
                    noMargin
                  />
                  <Input
                    key="createdAtBefore"
                    type="date"
                    label={t('report:created_at_before')}
                    name="createdAtBefore"
                    noMargin
                  />
                </>
              )
            }
          </HOCInput>
        </div>
        <div>
          <Input type="submit" text={t('common:search')} hideIcon />
          {!isEmpty(errors) && <Separator gap={24} direction="vertical" />}
        </div>
      </div>
      <Separator gap={16} direction="vertical" />
    </Form>
  );
}
