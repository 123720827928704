import {
  ReconciliationLiteModel,
  useGetReconciliations,
} from 'api-hooks/reconciliation';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import filterReduce from 'common/helpers/filter-reduce';
import notification from 'common/helpers/notification';
import ListHeader from 'components/common/list-header';
import QueryFilter, {
  useApplyQueryFilter,
} from 'components/common/query-filter/query-filter';
import Separator from 'components/common/separator';
import { IColumn } from 'components/elements/table';
import { format } from 'date-fns';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import { useAuthorization } from 'hooks/use-authorization';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import Balance from 'modules/entity-settings/common/components/balance';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import ReconciliationTable from './components/table';

export default function ReconciliationList() {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const navigation = useNavigation();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const _columns = React.useMemo<IColumn<ReconciliationLiteModel>[]>(
    () => [
      {
        header: t('common:transaction_at'),
        accessorKey: 'transactionAt',
        minSize: 200,
        sortName: 'transaction_at',
        cell: ({ getValue }) => (
          <>{format(getValue() as Date, 'dd MMM yyyy, HH:mm')}</>
        ),
      },
      {
        header: t('common:account'),
        accessorKey: 'account.name',
        minSize: 250,
        sortName: 'account',
      },
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 250,
        sortName: 'description',
      },
      {
        header: t('transfer:amount'),
        accessorKey: 'amount',
        minSize: 250,
        textAlign: 'right',
        cell: ({ row }) => {
          const {
            original: { amount, account },
          } = row;

          return <Balance currency={account.currency} balance={amount} />;
        },
      },
    ],
    [t],
  );

  const {
    data,
    refetch,
    isLoading,
    isFetching,
    error,
    extras: [{ filters, setFilters }, { columns }],
  } = useComposedQuery(
    useGetReconciliations,
    {
      params: {
        page,
        limit,
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
    useApplyQueryFilter((data: any) => {
      return data.filters;
    }),
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
  );

  const _filter = filterReduce(filters);

  const onCreate = React.useCallback(() => {
    navigation.navigate(NavigationRoutes.ReconciliationCreate);
  }, [navigation]);

  React.useEffect(() => {
    if (!!error && !!error.message) {
      notification.error({ message: error?.message });
    }
  }, [error]);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:reconciliation')}
        showAdd={can(AuthorizationRules.ReconciliationsCreate)}
        onCreate={onCreate}
        showRefresh={false}
        onRefresh={refetch}
      />
      <Separator gap={8} />
      <div className={moduleStyles.topContainer}>
        <QueryFilter
          setPage={setPage}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <ReconciliationTable
        reconciliations={data?.data || []}
        loading={isLoading || isFetching}
        page={page}
        onPageChange={(page) => setPage(page)}
        meta={data?.meta}
        filter={_filter}
        sortColumns={columns || []}
        limit={limit}
        onLimitChange={(limit) => setLimit(limit)}
      />
    </div>
  );
}
