import {
  RoleLiteModel,
  RoleModel,
  getRolesKey,
  useGetRoles,
} from 'api-hooks/role';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import { Input } from 'components/elements/field';
import { MultiOptionProps } from 'components/elements/multi-select';
import { useAuthorization } from 'hooks/use-authorization';
import useMultiSelectInputHelper from 'hooks/use-multi-select-input-helper';
import useNavigation from 'hooks/use-navigation';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(item: RoleLiteModel | RoleModel): MultiOptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  noMargin?: boolean;
}

export default function RoleSelectMultiInput(props: Props) {
  const { t } = useTranslation();
  const { label = t('common:role') } = props;
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const { navigate } = useNavigation();

  const { can } = useAuthorization();

  const selectProps = useMultiSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value || []);
    },
    useListQueryHook: useGetRoles,
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
        },
      }),
      [],
    ),
    getListsKey: React.useCallback(
      (page, q) => getRolesKey({ params: { q, page } }),
      [],
    ),
    renderCreate: !!can(AuthorizationRules.RolesCreate),
    onClickCreate: () => {
      navigate(NavigationRoutes.RolesCreate);
      props.onClose && props.onClose();
    },
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select-multi"
      name={props.name}
      required={props.required}
      label={label}
      placeholder={props.placeholder}
      noMargin={props.noMargin}
      {...selectProps}
    />
  );
}
