import {
  getRoleKey,
  getRolesKey,
  useGetRole,
  useUpdateRole,
} from 'api-hooks/role';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import DetailComponent from 'components/common/detail-component';
import ErrorViewComponent from 'components/common/error-view-component';
import FetchWrapperComponent from 'components/common/fetch-wrapper-component';
import LoadingComponent from 'components/common/loading';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import * as React from 'react';

import RoleForm from './components/form';

export default function RoleView(props: NavigationProps) {
  const { data, error, isLoading, isFetching, refetch } = useGetRole({
    id: props.navigation.params.id,
  });
  const { mutateAsync, error: mutateError } = useUpdateRole();

  const role = data?.data;

  return (
    <FetchWrapperComponent
      onRetry={refetch}
      loadingComponent={<LoadingComponent />}
      error={!!error}
      errorComponent={
        <DetailComponent>
          <ErrorViewComponent refetch={refetch} />
        </DetailComponent>
      }
      isLoading={isLoading || isFetching}
      component={
        <RoleForm
          {...props}
          renderError={
            !!mutateError &&
            !!mutateError.message && (
              <>
                <Alert variant="error" description={mutateError.message} />
                <Separator gap={8} />
              </>
            )
          }
          role={role}
          onSubmit={async (input, form) => {
            const result = await mutateAsync({
              body: input,
              roleId: role!.id,
            });

            queryClient.refetchQueries([getRolesKey()[0]]);
            queryClient.refetchQueries(getRoleKey({ id: role!.id }));

            notification.success({ message: result?.message });

            return result.data;
          }}
        />
      }
    />
  );
}
