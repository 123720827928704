import { Group, Loader, Modal } from '@mantine/core';
import { useGetFiles } from 'api-hooks/common';
import colors from 'common/styles/colors';
import Separator from 'components/common/separator';
import Text from 'components/elements/text';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface Props {
  path: string;
  onClose: () => void;
}
export default function AttachmentListModal(props: Props) {
  const { t } = useTranslation();
  const { onClose, path } = props;
  const { asPath } = useRouter();

  const { data, isLoading } = useGetFiles({
    params: path,
  });

  React.useEffect(() => {
    if (!asPath?.includes('/#')) {
      onClose();
    }
  }, [asPath, onClose]);

  return (
    <Modal
      opened
      onClose={onClose}
      title={
        <Text textVariant="HeadingSmall">{t('common:attachment_lists')}</Text>
      }
      size={500}
      style={{ textAlign: 'center' }}
    >
      {isLoading ? (
        <Separator gap={32}>
          <Loader color={colors.productNormal} size={32} />
        </Separator>
      ) : (
        <Group spacing="md">
          {data?.data?.map((image) => (
            <div
              onClick={() => window.open(image.url)}
              key={image.id}
              style={styles.imageContainer}
            >
              <Image
                alt={image.name}
                src={
                  image.url.includes('pdf') ? '/assets/file-pdf.svg' : image.url
                }
                width={100}
                height={50}
              />
              <Text textVariant="BodySmallest" style={styles.imageName}>
                <span style={styles.sticky as React.CSSProperties}>
                  {image.name}
                </span>
                <span style={styles.notSticky}>...</span>
              </Text>
            </div>
          ))}
        </Group>
      )}
    </Modal>
  );
}

const styles = {
  imageContainer: {
    cursor: 'pointer',
  },
  imageName: {
    width: '100px',
    display: 'flex',
    alignItems: 'center',
  },
  sticky: {
    display: 'inline-block',
    maxWidth: '80%',
    overflow: 'hidden',
    textOverflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  notSticky: {
    display: 'inline-block',
  },
};
