import {
  StockGroupingModel,
  StockGroupingMutationInput,
  stockGroupingKey,
  useDeleteStockGrouping,
} from 'api-hooks/stock-grouping';
import classNames from 'classnames';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import Form, { FormState } from 'components/elements/form';
import Text from 'components/elements/text';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import StockSelectInput from 'modules/stock/stock/components/select-input';
import StockItemSelectInput from 'modules/stock/stock-items/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import { StockGroupingFormType, formSchema } from './form-type';
import StockGroupingItem from './item';
import StockGroupingSubItem from './sub-item';
import { stockGroupingStyles } from '../styles.css';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  stockGrouping?: StockGroupingModel;
  onSubmit: (
    input: StockGroupingMutationInput,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}

export default function StockGroupingForm(props: Props) {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const { stockGrouping, navigation } = props;
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteStockGrouping();

  const dialog = useDialog();
  const resolver = useYupValidationResolver(formSchema());

  const initialValues = React.useMemo<StockGroupingFormType>(
    () => ({
      stockGroupingItems: stockGrouping?.stockGroupingItems?.map((item) => ({
        stockId: item.stock.id,
        stockItemId: item.stockItem.id,
      })) || [
        {
          stockId: '',
          stockItemId: '',
        },
      ],
      stockId: stockGrouping?.stock?.id || '',
      stockItemId: stockGrouping?.stockItem?.id || '',
    }),
    [
      stockGrouping?.stock?.id,
      stockGrouping?.stockGroupingItems,
      stockGrouping?.stockItem?.id,
    ],
  );

  const methods = useForm<StockGroupingFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        await props.onSubmit(
          {
            ...values,
            entityId: entity?.id!,
          },
          methods as any,
        );
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [entity?.id, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            stockGroupingId: stockGrouping?.id!,
          });
          queryClient.refetchQueries([stockGroupingKey.listKey]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, t, stockGrouping]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!stockGrouping}
      setNavigations={setNavigations}
      navigation={navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={stockGrouping}
          title={t('common:stock_groupings')}
          noDelete={!can(AuthorizationRules.StockGroupingsDelete)}
          noEdit={!can(AuthorizationRules.StockGroupingsUpdate)}
          noCancel
        />
        <div className={moduleStyles.fullContainer}>
          <Text textVariant="HeadingSmall">{t('inventory:main')}</Text>
          <FormState>
            {({ editable }) => (
              <>
                <Separator gap={16} direction="vertical" />
                <div
                  className={classNames(
                    moduleStyles.sectionContainer,
                    !editable && stockGroupingStyles.hide,
                  )}
                >
                  <div className={moduleStyles.halfContainer}>
                    <StockSelectInput name="stockId" />
                  </div>
                  <div className={moduleStyles.halfContainer}>
                    <HOCInput keys={['stockId']}>
                      {({ stockId }) => (
                        <StockItemSelectInput
                          stockId={stockId}
                          name="stockItemId"
                          label={t('inventory:barcode')}
                          placeholder={t('common:choose_extra', {
                            extra: t('inventory:barcode'),
                          })}
                          barcodeLabel
                          dependStockId
                          onAfterChange={(val) =>
                            methods.setValue('stockItemDetail', val)
                          }
                        />
                      )}
                    </HOCInput>
                  </div>
                </div>
              </>
            )}
          </FormState>

          <StockGroupingItem keyName="stockItemDetail" />
          <Separator gap={16} direction="vertical" />
          <StockGroupingSubItem t={t} />
        </div>
      </FormContent>
    </Form>
  );
}
