import { ActionIcon, Modal } from '@mantine/core';
import {
  AccountMutationLiteModel,
  getAccountMutationsKey,
  useUpdateTimeAccountMutation,
} from 'api-hooks/account-mutation';
import { PaginationMeta } from 'api-hooks/common/model';
import { EditIcon } from 'common/assets';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import { initialDate } from 'common/utils/date';
import Separator from 'components/common/separator';
import TableDetailButton from 'components/common/table-detail-button';
import { Button } from 'components/elements/button';
import DateTimePicker from 'components/elements/date-time-picker';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import Pagination from 'components/elements/pagination';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface Props {
  data: AccountMutationLiteModel[];
  loading?: boolean;
  page?: number;
  onPageChange?: (page: number) => void;
  meta?: PaginationMeta;
  sortColumns: IColumn<AccountMutationLiteModel>[];
  filter?: string;
  limit?: number;
  onLimitChange?: (limit: number) => void;
  noPagination?: boolean;
}

type ChangeTransactionAtFormType = {
  transactionAt: Date;
};
export default function AccountMutationTable(props: Props) {
  const { data, sortColumns, loading, meta, noPagination = false } = props;

  const { mutateAsync } = useUpdateTimeAccountMutation();
  const { navigate } = useNavigation();
  const drawer = useDialog();
  const { t } = useTranslation();
  const { can } = useAuthorization();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      transactionAt: Yup.date().required().nullable(),
    }),
  );
  const methods = useForm<ChangeTransactionAtFormType>({
    mode: 'onChange',
    defaultValues: {
      transactionAt: initialDate(),
    },
    resolver,
  });

  const onItemInvoked = React.useCallback(
    (id: string) => {
      can(AuthorizationRules.WalletMutationsView) &&
        navigate(NavigationRoutes.AccountMutationView, {
          params: {
            id,
          },
        });
    },
    [can, navigate],
  );

  const handleEditDateTime = React.useCallback(
    async (
      values: ChangeTransactionAtFormType,
      id: string,
      dismiss: () => void,
    ) => {
      try {
        const result = await mutateAsync({
          body: { ...values },
          accountMutationId: id,
        });
        queryClient.refetchQueries([getAccountMutationsKey()[0]]);
        notification.success({ message: result?.message });
        dismiss();
      } catch (error) {
        notification.error({ message: error.message });
      }
    },
    [mutateAsync],
  );

  const onItemEdit = React.useCallback(
    (id: string, transactionAt: Date) => {
      drawer.showCustom({
        render: (dismiss) => (
          <Modal
            opened
            onClose={dismiss}
            title={
              <Text textVariant="HeadingLarge">
                {t('modal:change_transaction_at')}
              </Text>
            }
          >
            <Form
              methods={methods}
              onSubmit={(values) => handleEditDateTime(values, id, dismiss)}
            >
              <Separator direction="vertical" gap={16} />
              <Text textVariant="BodyBoldDefault">
                {t('modal:current_transaction_at')}
              </Text>
              <DateTimePicker value={transactionAt} disabled />
              <Input
                type="date-time"
                name="transactionAt"
                label={t('modal:new_transaction_at')}
              />

              <Separator direction="vertical" gap={16} />
              <Button className={moduleStyles.buttonDialog} type="submit">
                {t('modal:change')}
              </Button>
            </Form>
          </Modal>
        ),
      });
    },
    [drawer, handleEditDateTime, methods, t],
  );

  const columns = React.useMemo<IColumn<AccountMutationLiteModel>[]>(
    () => [
      {
        header: '',
        accessorKey: 'action',
        minSize: 80,
        maxSize: 80,
        stickyLeft: true,
        cell: ({ row }) => {
          const { original } = row;
          return (
            <TableDetailButton onClick={() => onItemInvoked(original.id)} />
          );
        },
      },
      ...sortColumns,
      {
        header: '',
        accessorKey: 'action',
        minSize: 80,
        maxSize: 80,
        textAlign: 'right',
        cell: ({ row }) => {
          const { original } = row;

          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ActionIcon
                variant="transparent"
                onClick={() => onItemEdit(original.id, original.transactionAt)}
              >
                <EditIcon size={24} color={colors.productNormal} />
              </ActionIcon>
            </div>
          );
        },
      },
    ],
    [onItemEdit, onItemInvoked, sortColumns],
  );
  return (
    <>
      <div className={moduleStyles.tableContainer}>
        <TableComponent
          columns={columns}
          isLoading={loading}
          data={data || []}
        />
      </div>
      {!noPagination && (
        <Pagination
          {...{
            page: props.page!,
            onPageChange: props.onPageChange!,
            meta,
            limit: props.limit!,
            onLimitChange: props.onLimitChange!,
          }}
        />
      )}
    </>
  );
}
