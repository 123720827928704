import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  getWarehouseInput,
  getWarehousesInput,
  WarehouseLiteModel,
  WarehouseModel,
} from './model';

export const warehouseKey = {
  listKey: 'getWarehouses',
  detailKey: 'getWarehouse',
  list(input?: getWarehousesInput) {
    return [warehouseKey.listKey, input?.params];
  },
  detail(input?: getWarehouseInput) {
    return [warehouseKey.detailKey, input?.warehouseId];
  },
};

export function useGetWarehouses(
  input?: getWarehousesInput,
  options?: UseQueryOptions<ExtendedApiResult<WarehouseLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<WarehouseLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      warehouseKey.list(input),
      () =>
        QueryFetchFunction({
          url: 'warehouses',
          params: input?.params,
        }),
      options,
    ),
    WarehouseLiteModel,
  );
}

export function useGetWarehouse(
  input?: getWarehouseInput,
  options?: UseQueryOptions<ApiResult<WarehouseModel>, ApiError>,
): UseQueryResult<ApiResult<WarehouseModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      warehouseKey.detail(input),
      () =>
        QueryFetchFunction({
          url: `warehouses/${input?.warehouseId}`,
        }),
      options,
    ),
    WarehouseModel,
  );
}
