import { Modal } from '@mantine/core';
import {
  NotifyEnum,
  OutcomeNotifyMutationInput,
  useNotifyOutcome,
} from 'api-hooks/outcome';
import classNames from 'classnames';
import { ArrowRightIcon, BellIcon } from 'common/assets';
import notification from 'common/helpers/notification';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import { Radio } from 'components/elements/radio';
import Text from 'components/elements/text';
import useDialog from 'hooks/use-dialog';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import DivisionSelectInput from 'modules/master/division/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { NotificationEmail } from './notification-email';
import { NotificationEmployee } from './notification-employee';
import {
  NotificationTypeEnum,
  OutcomeNotificationFormType,
} from '../form-type';
import { outcomeTableStyles } from '../styles.css';

interface Props {
  outcomeId: string;
}

interface NotificationModalProps {
  outcomeId: string;
  close: () => void;
}

export function NotificationModal(props: NotificationModalProps) {
  const { outcomeId, close } = props;
  const { t } = useTranslation();
  const { mutateAsync: notifyOutcome } = useNotifyOutcome();
  const [notificationType, setNotificationType] =
    React.useState<NotificationTypeEnum>(NotificationTypeEnum.employee);

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      email:
        notificationType === NotificationTypeEnum.email
          ? Yup.array().of(Yup.string().nullable()).required().min(1)
          : Yup.array().nullable(),
      employeeId:
        notificationType === NotificationTypeEnum.employee
          ? Yup.array().of(Yup.string().nullable()).required().min(1)
          : Yup.array().nullable(),
      notifyOptions: Yup.array().of(Yup.string().nullable()).required().min(1),
    }),
  );

  const intialValues = React.useMemo<OutcomeNotificationFormType>(() => {
    return {
      email: [],
      employeeId: [],
      notifyOptions: [],
    };
  }, []);

  const methods = useForm<OutcomeNotificationFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });
  const { setValue } = methods;

  const onSubmit = React.useCallback(
    async (values: OutcomeNotificationFormType) => {
      try {
        const { email, employeeId, notifyOptions } = values;
        const input: OutcomeNotifyMutationInput = {
          notifies:
            notificationType === NotificationTypeEnum.email
              ? email
              : employeeId,
          notifyType: notificationType,
          notifyOptions,
        };
        const res = await notifyOutcome({
          body: input,
          outcomeId,
        });
        notification.success({ message: res?.message });
        close();
      } catch (e) {
        notification.error({ message: e?.message });
      }
    },
    [close, notificationType, notifyOutcome, outcomeId],
  );

  const notifyOption = React.useMemo(
    () => [
      {
        label: t('common:account'),
        value: NotifyEnum.account,
      },
      {
        label: t('common:amount'),
        value: NotifyEnum.amount,
      },
      {
        label: t('common:description'),
        value: NotifyEnum.description,
      },
      {
        label: t('modal:attachments'),
        value: NotifyEnum.attachments,
      },
    ],
    [t],
  );

  return (
    <Modal
      opened
      onClose={close}
      title={<Text textVariant="HeadingLarge">{t('common:notify')}</Text>}
      size={500}
      centered
    >
      <Form methods={methods} onSubmit={onSubmit}>
        <Input
          type="checkbox-group"
          name="notifyOptions"
          label={t('modal:notify_options')}
          data={notifyOption}
        />
        <Separator gap={16} direction="vertical" />
        <div
          className={classNames(
            moduleStyles.row(),
            outcomeTableStyles.itemsCenter,
          )}
        >
          <Radio
            label={t('common:send_to_employee')}
            checked={notificationType === NotificationTypeEnum.employee}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              e.target.checked &&
              setNotificationType(NotificationTypeEnum.employee)
            }
          />
          {notificationType === NotificationTypeEnum.employee && (
            <>
              <Separator gap={16} direction="horizontal" />
              <DivisionSelectInput
                name="divisionId"
                label=""
                className={moduleStyles.flex1}
                noMargin
                onAfterChange={() => setValue('employeeId', [])}
              />
            </>
          )}
        </div>
        {notificationType === NotificationTypeEnum.employee && (
          <NotificationEmployee name="employeeId" watchName="divisionId" />
        )}
        <Separator direction="vertical" gap={16} />
        <Radio
          label={t('common:send_to_email')}
          checked={notificationType === NotificationTypeEnum.email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            e.target.checked && setNotificationType(NotificationTypeEnum.email)
          }
        />
        {notificationType === NotificationTypeEnum.email && (
          <NotificationEmail name="email" />
        )}
        <div className={outcomeTableStyles.footerContainer}>
          <Button variant="tertiary" onClick={close} type="button">
            {t('common:cancel')}
          </Button>
          <Separator gap={16} direction="horizontal" />

          <Button
            rightIcon={(size) => <ArrowRightIcon size={size} />}
            onClick={() => methods.handleSubmit(onSubmit)()}
          >
            {t('common:send')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default function NotificationComponent(props: Props) {
  const { t } = useTranslation();
  const dialog = useDialog();
  const onClickNotify = React.useCallback(() => {
    dialog.showCustom({
      render: (close) => (
        <NotificationModal outcomeId={props.outcomeId!} close={close} />
      ),
    });
  }, [dialog, props.outcomeId]);
  return (
    <Button
      type="button"
      variant="secondary"
      leftIcon={(size) => <BellIcon size={size} />}
      onClick={onClickNotify}
    >
      {t('common:notify')}
    </Button>
  );
}
