import {
  BudgetingUsageModel,
  BudgetingUsageTypeEnum,
} from 'api-hooks/budgeting';
import { NavigationRoutes } from 'common/constants';
import { removeUnderscore, string2moneyDefault } from 'common/utils/string';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import TableComponent, { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { format } from 'date-fns';
import useNavigation from 'hooks/use-navigation';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

interface Props {
  data: BudgetingUsageModel[];
}

const getRoute = (type) => {
  switch (type) {
    case BudgetingUsageTypeEnum.expense:
      return NavigationRoutes.OutcomeView;
    case BudgetingUsageTypeEnum.income:
      return NavigationRoutes.IncomeView;
    case BudgetingUsageTypeEnum.top_up:
      return NavigationRoutes.TransferView;

    default:
      return NavigationRoutes.TransferView;
  }
};

const buttonStyles = {
  textDecoration: 'underline',
  padding: 4,
  borderRadius: 0,
};
export default function TransactionHistoryTable(props: Props) {
  const { t } = useTranslation();
  const { data } = props;
  const { navigate } = useNavigation();

  const _columns = React.useMemo<IColumn<BudgetingUsageModel>[]>(
    () => [
      {
        header: t('common:transaction_at'),
        accessorKey: 'transactionAt',
        minSize: 200,

        cell: ({ getValue }) => format(getValue() as Date, 'dd/MM/yyyy, HH:mm'),
      },
      {
        header: t('common:type'),
        accessorKey: 'type',
        minSize: 150,
        sortName: 'type',
        cell: ({ getValue }) => removeUnderscore(getValue() as string),
      },
      {
        header: t('budgeting:transaction_id'),
        accessorKey: 'action',
        minSize: 200,

        cell: ({ row: { original } }) => (
          <Separator gap={2} direction="vertical">
            <Button
              variant="tertiary"
              style={buttonStyles}
              onClick={() =>
                navigate(getRoute(original.type), {
                  params: {
                    id: original.id,
                  },
                })
              }
            >
              {original.code}
            </Button>
          </Separator>
        ),
      },
      {
        header: t('budgeting:amount'),
        accessorKey: 'amount',
        minSize: 200,
        textAlign: 'right',
        cell: ({ getValue }) => string2moneyDefault(getValue() as number),
      },
    ],
    [navigate, t],
  );

  return (
    <>
      <Text textVariant="HeadingSmall">
        {t('budgeting:transaction_history')}
      </Text>
      <Separator gap={16} direction="vertical" />
      <TableComponent columns={_columns} data={data || []} />
    </>
  );
}
