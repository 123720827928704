import { useGetChartOfAccounts } from 'api-hooks/chart-of-accounts';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import filterReduce from 'common/helpers/filter-reduce';
import notification from 'common/helpers/notification';
import { string2moneyDefault } from 'common/utils/string';
import ListHeader from 'components/common/list-header';
import QueryFilter, {
  useApplyQueryFilter,
} from 'components/common/query-filter/query-filter';
import { IColumn } from 'components/elements/table';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import { useAuthorization } from 'hooks/use-authorization';
import useComposedQuery from 'hooks/use-composed-query';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import ChartOfAccountsTable from './components/table';

export default function ChartOfAccountsList() {
  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { entity } = useEntity();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(15);
  const navigation = useNavigation();

  const _columns = React.useMemo<IColumn<any>[]>(
    () => [
      {
        header: t('common:number_full'),
        accessorKey: 'number',
        minSize: 200,
        sortName: 'number',
      },
      {
        header: t('common:name'),
        accessorKey: 'name',
        minSize: 200,
        sortName: 'name',
      },
      {
        header: t('common:category'),
        accessorKey: 'category.name',
        minSize: 150,
      },
      {
        header: t('common:amount'),
        accessorKey: 'balance',
        minSize: 150,
        textAlign: 'right',
        cell: ({ getValue, row }) => {
          return `${
            row.original?.entityCurrency?.currency?.currency || ''
          } ${string2moneyDefault(getValue() as string)}`;
        },
      },
      {
        header: t('common:is_parent'),
        accessorKey: 'isParent',
        minSize: 200,
        cell: ({ getValue }) => {
          const val = (getValue() as boolean).toString();
          return val.charAt(0).toUpperCase() + val.slice(1);
        },
      },
      {
        header: t('common:parent'),
        accessorKey: 'parent.fullName',
        minSize: 250,
        cell: ({ getValue }) => (getValue() ? getValue() : '-'),
      },
    ],
    [t],
  );

  const {
    data,
    refetch,
    isLoading,
    isFetching,
    error,
    extras: [{ filters, setFilters }, { columns }],
  } = useComposedQuery(
    useGetChartOfAccounts,
    {
      params: {
        page,
        limit,
        filter: {
          entity_id: entity?.id!,
        },
      },
    },
    useApplyQueryFilter((data: any) => {
      return data.filters;
    }),
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
  );

  const _filter = filterReduce(filters);

  const onCreate = React.useCallback(() => {
    navigation.navigate(NavigationRoutes.ChartOfAccountsCreate);
  }, [navigation]);

  React.useEffect(() => {
    if (!!error && !!error.message) {
      notification.error({ message: error?.message });
    }
  }, [error]);

  return (
    <div className={moduleStyles.container}>
      <ListHeader
        title={t('common:chart_of_accounts')}
        showAdd={can(AuthorizationRules.ChartOfAccountsCreate)}
        onCreate={onCreate}
        onRefresh={refetch}
      />
      <div className={moduleStyles.topContainer}>
        <QueryFilter
          setPage={setPage}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <ChartOfAccountsTable
        chartOfAccounts={data?.data || []}
        page={page}
        sortColumns={columns || []}
        loading={isLoading || isFetching}
        onPageChange={(page) => setPage(page)}
        meta={data?.meta}
        filter={_filter}
        limit={limit}
        onLimitChange={(limit) => setLimit(limit)}
      />
    </div>
  );
}
