import classNames from 'classnames';
import { PlusIcon, TrashIcon } from 'common/assets';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import EmployeeSelectInput from 'modules/master/employee/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { outcomeTableStyles } from '../styles.css';

export function NotificationEmployee({
  name,
  watchName,
}: {
  name: string;
  watchName: string;
}) {
  const { control } = useFormContext<any>();
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
    keyName: 'customId',
  });

  return (
    <Separator gap={16} direction="vertical">
      {fields.map((item, idx) => (
        <div
          className={classNames(moduleStyles.flex1, moduleStyles.row())}
          key={item.customId}
        >
          <HOCInput keys={[`${watchName}`]}>
            {(value) => (
              <EmployeeSelectInput
                name={`${name}.${idx}`}
                key={`${name}.${idx}.${item.customId}`}
                label=""
                placeholder={t('common:choose_extra', {
                  extra: t('common:employee'),
                })}
                className={moduleStyles.flex1}
                division={value[`${watchName}`]}
              />
            )}
          </HOCInput>
          {idx ? (
            <Button
              variant="tertiary"
              error
              type="button"
              onClick={() => remove(idx)}
            >
              <TrashIcon size={24} />
            </Button>
          ) : (
            <div className={outcomeTableStyles.deleteContainer} />
          )}
        </div>
      ))}
      <HOCInput keys={[`${watchName}`]}>
        {(value) => (
          <>
            {!!value[`${watchName}`] && (
              <div>
                <Button
                  onClick={() => append('')}
                  type="button"
                  variant="secondary"
                  leftIcon={(size) => <PlusIcon size={size} />}
                >
                  {t('common:add_extra', { extra: t('common:employee') })}
                </Button>
              </div>
            )}
          </>
        )}
      </HOCInput>
    </Separator>
  );
}
