import { ChartOfAccountCategoriesLiteModel } from 'api-hooks/chart-of-account-categories';
import { getIndexInput } from 'api-hooks/common';
import { EntityCurrencyLiteModel } from 'api-hooks/entity-currency/model';
import { Expose, Type } from 'class-transformer';

// Model
export class ChartOfAccountParentModel {
  id: string;
  name: string;

  @Expose({ name: 'full_name' })
  fullName: string;
}

export class ChartOfAccountsLiteModel {
  id: string;
  name: string;
  description: string;
  number: string;

  @Expose({ name: 'id_name' })
  idName: string;

  @Expose({ name: 'full_name' })
  fullName: string;

  @Expose({ name: 'is_parent' })
  isParent: boolean;

  @Type(() => ChartOfAccountParentModel)
  parent: ChartOfAccountParentModel;

  @Type(() => ChartOfAccountCategoriesLiteModel)
  category: ChartOfAccountCategoriesLiteModel;

  @Type(() => Number)
  balance: number;

  @Expose({ name: 'entity_currency' })
  @Type(() => EntityCurrencyLiteModel)
  entityCurrency: EntityCurrencyLiteModel;
}

export class ChartOfAccountsModel {
  id: string;
  name: string;
  description: string;
  number: string;

  @Type(() => ChartOfAccountParentModel)
  parent: ChartOfAccountParentModel;

  @Expose({ name: 'id_name' })
  idName: string;

  @Expose({ name: 'full_name' })
  fullName: string;

  @Expose({ name: 'is_parent' })
  isParent: boolean;

  @Type(() => ChartOfAccountCategoriesLiteModel)
  category: ChartOfAccountCategoriesLiteModel;

  @Type(() => Number)
  balance: number;

  @Expose({ name: 'entity_currency' })
  @Type(() => EntityCurrencyLiteModel)
  entityCurrency: EntityCurrencyLiteModel;
}

export class ChartOfAccountChildrenModel {
  id: string;
  name: string;

  @Expose({ name: 'full_name' })
  fullName: string;
}

// Input

export type getChartOfAccountInput = {
  id: string;
};

export type getChartOfAccountChildrenInput = {
  id: string;
};

export type getChartOfAccountsInput = getIndexInput;

export type ChartOfAccountsMutationInput = {
  name: string;
  idName: string;
  number: string;
  parentId: string;
  categoryId: string;
  balance: number;
  desription: string;
  entityId: string;
};

export type ChartOfAccountsUpdateInput = {
  id: string;
  body: ChartOfAccountsMutationInput;
};
export type ChartAccountsDeleteInput = {
  id: string;
};
