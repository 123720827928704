import {
  OutcomeModel,
  getOutcomesKey,
  useDeleteOutcome,
} from 'api-hooks/outcome';
import { CancelIcon } from 'common/assets';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import { initialDate } from 'common/utils/date';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form, { FormState } from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { NotificationTypeEnum, OutcomeFormType, formSchema } from './form-type';
import OutcomeInformationForm from './information-form';
import NotificationComponent from './notification-modal';

interface Props extends NavigationProps {
  renderError?: React.ReactNode;
  outcome?: OutcomeModel;
  onSubmit: (
    input: any,
    form: ReturnType<typeof useForm>,
  ) => Promise<OutcomeModel | undefined>;
}

export default function ExpenseForm(props: Props) {
  const { t } = useTranslation();
  const { outcome } = props;
  const dialog = useDialog();
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate, isLoading: deleteLoading } =
    useDeleteOutcome();

  const { can } = useAuthorization();

  const resolver = useYupValidationResolver(formSchema(outcome));

  const intialValues = React.useMemo<OutcomeFormType>(() => {
    return {
      amount: outcome?.amount || '',
      account: {
        id: '',
        isBaseCurrency: false,
        balance: 0,
        currency: '',
        entityCurrencyId: '',
      },
      accountId: outcome?.account?.id || null,
      description: outcome?.description || '',
      isBaseCurrency: outcome?.isBaseCurrency || false,
      brands: (outcome?.brands || []).map((item) => item.id),
      rate: outcome?.rate || 1,
      transactionAt: outcome?.transactionAt || initialDate(),
      entityId: outcome?.entityId || entity?.id || null,
      isIncludeMutation: outcome ? outcome?.isIncludeMutation : true,
      hasAttachment: outcome?.hasAttachment || false,
      chartOfAccountId: outcome?.chartOfAccount?.id || null,
      isAttachmentCompleted: outcome?.isAttachmentCompleted || false,
      requestFormId: outcome?.requestForm?.id || '',
      files: outcome?.files || [],
      supplierId: outcome?.supplier.id || '',
      divisionId: outcome?.division.id || '',
      isNotify: false,
      notifyType: NotificationTypeEnum.employee,
      email: [],
      employeeId: [],
      entityCurrencyId: outcome?.entityCurrency.id || '',
      transactionRate: outcome?.transactionRate || '',
      isStockPurchase: !!outcome?.stockPurchaseItems?.length || false,
      stockPurchaseItems: outcome?.stockPurchaseItems?.map((item) => ({
        stockId: item.stock?.id,
        qty: item.qty,
        price: item.price,
        warehouseId: item.warehouse?.id,
        items: item.stockPurchaseItemDetails?.map((detail) => ({
          merk: detail.stockItem.merk,
          description: detail.stockItem.description,
          files: detail.stockItem.files.map((file) => ({
            file: file.name,
            url: file.url,
          })),
          isUsed: detail.stockItem.isUsed,
          barcode: detail.stockItem.barcode,
        })),
      })),
    };
  }, [outcome, entity]);

  const methods = useForm<OutcomeFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values: OutcomeFormType) => {
      const {
        email,
        employeeId,
        notifyType,
        files,
        entityCurrencyId,
        account,
        transactionRate,
        stockPurchaseItems,
        ...rest
      } = values;
      const notify = !outcome && values.isNotify;
      try {
        const _input = {
          ...rest,
          entityCurrencyId,
          transactionRate: (entityCurrencyId !== account.entityCurrencyId
            ? transactionRate
            : 1) as number,
          files: files?.map((item) => item.file),
          notifyType: !outcome && values.isNotify ? notifyType : null,
          notifies: notify
            ? notifyType === NotificationTypeEnum.email
              ? email
              : employeeId
            : null,

          stockPurchaseItems: stockPurchaseItems?.map((purchaseItem) => ({
            ...purchaseItem,
            items: purchaseItem?.items?.map((item) => ({
              ...item,
              files: item?.files?.map((file) => file.file),
            })),
          })),
        };

        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
        notification.error({ message: e.message });
      }
    },
    [outcome, methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            outcomeId: outcome!.id,
          });
          queryClient.refetchQueries([getOutcomesKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, outcome, t]);

  const errorMessage = React.useMemo(() => {
    const employeeErr = methods.formState.errors?.employeeId?.message;
    const emailErr = methods.formState.errors?.email?.message;

    return employeeErr || emailErr
      ? `${t('common:notify')} ${
          employeeErr ? t('common:employee') : t('common:email')
        } ${employeeErr || emailErr}`
      : '';
  }, [
    t,
    methods.formState.errors?.employeeId?.message,
    methods.formState.errors?.email?.message,
  ]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!outcome}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {errorMessage && <Alert variant="error" description={errorMessage} />}
        {props.renderError}
        <FormState>
          {({ editable }) => (
            <FormHeader
              onDelete={onDelete}
              data={outcome}
              title={
                outcome
                  ? t('common:outcome')
                  : t('common:create_extra', { extra: t('common:outcome') })
              }
              noDelete={
                !!outcome?.isTransactionClosed ||
                !can(AuthorizationRules.ExpensesDelete)
              }
              noEdit={
                !!outcome?.isTransactionClosed ||
                !can(AuthorizationRules.ExpensesUpdate)
              }
              noCancelLarge
              noSubmitLarge
              isDeleteLoading={deleteLoading}
              onRenderComponent={
                <>
                  {!editable && (
                    <NotificationComponent outcomeId={outcome?.id!} />
                  )}
                  <Separator gap={16} direction="horizontal" />
                </>
              }
            />
          )}
        </FormState>

        <OutcomeInformationForm
          requestForm={outcome?.requestForm?.title}
          outcome={outcome}
        />
        <Separator gap={32} direction="vertical" />
        <FormState>
          {({ editable, setIsEditable }) => (
            <>
              {editable && (
                <>
                  <div className={moduleStyles.viewScreenLargeFlex}>
                    <div className={moduleStyles.buttonContainer}>
                      <Input type="submit" />
                    </div>
                    <Separator gap={16} direction="horizontal" />
                    {!!outcome && (
                      <div className={moduleStyles.buttonContainer}>
                        <Button
                          onClick={() => {
                            methods.reset();
                            setIsEditable(false);
                          }}
                          variant="secondary"
                          error
                          leftIcon={(size) => (
                            <CancelIcon width={size} height={size} />
                          )}
                        >
                          {t('common:cancel')}
                        </Button>
                      </div>
                    )}
                  </div>
                  <Separator gap={16} direction="vertical" />
                </>
              )}
            </>
          )}
        </FormState>
      </FormContent>
    </Form>
  );
}
