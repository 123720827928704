import { TransactionReportTotalModel } from 'api-hooks/report';
import { string2money } from 'common/utils/string';
import Separator from 'components/common/separator';
import Text from 'components/elements/text';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import { reportStyle } from './style.css';

interface Props extends TransactionReportTotalModel {
  currency: string;
}
export default function TotalComponent(props: Props) {
  const { currency, amount, name, transaction } = props;
  const { t } = useTranslation();
  return (
    <div className={reportStyle.totalComponentContainer}>
      <Text
        textVariant="BodySmaller"
        className={reportStyle.totalComponentTitle}
      >
        {t(`common:${name?.toLowerCase()}`)}:
      </Text>
      <Separator gap={16} direction="horizontal" />
      <div>
        <Text
          textVariant="MonoDefault"
          className={reportStyle.totalComponentAmount}
        >{` ${currency} ${string2money(amount, 4)}`}</Text>

        <Text textVariant="BodySmaller">
          {`${transaction} ${t(
            `common:${transaction < 2 ? 'transaction' : 'transactions'}`,
          ).toLowerCase()}`}
        </Text>
      </div>
    </div>
  );
}
