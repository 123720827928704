import { getAccountsKey, useCreateAccount } from 'api-hooks/account';
import { getEntityAccountsKey, getEntityKey } from 'api-hooks/entity';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import React from 'react';

import AccountForm from './components/form';

const AccountCreate = (props: NavigationProps) => {
  const { navigate, close } = useNavigation();
  const { can } = useAuthorization();
  const { mutateAsync, error } = useCreateAccount();
  const { entity } = useEntity();

  return (
    <div>
      <AccountForm
        {...props}
        renderError={
          !!error &&
          !!error.message && (
            <>
              <Alert variant="error" description={error.message} />
              <Separator gap={8} />
            </>
          )
        }
        onSubmit={async ({ currencyId, ...input }, form) => {
          const _input = {
            ...input,
            entityId: entity?.id,
          };
          const result = await mutateAsync(_input);
          close();
          form.reset();
          queryClient.refetchQueries([getAccountsKey()[0]]);
          queryClient.refetchQueries(getEntityKey({ id: entity?.id || '' }));
          queryClient.refetchQueries([getEntityAccountsKey()[0]]);
          if (can(AuthorizationRules.AccountsView)) {
            navigate(NavigationRoutes.AccountView, {
              params: { id: result.data?.id },
            });
          }
          notification.success({ message: result?.message });
          return result.data;
        }}
      />
    </div>
  );
};

export default AccountCreate;
