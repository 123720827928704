import {
  StockLiteModel,
  StockModel,
  stockKey,
  useGetStock,
  useGetStocks,
} from 'api-hooks/stock';
import { QueryTransformer } from 'common/helpers/common';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  onAfterChange?: (value?: StockModel) => void;
  overrideOnChange?: (prevValue: any, value: any) => void;
  noMargin?: boolean;
  isAsset?: boolean;
}

function transformer(item: StockLiteModel): OptionProps {
  return {
    value: item.id,
    label: `${item.code ? `${item.code} - ` : ''} ${item.name}`,
  };
}

export default function StockSelectInput(props: Props) {
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });
  const { t } = useTranslation();
  const {
    label = t('common:stock'),
    placeholder = t('common:choose_extra', { extra: t('common:stock') }),
    isAsset,
  } = props;

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
      !value && props.onAfterChange?.(undefined);
    },
    useListQueryHook: useGetStocks,
    useDetailLazyQueryHook: useGetStock,
    getListsKey: React.useCallback(
      (page, q) =>
        stockKey.list({
          params: {
            q,
            page,
            filter: {
              ...(isAsset && {
                is_asset: isAsset,
              }),
            },
          },
        }),
      [isAsset],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            ...(isAsset && {
              is_asset: isAsset,
            }),
          },
        },
      }),
      [isAsset],
    ),
    renderCreate: false,
    renderNoData: true,
    getDetailKey: React.useCallback(
      (value) =>
        stockKey.detail({
          stockId: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        stockId: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      const newResult = QueryTransformer(result, StockModel);
      props.onAfterChange?.(newResult.data);
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      required
      label={label}
      placeholder={placeholder}
      name={props.name}
      noMargin={props.noMargin}
      disabled={props.disabled}
      {...selectProps}
    />
  );
}
