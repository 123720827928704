import {
  useMutation,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import {
  MutationFetchFunction,
  QueryFetchFunction,
  QueryTransformer,
} from 'common/helpers/common';
import { useEntity } from 'hooks/use-entities';

import {
  getOutcomeInput,
  getOutcomesInput,
  OutcomeLiteModel,
  OutcomeLiteModelData,
  OutcomeModel,
} from './model';

const OUTCOMES_KEY = 'getOutcomes';
const OUTCOME_KEY = 'getOutcome';

export function useGetOutcomes(
  input?: getOutcomesInput,
  options?: UseQueryOptions<OutcomeLiteModel, ApiError>,
): UseQueryResult<OutcomeLiteModel, ApiError> {
  return QueryTransformer(
    useQuery(
      [OUTCOMES_KEY, input?.params],
      () =>
        QueryFetchFunction({
          url: 'expenses',
          params: input?.params,
        }),
      options,
    ),
    OutcomeLiteModelData,
  );
}

export function useGetOutcome(
  input?: getOutcomeInput,
  options?: UseQueryOptions<ApiResult<OutcomeModel>, ApiError>,
): UseQueryResult<ApiResult<OutcomeModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      [OUTCOME_KEY, input?.id],
      () =>
        QueryFetchFunction({
          url: `expenses/${input?.id}`,
        }),
      options,
    ),
    OutcomeModel,
  );
}

export function useExportPDFOutcome() {
  return useMutation<ApiResult<any>, ApiError, getOutcomesInput>((input) =>
    MutationFetchFunction({
      url: 'expenses/export-pdf',
      params: input?.params,
      method: 'GET',
    }),
  );
}

export function useImportTemplate() {
  const { entity } = useEntity();
  return useMutation<ApiResult<any>, ApiError>(() =>
    MutationFetchFunction({
      url: 'expenses/import/template',
      method: 'GET',
      params: {
        entity_id: entity?.id!,
      },
    }),
  );
}
export function getOutcomesKey(input?: getOutcomesInput) {
  return [OUTCOMES_KEY, input?.params];
}

export function getOutcomeKey(input?: getOutcomeInput) {
  return [OUTCOME_KEY, input?.id];
}
