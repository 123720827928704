import {
  getAllEntitiesKey,
  getEntityKey,
  useGetAllEntity,
  useGetEntity,
} from 'api-hooks/entity';
import { EntityLiteModel, EntityModel } from 'api-hooks/role';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(item: EntityModel | EntityLiteModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  disabled?: boolean;
  noMargin?: boolean;
}

const EntitySelectInput = (props: Props) => {
  const { navigate } = useNavigation();
  const { can } = useAuthorization();
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetAllEntity,
    useDetailLazyQueryHook: useGetEntity,
    getListsKey: React.useCallback(
      (_, q) => getAllEntitiesKey({ params: { q } }),
      [],
    ),
    getMemoizedListVariables: React.useCallback(
      (_, q) => ({
        params: {
          q,
        },
      }),
      [],
    ),
    renderCreate: !!can(AuthorizationRules.EntitiesCreate),
    onClickCreate: () => {
      navigate(NavigationRoutes.EntityCreate);
      props.onClose && props.onClose();
    },
    getDetailKey: React.useCallback(
      (value) =>
        getEntityKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      name={props.name}
      required={props.required}
      label={props.label}
      disabled={props.disabled}
      placeholder={props.placeholder}
      onAfterChange={props.onAfterChange}
      noMargin={props.noMargin}
      {...selectProps}
    />
  );
};

export default EntitySelectInput;
