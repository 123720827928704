import { OutcomeModel } from 'api-hooks/outcome';
import { PurchaseRequisitionsModel } from 'api-hooks/purchase-requisitions';
import colors from 'common/styles/colors';
import { string2moneyDefault } from 'common/utils/string';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { Input } from 'components/elements/field';
import { FormState } from 'components/elements/form';
import FormStateRawField from 'components/elements/form-state-raw-field';
import Text from 'components/elements/text';
import { useEntity } from 'hooks/use-entities';
import AccountSelectInputByEntityId from 'modules/entity-settings/account/components/select-input-by-entity';
import BrandMultiAllSelect from 'modules/entity-settings/brand/components/multi-all-select-input';
import EntityCurrencySelectInput from 'modules/entity-settings/entity-currency/components/select-input';
import ChartOfAccountsSelectInput from 'modules/master/chart-of-accounts/components/select-input';
import DivisionByEmployeeSelectInput from 'modules/master/division/components/select-input-by-employee';
import SupplierSelectInput from 'modules/master/supplier/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import PurchaseRequisitionsOutcomeSelectInput from 'modules/transactions/purchase-requisitions/components/select-input-outcome';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import NotificationForm from './notification-form';
import StockPurchaseForm from './stock-purchase';

interface Props {
  requestForm?: string;
  outcome?: OutcomeModel;
}
export default function OutcomeInformationForm(props: Props) {
  const { t } = useTranslation();
  const { setValue, clearErrors } = useFormContext<any>();
  const { entity } = useEntity();
  const { outcome } = props;

  return (
    <div className={moduleStyles.fullContainer}>
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <Input
            label={t('common:transaction_at')}
            placeholder={t('common:transaction_at')}
            name="transactionAt"
            type="date-time"
            required
            disabled={!!outcome}
          />
        </div>
        <div className={moduleStyles.halfContainer}>
          <Input
            label={t('common:description')}
            placeholder={t('common:description')}
            name="description"
            type="text-area"
          />
        </div>
      </div>
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <FormState>
            {({ editable }) => (
              <AccountSelectInputByEntityId
                name="accountId"
                required
                onAfterDetailChange={(val) => {
                  if (!outcome) {
                    setValue('transactionRate', 1);
                    setValue('entityCurrencyId', val.entityCurrencyId, {
                      shouldValidate: true,
                    });
                  }

                  setValue('account', val);
                  if (!val.isBaseCurrency) {
                    editable &&
                      setValue('rate', val.rate, { shouldValidate: true });
                  } else {
                    setValue('rate', 1);
                  }
                }}
                noMargin
                description={
                  <HOCInput keys={['account']}>
                    {({ account }) =>
                      account.id ? (
                        <>
                          <Separator gap={8} direction="vertical" />
                          <Text
                            textVariant="MonoSmaller"
                            color={colors.textLight}
                          >{`${t('common:balance')}: ${
                            account.currency
                          } ${string2moneyDefault(account.balance)}`}</Text>
                          <Separator gap={16} direction="vertical" />
                        </>
                      ) : (
                        <Separator gap={16} direction="vertical" />
                      )
                    }
                  </HOCInput>
                }
              />
            )}
          </FormState>
        </div>
        <div className={moduleStyles.halfContainer}>
          <ChartOfAccountsSelectInput
            name="chartOfAccountId"
            required
            isParent={false}
            label={t('common:chart_of_accounts')}
            placeholder={t('common:choose_extra', {
              extra: t('common:chart_of_accounts'),
            })}
          />
        </div>
      </div>

      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <DivisionByEmployeeSelectInput name="divisionId" />
        </div>
        <div className={moduleStyles.halfContainer}>
          <BrandMultiAllSelect
            label={t('common:brand')}
            placeholder={t('common:brand')}
            name="brands"
            required
            entityId={entity?.id!}
            model="expenses"
          />
        </div>
      </div>

      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <HOCInput keys={['account.entityCurrencyId']}>
            {({ 'account.entityCurrencyId': accountEntityCurrencyId }) => (
              <EntityCurrencySelectInput
                name="entityCurrencyId"
                placeholder={t('common:currency')}
                label={t('common:currency')}
                onAfterChange={(val) => {
                  val.id !== accountEntityCurrencyId &&
                    setValue(
                      'transactionRate',
                      val.id !== outcome?.entityCurrency.id
                        ? val.rate
                        : outcome?.transactionRate,
                    );
                }}
              />
            )}
          </HOCInput>
        </div>
        <div className={moduleStyles.halfContainer}>
          <Input
            label={t('transfer:amount')}
            placeholder={t('transfer:amount')}
            name="amount"
            type="number"
            isMoneyFormat
            required
            noMargin
          />
          <Separator gap={8} direction="vertical" />
          <HOCInput keys={['transactionRate', 'amount', 'account.currency']}>
            {({
              transactionRate,
              amount,
              'account.currency': accountCurrency,
            }) =>
              !!amount && !!transactionRate && !!accountCurrency ? (
                <>
                  <Text
                    textVariant="MonoSmaller"
                    color={colors.textLight}
                  >{`~ ${accountCurrency} ${string2moneyDefault(
                    transactionRate * amount,
                  )}`}</Text>
                  <Separator gap={16} direction="vertical" />
                </>
              ) : (
                <></>
              )
            }
          </HOCInput>
        </div>
      </div>
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <HOCInput keys={['account.entityCurrencyId', 'entityCurrencyId']}>
            {({
              'account.entityCurrencyId': accountEntityCurrencyId,
              entityCurrencyId,
            }) =>
              accountEntityCurrencyId !== entityCurrencyId ? (
                <Input
                  label={t('common:transaction_rate')}
                  placeholder={t('common:transaction_rate')}
                  name="transactionRate"
                  type="number"
                  isMoneyFormat
                  inputMode="decimal"
                  required
                />
              ) : (
                <></>
              )
            }
          </HOCInput>
        </div>
        <div className={moduleStyles.halfContainer}>
          <HOCInput keys={['account.isBaseCurrency', 'account.currencyName']}>
            {({
              'account.isBaseCurrency': isBase,
              'account.currencyName': currencyName,
            }) =>
              !isBase && currencyName ? (
                <Input
                  label={t('common:base_rate')}
                  placeholder={t('common:base_rate')}
                  name="rate"
                  type="number"
                  isMoneyFormat
                  inputMode="decimal"
                  required
                />
              ) : (
                <></>
              )
            }
          </HOCInput>
        </div>
      </div>

      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          {outcome ? (
            <FormStateRawField
              label={t('common:purchase_requisitions')}
              value={props?.requestForm || '-'}
              type="text"
            />
          ) : (
            <HOCInput keys={['brands', 'divisionId']}>
              {({ brands, divisionId }) => (
                <PurchaseRequisitionsOutcomeSelectInput
                  name="requestFormId"
                  onAfterChange={(val: PurchaseRequisitionsModel) => {
                    setValue('description', val.description);
                    if (val?.files?.length) {
                      setValue('hasAttachment', true, {
                        shouldValidate: true,
                      });
                      setValue(
                        'files',
                        val.files.map((file) => ({
                          file: file.name,
                          url: file.url,
                        })),
                        {
                          shouldValidate: true,
                        },
                      );
                    }
                  }}
                  divisionId={divisionId}
                  brandsId={brands}
                />
              )}
            </HOCInput>
          )}
        </div>
        <div className={moduleStyles.halfContainer}>
          <HOCInput keys={['divisionId']}>
            {({ divisionId }) => (
              <SupplierSelectInput name="supplierId" divisionId={divisionId} />
            )}
          </HOCInput>
        </div>
      </div>

      {/* MUTATION */}
      <div className={moduleStyles.sectionContainer}>
        <Input
          type="checkbox"
          name="isIncludeMutation"
          label={t('common:include_account_mutation')}
        />
      </div>
      <Separator gap={48} direction="vertical" />
      <StockPurchaseForm t={t} />
      <div className={moduleStyles.sectionContainer}>
        <div className={moduleStyles.halfContainer}>
          <Separator gap={48} direction="vertical" />
          <Input
            type="checkbox"
            name="hasAttachment"
            label={t('common:has_attachment')}
            onAfterChange={() => clearErrors()}
          />
          <HOCInput keys={['hasAttachment']}>
            {({ hasAttachment }) =>
              hasAttachment ? (
                <>
                  <Separator gap={16} direction="vertical" />
                  <Input
                    type="checkbox"
                    name="isAttachmentCompleted"
                    label={t('common:attachment_completed')}
                  />
                  <Separator gap={16} direction="vertical" />
                  <Input
                    name="files"
                    previewKey="url"
                    valueKey="file"
                    type="files"
                    label={t('common:attachment')}
                    required
                  />
                </>
              ) : (
                <></>
              )
            }
          </HOCInput>
        </div>
        {!outcome && (
          <div className={moduleStyles.halfContainer}>
            <Separator gap={48} direction="vertical" />
            <Input
              type="checkbox"
              name="isNotify"
              label={t('common:notify')}
              onAfterChange={() => clearErrors()}
            />
            <HOCInput keys={['isNotify']}>
              {({ isNotify }) => (isNotify ? <NotificationForm /> : <></>)}
            </HOCInput>
          </div>
        )}
      </div>
    </div>
  );
}
