import { BrandLiteModel } from 'api-hooks/brand';
import { ChartOfAccountParentModel } from 'api-hooks/chart-of-accounts';
import { CommonSimpleModel, getIndexInput } from 'api-hooks/common/model';
import { Expose, Type } from 'class-transformer';

// Enum
export enum BudgetStatusEnum {
  upcoming = 'upcoming',
  active = 'active',
  completed = 'completed',
}

export enum BudgetingUsageTypeEnum {
  top_up = 'top_up',
  income = 'income',
  expense = 'expense',
}

// Model

export class ActiveBudgetingItemLiteModel {
  id: string;
  status: BudgetStatusEnum;

  @Type(() => Number)
  amount: number;

  @Expose({ name: 'is_over_threshold' })
  isOverThreshold: boolean;

  @Expose({ name: 'is_over_budget' })
  isOverBudget: boolean;

  @Expose({ name: 'used_amount' })
  @Type(() => Number)
  usedAmount: number;

  @Expose({ name: 'topped_up_amount' })
  @Type(() => Number)
  toppedUpAmount: number;

  @Expose({ name: 'start_from' })
  @Type(() => Date)
  startFrom: Date;

  @Expose({ name: 'end_at' })
  @Type(() => Date)
  endAt: Date;
}

export class BudgetingUsageModel {
  id: string;

  type: string;
  code: string;

  @Type(() => Number)
  amount: number;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;
}

export class ActiveBudgetingItemModel extends ActiveBudgetingItemLiteModel {
  @Expose({ name: 'notify_threshold_percent' })
  @Type(() => Number)
  notifyThresholdPercent: number;

  @Expose({ name: 'employee_to_notifies' })
  employeeToNotifies: CommonSimpleModel[];

  @Expose({ name: 'chart_of_accounts' })
  @Type(() => ChartOfAccountParentModel)
  chartOfAccounts: ChartOfAccountParentModel;

  @Expose({ name: 'budgeting_usages' })
  @Type(() => BudgetingUsageModel)
  budgetingUsages: BudgetingUsageModel[];
}

export class BudgetingLiteModel {
  id: string;
  name: string;
  status: BudgetStatusEnum;
  division: CommonSimpleModel;

  @Type(() => BrandLiteModel)
  brands: BrandLiteModel[];

  @Expose({ name: 'employee_to_notifies' })
  employeeToNotifies: CommonSimpleModel[];

  @Expose({ name: 'active_budgeting_item' })
  @Type(() => ActiveBudgetingItemLiteModel)
  activeBudgetingItem: ActiveBudgetingItemLiteModel | null;
}

export class BudgetingItemsModel {
  id: string;
  status: BudgetStatusEnum;

  @Expose({ name: 'is_over_threshold' })
  isOverThreshold: boolean;

  @Expose({ name: 'is_over_budget' })
  isOverBudget: boolean;

  @Expose({ name: 'start_from' })
  @Type(() => Date)
  startFrom: Date;

  @Expose({ name: 'end_at' })
  @Type(() => Date)
  endAt: Date;

  @Expose({ name: 'used_amount' })
  @Type(() => Number)
  usedAmount: number;

  @Type(() => Number)
  amount: number;
}

export class BudgetingModel {
  id: string;
  name: string;
  division: CommonSimpleModel;

  @Expose({ name: 'employee_to_notifies' })
  employeeToNotifies: CommonSimpleModel[];

  @Type(() => BrandLiteModel)
  brands: BrandLiteModel[];

  @Expose({ name: 'chart_of_accounts' })
  @Type(() => ChartOfAccountParentModel)
  chartOfAccounts: ChartOfAccountParentModel[];

  @Expose({ name: 'budgeting_items' })
  @Type(() => BudgetingItemsModel)
  budgetingItems: BudgetingItemsModel[];

  @Expose({ name: 'active_budgeting_item' })
  @Type(() => ActiveBudgetingItemModel)
  activeBudgetingItem: ActiveBudgetingItemModel | null;
}

// Input
export type getBudgetingsInput = getIndexInput;

export type getBudgetingInput = {
  id: string;
};
export type BudgetingMutationInput = {
  name: string;
  entityId: string;
  divisionId: string;
  brandIds: string[];
  notifyThresholdPercent: number;
  employeeToNotifyIds: string[];
  chartOfAccountIds: string[];
  amount: number;
  startFrom: Date;
  endAt: Date | string;
};

export type BudgetingUpdateItemBodyInput = {
  employeeToNotifyIds: string[];
  notifyThresholdPercent: number;
  amount: number;
  startFrom: Date;
  endAt: Date | string;
};

export type BudgetingUpdateItemInput = {
  budgetingId: string;
  budgetingItem: string;
  body: BudgetingUpdateItemBodyInput;
};

export type BudgetingMutationItemBodyInput = {
  amount: number;
  startFrom: Date;
  endAt: Date | string;
  notifyThresholdPercent: number;
  employeeToNotifyIds: string[];
  chartOfAccountIds: string[];
};

export type BudgetingMutationItemInput = {
  budgetingId: string;
  body: BudgetingMutationItemBodyInput;
};

export type BudgetingMutationTransferBodyInput = {
  toBudgetingId: string;
  amount: number;
};
export type BudgetingMutationTransferInput = {
  budgetingId: string;
  body: BudgetingMutationTransferBodyInput;
};

export type BudgetingDeleteInput = {
  budgetingId: string;
};
