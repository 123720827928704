import { AccountAccessModel } from 'api-hooks/account-access/model';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import FormContent from 'components/common/form-content';
import Separator from 'components/common/separator';
import Form from 'components/elements/form';
import FormStateRawField from 'components/elements/form-state-raw-field';
import { OptionProps } from 'components/elements/select';
import Text from 'components/elements/text';
import FormHeader from 'components/widgets/form-header';
import { NavigationProps } from 'containers/navigation';
import { useAuthorization } from 'hooks/use-authorization';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';

import { AccountAccessFormType, formSchema } from './form-type';
import AccountAccessItemForm from './item-form';

export interface Props extends NavigationProps {
  accountAccess?: AccountAccessModel;
  entitiesSelectOptions: OptionProps[];
  renderError?: React.ReactNode;
  onSubmit: (
    input: any,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}

export default function AccountAccessForm(props: Props) {
  const { accountAccess, entitiesSelectOptions } = props;

  const { t } = useTranslation();
  const { can } = useAuthorization();
  const { setNavigations } = useNavigation();

  const resolver = useYupValidationResolver(formSchema());

  const initialValues = React.useMemo<AccountAccessFormType>(
    () => ({
      entities:
        accountAccess?.entities.map((entity) => ({
          id: entity.id,
          accounts: entity.accounts.map((acc) => acc.id),
          isAllAccount: entity.isAllAccount,
        })) || [],
    }),
    [accountAccess?.entities],
  );

  const methods = useForm<AccountAccessFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = async (values: AccountAccessFormType) => {
    try {
      await props.onSubmit(values, methods as any);
    } catch (e: any) {
      if (e.errors) {
        formSetErrors(e.errors, methods.setError);
      }
    }
  };

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      setNavigations={setNavigations}
      navigation={props.navigation}
      defaultEditable={false}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          data={accountAccess}
          title={`${t('common:account_access')} - ${accountAccess?.name}`}
          noDelete
          noEdit={!can(AuthorizationRules.AccountAccessesUpdate)}
        />
        <div className={moduleStyles.fullContainer}>
          <FormStateRawField
            type="text"
            value={accountAccess?.name}
            label={t('common:user_name')}
          />

          <Separator gap={32} direction="vertical" />
          <Text textVariant="HeadingSmall">{t('common:account_access')}</Text>
          <Separator gap={16} direction="vertical" />
          <AccountAccessItemForm
            entitiesSelectOptions={entitiesSelectOptions}
          />
        </div>
      </FormContent>
    </Form>
  );
}
