import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  EntityCurrencyDeleteInput,
  EntityCurrencyModel,
  EntityCurrencyMutationInput,
  EntityCurrencyUpdateInput,
} from './model';

export function useCreateEntityCurrency(
  options?: UseMutationOptions<
    ApiResult<EntityCurrencyModel>,
    ApiError,
    EntityCurrencyMutationInput
  >,
): UseMutationResult<
  ApiResult<EntityCurrencyModel>,
  ApiError,
  EntityCurrencyMutationInput
> {
  return useMutation<
    ApiResult<EntityCurrencyModel>,
    ApiError,
    EntityCurrencyMutationInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: 'entity-currencies',
      method: 'POST',
      classType: EntityCurrencyModel,
      body,
    });
  }, options);
}

export function useUpdateEntityCurrency(
  options?: UseMutationOptions<
    ApiResult<EntityCurrencyModel>,
    ApiError,
    EntityCurrencyUpdateInput
  >,
): UseMutationResult<
  ApiResult<EntityCurrencyModel>,
  ApiError,
  EntityCurrencyUpdateInput
> {
  return useMutation<
    ApiResult<EntityCurrencyModel>,
    ApiError,
    EntityCurrencyUpdateInput
  >(async function (body) {
    return await MutationFetchFunction({
      url: `entity-currencies/${body.entityId}`,
      method: 'PUT',
      classType: EntityCurrencyModel,
      body: body.body,
    });
  }, options);
}

export function useDeleteEntityCurrency(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    EntityCurrencyDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, EntityCurrencyDeleteInput> {
  return useMutation<MessageResult, ApiError, EntityCurrencyDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `entity-currencies/${body.entityId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
