import { BudgetingMutationInput } from 'api-hooks/budgeting';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import {
  convertDateEnd,
  convertDateStart,
  initialEndAt,
  initialStartAt,
} from 'common/utils/date';
import FormContent from 'components/common/form-content';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import BrandMultiAllSelect from 'modules/entity-settings/brand/components/multi-all-select-input';
import DivisionByEmployeeSelectInput from 'modules/master/division/components/select-input-by-employee';
import { EmployeeMultiSelectInput } from 'modules/master/employee/components/multi-select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { BudgetCreateFormType, BudgetingCreateFormProps } from './form-type';
import ListOfCOASelector from './list-of-coa-selector';

export default function CreateBudgetingForm(props: BudgetingCreateFormProps) {
  const { t } = useTranslation();

  const { setNavigations } = useNavigation();
  const { entity } = useEntity();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().required(),
      divisionId: Yup.string().required().nullable(),
      brandIds: Yup.array()
        .of(Yup.string().required().nullable())
        .required()
        .min(1),
      amount: Yup.number().required().nullable(),
      employeeToNotifyIds: Yup.array()
        .of(Yup.string().required())
        .nullable()
        .min(1)
        .required(),
      chartOfAccountIds: Yup.array()
        .of(
          Yup.object({
            value: Yup.string().required(),
            label: Yup.string().required(),
          }),
        )
        .nullable()
        .min(1)
        .required(),
      startFrom: Yup.date().required().nullable(),
      endAt: Yup.date().required().nullable(),
    }),
  );

  const initialValues: BudgetCreateFormType = {
    amount: '',
    brandIds: [],
    divisionId: '',
    endAt: initialEndAt(),
    startFrom: initialStartAt(),
    name: '',
    employeeToNotifyIds: [],
    chartOfAccountIds: [],
  };

  const methods = useForm<BudgetCreateFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: initialValues,
  });

  const onSubmit = React.useCallback(
    async (values: BudgetCreateFormType) => {
      try {
        const { amount, chartOfAccountIds, endAt, startFrom, ...rest } = values;
        const _input: BudgetingMutationInput = {
          ...rest,
          amount: Number(values.amount),
          chartOfAccountIds: values.chartOfAccountIds.map((item) => item.value),
          endAt: convertDateEnd(endAt),
          startFrom: convertDateStart(startFrom),
          // DEFAULT MIGHT CHANGE IN THE FUTURE
          notifyThresholdPercent: 80,
          entityId: entity?.id || '',
        };
        await props.onSubmit(_input, methods as any);
      } catch (e: any) {
        if (e.errors) {
          const temp = {};
          Object.keys(e.errors).forEach((item) => {
            const currErr = e.errors[item];
            if (item.includes('brandIds')) {
              temp[`${item}`] =
                currErr.length > 1
                  ? `${currErr?.[0]} ${t('error:and_extra_more_errors', {
                      extra: currErr.length - 1,
                    })}`
                  : currErr?.[0];
            } else {
              temp[`${item}`] = currErr;
            }
          });
          formSetErrors(temp, methods.setError);
        }
        e.message && notification.error({ message: e.message });
      }
    },
    [entity?.id, methods, props, t],
  );

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          title={t('common:create_extra', { extra: t('common:budgeting') })}
          noDelete
          noEdit
        />
        <div className={moduleStyles.fullContainer}>
          <div className={moduleStyles.halfContainer}>
            <Input
              name="name"
              type="text"
              label={t('common:name')}
              placeholder={t('common:enter_extra', {
                extra: t('common:name'),
              })}
              required
            />
          </div>

          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <DivisionByEmployeeSelectInput name="divisionId" />
            </div>
            <div className={moduleStyles.halfContainer}>
              <BrandMultiAllSelect
                label={t('common:brand')}
                placeholder={t('common:brand')}
                name="brandIds"
                entityId={entity?.id!}
                model="incomes"
                required
              />
            </div>
          </div>

          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="number"
                name="amount"
                label={t('common:amount')}
                placeholder={t('common:enter_extra', {
                  extra: t('common:amount'),
                })}
                required
                hideControls
                description={t('budgeting:in_extra', {
                  extra: entity?.currency,
                })}
                isMoneyFormat
              />
            </div>

            <div className={moduleStyles.halfContainer}>
              <EmployeeMultiSelectInput
                name="employeeToNotifyIds"
                label={t('budgeting:notify_to')}
                required
              />
            </div>
          </div>

          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                name="startFrom"
                type="date"
                placeholder={t('common:select_extra', {
                  extra: t('common:date'),
                })}
                label={t('budgeting:starts_from')}
                required
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                name="endAt"
                type="date"
                placeholder={t('common:select_extra', {
                  extra: t('common:date'),
                })}
                label={t('budgeting:end_at')}
                required
              />
            </div>
          </div>

          <ListOfCOASelector />
        </div>
      </FormContent>
    </Form>
  );
}
