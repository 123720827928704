import { CommonSimpleModel, getIndexInput } from 'api-hooks/common/model';
import { Expose, Type } from 'class-transformer';

// Model
export class AccountSimpleModel extends CommonSimpleModel {
  currency: string;
}

export class AccountAccessLiteModel extends CommonSimpleModel {
  email: string;
  entities: CommonSimpleModel[];
  accounts: AccountSimpleModel[];
}

export class AccountEntityModel extends AccountAccessLiteModel {
  @Expose({ name: 'is_all_account' })
  isAllAccount: boolean;
}

export class AccountAccessModel extends CommonSimpleModel {
  email: string;

  @Type(() => AccountEntityModel)
  entities: AccountEntityModel[];
}

// Input

export type getAccountAccessInput = {
  employeeId: string;
};

export type getAccountsAccessInput = getIndexInput;

export type AccountAccessMutationInput = {
  entities: {
    id: string;
    accounts: string[];
    isAllAccount: boolean;
  }[];
};

export type AccountAccessUpdateInput = {
  employeeId: string;
  body: AccountAccessMutationInput;
};
