import { StockAdjustmentTypeEnum } from 'api-hooks/stock-adjustment';
import { PlusIcon, TrashIcon } from 'common/assets';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import { ActionIcon, Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import { FormContext } from 'components/elements/form';
import StockSelectInput from 'modules/stock/stock/components/select-input';
import StockItemSelectInput from 'modules/stock/stock-items/components/select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React, { useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { StockAdjustmentFormType } from './form-type';

const typeSelectOptions = [
  {
    value: StockAdjustmentTypeEnum.in,
    label: StockAdjustmentTypeEnum.in.toUpperCase(),
  },
  {
    value: StockAdjustmentTypeEnum.out,
    label: StockAdjustmentTypeEnum.out.toUpperCase(),
  },
];

const DEFAULT_VALUE = {
  type: '',
  stockId: '',
  price: null,
  stockItemId: '',
  isAsset: false,
};

export default function StockAdjustmentFormItem() {
  const context = useContext(FormContext);
  const { t } = useTranslation();
  const { control, setValue, clearErrors } =
    useFormContext<StockAdjustmentFormType>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'stockAdjustmentItems',
    keyName: 'customId',
  });

  return (
    <div className={moduleStyles.fullContainer}>
      {fields.map((field, index) => (
        <div className={moduleStyles.itemContainer} key={field.customId}>
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="select"
                data={typeSelectOptions}
                name={`stockAdjustmentItems.${index}.type`}
                label={t('common:type')}
                placeholder={t('common:choose_extra', {
                  extra: t('common:type'),
                })}
                required
                onAfterChange={() =>
                  clearErrors(`stockAdjustmentItems.${index}`)
                }
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <StockSelectInput
                name={`stockAdjustmentItems.${index}.stockId`}
                onAfterChange={(val) => {
                  clearErrors(`stockAdjustmentItems.${index}`);
                  setValue(
                    `stockAdjustmentItems.${index}.isAsset`,
                    val?.isAsset,
                  );
                  !val &&
                    setValue(`stockAdjustmentItems.${index}.stockItemId`, '');
                }}
              />
            </div>
          </div>
          <HOCInput
            keys={[
              `stockAdjustmentItems.${index}.type`,
              `stockAdjustmentItems.${index}.isAsset`,
            ]}
          >
            {({
              [`stockAdjustmentItems.${index}.type`]: type,
              [`stockAdjustmentItems.${index}.isAsset`]: isAsset,
            }) => (
              <>
                {type === StockAdjustmentTypeEnum.in && (
                  <div className={moduleStyles.sectionContainer}>
                    {isAsset && (
                      <div className={moduleStyles.halfContainer}>
                        <Input
                          type="text"
                          name={`stockAdjustmentItems.${index}.merk`}
                          label={t('inventory:merk')}
                          placeholder={t('common:enter_extra', {
                            extra: t('inventory:merk'),
                          })}
                          required
                        />
                      </div>
                    )}
                    <div className={moduleStyles.halfContainer}>
                      <Input
                        type="number"
                        isMoneyFormat
                        name={`stockAdjustmentItems.${index}.price`}
                        hideControls
                        label={t('inventory:price')}
                        placeholder={t('common:enter_extra', {
                          extra: t('inventory:price'),
                        })}
                        required
                      />
                    </div>
                    {!isAsset && (
                      <div className={moduleStyles.halfContainer}>
                        <Input
                          type="number"
                          isMoneyFormat
                          hideControls
                          label={t('inventory:qty')}
                          placeholder={t('common:enter_extra', {
                            extra: t('inventory:qty'),
                          })}
                          name={`stockAdjustmentItems.${index}.qty`}
                          required
                        />
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </HOCInput>
          <HOCInput keys={[`stockAdjustmentItems.${index}.isAsset`]}>
            {({ [`stockAdjustmentItems.${index}.isAsset`]: isAsset }) =>
              isAsset ? (
                <HOCInput keys={[`stockAdjustmentItems.${index}.type`]}>
                  {({ [`stockAdjustmentItems.${index}.type`]: type }) =>
                    type === StockAdjustmentTypeEnum.in ? (
                      <div className={moduleStyles.sectionContainer}>
                        <div className={moduleStyles.halfContainer}>
                          <Input
                            type="text-area"
                            name={`stockAdjustmentItems.${index}.description`}
                            label={t('common:description')}
                            placeholder={t('common:enter_extra', {
                              extra: t('common:description'),
                            })}
                          />
                        </div>
                        <div className={moduleStyles.halfContainer}>
                          <Input
                            type="files"
                            label={t('inventory:picture')}
                            required
                            name={`stockAdjustmentItems.${index}.files`}
                            previewKey="url"
                            valueKey="file"
                          />
                        </div>
                      </div>
                    ) : (
                      <HOCInput
                        keys={[`stockAdjustmentItems.${index}.stockId`]}
                      >
                        {({
                          [`stockAdjustmentItems.${index}.stockId`]: stockId,
                        }) => (
                          <div className={moduleStyles.halfContainer}>
                            <StockItemSelectInput
                              stockId={stockId}
                              name={`stockAdjustmentItems.${index}.stockItemId`}
                              label={t('inventory:barcode')}
                              placeholder={t('common:choose_extra', {
                                extra: t('inventory:barcode'),
                              })}
                              dependStockId
                            />
                          </div>
                        )}
                      </HOCInput>
                    )
                  }
                </HOCInput>
              ) : (
                <HOCInput keys={[`stockAdjustmentItems.${index}.type`]}>
                  {({ [`stockAdjustmentItems.${index}.type`]: type }) => (
                    <>
                      {type === StockAdjustmentTypeEnum.out && (
                        <div className={moduleStyles.halfContainer}>
                          <Input
                            type="number"
                            isMoneyFormat
                            hideControls
                            label={t('inventory:qty')}
                            placeholder={t('common:enter_extra', {
                              extra: t('inventory:qty'),
                            })}
                            name={`stockAdjustmentItems.${index}.qty`}
                            required
                          />
                        </div>
                      )}
                    </>
                  )}
                </HOCInput>
              )
            }
          </HOCInput>
          {context.editable && (
            <ActionIcon
              children={(size) => <TrashIcon size={size} />}
              variant="transparent"
              error
              onClick={() => remove(index)}
            />
          )}
        </div>
      ))}

      {context.editable && (
        <Button
          onClick={() => append(DEFAULT_VALUE)}
          leftIcon={(size) => <PlusIcon size={size} />}
          variant="secondary"
        >
          {t('common:add_extra', { extra: t('inventory:item') })}
        </Button>
      )}

      <Separator gap={32} direction="vertical" />
    </div>
  );
}
