import {
  EntityCurrencyLiteModel,
  EntityCurrencyModel,
} from 'api-hooks/entity-currency/model';
import {
  getEntitiesCurrenciesKey,
  getEntitiesCurrencyKey,
  useGetEntityCurrencies,
  useGetEntityCurrency,
} from 'api-hooks/entity-currency/query';
import { QueryTransformer } from 'common/helpers/common';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import { useEntity } from 'hooks/use-entities';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

function transformer(
  item: EntityCurrencyLiteModel | EntityCurrencyModel,
): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  readOnly?: boolean;
  disabled?: boolean;
  onAfterChange?: (value?: any) => void;
  overrideOnChange?: (prevValue: any, value: any) => void;
  noMargin?: boolean;
}

const EntityCurrencySelectInput = (props: Props) => {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const {
    placeholder = `${t('common:choose_extra', {
      extra: t('common:entity_currency'),
    })}`,
    label = t('common:entity_currency'),
  } = props;

  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetEntityCurrencies,
    getListsKey: React.useCallback(
      (page, q) =>
        getEntitiesCurrenciesKey({
          params: {
            q,
            page,
            filter: {
              entity_id: entity?.id!,
            },
          },
        }),
      [entity?.id],
    ),
    useDetailLazyQueryHook: useGetEntityCurrency,
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          filter: {
            entity_id: entity?.id!,
          },
        },
      }),
      [entity?.id],
    ),
    renderCreate: false,
    getDetailKey: React.useCallback(
      (value) =>
        getEntitiesCurrencyKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    listTransformer(result) {
      return result.data.map((v) => transformer(v));
    },
    detailTransformer(result) {
      const newResult = QueryTransformer(result, EntityCurrencyModel);
      props.onAfterChange?.(newResult.data);
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      label={label}
      required
      name={props.name}
      placeholder={placeholder}
      onAfterChange={props.onAfterChange}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
};

export default EntityCurrencySelectInput;
