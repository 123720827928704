import {
  ApprovalMasterModel,
  ApprovalMasterTypeEnum,
  DetailInput,
} from 'api-hooks/approval-master';
import { NavigationProps } from 'containers/navigation';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export enum AmountTypeEnum {
  less_than = 'less_than',
  more_than = 'more_than',
  between = 'between',
}

export type DivisionType = {
  divisionId: string;
  details: DetailInput[];
  carbonCopies: string[];
  brands: string[];
};

export type FinanceType = {
  paymentEmployeeId: string;
  lowerBound: number | null;
  upperBound: number | null;
  details: DetailInput[];
  carbonCopies: string[];
  amountType: AmountTypeEnum;
};

export type ApprovalMasterFormType = {
  type: string;
  chiefEmployeeId?: string;
  divisions?: DivisionType[];
  finances?: FinanceType[];
};

export interface ApprovalMasterFormProps extends NavigationProps {
  approvalMaster?: ApprovalMasterModel;
  renderError?: React.ReactNode;
  onSubmit: (
    input: any,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}

export const divisionsSchema = () =>
  Yup.array()
    .of(
      Yup.object()
        .shape({
          divisionId: Yup.string().nullable().required(),
          details: Yup.array().of(
            Yup.object().shape({
              employeeId: Yup.string().nullable().required(),
              order: Yup.number().required(),
            }),
          ),
          carbonCopies: Yup.array(
            Yup.string().required().nullable(),
          ).nullable(),
        })
        .required(),
    )
    .min(1)
    .required();

export const financesSchema = () =>
  Yup.array()
    .of(
      Yup.object()
        .shape({
          paymentEmployeeId: Yup.string().nullable().required(),
          amountType: Yup.string().required().nullable(),
          lowerBound: Yup.number().when('amountType', {
            is: (type) => type !== AmountTypeEnum.less_than,
            then: Yup.number().required().nullable(),
            otherwise: Yup.number().nullable().strip(),
          }),
          upperBound: Yup.number().when('amountType', {
            is: (type) => type !== AmountTypeEnum.more_than,
            then: Yup.number().required().nullable(),
            otherwise: Yup.number().nullable().strip(),
          }),
          details: Yup.array().of(
            Yup.object().shape({
              employeeId: Yup.string().nullable().required(),
              order: Yup.number().required(),
            }),
          ),
          carbonCopies: Yup.array(
            Yup.string().required().nullable(),
          ).nullable(),
        })
        .required(),
    )
    .min(1)
    .required();

export const formSchema = () =>
  Yup.object({
    type: Yup.string().nullable().required(),
    chiefEmployeeId: Yup.string().when('type', {
      is: ApprovalMasterTypeEnum.head_division,
      then: Yup.string().nullable().strip(),
      otherwise: Yup.string().required().nullable(),
    }),
    divisions: Yup.array().when('type', {
      is: ApprovalMasterTypeEnum.head_division,
      then: divisionsSchema(),
      otherwise: Yup.array().strip().nullable(),
    }),
    finances: Yup.array().when('type', {
      is: ApprovalMasterTypeEnum.finance,
      then: financesSchema(),
      otherwise: Yup.array().strip().nullable(),
    }),
  });
