import { PivotModel } from 'api-hooks/common';
import { Filter, PaginationMeta } from 'api-hooks/common/model';
import { Expose, Type } from 'class-transformer';
// Enum
export enum TransactionReportViewModeEnum {
  split_transaction = 'split_transaction',
  merge_transaction = 'merge_transaction',
}

// Model
export class getReportsInput {
  params?: any;
}
export class getReportsDetailInput extends getReportsInput {
  id: string;
}
export class ExportExcelReportInput {
  params: {
    filter?: any;
  };
}

export class ReportingPivotResultModel {
  @Type(() => Filter)
  filters: Filter;

  @Type(() => PivotModel)
  pivot: PivotModel;

  @Type(() => PaginationMeta)
  meta: PaginationMeta;
}

export class ReportingResultModel {
  data: any[];

  @Type(() => Filter)
  filters: Filter;

  @Type(() => PivotModel)
  pivot: PivotModel;
}

export class TransactionReportTotalModel {
  name: string;

  @Type(() => Number)
  amount: number;

  @Type(() => Number)
  transaction: number;
}
export class TransactionReportData {
  @Type(() => TransactionReportTotalModel)
  total: TransactionReportTotalModel[];

  list: any[];
}

export class TransactionReportResultModel extends ReportingPivotResultModel {
  @Type(() => TransactionReportData)
  data: TransactionReportData;
}

export class TransactionSummaryDataModel {
  name: string;

  @Type(() => Number)
  amount: number;

  @Type(() => Number)
  income: number;

  @Type(() => Number)
  outcome: number;

  @Type(() => Number)
  total: number;

  @Expose({ name: 'total_income' })
  @Type(() => Number)
  totalIncome: number;

  @Expose({ name: 'total_outcome' })
  @Type(() => Number)
  totalOutcome: number;
}
export class TransactionReportSummaryModel {
  @Type(() => TransactionSummaryDataModel)
  brands: TransactionSummaryDataModel[];

  @Type(() => TransactionSummaryDataModel)
  divisions: TransactionSummaryDataModel[];

  @Type(() => TransactionSummaryDataModel)
  suppliers: TransactionSummaryDataModel[];

  @Type(() => TransactionSummaryDataModel)
  accounts: TransactionSummaryDataModel[];

  @Expose({ name: 'chart_of_accounts' })
  @Type(() => TransactionSummaryDataModel)
  chartOfAccounts: TransactionSummaryDataModel[];
}

export class TransactionReportSummaryResultModel {
  data: TransactionReportSummaryModel;
  filters: Filter;
}

export class StockSimpleListModel {
  code: string;
  stock: string;
  unit: string;

  @Expose({ name: 'code_key' })
  codeKey: string;

  @Expose({ name: 'stock_key' })
  stockKey: string;

  @Expose({ name: 'unit_key' })
  unitKey: string;

  @Expose({ name: 'used_qty' })
  usedQty: number;

  @Expose({ name: 'available_qty' })
  availableQty: number;

  @Expose({ name: 'in_unit' })
  inUnit: number;

  @Expose({ name: 'out_unit' })
  outUnit: number;

  @Expose({ name: 'opening_qty' })
  @Type(() => Number)
  openingQty: number;

  @Expose({ name: 'closing_qty' })
  @Type(() => Number)
  closingQty: number;
}
export class StockSimpleDataModel {
  total: null;

  @Type(() => StockSimpleListModel)
  list: StockSimpleListModel[];
}

export class StockDetailDataModel {
  total: null;

  @Type(() => StockSimpleListModel)
  list: StockSimpleListModel[];
}

export class StockSimpleReportResultModel extends ReportingPivotResultModel {
  @Type(() => StockSimpleDataModel)
  data: StockSimpleDataModel;
}

export class StockDetailReportResultModel extends ReportingPivotResultModel {
  @Type(() => StockDetailDataModel)
  data: StockDetailDataModel;
}
