import {
  AccountTypeEnum,
  getAccountsKey,
  useDeleteAcount,
} from 'api-hooks/account';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import { string2moneyDefault } from 'common/utils/string';
import FormContent from 'components/common/form-content';
import HOCInput from 'components/common/hoc-input';
import Separator from 'components/common/separator';
import Alert from 'components/elements/alert';
import Form, { FormState } from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import AccountInformationForm from './account-information-form';
import BankMutation from './bank-mutation';
import ConnectBankForm from './connect-bank-form';
import { AccountFormProps, AccountFormType } from './form-type';

const AccountForm = (props: AccountFormProps) => {
  const { t } = useTranslation();
  const { account } = props;
  const dialog = useDialog();
  const { close, setNavigations } = useNavigation();
  const { entity } = useEntity();
  const { mutateAsync: deleteMutate } = useDeleteAcount();
  const { can } = useAuthorization();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().nullable().required(),
      entityCurrencyId: Yup.string().nullable().required(),
      description: Yup.string(),
      isChangeCredential: Yup.bool(),
      isBankConnected: Yup.bool(),
      bankUsername: Yup.string()
        .nullable()
        .when(['isChangeCredential', 'isBankConnected'], {
          is: (changeCred, bankConnect) =>
            (account && (changeCred || bankConnect)) ||
            (!account && bankConnect),
          then: Yup.string().nullable().required(),
        }),
      balance: account
        ? Yup.number().nullable()
        : Yup.number()
            .nullable()
            .when('isBankConnected', {
              is: (val) => val,
              then: Yup.number()
                .transform((value) =>
                  isNaN(value) || value === null || value === undefined
                    ? 0
                    : value,
                )
                .notRequired()
                .nullable(),
              otherwise: Yup.number().required(),
            }),
      interval: Yup.number()
        .nullable()
        .when('isBankConnected', {
          is: (val) => val,
          then: Yup.number().required().min(15),
        }),
    }),
  );

  const intialValues = React.useMemo<AccountFormType>(() => {
    return {
      name: account?.name || '',
      entityCurrencyId: account?.entityCurrencyId || '',
      description: account?.description || '',
      isBankConnected: account?.isBankConnected || false,
      bankPassword: '',
      bankUsername: '',
      bankType: account?.bankType || '',
      interval: account?.interval || 15,
      isChangeCredential: false,
      balance: account?.balance || null,
      type: account?.type || '',
    };
  }, [account]);

  const _balance = account?.isBaseCurrency
    ? `${account?.currency} ${string2moneyDefault(account?.balance)}`
    : `${account?.currency} ${string2moneyDefault(account?.balance!)} ~ ${
        entity?.currency
      } ${string2moneyDefault(account?.balance! * account?.rate!)}`;

  const methods = useForm<AccountFormType>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        await props.onSubmit(values, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          await deleteMutate({
            accountId: account!.id,
          });
          queryClient.refetchQueries([getAccountsKey()[0]]);
          close();
          notification.success({ message: t('common:successfully_deleted') });
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, account, t]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!account}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={account}
          title={
            account
              ? `${t('common:account')} - ${account?.name}`
              : t('common:create_extra', { extra: t('common:account') })
          }
          noDelete={!can(AuthorizationRules.AccountsDelete)}
          noEdit={!can(AuthorizationRules.AccountsUpdate)}
        />
        <AccountInformationForm
          hasData={!!account}
          balance={_balance}
          id={account?.id}
        />
        {!account && (
          <div className={moduleStyles.halfContainer}>
            <Alert
              variant="warning"
              title={t('account:alert_title')}
              description={t('account:alert_description')}
            />
            <Separator gap={16} direction="vertical" />
          </div>
        )}

        <FormState>
          {({ editable }) =>
            editable ? (
              <HOCInput keys={['type']}>
                {({ type }) => (
                  <>
                    {type === AccountTypeEnum.bank && (
                      <ConnectBankForm hasData={!!account} />
                    )}
                  </>
                )}
              </HOCInput>
            ) : (
              <BankMutation />
            )
          }
        </FormState>
      </FormContent>
    </Form>
  );
};

export default AccountForm;
