import {
  BudgetingMutationItemBodyInput,
  getBudgetingKey,
  getBudgetingsKey,
  useCreateBudgetingItem,
} from 'api-hooks/budgeting';
import { CancelIcon } from 'common/assets';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import {
  convertDateEnd,
  convertDateStart,
  initialEndAt,
  initialStartAt,
} from 'common/utils/date';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import { Input } from 'components/elements/field';
import Form from 'components/elements/form';
import { useEntity } from 'hooks/use-entities';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import { EmployeeMultiSelectInput } from 'modules/master/employee/components/multi-select-input';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { BudgetSetBudgetFormType } from '../form-type';
import ListOfCOASelector from '../list-of-coa-selector';

interface Props {
  onClose: () => void;
  id: string;
}

export default function SetBudgetModal(props: Props) {
  const { onClose, id } = props;
  const { t } = useTranslation();
  const { entity } = useEntity();
  const { mutateAsync, isLoading } = useCreateBudgetingItem();

  const initialValues = React.useMemo(
    () => ({
      endAt: initialEndAt(),
      startFrom: initialStartAt(),
    }),
    [],
  );

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      amount: Yup.number().required().nullable(),
      employeeToNotifyIds: Yup.array()
        .of(Yup.string().required())
        .min(1)
        .required(),
      chartOfAccountIds: Yup.array()
        .of(
          Yup.object({
            value: Yup.string().required(),
            label: Yup.string().required(),
          }),
        )
        .nullable()
        .min(1)
        .required(),
      startFrom: Yup.date().required().nullable(),
      endAt: Yup.date().required().nullable(),
    }),
  );
  const methods = useForm<any>({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver,
  });

  const onSubmit = React.useCallback(
    async (values: BudgetSetBudgetFormType) => {
      try {
        const { chartOfAccountIds, amount, endAt, startFrom, ...rest } = values;
        const _input: BudgetingMutationItemBodyInput = {
          ...rest,
          amount: Number(amount),
          endAt: convertDateEnd(endAt),
          startFrom: convertDateStart(startFrom),
          chartOfAccountIds: values.chartOfAccountIds.map((item) => item.value),
          // DEFAULT MIGHT CHANGE IN THE FUTURE
          notifyThresholdPercent: 80,
        };
        const res = await mutateAsync({
          body: _input,
          budgetingId: id,
        });
        onClose();
        queryClient.refetchQueries([getBudgetingsKey()[0]]);
        queryClient.refetchQueries(getBudgetingKey({ id }));
        notification.success({ message: res.message });
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
        e.message && notification.error({ message: e.message });
      }
    },
    [id, methods.setError, mutateAsync, onClose],
  );

  return (
    <div className={moduleStyles.panelContainer}>
      <div className={moduleStyles.fullContainer}>
        <Form methods={methods} onSubmit={onSubmit}>
          <Input
            type="number"
            name="amount"
            label={t('common:amount')}
            placeholder={t('common:enter_extra', {
              extra: t('common:amount'),
            })}
            required
            hideControls
            description={t('budgeting:in_extra', {
              extra: entity?.currency,
            })}
            isMoneyFormat
          />
          <div className={moduleStyles.sectionContainer}>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="date"
                name="startFrom"
                label={t('budgeting:starts_from')}
                placeholder={t('common:select_extra', {
                  extra: t('common:date'),
                })}
                required
              />
            </div>
            <div className={moduleStyles.halfContainer}>
              <Input
                type="date"
                name="endAt"
                required
                label={t('budgeting:end_at')}
                placeholder={t('common:select_extra', {
                  extra: t('common:date'),
                })}
              />
            </div>
          </div>
          <EmployeeMultiSelectInput
            name="employeeToNotifyIds"
            label={t('budgeting:notify_to')}
            required
          />

          <ListOfCOASelector variant="modal" />
          <Separator gap={32} direction="vertical" />

          <div className={moduleStyles.row()}>
            <Input type="submit" loading={isLoading} />
            <Separator gap={16} direction="horizontal" />
            <Button
              type="button"
              variant="secondary"
              onClick={onClose}
              error
              disabled={isLoading}
              leftIcon={(size) => <CancelIcon size={size} />}
            >
              {t('common:cancel')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
