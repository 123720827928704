import {
  ApprovalLiteModel,
  ApprovalStatusQueryEnum,
  getApprovalsKey,
  useMarkApproval,
} from 'api-hooks/approval';
import { BrandModel } from 'api-hooks/brand';
import { DivisionModel } from 'api-hooks/division';
import { AuthorizationRules } from 'common/constants';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import colors from 'common/styles/colors';
import { initialDate } from 'common/utils/date';
import { extractNumber } from 'common/utils/string';
import Badge from 'components/elements/badge';
import Checkbox from 'components/elements/checkbox';
import { IColumn } from 'components/elements/table';
import Text from 'components/elements/text';
import { format, formatDistanceStrict } from 'date-fns';
import { enUS, id } from 'date-fns/locale';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useTableViewDetailColumn } from 'modules/common/table';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

export default function useApprovalsColumns(status: string) {
  const { can } = useAuthorization();
  const { t, lang } = useTranslation();

  const dialog = useDialog();
  const isStatusComplete = status === ApprovalStatusQueryEnum.COMPLETED;

  const { mutateAsync, isLoading } = useMarkApproval();

  const onHandleMarkChange = React.useCallback(
    async (id: string, curr: boolean, dismiss: () => void) => {
      try {
        const res = await mutateAsync({
          approvalId: id,
          body: {
            isComplete: curr,
          },
        });
        notification.success({
          message: res.message,
        });
        queryClient.refetchQueries([getApprovalsKey()[0]]);
      } catch (e) {
        notification.error({
          message: e?.message,
        });
      }
      dismiss();
    },
    [mutateAsync],
  );

  const onMarkClick = React.useCallback(
    async (original: ApprovalLiteModel) =>
      dialog.showConfirmation({
        title: t(`modal:${!original.isComplete ? 'mark_as_done' : 'unmark'}`),
        loading: isLoading,
        fullFooter: true,
        message: (
          <Text align="center">
            {t(
              `modal:${!original.isComplete ? 'mark_as_done' : 'unmark'}_desc`,
            )}
          </Text>
        ),
        negativeLabel: t('common:cancel'),
        positiveLabel: t('common:confirm'),
        onPositiveAction: (dismiss) =>
          onHandleMarkChange(original.id, !original.isComplete, dismiss),
        onNegativeAction: (dismiss) => dismiss(),
      }),
    [dialog, isLoading, onHandleMarkChange, t],
  );

  const columns = React.useMemo<IColumn<ApprovalLiteModel>[]>(
    () => [
      ...(isStatusComplete && can(AuthorizationRules.ApprovalsMarkDone)
        ? [
            {
              header: '',
              accessorKey: 'isComplete',
              size: 120,
              stickyLeft: true,
              cell: ({ row }) => {
                const { original } = row;
                return (
                  <Checkbox
                    checked={original.isComplete}
                    {...(original.isComplete && {
                      label: original.completedBy?.name,
                    })}
                    disabled={!original.markDonePermission}
                    onClick={() => onMarkClick(original)}
                  />
                );
              },
            },
          ]
        : []),
      {
        header: t('common:requested_at'),
        accessorKey: 'requestedAt',
        minSize: 200,
        sortName: 'requested_at',
        cell: ({ getValue }) =>
          format(getValue() as Date, 'dd MMM yyyy, HH:mm'),
      },
      ...(status === ApprovalStatusQueryEnum.COMPLETED
        ? [
            {
              header: t('common:approved_at'),
              accessorKey: 'approvedAt',
              minSize: 200,
              sortName: 'approved_at',
              cell: ({ getValue }) =>
                getValue()
                  ? format(getValue() as Date, 'dd MMM yyyy, HH:mm')
                  : '-',
            },
          ]
        : []),
      {
        header: t('common:requested_by'),
        accessorKey: 'employee.name',
        minSize: 200,
        sortName: 'employee_id',
      },
      {
        header: t('common:deadline_at'),
        accessorKey: 'deadlineAt',
        minSize: 200,
        sortName: 'deadline_at',
        cell: ({ getValue }) => {
          if (!getValue()) {
            return '-';
          }
          const distance = formatDistanceStrict(
            getValue() as Date,
            initialDate(),
            {
              unit: 'day',
              locale: lang === 'en' ? enUS : id,
            },
          );
          return (
            <Text
              textVariant="BodyDefault"
              color={
                extractNumber(distance) < 3
                  ? colors.textError
                  : colors.textNormal
              }
            >
              {distance}
            </Text>
          );
        },
      },
      {
        header: t('common:model'),
        accessorKey: 'model',
        minSize: 200,
      },
      {
        header: t('common:code'),
        accessorKey: 'code',
        minSize: 200,
        sortName: 'code',
      },
      {
        header: t('common:title_title'),
        accessorKey: 'title',
        minSize: 200,
        sortName: 'title',
      },
      {
        header: t('common:brand'),
        accessorKey: 'brand',
        minSize: 150,
        cell: ({ getValue }) => {
          const val = getValue() as BrandModel;
          return (
            !!val && (
              <Badge color={val?.color} key={val?.id}>
                {val?.name}
              </Badge>
            )
          );
        },
      },
      {
        header: t('common:division'),
        accessorKey: 'division.name',
        minSize: 200,
        sortName: 'division',
      },
      ...(isStatusComplete
        ? [
            {
              header: t('common:forwarded_divisions'),
              accessorKey: 'forwardedDivisions',
              minSize: 200,
              sortName: 'forwarded_division',
              cell: ({ getValue }) => {
                const val = getValue() as DivisionModel[];
                return val.length
                  ? val?.map((item) => item.name).join(',')
                  : '-';
              },
            },
          ]
        : []),
      {
        header: t('common:description'),
        accessorKey: 'description',
        minSize: 250,
        sortName: 'description',
      },
    ],
    [isStatusComplete, can, t, status, onMarkClick, lang],
  );

  return {
    columns: useTableViewDetailColumn(columns, 'ApprovalView', 'ApprovalsView'),
  };
}
