import { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { getIndexInput } from 'api-hooks/common';
import {
  ApiError,
  ExtendedApiResult,
  Filter,
  PaginationMeta,
} from 'api-hooks/common/model';
import classNames from 'classnames';
import { AuthorizationRules, NavigationRoutes } from 'common/constants';
import notification from 'common/helpers/notification';
import QueryFilter, {
  useApplyQueryFilter,
} from 'components/common/query-filter/query-filter';
import Separator from 'components/common/separator';
import TableDetailButton from 'components/common/table-detail-button';
import Pagination from 'components/elements/pagination';
import TableComponent, { IColumn } from 'components/elements/table';
import Tabs from 'components/elements/tabs';
import useApplyQuerySort from 'hooks/use-apply-query-sort';
import { useAuthorization } from 'hooks/use-authorization';
import useComposedQuery from 'hooks/use-composed-query';
import useNavigation from 'hooks/use-navigation';
import { moduleStyles } from 'modules/styles.css';
import React from 'react';

interface TabsOptions<TabTypes extends string = string> {
  tab: TabTypes;
  onChangeTab: (tab: TabTypes) => void;
  options: {
    value: TabTypes;
    label: string;
    icon: (size: number, color: string) => React.ReactNode;
  }[];
}

// Jika ada komponen QueryFilter custom, tidak usah kasih filters dan setFilters. Pemanggil yang bertanggungjawab mengelola filters dan setFilters.
type GenericQueryTableFilterType =
  | {
      queryFilter: React.ReactElement;
      filters?: never;
      setFilters?: never;
    }
  | {
      queryFilter?: never;
      filters: Filter[];
      setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
    };

// Ini yang dikembalikan oleh useGenericTableProps. Ia mengandung DATA yang diperlukan untuk GenericQueryTable
type GenericQueryTableProps<T> = {
  columns: IColumn<T>[];
  isLoading?: boolean;
  data?: T[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  filters: Filter[];
  search?: Filter[];
  setFilters: React.Dispatch<React.SetStateAction<Filter[]>>;
  meta?: PaginationMeta;
  onRenderBesideQueryFilter?: React.ReactNode;
};

// Ini props tambahan untuk kustomisasi tabel, seperti tabs dan queryFilter
type GenericQueryTableComponentProps<
  T,
  TabTypes extends string = string,
> = Omit<GenericQueryTableProps<T>, 'filters' | 'setFilters'> &
  GenericQueryTableFilterType & {
    tabs?: TabsOptions<TabTypes>;
    aboveTable?: React.ReactNode;
  };

export function useTableActionColumn<T>(
  columns: IColumn<T>[],
  action: (original: T) => void,
  actionLabel?: string,
): IColumn<T>[] {
  const _columns = React.useMemo<IColumn<T>[]>(
    () => [
      {
        header: '',
        accessorKey: 'action',
        stickyLeft: true,
        minSize: 80,
        maxSize: 80,
        cell: ({ row }) => {
          return (
            <TableDetailButton onClick={() => action(row.original)}>
              {actionLabel}
            </TableDetailButton>
          );
        },
      },
      ...columns,
    ],
    [action, actionLabel, columns],
  );
  return _columns;
}

/** Agar lebih mudah menambah kolom Detail tanpa harus copy-paste terus */
export function useTableViewDetailColumn<T extends { id: string }>(
  columns: IColumn<T>[],
  destination: keyof typeof NavigationRoutes,
  auth?: keyof typeof AuthorizationRules,
) {
  const { can } = useAuthorization();
  const { navigate } = useNavigation();
  const onItemInvoked = React.useCallback(
    (original: T) => {
      if (can(AuthorizationRules[auth ?? 'NoAuthorization'])) {
        navigate(NavigationRoutes[destination], {
          params: { id: original.id },
        });
      }
    },
    [auth, can, destination, navigate],
  );
  return useTableActionColumn(columns, onItemInvoked);
}

/**
Untuk mengurangi kebutuhan harus terus menulis tampilan tabel di table.tsx kalau tabel tidak ada logika kompleks atau tampilan yang lebih spesifik, dan agar tabel lebih selaras desainnya.
*/
export function GenericQueryTable<T, TabsOptions extends string = string>(
  props: GenericQueryTableComponentProps<T, TabsOptions>,
) {
  return (
    <>
      <Separator gap={8} />
      {props.tabs && (
        <>
          <Tabs
            value={props.tabs.tab}
            items={props.tabs.options.map((tab) => ({
              value: tab.value,
              header: {
                label: tab.label,
                icon: tab.icon,
                onClick: () => {
                  props.tabs?.onChangeTab(tab.value);
                  // Clear filters on tab change
                  props.setFilters?.([]);
                },
              },
            }))}
          />
          <Separator gap={16} />
        </>
      )}
      <div className={moduleStyles.topContainer}>
        {props.queryFilter ? (
          props.queryFilter
        ) : (
          <div
            className={classNames(
              moduleStyles.row(),
              moduleStyles.fullContainer,
            )}
          >
            <QueryFilter
              setPage={props.setPage}
              filters={props.filters}
              setFilters={props.setFilters}
            />
            {!!props.onRenderBesideQueryFilter && (
              <>
                <Separator gap={16} direction="horizontal" />
                {props.onRenderBesideQueryFilter}
              </>
            )}
          </div>
        )}
      </div>

      {props.aboveTable && (
        <>
          {props.aboveTable}
          <Separator gap={24} direction="vertical" />
        </>
      )}
      <div className={moduleStyles.tableContainer}>
        <TableComponent
          columns={props.columns}
          isLoading={props.isLoading}
          data={props.data || []}
        />
      </div>
      <Pagination
        page={props.page}
        onPageChange={props.setPage}
        limit={props.limit}
        onLimitChange={props.setLimit}
        meta={props.meta}
      />
    </>
  );
}

export function useGenericTableProps<
  T,
  QueryInput extends getIndexInput<{ [key: string]: any }>,
>(
  _columns: IColumn<T>[],
  useQuery: (
    input?: QueryInput,
    baseOptions?: UseQueryOptions<ExtendedApiResult<T[]>, ApiError>,
  ) => UseQueryResult<ExtendedApiResult<T[]>, ApiError>,
  useQueryInput: QueryInput,
  onSuccess?: (data: any) => void,
): GenericQueryTableProps<T> & {
  refetch: UseQueryResult<ExtendedApiResult<T[]>, ApiError>['refetch'];
} {
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(15);
  useQueryInput.params.page = page;
  useQueryInput.params.limit = limit;
  const {
    data,
    isLoading,
    isFetching,
    error,
    refetch,
    extras: [{ filters, setFilters }, { columns }],
  } = useComposedQuery(
    useQuery,
    useQueryInput,
    useApplyQueryFilter((data: any) => {
      return data.filters;
    }),
    useApplyQuerySort((data: any) => {
      return data.sorts;
    }, _columns),
    {
      onSuccess,
    } as any,
  );

  React.useEffect(() => {
    if (error?.message) {
      notification.error({ message: error.message });
    }
  }, [error]);

  return {
    columns: columns || [],
    meta: data?.meta,
    data: data?.data,
    isLoading: isLoading || isFetching,
    search: (data as any)?.search,
    page,
    setPage,
    limit,
    setLimit,
    filters: filters || [],
    setFilters,
    refetch,
  };
}
