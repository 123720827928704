import { PurchaseRequisitionsTypeEnum } from 'api-hooks/purchase-requisitions';
import useTranslation from 'next-translate/useTranslation';

export default function useRequestTypeOption() {
  const { t } = useTranslation();

  const requestTypeRadioOption = [
    {
      label: t('pr:top_up'),
      value: PurchaseRequisitionsTypeEnum.top_up,
    },
    {
      label: t('pr:others'),
      value: PurchaseRequisitionsTypeEnum.others,
    },
  ];

  return { requestTypeRadioOption };
}
