import {
  PurchaseRequisitionsTypeEnum,
  PurchaseRequisitionsModel,
} from 'api-hooks/purchase-requisitions';
import { NavigationProps } from 'containers/navigation';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

export type PurchaseRequisitionsFormType = {
  title: string;
  itemType: string;
  description: string;
  brandId: string;
  files?: { id?: string; file: string; url?: string }[];
  requestType: string;
  deadlineAt: Date | null;

  // top up only
  divisionId?: string;
  accountId?: string;
  amount?: number | null;
};

export interface PurchaseRequisitionsFormProps extends NavigationProps {
  purchaseRequisitions?: PurchaseRequisitionsModel;
  renderError?: React.ReactNode;
  onSubmit: (
    input: any,
    form: ReturnType<typeof useForm>,
  ) => Promise<any | undefined>;
}

export const formSchema = () =>
  Yup.object({
    title: Yup.string().required().nullable(),
    brandId: Yup.string().required().nullable(),
    itemType: Yup.string().when('requestType', (val) =>
      val === PurchaseRequisitionsTypeEnum.others
        ? Yup.string().nullable().strip()
        : Yup.string().required().nullable(),
    ),
    description: Yup.string().required().nullable(),
    requestType: Yup.string().required().nullable(),
    deadlineAt: Yup.date().nullable().required(),
    divisionId: Yup.string().when('requestType', (val) =>
      val === PurchaseRequisitionsTypeEnum.others
        ? Yup.string().nullable().strip()
        : Yup.string().required().nullable(),
    ),
    accountId: Yup.string().when('requestType', (val) =>
      val === PurchaseRequisitionsTypeEnum.others
        ? Yup.string().nullable().strip()
        : Yup.string().required().nullable(),
    ),
    amount: Yup.number().when('requestType', (val) =>
      val === PurchaseRequisitionsTypeEnum.others
        ? Yup.number().nullable().strip()
        : Yup.number().required().nullable(),
    ),
    files: Yup.array(
      Yup.object({
        url: Yup.string().required(),
        file: Yup.string().required(),
      }),
    ),
  });
