import { StockTransferItemModelInput } from 'api-hooks/stock-transfer';
import * as Yup from 'yup';

export interface StockTransferItemType
  extends Omit<StockTransferItemModelInput, 'qty'> {
  qty: number | null;
  isAsset?: boolean;
}

export type StockTransferFormType = {
  transactionAt: Date;
  description: string;
  stockTransferItems: StockTransferItemType[];
  code: string;
};

export const formSchema = ({ t }) =>
  Yup.object().shape({
    transactionAt: Yup.date().required().nullable(),
    description: Yup.string().nullable(),
    stockTransferItems: Yup.array().of(
      Yup.object({
        stockId: Yup.string().required().nullable(),
        fromWarehouseId: Yup.string().test(
          'duplicate',
          t('error:should_not_be_equal_with_extra', {
            extra: t('inventory:to_warehouse'),
          }),
          (value, ctx) =>
            (ctx.parent as StockTransferItemType).toWarehouseId !== value,
        ),
        toWarehouseId: Yup.string().test(
          'duplicate',
          t('error:should_not_be_equal_with_extra', {
            extra: t('inventory:from_warehouse'),
          }),
          (value, ctx) =>
            (ctx.parent as StockTransferItemType).fromWarehouseId !== value,
        ),
        stockItemId: Yup.string().when('isAsset', {
          is: (val) => val,
          then: Yup.string().nullable().required(),
          otherwise: Yup.string().nullable().strip(),
        }),

        qty: Yup.number().when('isAsset', {
          is: (val) => !val,
          then: Yup.number().nullable().required(),
          otherwise: Yup.number().strip(),
        }),
      }),
    ),
  });
