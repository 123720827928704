import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';
import { client } from 'hooks/use-ky';

import {
  TransferDeleteInput,
  TransferModel,
  TransferMutationInput,
  TransferUpdateInput,
} from './model';

export function useCreateTransfer(
  options?: UseMutationOptions<
    ApiResult<TransferModel>,
    ApiError,
    TransferMutationInput
  >,
): UseMutationResult<
  ApiResult<TransferModel>,
  ApiError,
  TransferMutationInput
> {
  return useMutation<ApiResult<TransferModel>, ApiError, TransferMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'transfers',
        method: 'POST',
        classType: TransferModel,
        body,
      });
    },
    options,
  );
}
export function useUpdateTransfer(
  options?: UseMutationOptions<
    ApiResult<TransferModel>,
    ApiError,
    TransferUpdateInput
  >,
): UseMutationResult<ApiResult<TransferModel>, ApiError, TransferUpdateInput> {
  return useMutation<ApiResult<TransferModel>, ApiError, TransferUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `transfers/${body.transferId}`,
        method: 'PUT',
        classType: TransferModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteTransfer(
  options?: UseMutationOptions<MessageResult, ApiError, TransferDeleteInput>,
): UseMutationResult<MessageResult, ApiError, TransferDeleteInput> {
  return useMutation<MessageResult, ApiError, TransferDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `transfers/${body.transferId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}

export function useImportTransfer(
  options?: UseMutationOptions<any>,
): UseMutationResult<any> {
  return useMutation<ApiResult<MessageResult>>(async function (body) {
    return await client
      .post('transfers/import', {
        body: body as any,
      })
      .json<ApiResult<MessageResult>>();
  }, options);
}
