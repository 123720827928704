import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { AccountMutationDeleteInput } from 'api-hooks/account-mutation';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import { ReconciliationMutationInput } from './model';

export function useCreateReconciliation(
  options?: UseMutationOptions<
    ApiResult<any>,
    ApiError,
    ReconciliationMutationInput
  >,
): UseMutationResult<ApiResult<any>, ApiError, ReconciliationMutationInput> {
  return useMutation<ApiResult<any>, ApiError, ReconciliationMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'reconciliations',
        method: 'POST',
        body,
      });
    },
    options,
  );
}

export function useDeleteReconciliation(
  options?: UseMutationOptions<
    MessageResult,
    ApiError,
    AccountMutationDeleteInput
  >,
): UseMutationResult<MessageResult, ApiError, AccountMutationDeleteInput> {
  return useMutation<MessageResult, ApiError, AccountMutationDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `reconciliations/${body.accountMutationId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
