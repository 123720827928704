import { UseMutateAsyncFunction } from '@tanstack/react-query';
import {
  ApprovalModel,
  ApprovalUpdateInput,
  getApprovalKey,
  getApprovalsKey,
} from 'api-hooks/approval';
import { ApiError, ApiResult } from 'api-hooks/common/model';
import { getNotificationsKey } from 'api-hooks/notification';
import { queryClient } from 'common/repositories/query-client';
import * as Yup from 'yup';

export const defaultValues = { note: '' };
export const formSchema = Yup.object({
  note: Yup.string().required().nullable(),
});

export const onAfterAction = (id: string) => {
  queryClient.refetchQueries(getApprovalKey({ id }));
  queryClient.refetchQueries([getApprovalsKey()[0]]);
  queryClient.refetchQueries([getNotificationsKey()[0]]);
};

export type ModalExtraType = {
  submitAction: UseMutateAsyncFunction<
    ApiResult<ApprovalModel>,
    ApiError,
    ApprovalUpdateInput,
    unknown
  >;
  title: string;
  button: {
    label: string;
    warning?: boolean;
    error?: boolean;
    success?: boolean;
  };
};
