import { Filter } from 'api-hooks/common/model';
import {
  TransactionReportViewModeEnum,
  useGetInfiniteTransactionReport,
} from 'api-hooks/report';
import { ArrowRightIcon } from 'common/assets';
import filterReduce from 'common/helpers/filter-reduce';
import { aMonthBefore } from 'common/utils/date';
import Separator from 'components/common/separator';
import { Button } from 'components/elements/button';
import Select from 'components/elements/select';
import useDrawer from 'hooks/use-drawer';
import { useEntity } from 'hooks/use-entities';
import { isEmpty } from 'lodash';
import { moduleStyles } from 'modules/styles.css';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';

import TransactionReportSummary from './summary';
import TransactionReportUpperFilter from './upper-filter';
import InfiniteReportContainer, {
  getNextPageParam,
} from '../components/infinite-report-container';
import TotalComponent from '../components/total-component';

const REQUIRED_KEY = ['transaction_at_after'];
const HIDE_FILTER_KEY = [
  'transaction_at_after',
  'transaction_at_before',
  'created_at_after',
  'created_at_before',
];

type UpperFilterType = { [key: string]: Date | null };

export default function TransactionReport() {
  const { t } = useTranslation();
  const { entity } = useEntity();
  const [filters, setFilters] = React.useState<Filter[]>([]);
  const [upperfilters, setUpperFilters] = React.useState<UpperFilterType>({});
  const [viewOptionValue, setViewOptionValue] =
    React.useState<TransactionReportViewModeEnum>(
      TransactionReportViewModeEnum.split_transaction,
    );
  const [headerRef, setHeaderRef] = React.useState<HTMLDivElement | null>();

  const drawer = useDrawer();

  const query = useGetInfiniteTransactionReport(
    {
      params: {
        filter: {
          entity_id: entity?.id!,
          ...(isEmpty(upperfilters) && {
            transaction_at_after: aMonthBefore(),
          }),
          ...(filterReduce(filters) as any),
          ...(upperfilters || {}),
        } as unknown as any,
        option: {
          view_mode: viewOptionValue,
        },
      },
    },
    {
      getNextPageParam,
      cacheTime: 0,
      onSuccess: (data) => {
        const hideFilter: UpperFilterType = {};
        const unhideFilter: Filter[] = [];
        ((data.pages?.[0]?.filters || []) as any).forEach((filter: Filter) =>
          HIDE_FILTER_KEY.includes(filter.name)
            ? !!filter.value &&
              (hideFilter[filter.name] = new Date(filter.value))
            : unhideFilter.push(filter as any),
        );

        setFilters(unhideFilter as any);
        setUpperFilters(hideFilter);
      },
    },
  );

  const _params = React.useMemo(
    () => ({
      filter: {
        entity_id: entity?.id!,
        ...(filterReduce(
          query.data?.pages?.[0]?.filters || ([] as any),
        ) as any),
      },
      option: {
        view_mode: viewOptionValue,
      },
    }),
    [entity?.id, query.data?.pages, viewOptionValue],
  );

  const viewOptions = React.useMemo(
    () => [
      {
        value: TransactionReportViewModeEnum.split_transaction,
        label: t('report:split_transaction'),
      },
      {
        value: TransactionReportViewModeEnum.merge_transaction,
        label: t('report:merge_transaction'),
      },
    ],
    [t],
  );

  const onSummary = React.useCallback(
    () =>
      drawer.showCustom(
        TransactionReportSummary({
          filters: query.data?.pages?.[0]?.filters,
          t,
        }),
      ),
    [drawer, query.data?.pages, t],
  );

  const onExtraRenderBottomHeader = React.useCallback(
    (currentData) => (
      <>
        <Separator gap={16} direction="vertical" />
        <div className={moduleStyles.sectionContainer}>
          {currentData?.data?.total?.map((item) => (
            <div className={moduleStyles.oneThirdContainer} key={item.name}>
              <TotalComponent {...item} currency={entity?.currency!} />
            </div>
          ))}
        </div>
        <div className={moduleStyles.row({ justify: 'between' })}>
          <Select
            data={viewOptions}
            onChange={(value) =>
              setViewOptionValue(value as TransactionReportViewModeEnum)
            }
            value={viewOptionValue}
            clearable={false}
          />

          <Button
            onClick={onSummary}
            variant="tertiary"
            rightIcon={(size) => <ArrowRightIcon size={size} />}
          >
            {t('common:show_summary')}
          </Button>
        </div>
      </>
    ),
    [entity?.currency, onSummary, t, viewOptionValue, viewOptions],
  );

  return (
    <InfiniteReportContainer
      header={{
        title: t('common:transaction_report'),
        params: _params,
        category: 'transaction',
      }}
      setHeaderRef={setHeaderRef}
      {...query}
      filters={filters}
      setFilters={setFilters}
      headerOffsetHeight={headerRef?.offsetHeight}
      requiredKeys={REQUIRED_KEY}
      onExtraRenderUpperHeader={() => (
        <TransactionReportUpperFilter
          onSubmit={(values) => setUpperFilters(values)}
          filters={upperfilters}
        />
      )}
      onExtraRenderBottomHeader={onExtraRenderBottomHeader}
    />
  );
}
