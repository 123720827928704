import { useOutcomeMassUpdate } from 'api-hooks/mass-update';
import { useGetOutcomes } from 'api-hooks/outcome';
import UpdateRateComponent from 'modules/transactions/components/update-rate-component';
import React from 'react';

import { useOutcomeUpdateRateColumns } from './column';

interface Props {
  setIsUpdateRate: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OutcomeUpdateRate({ setIsUpdateRate }: Props) {
  const columns = useOutcomeUpdateRateColumns();

  return (
    <UpdateRateComponent
      columns={columns}
      setIsUpdateRate={setIsUpdateRate}
      useGetData={useGetOutcomes}
      useMassUpdate={useOutcomeMassUpdate}
    />
  );
}
