import { AccountLiteModel } from 'api-hooks/account';
import { getIndexInput } from 'api-hooks/common';
import { Expose, Type } from 'class-transformer';

// Model
export class ReconciliationLiteModel {
  id: string;
  description: string | null;

  @Type(() => AccountLiteModel)
  account: AccountLiteModel;

  @Type(() => Number)
  amount: number;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;
}

export class ReconciliationModel extends ReconciliationLiteModel {}

export class ReconciliationSystemList {
  id: string;
  description: string | null;

  @Type(() => AccountLiteModel)
  account: AccountLiteModel;

  @Type(() => Number)
  amount: number;

  @Type(() => Number)
  rate: number;

  @Expose({ name: 'transaction_at' })
  @Type(() => Date)
  transactionAt: Date;

  @Expose({ name: 'is_base_currency' })
  @Type(() => Boolean)
  isBaseCurrency: boolean;
}

// Input
export type getReconciliationInput = {
  id: string;
};

export type getReconciliationsInput = getIndexInput;

export type ReconciliationMutationInput = {
  transactionAt: Date;
  description: string;
  accountId: string;
  systems: string[];
  accounts: string[];
};

export type ReconciliationDeleteInput = {
  reconId: string;
};

export type getReconciliationSystemListInput = getIndexInput<{
  account_id?: string;
}>;
