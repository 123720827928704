import numeral from 'numeral';

export function string2money(
  value: string | number,
  presicion?: number,
): string {
  const fixed = '0'.repeat(presicion !== undefined ? presicion : 2);
  return numeral(`${value}`).format(`0,0.${fixed}`);
}

export function string2moneyDefault(value: string | number): string {
  const countDecimal = String(value).split('.')[1]?.length || 0;
  const fixed = '0'.repeat(countDecimal);
  return numeral(`${value}`).format(`0,0.${fixed}`);
}

export function string2number(value: string | number): string {
  return numeral(`${value}`).format('0,0');
}

export function money2number(value: string): number {
  return numeral(value).value() || 0;
}

export function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export function capitalize(value: string): string {
  const words = value.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(' ');
}

/**
 * Only work for string with number as first appearance
 * ex: 3 days, 3jfdkajfdla
 * @param value string
 * @returns number
 *
 */
export function extractNumber(value: string): number {
  const matches = value.match(/(\d+)/);
  if (matches) {
    return Number(matches[0]);
  }
  return 0;
}

export function removeUnderscore(value: string): string {
  return capitalize(value.replace(/_/g, ' '));
}
