import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiError, ApiResult, MessageResult } from 'api-hooks/common/model';
import { MutationFetchFunction } from 'common/helpers/common';

import {
  AccountDeleteInput,
  AccountModel,
  AccountMutationInput,
  AccountUpdateInput,
} from './model';

export function useCreateAccount(
  options?: UseMutationOptions<
    ApiResult<AccountModel>,
    ApiError,
    AccountMutationInput
  >,
): UseMutationResult<ApiResult<AccountModel>, ApiError, AccountMutationInput> {
  return useMutation<ApiResult<AccountModel>, ApiError, AccountMutationInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: 'accounts',
        method: 'POST',
        classType: AccountModel,
        body,
      });
    },
    options,
  );
}

export function useUpdateAccount(
  options?: UseMutationOptions<
    ApiResult<AccountModel>,
    ApiError,
    AccountUpdateInput
  >,
): UseMutationResult<ApiResult<AccountModel>, ApiError, AccountUpdateInput> {
  return useMutation<ApiResult<AccountModel>, ApiError, AccountUpdateInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `accounts/${body.accountId}`,
        method: 'PUT',
        classType: AccountModel,
        body: body.body,
      });
    },
    options,
  );
}

export function useDeleteAcount(
  options?: UseMutationOptions<MessageResult, ApiError, AccountDeleteInput>,
): UseMutationResult<MessageResult, ApiError, AccountDeleteInput> {
  return useMutation<MessageResult, ApiError, AccountDeleteInput>(
    async function (body) {
      return await MutationFetchFunction({
        url: `accounts/${body.accountId}`,
        method: 'DELETE',
      });
    },
    options,
  );
}
