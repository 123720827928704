import {
  EntityMutationInput,
  useDeleteEntity,
  getEntitiesKey,
} from 'api-hooks/entity';
import { AuthorizationRules } from 'common/constants';
import formSetErrors from 'common/helpers/form-setError';
import notification from 'common/helpers/notification';
import { queryClient } from 'common/repositories/query-client';
import FormContent from 'components/common/form-content';
import Form, { FormState } from 'components/elements/form';
import FormHeader from 'components/widgets/form-header';
import { useAuthorization } from 'hooks/use-authorization';
import useDialog from 'hooks/use-dialog';
import { useEntity } from 'hooks/use-entities';
import useNavigation from 'hooks/use-navigation';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import EntityAccountsInformation from './entity-accounts-information';
import EntityCurrencyInformation from './entity-currency-information';
import EntityInformationForm from './entity-information-form';
import { EntityFormProps } from './form-type';

const EntityForm = (props: EntityFormProps) => {
  const { t } = useTranslation();
  const { entity } = props;
  const { entity: userEntity } = useEntity();
  const dialog = useDialog();
  const { close, setNavigations } = useNavigation();
  const { mutateAsync: deleteMutate } = useDeleteEntity();
  const { can } = useAuthorization();

  const resolver = useYupValidationResolver(
    Yup.object().shape({
      name: Yup.string().nullable().required(),
      description: Yup.string(),
      currencyId: Yup.string().nullable().required(),
    }),
  );

  const intialValues = React.useMemo<EntityMutationInput>(() => {
    return {
      currencyId: entity?.currencyId || '',
      description: entity?.description || '',
      name: entity?.name || '',
    };
  }, [entity]);

  const methods = useForm<EntityMutationInput>({
    resolver,
    mode: 'onChange',
    defaultValues: intialValues,
  });

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        await props.onSubmit(values as EntityMutationInput, methods as any);
      } catch (e: any) {
        if (e.errors) {
          formSetErrors(e.errors, methods.setError);
        }
      }
    },
    [methods, props],
  );

  const onDelete = React.useCallback(async () => {
    dialog.showConfirmation({
      message: t('common:confirmation_delete_text'),
      title: t('common:confirmation'),
      onPositiveAction: async (dismiss) => {
        try {
          dismiss();
          if (entity?.id === userEntity?.id!) {
            notification.error({
              message: t('entity:cant_delete'),
            });
          } else {
            await deleteMutate({
              entityId: entity!.id,
            });
            queryClient.refetchQueries([getEntitiesKey()[0]]);
            close();
            notification.success({ message: t('common:successfully_deleted') });
          }
        } catch (error) {
          notification.error({ message: error.message });
        }
      },
      onNegativeAction: (dismiss) => {
        dismiss();
      },
    });
  }, [close, deleteMutate, dialog, entity, t, userEntity?.id]);

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      defaultEditable={!entity}
      setNavigations={setNavigations}
      navigation={props.navigation}
    >
      <FormContent>
        {props.renderError}
        <FormHeader
          onDelete={onDelete}
          data={entity}
          title={
            entity
              ? `${t('common:entity')} - ${entity?.name}`
              : t('common:create_extra', { extra: t('common:entity') })
          }
          noDelete={!can(AuthorizationRules.EntitiesDelete)}
          noEdit={!can(AuthorizationRules.EntitiesUpdate)}
        />
        <EntityInformationForm hasValue={!!entity} />
        <FormState>
          {({ editable }) => (
            <>
              {!editable && entity && (
                <div style={{ marginTop: 48 }}>
                  <EntityCurrencyInformation data={entity.entityCurrencies} />
                  <EntityAccountsInformation data={entity.accounts} />
                </div>
              )}
            </>
          )}
        </FormState>
      </FormContent>
    </Form>
  );
};

export default EntityForm;
