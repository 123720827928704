import { getIndexInput } from 'api-hooks/common/model';
import { Expose, Type } from 'class-transformer';

// Model
export class AssetModel {
  id: string;
  name: string;

  @Expose({ name: 'has_asset' })
  hasAsset: boolean;
}

export class StockItemCodeModel {
  id: string;
  code: string;
  name: string;
  type: string;

  @Expose({ name: 'entity_id' })
  entityId: string;
}

export class StockLiteModel {
  id: string;
  name: string;
  code: string;
  unit: string;
  description: string | null;

  @Type(() => AssetModel)
  asset: AssetModel;

  @Expose({ name: 'is_usable' })
  isUsable: boolean;

  @Expose({ name: 'is_asset' })
  isAsset: boolean;
}

export class StockModel extends StockLiteModel {
  @Expose({ name: 'stock_item_code_component' })
  @Type(() => StockItemCodeModel)
  stockItemCodeComponent: StockItemCodeModel;

  @Expose({ name: 'stock_item_code_group' })
  @Type(() => StockItemCodeModel)
  stockItemCodeGroup: StockItemCodeModel;

  @Expose({ name: 'stock_item_code_item' })
  @Type(() => StockItemCodeModel)
  stockItemCodeItem: StockItemCodeModel;

  @Expose({ name: 'stock_item_code_stated' })
  @Type(() => StockItemCodeModel)
  stockItemCodeStated: StockItemCodeModel;
}

export class StockBarcodeModel {
  barcodes: string[];
}

// Input
export class getStockInput {
  stockId: string;
}

export type getStocksInput = getIndexInput;

export type getStockBarcodeInput = {
  body: {
    transactionAt: Date;
    qty: number;
  };
  stockId: string;
};

export type StockMutationInput = {
  name: string;
  unit: string;
  isAsset: boolean;
  isUsable: boolean;
  assetId: string;
  description: string;
  entityId: string;
  stockItemCodeGroupId: string;
  stockItemCodeItemId: string;
  stockItemCodeStatedId: string;
  stockItemCodeComponentId: string;
};

export type StockUpdateInput = {
  stockId: string;
  body: StockMutationInput;
};

export type StockDeleteInput = {
  stockId: string;
};
