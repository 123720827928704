import {
  EmployeeLiteModel,
  EmployeeModel,
  getEmployeeKey,
  getEmployeesKey,
  useGetEmployee,
  useGetEmployees,
} from 'api-hooks/employee';
import { QueryTransformer } from 'common/helpers/common';
import { Input } from 'components/elements/field';
import { OptionProps } from 'components/elements/select';
import useSelectInputHelper from 'hooks/use-select-input-helper';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
function transformer(item: EmployeeModel | EmployeeLiteModel): OptionProps {
  return {
    value: item.id,
    label: item.name,
  };
}

interface Props {
  name: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  onClose?: () => void;
  onAfterChange?: (value?: any) => void;
  onAfterDetailChange?: (value: any) => void;
  disabled?: boolean;
  noMargin?: boolean;
  removeArr?: string[];
  className?: any;
  division?: string;
}

const EmployeeSelectInput = (props: Props) => {
  const { t } = useTranslation();
  const { label = t('common:employee'), division } = props;
  const { control } = useFormContext<any>();
  const { field } = useController({ control, name: props.name });

  const selectProps = useSelectInputHelper({
    value: field.value,
    onSelectItem: (value) => {
      field.onChange(value);
    },
    useListQueryHook: useGetEmployees,
    useDetailLazyQueryHook: useGetEmployee,
    getListsKey: React.useCallback(
      (page, q) =>
        getEmployeesKey({
          params: {
            q,
            page,
            ...(division && {
              filter: {
                division,
              },
            }),
          },
        }),
      [division],
    ),
    getMemoizedListVariables: React.useCallback(
      (page, q) => ({
        params: {
          q,
          page,
          ...(division && {
            filter: {
              division,
            },
          }),
        },
      }),
      [division],
    ),
    renderCreate: false,
    getDetailKey: React.useCallback(
      (value) =>
        getEmployeeKey({
          id: value,
        }),
      [],
    ),
    getMemoizedDetailVariables: React.useCallback(
      (value: string) => ({
        id: value,
      }),
      [],
    ),
    listTransformer(result) {
      const list = result.data.map((v) => transformer(v));
      return props.removeArr
        ? list.filter((res) => !props.removeArr?.includes(res.value))
        : list;
    },
    detailTransformer(result) {
      const newResult = QueryTransformer(result, EmployeeModel);
      props.onAfterChange?.(newResult.data);
      return transformer(result.data);
    },
    paginationTransformer(result) {
      return result.meta;
    },
  });

  return (
    <Input
      type="select"
      name={props.name}
      required={props.required}
      label={label}
      className={props.className}
      placeholder={props.placeholder}
      noMargin={props.noMargin}
      {...selectProps}
      disabled={props.disabled}
    />
  );
};

export default EmployeeSelectInput;
