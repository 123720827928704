import { BrandModel } from 'api-hooks/brand';
import { FileResultModel } from 'api-hooks/common';
import { CommonSimpleModel, getIndexInput } from 'api-hooks/common/model';
import { DivisionModel } from 'api-hooks/division';
import { PurchaseRequisitionsModel } from 'api-hooks/purchase-requisitions';
import { Expose, Type } from 'class-transformer';

// Enum
export enum ApprovalStatusQueryEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  NEED_REVISION = 'need_revision',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum ApprovalActionEnum {
  REJECT = 'reject',
  APPROVE = 'approve',
  CANCEL = 'cancel',
  PENDING = 'pending',
  DEADLINE = 'deadline',
}

// Model
export class ApprovalLiteModel {
  id: string;
  title: string;
  code: string;
  model: string;
  status: string;
  description: string;

  division: DivisionModel;
  employee: CommonSimpleModel;

  @Expose({ name: 'completed_by' })
  completedBy: CommonSimpleModel;

  @Expose({ name: 'forwarded_divisions' })
  forwardedDivisions: DivisionModel;

  @Expose({ name: 'is_complete' })
  isComplete: boolean;

  @Expose({ name: 'mark_done_permission' })
  markDonePermission: boolean;

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  requestedAt: Date;

  @Expose({ name: 'approved_at' })
  @Type(() => Date)
  approvedAt: Date;

  @Expose({ name: 'deadline_at' })
  @Type(() => Date)
  deadlineAt: Date | null;
}

export class ApprovalHistory {
  id: string;
  status: string;
  description: string;
  process: string;
  employee: CommonSimpleModel;
  division: CommonSimpleModel;

  @Expose({ name: 'action_at' })
  @Type(() => Date)
  actionAt: Date;

  @Expose({ name: 'approval_at' })
  @Type(() => Date)
  approvalAt: Date;

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  requestedAt: Date | null;
}

export class ForwardedDivisionApprovalModel {
  division: CommonSimpleModel;
  employee: CommonSimpleModel;
  id: string;
}

export class ApprovalModel {
  id: string;
  model: string;
  code: string;
  action: string[];
  status: string;
  description: string;
  title: string;
  files: FileResultModel[];
  division: DivisionModel;

  @Type(() => BrandModel)
  brand: BrandModel;

  @Expose({ name: 'forwarded_division' })
  forwardedDivision: ForwardedDivisionApprovalModel;

  @Expose({ name: 'forwarded_divisions' })
  @Type(() => DivisionModel)
  forwardedDivisions: DivisionModel[];

  employee: CommonSimpleModel;

  @Expose({ name: 'approvalable_type' })
  approvalableType: string;

  @Expose({ name: 'approvalable_id' })
  approvalableId: string;

  @Expose({ name: 'created_at' })
  @Type(() => Date)
  createdAt: Date;

  @Expose({ name: 'deadline_at' })
  @Type(() => Date)
  deadlineAt: Date;

  @Expose({ name: 'approval_histories' })
  @Type(() => ApprovalHistory)
  approvalHistories: ApprovalHistory[];

  @Expose({ name: 'requested_at' })
  @Type(() => Date)
  requestedAt: Date;

  @Expose({ name: 'request_form' })
  @Type(() => PurchaseRequisitionsModel)
  requestForm: PurchaseRequisitionsModel;
}

// Input
export class getApprovalInput {
  id: string;
}

export class ApprovalUpdateInput {
  id: string;
}

export class ApprovalApproveInput {
  id: string;
  description: string;
  divisionId: string;
}

export class ApprovalNeedRevisionInput {
  note: string;
  id: string;
}

export class ApprovalMarkInput {
  approvalId: string;
  body: {
    isComplete: boolean;
  };
}
export type getApprovalsInput = getIndexInput<{ status?: string }>;
