import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { ApiError, ApiResult, ExtendedApiResult } from 'api-hooks/common/model';
import { QueryFetchFunction, QueryTransformer } from 'common/helpers/common';

import {
  BudgetingLiteModel,
  BudgetingModel,
  getBudgetingInput,
  getBudgetingsInput,
} from './model';

export function useGetBudgetings(
  input?: getBudgetingsInput,
  options?: UseQueryOptions<ExtendedApiResult<BudgetingLiteModel[]>, ApiError>,
): UseQueryResult<ExtendedApiResult<BudgetingLiteModel[]>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getBudgetings', input?.params],
      () =>
        QueryFetchFunction({
          url: 'budgetings',
          params: input?.params,
        }),
      options,
    ),
    BudgetingLiteModel,
  );
}

export function useGetBudgeting(
  input?: getBudgetingInput,
  options?: UseQueryOptions<ApiResult<BudgetingModel>, ApiError>,
): UseQueryResult<ApiResult<BudgetingModel>, ApiError> {
  return QueryTransformer(
    useQuery(
      ['getBudgeting', input?.id],
      () =>
        QueryFetchFunction({
          url: `budgetings/${input?.id}`,
        }),
      options,
    ),
    BudgetingModel,
  );
}

export function getBudgetingsKey(input?: getBudgetingsInput) {
  return ['getBudgetings', input?.params];
}

export function getBudgetingKey(input?: getBudgetingInput) {
  return ['getBudgeting', input?.id];
}
